import React from "react";
import CustomIcon from "../UI-components/CustomIcon";

const ButtonExport = (props) => {
   return (
      <div className="button-export-container" onClick={props.handleClick} style={props.style}>
         <CustomIcon
                           iconContClass="button-icon-container-export"
                           iconClass={props.iconClass}
                  />
         <br/>
         <button
            className={
               "button button-export-image " + props.btnClass + (props.disabled ? " disabled" : "")
            }
            onKeyDown={props.onKeyDown}
            onKeyPress={props.onKeyPress}
            disabled={props.disabled}
            type={props.type}
         >
            {props.iconBefore ? <span className={"icon " + props.icon} /> : null}
            {props.children}
            {props.iconAfter ? <span className={"icon " + props.icon} /> : null}
         </button>
      </div>
   );
};
export default ButtonExport;
