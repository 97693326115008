import { useEffect, useState } from "react"

import Button from "../../UI-components/Button"
import { CLIENT_CLASSIFICATION_ALL } from "../../../config"
import CustomInput from "../../UI-components/CustomInput"
import Loader from "../../UI-components/Loader"
import Popup from "../../UI-components/Popup"
import axios from "axios"
import { getCookie } from "../../../services/cookie"

const CheckboxClientClassification = ({
    item,
    handleChange
}) => {
    return (
        <div className="inner-box">
            <label className="container">
                <input
                    type="checkbox"
                    name={item.classificationName}
                    value={item.classificationId}
                    checked={item.isChecked}
                    onChange={(e) => handleChange(e.target.value)}
                />
                <span className="checkmark" />
                <p className="inner-text">{item.classificationName}</p>
            </label>
        </div>
    )
}

const PopupClusterClassification = ({
    classificationList,
    addClassification,
    clusterId
}) => {

    const [showPopup, setShowPopup] = useState(false)
    const [isLoadingClassificationList, setIsLoadingClassificationList] = useState(false)
    const [globalClassificationList, setGlobalClassificationList] = useState([])
    const [query, setQuery] = useState("")

    const handleAddRemoveClientClassification = (classificationId) => {
        const newClassificationList = globalClassificationList.map(elem => {
            if (elem.classificationId === classificationId) {
                return ({
                    ...elem,
                    isChecked: !elem.isChecked
                })
            }
            return elem
        })
        setGlobalClassificationList(newClassificationList);
    }

    const confirmClassificationList = () => {
        const selectedIds = globalClassificationList
            .filter(elem => elem.isChecked)
            .map(elem => ({
                classificationId: elem.classificationId
            }));
        addClassification(selectedIds, clusterId);
        setQuery("")
        setShowPopup(false)
    }

    const openPopup = () => {
        setShowPopup(true)
    }

    const closePopup = () => {
        setQuery("")
        setShowPopup(false)
    }

    const handleClientClassificationSearch = (e) => {
        const searchedLabel = e.target.value;
        setQuery(searchedLabel)
    }

    const searchClientClassification = () => {
        return globalClassificationList.filter(elem => {
            return elem.classificationLabel.toLowerCase().includes(query.toLowerCase());
        })
    }

    const sortClassificationLabel = (class1, class2) => {
        if(class1.classificationLabel > class2.classificationLabel) return 1
        else if(class1.classificationLabel < class2.classificationLabel) return -1
        else return 0
    }

    const displayContent = (classifications) => {
        if(classifications.length === 0){
            return <p>No options</p>
        }
        else{
            return classifications.map(elem => {
                return (
                    <CheckboxClientClassification
                        key={elem.classificationId}
                        item={{
                            classificationId: elem.classificationId,
                            classificationName: elem.classificationLabel,
                            isChecked: elem.isChecked
                        }}
                        handleChange={handleAddRemoveClientClassification}
                    />
                )
            })
        }
    }

    useEffect(() => {
        if (showPopup) {
            setIsLoadingClassificationList(true)
            axios({
                url: CLIENT_CLASSIFICATION_ALL,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken")
                }
            })
                .then((response) => {
                    const clusterIds = classificationList.map(elem => elem.classificationId)
                    const list = response.data.map(elem => (
                        {
                            classificationId: elem.classificationId,
                            classificationLabel: elem.classificationLabel,
                            isChecked: clusterIds.includes(elem.classificationId)
                        }
                    ))
                    list.sort(sortClassificationLabel)
                    setGlobalClassificationList(list)
                    setIsLoadingClassificationList(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoadingClassificationList(false)
                })
        }
    }, [showPopup, classificationList])

    return (
        <div className="wrapper-lia">
            <Button
                btnClass="btn-lia btn-medium"
                icon="icon-arrow2"
                handleClick={openPopup}
                iconAfter
            >
                Client classification
            </Button>
            {
                showPopup &&
                <Popup
                    title={"Edit client classification"}
                    popupClass={"popup-medium"}
                    close={closePopup}
                    isDoubleButton={true}
                    btnName="Confirm"
                    btnName2="Cancel"
                    handleClick={confirmClassificationList}
                >
                    {
                        isLoadingClassificationList ?
                            <div style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <Loader />
                            </div>
                            :
                            <>
                                <CustomInput
                                    type="text"
                                    placeholder="Search for a Client Classification"
                                    inputClass={`input-big`}
                                    value={query}
                                    handleChange={handleClientClassificationSearch}
                                    maxLength={15}
                                />
                                {
                                    displayContent(searchClientClassification())
                                }
                            </>
                    }
                </Popup>
            }
        </div>
    )
}

export default PopupClusterClassification