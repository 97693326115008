import React, { Component } from "react";
import { connect } from "react-redux";

class CheckBoxLia extends Component {
  checkClusterLias = () => {
    this.props.clusterLias.map((clusterLia) => {
      return this.handleChange(null, clusterLia);
    });
  };

  componentDidMount() {
    this.checkClusterLias();
  }

  handleChange = (e = null, clusterLia, isAllchecked = false) => {
    const itemName = e ? e.target.name : clusterLia.liaCode;
    const checked = e ? e.target.checked : true;
    const list = this.props.popupState.list;
    let newList;

    newList = this.props.allLia.map((item, i) => {
      if (item.liaCode === itemName || isAllchecked) {
        return {
          liaCode: item.liaCode,
          planningChannelId: item.planningChannelId,
          isChecked: checked,
        };
      } else {
        return list[i];
      }
    });
    this.props.setList(newList);
  };

  render() {
    const LiaItem = ({ item }) => {
      return item.isChecked || !this.props.isFiltered ? (
        <div>
          <div className="inner-box">
            <label className="container">
              <input
                type="checkbox"
                name={item.liaCode}
                value={item.liaCode}
                checked={item.isChecked}
                onChange={this.handleChange}
              />
              <span className="checkmark" />
              <p className="inner-text">{item.liaCode}</p>
              <p className="inner-text">{item.planningChannelId}</p>
            </label>
          </div>
        </div>
      ) : null;
    };

    const allChecked = this.props.popupState.list.every(
      (item) => item.isChecked
    );

    return (
      <div
        className={
          "popup-lia" + (this.props.readOnly ? " popup-lia--read-only" : "")
        }
      >
        <div className="outer-box">
          <label className="container">
            <input
              type="checkbox"
              name="checkAll"
              checked={allChecked}
              onChange={(e) => this.handleChange(e, null, true)}
            />
            <span className="checkmark" />
            <p className="outer-text">LIA ID</p>
            <p className="outer-text">LIA Label</p>
          </label>
        </div>
        {this.props.popupState.list.map((item) => (
          <LiaItem
            key={`${item.liaCode} ${item.isChecked ? "1" : "0"}`}
            item={item}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //clusterLias: state.cluster.cluster.lias,
    allLia: state.cluster.lia,
  };
};

export default connect(mapStateToProps)(CheckBoxLia);
