import React from "react";
import Button from "../../UI-components/Button";
import ManageUserPopupContent from "./ManageUserPopupContent";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";

class ManageUserPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "view",
      isSaveDisabled: false,
      isUserEnabled: true,
    };
  }

  componentDidMount = () => {
    this.setState({
      isUserEnabled: this.props.currentUser.status === "Enabled",
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.grants !== this.props.grants && this.props.saveLoading) {
      this.checkEnableSave();
    }
  }

  handleSelectedTab = (value) => {
    this.setState({ selectedTab: value });
  };

  handleChangeStatus = () => {
    this.props.getStatusUser(
      this.props.currentUser.userId,
      this.state.isUserEnabled
    );
    this.setState({
      isUserEnabled: !this.state.isUserEnabled,
    });
  };

  handleSaveUser = () => this.props.saveUser(this.props.currentUser);

  checkEnableSave = () => {
    let { grants } = this.props;
    let isPlChInView = false;
    let isBrandInView = false;
    let isPlChInEdit = false;
    let isBrandInEdit = false;
    let isPlChInSubmit = false;
    let isBrandInSubmit = false;

    for (let i = 0; i < grants.length; i++) {
      switch (grants[i].permissionType) {
        case "view":
          if (grants[i].grantType === "brand") {
            isBrandInView = true;
          } else if (grants[i].grantType === "starszone") {
            isPlChInView = true;
          }
          break;
        case "edit":
          if (grants[i].grantType === "brand") {
            isBrandInEdit = true;
          } else if (grants[i].grantType === "starszone") {
            isPlChInEdit = true;
          }
          break;
        case "submit":
          if (grants[i].grantType === "brand") {
            isBrandInSubmit = true;
          } else if (grants[i].grantType === "starszone") {
            isPlChInSubmit = true;
          }
          break;
        default:
      }
    }

    if ((isBrandInView || isPlChInView) && isBrandInView !== isPlChInView) {
      return this.setState({
        isSaveDisabled: true,
      });
    }

    if ((isBrandInEdit || isPlChInEdit) && isBrandInEdit !== isPlChInEdit) {
      return this.setState({
        isSaveDisabled: true,
      });
    }

    if (
      (isBrandInSubmit || isPlChInSubmit) &&
      isBrandInSubmit !== isPlChInSubmit
    ) {
      return this.setState({
        isSaveDisabled: true,
      });
    }

    this.setState({
      isSaveDisabled: false,
    });
  };

  render() {
    let renderTabView =
      this.state.selectedTab === "view" ? "single-tab-active" : "";
    let renderTabEdit =
      this.state.selectedTab === "edit" ? "single-tab-active" : "";
    let renderTabSubmit =
      this.state.selectedTab === "submit" ? "single-tab-active" : "";

    let currentUser = this.props.currentUser.userId;

    // let grantTypeFromGrants = this.props.grants.map((grant) => {
    //   return grant.grantType;
    // });

    // let deleteGrantType = grantTypeFromGrants.splice(1);

    let disbledSave = () => {
      const brandView = this.props.grants.some((grant) => {
        return grant.grantType === "brand" && grant.permissionType === "view";
      });
      const starzoneView = this.props.grants.some((grant) => {
        return (
          grant.grantType === "starszone" && grant.permissionType === "view"
        );
      });

      const view = (brandView && starzoneView) || (!brandView && !starzoneView);

      const brandEdit = this.props.grants.some((grant) => {
        return grant.grantType === "brand" && grant.permissionType === "edit";
      });
      const starzoneEdit = this.props.grants.some((grant) => {
        return (
          grant.grantType === "starszone" && grant.permissionType === "edit"
        );
      });

      let edit = (brandEdit && starzoneEdit) || (!brandEdit && !starzoneEdit);

      const brandSubmit = this.props.grants.some((grant) => {
        return grant.grantType === "brand" && grant.permissionType === "submit";
      });
      const starzoneSubmit = this.props.grants.some((grant) => {
        return (
          grant.grantType === "starszone" && grant.permissionType === "submit"
        );
      });

      let submit =
        (brandSubmit && starzoneSubmit) || (!brandSubmit && !starzoneSubmit);

      return view && edit && submit;
    };

    return (
      <div className="manage-user-popup-wrapper">
        <div className="popup-background" onClick={this.props.close} />
        <div className="popup-container-user">
          <div className="close-popup" onClick={this.props.close}>
            <span className="icon-plus" />
          </div>
          <div className="popup-header">
            <div className="section-container">
              <div className="header-container">
                <div>
                  <h3>{currentUser ? currentUser : null}</h3>
                  <Button
                    btnClass="btn-medium"
                    handleClick={this.handleChangeStatus}
                    disabled={this.props.saveLoading}
                  >
                    {this.state.isUserEnabled ? "Enabled" : "Disabled"}
                  </Button>
                </div>
                <div>
                  <Button
                    btnClass="btn-medium"
                    handleClick={this.handleSaveUser}
                    disabled={
                      this.props.saveLoading ||
                      this.state.isSaveDisabled ||
                      !this.state.isUserEnabled ||
                      !disbledSave()
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
              <div className="tab-container">
                <div className="tab-header">
                  <div
                    onClick={() => this.handleSelectedTab("view")}
                    className={`single-tab ${renderTabView} `}
                  >
                    <h4>View</h4>
                  </div>
                  <div
                    onClick={() => this.handleSelectedTab("edit")}
                    className={`single-tab ${renderTabEdit} `}
                  >
                    <h4>Edit</h4>
                  </div>
                  <div
                    onClick={() => this.handleSelectedTab("submit")}
                    className={`single-tab ${renderTabSubmit} `}
                  >
                    <h4>Submit</h4>
                  </div>
                  <div className="single-tab" />
                </div>
                <ManageUserPopupContent
                  tabType={this.state.selectedTab}
                  brandList={this.props.brandList}
                  starsZoneList={this.props.starsZoneList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    grants: state.user.currentUser.grants,
    currentUser: state.user.currentUser,
    saveLoading: state.user.saveLoading,
    statusUser: state.user.statusUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: (currentUser) => dispatch(actions.saveUser(currentUser)),
    getStatusUser: (user, isEnabled) =>
      dispatch(actions.getStatusUser(user, isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserPopup);
