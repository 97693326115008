import * as _ from "lodash";
import * as actions from "../../../store/actions";

import React, { Component } from "react";
import {
  canCreate,
  filterResults,
  handleSearchResults
} from "../../../services/Api";

import AddClusterPopup from "../Popup/AddClusterPopup";
import CustomSearch from "../../UI-components/CustomSearch";
import CustomSelect from "../../UI-components/CustomSelect";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ListItem from "./ListItem";
import ListItemHome from "./ListItemHome";
import { connect } from "react-redux";
import Button from "../../UI-components/Button";
import { OPENED_CLUSTER_LIMIT } from "../../../config";

class ListCluster extends Component {
  state = {
    isAddClusterOpen: false,
    selectedClusters: [],
    listItem: [],
    valueSearch: "",
    options: {
      customerSegmentation: [],
      starsCustomer: [],
      rangeFacing: [],
      storeId: [],
      codiceStoreSap: [],
      publishDate: [],
      status: [],
      classification: []
    },
    valuesFilters: {
      customerSegmentation: null,
      starsCustomer: null,
      rangeFacing: null,
      storeId: null,
      codiceStoreSap: null,
      publishDate: null,
      status: null,
      classification: null
    },
    isClusterClicked: false,
  };

  componentDidMount = () => {
    if (this.props.listItem) {
      this.setState({
        listItem: [...this.props.listItem],
      });
    }
    this.props.getClusterFiltersOptions(this.props.selectedFilters);
  };

  componentDidUpdate = (prevProps) => {

    if (prevProps.clientClassificationPopupVisible !== this.props.clientClassificationPopupVisible) {
      if (!this.props.clientClassificationPopupVisible) {
        this.props.getClusterFiltersOptions(this.props.selectedFilters);
      }
    }
    if (prevProps.listItem !== this.props.listItem)
      this.setState({
        listItem: [...this.props.listItem],
      });

    if (prevProps.options !== this.props.options) {
      this.setState({
        options: _.cloneDeep(this.props.options),
      });
    }

    if (
      this.state.isClusterClicked
    ) {
      this.props.history.push("/cluster");
      this.setState({
        isClusterClicked: false,
      });
    }
  };

  selectClusters = (singleItem) => {
    let selectedClusters = [...this.state.selectedClusters]
    const clusterLabels = selectedClusters.map(cluster => cluster.clusterLabel)

    if (clusterLabels.includes(singleItem.clusterLabel) && this.state.selectedClusters.length <= OPENED_CLUSTER_LIMIT) {
      selectedClusters = selectedClusters.filter(cluster => cluster.clusterLabel !== singleItem.clusterLabel)
    }
    else if(!clusterLabels.includes(singleItem.clusterLabel) && this.state.selectedClusters.length < OPENED_CLUSTER_LIMIT) {
      selectedClusters.push(singleItem)
    }
    this.setState({
      selectedClusters: selectedClusters
    })
  }

  checkIfSelected = (singleItem) => {
    return this.state.selectedClusters.map(cluster => cluster.clusterLabel).includes(singleItem.clusterLabel)
  }

  goToCluster = () => {
    const viewer = this.state.selectedClusters.map(singleItem => {
      const viewerOfSingleItem = (singleItem.userLock && (this.props.user.user_name !== singleItem.userLock)) ? true : false
      return viewerOfSingleItem
    }).reduce((a, b) => a || b)
    
    this.props.getClusterWithLock(this.state.selectedClusters, () => { }, viewer);
    this.setState({
      isClusterClicked: true, // il componente reagisce e mi porta a /cluster
    });
  };

  selectAll = () => {
    let selectedClusters = this.state.listItem.slice(0, OPENED_CLUSTER_LIMIT)
    this.setState({
      selectedClusters
    })
  }

  deselectAll = () => {
    let selectedClusters = []
    this.setState({
      selectedClusters
    })
  }

  togglePopup = () =>
    this.setState((prevState) => {
      return { isAddClusterOpen: !prevState.isAddClusterOpen };
    });

  handleSearch = (e) => {
    let valueObj = handleSearchResults(e, this.props.listItem, "cluster");

    this.setState({
      listItem: valueObj.listItem,
      valueSearch: valueObj.valueSearch,
    });
  };

  handleChangeOptions = (valueArray, type) => {
    let valueSearch = this.state.valueSearch,
      valuesFilters = { ...this.state.valuesFilters },
      filtersObj,
      listObj;

    // check search results
    let { listItem } = handleSearchResults(
      valueSearch,
      this.props.listItem,
      "cluster"
    );
    // filter results
    filtersObj = filterResults(listItem, valuesFilters, valueArray, type, true);
    //check publish date
    if (valuesFilters.publishDate)
      listObj = handleSearchResults(
        valuesFilters.publishDate,
        filtersObj.filteredResults,
        "date"
      );

    this.setState({
      listItem: listObj ? listObj.listItem : filtersObj.filteredResults,
      valuesFilters: filtersObj.selectedFilters,
    });
  };

  onChangeDatePicker = (publishDate) => {
    let valuesFilters = { ...this.state.valuesFilters },
      filtersObj,
      listObj;

    if (publishDate === null) {
      let valueSearch = this.state.valueSearch;
      let { listItem } = handleSearchResults(
        valueSearch,
        this.props.listItem,
        "cluster"
      );
      filtersObj = filterResults(
        listItem,
        valuesFilters,
        [],
        "publishDate",
        true
      );
      //check date
      listObj = handleSearchResults(
        publishDate,
        filtersObj.filteredResults,
        "dateRange"
      );
    } else {
      listObj = handleSearchResults(
        publishDate,
        this.state.listItem,
        "dateRange"
      );
    }

    this.setState({
      listItem: listObj.listItem,
      valuesFilters: {
        ...valuesFilters,
        publishDate,
      },
    });
  };

  redirect = () => this.props.history.push("/cluster");

  handleDeleteCluster = (singleCluster) => {
    const selectedCluster = _.cloneDeep(singleCluster);
    selectedCluster.status = "DELETED";
    selectedCluster.userLock = this.props.user.user_name;
    this.props.deleteCluster(selectedCluster, this.props.selectedFilters);
  };

  render() {
    const notDeletedItems = this.state.listItem.filter(
      (cluster) => cluster.status !== "DELETED"
    );
    const listItem = notDeletedItems.map((singleItem) => {
      let isSelected = this.checkIfSelected(singleItem);
      return(
        <ListItemHome
          item={singleItem}
          type="cluster"
          key={singleItem.clusterId}
          handleClick={() => this.selectClusters(singleItem)}
          iconClass="icon-arrow2"
          iconClassTrash="icon-cestino"
          iconClassWarning="icon-triangle-black"
          deleteItem={this.handleDeleteCluster}
          listItemClass={isSelected ? "selected" : ""}
        >
          {singleItem.clusterLabel}
        </ListItemHome>
      )})

    let options = this.state.options;
    let valuesFilters = this.state.valuesFilters;

    return (
      <React.Fragment>
        <div className="list-component col-4 module">
          <div className="list-content component">
            <div className="list-container-element">
              <header>
                <h3>Cluster Selection</h3>
                <CustomSearch
                  placeholder="Cluster Label"
                  handleChange={this.handleSearch}
                  value={this.state.valueSearch}
                />
              </header>
              <div className="filter-list">
                <div className="row no-gutters">
                  <div className="col-6">
                    <CustomSelect
                      placeholder="Client Name"
                      options={options.starsCustomer}
                      isMulti
                      handleChange={(e) =>
                        this.handleChangeOptions(e, "starsCustomer")
                      }
                      value={valuesFilters.starsCustomer}
                    />
                    <CustomSelect
                      placeholder="SAP Number"
                      options={options.codiceStoreSap}
                      isMulti
                      handleChange={(e) =>
                        this.handleChangeOptions(e, "codiceStoreSap")
                      }
                      value={valuesFilters.codiceStoreSap}
                    />
                    <CustomSelect
                      placeholder="Store id"
                      options={options.storeId}
                      isMulti
                      handleChange={(e) => this.handleChangeOptions(e, "storeId")}
                      value={valuesFilters.storeId}
                    />
                    <CustomSelect
                      placeholder="Status"
                      options={options.status}
                      handleChange={(e) => this.handleChangeOptions(e, "status")}
                      value={valuesFilters.status}
                      isMulti
                    />
                  </div>
                  <div className="col-6">
                    <CustomSelect
                      placeholder="Range Facing"
                      options={options.rangeFacing}
                      handleChange={(e) =>
                        this.handleChangeOptions(e, "rangeFacing")
                      }
                      isMulti
                      value={valuesFilters.rangeFacing}
                    />
                    <CustomSelect
                      placeholder="Segmentation"
                      options={options.customerSegmentation}
                      isMulti
                      handleChange={(e) =>
                        this.handleChangeOptions(e, "customerSegmentation")
                      }
                      value={valuesFilters.customerSegmentation}
                    />
                    <CustomSelect
                      placeholder="Client Classification"
                      options={options.classification}
                      isMulti
                      handleChange={(e) =>
                        this.handleChangeOptions(e, "classification")
                      }
                      value={valuesFilters.classification}
                    />
                    <DateRangePicker
                      onChange={this.onChangeDatePicker}
                      value={this.state.valuesFilters.publishDate}
                      format="dd/MM/y"
                      calendarIcon={null}
                    />
                  </div>
                </div>
              </div>
              <div className="main-content">
                {
                this.state.selectedClusters.length > 0 && 
                  <p style={{
                    textAlign: "left",
                    paddingLeft: "24px",
                    color: this.state.selectedClusters.length === OPENED_CLUSTER_LIMIT ? "red" : "black"
                  }}>
                    You selected {this.state.selectedClusters.length} out of {this.state.listItem?.length} clusters
                  </p>
                }
                <ul className="list">
                  <ListItem
                    handleClick={() => this.togglePopup("isAddClusterOpen")}
                    isDisabled={
                      this.props.auth &&
                      this.props.auth.grants &&
                      !canCreate(
                        this.props.auth.grants,
                        !!this.props.selectedFilters.brand.length &&
                        this.props.selectedFilters.brand
                      )
                    }
                    listItemClass={
                      "list-item-black" + (this.props.isEditable ? "" : " close")
                    }
                    iconClass="icon-plus"
                  >
                    Create New Cluster
                  </ListItem>
                  {listItem}
                </ul>
              </div>
              <div className="module-footer">
                <div className="outer-box-btn">
                  <div className="btn-box">
                    <Button
                      btnClass="btn-medium"
                      disabled={this.state.selectedClusters.length === 0}
                      handleClick={this.goToCluster}
                    >
                      Open Selected
                    </Button>
                    <Button
                      btnClass="btn-medium"
                      handleClick={this.selectAll}
                    >
                      Select All (max {OPENED_CLUSTER_LIMIT})
                    </Button>
                    <Button
                      btnClass="btn-medium"
                      disabled={this.state.selectedClusters.length === 0}
                      handleClick={this.deselectAll}
                    >
                      Deselect All
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isAddClusterOpen && (
          <AddClusterPopup
            togglePopup={() => this.togglePopup("isAddClusterOpen")}
            redirect={this.redirect}
            macroList={this.props.allMacroList}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedFilters: state.filter.selectedFilters,
    options: state.filter.filtersOptionsCluster,
    lockCluster: state.user.lockCluster,
    isLockClusterLoading: state.user.isLockClusterLoading,
    user: state.authentication.user,
    auth: state.authentication,
    allMacroList: state.filter.allMacroList,
  };
};

const mapDispatchToProps = (dispatch) => { 
  return {
    setNewCluster: (filters, cluster) =>
      dispatch(actions.setNewCluster(filters, cluster)),
    getClusterFiltersOptions: (selectedFilters) =>
      dispatch(actions.getClusterFiltersOptions(selectedFilters)),
    getStoreIdFiltered: (selectedFilters, stars) =>
      dispatch(actions.getStoreIdFiltered(selectedFilters, stars)),
    getClusterWithLock: (cluster, callBack, viewer) =>
      dispatch(actions.getClusterWithLock(cluster, callBack, viewer)),
    deleteCluster: (cluster, filters) =>
      dispatch(actions.deleteCluster(cluster, filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCluster);
