/* eslint-disable array-callback-return */
import React, { Component } from "react";
import CustomSelect from "../UI-components/CustomSelect";
import Button from "../UI-components/Button";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import * as _ from "lodash";
import * as urlList from "../../config";
import RetractableButton from "../RetractableButton/RetractableButton";
import { getCookie } from "../../services/cookie";
import {
   addSelectAll,
   checkThereIsSelectAll,
   selectedModuleBrandAndColl,
   isAdmin,
   isSubmitUser,
   isEditorUser,
} from "../../services/Api";
import PopupCopyAll from "../Copy-Composition/PopupCopyAll";
import Popup from "../UI-components/Popup";
import "../RetractableButton/RetractableButton.scss";
import Loader from "../../components/UI-components/Loader";
import { alertMessage } from "../../Mock/alertMessage";

const perfoFiltersLabel = [
   "zonaPerMysa",
   "monthsRolling",
   "starsCustomer",
   "customerCode",
   "distrChannelActual",
   "legoClassDes",
];
class PerformanceFilters extends Component {
   constructor(props) {
      super(props);

      this.state = {
         ibicFilters: null,
         modulesParameters: {},
         moduleLabelList: [],
         savablePublishableModulesUpdated: [],
         checkboxRowsSaveAllPopupState: [],
         checkboxRowsPublishAllPopupState: [],
         checkedModulesSaveAll: [],
         checkedModulesPublishAll: [],
         isLoading: false,
      };

      //this.retractableButtonRef = React.createRef();
   }

   componentDidMount() {
      if (
         this.props.macroList &&
         this.props.macroList.some((macro) => {
            return macro.moduleList.length;
         })
      ) {
         this.requestActiveFilters();
      }
      this.getStatusOfModule();

   }

   arr = [];

   componentDidUpdate(prevProps, prevState) {
      if (
         prevProps.ibicFilters !== this.props.ibicFilters &&
         this.props.ibicFilters
      ) {
         //add select All
         let ibicFilters = addSelectAll(this.props.ibicFilters);
         this.setState({
            ibicFilters,
         });
      }
      if (
         prevProps.ibicFilters !== this.props.ibicFilters &&
         !this.props.ibicFilters
      ) {
         //add select All
         this.setState({
            ibicFilters: null,
         });
      }

      // call to product/filters
      if (prevProps.macroList !== this.props.macroList) {
         this.requestActiveFilters();
      }

      if (
         prevProps.savablePublishableModules !==
         this.props.savablePublishableModules
      ) {
         this.getStatusOfModule();
      }

      if (
         prevState.savablePublishableModulesUpdated !==
         this.state.savablePublishableModulesUpdated ||
         prevState.isPublishAllPopupOpen !== this.state.isPublishAllPopupOpen ||
         prevState.isSaveAllPopupOpen !== this.state.isSaveAllPopupOpen
      ) {
         const checkboxRowsSavePopup =
            this.state.savablePublishableModulesUpdated &&
            this.state.savablePublishableModulesUpdated.filter((el) => {
               if (el.isEdited !== null || el.isEdited !== undefined) {
                  return el.isEdited === true;
               } else {
                  return [];
               }
            });

         const checkboxRowsPublishAllPopup =
            this.state.savablePublishableModulesUpdated &&
            this.state.savablePublishableModulesUpdated.filter((el) => {
               return el.status === "WIP";
            });

         const saveAllAlreadyChecked =
            checkboxRowsSavePopup &&
            checkboxRowsSavePopup.map((el) => {
               return {
                  ...el,
                  isChecked: true,
               };
            });

         const publishAllAlreadyChecked =
            checkboxRowsPublishAllPopup &&
            checkboxRowsPublishAllPopup.map((el) => {
               return {
                  ...el,
                  isChecked: true,
               };
            });


         this.setState({
            checkboxRowsSaveAllPopupState: saveAllAlreadyChecked,
            checkboxRowsPublishAllPopupState: publishAllAlreadyChecked,
         });
      }
   }

   requestActiveFilters() {
      const modulesParameters = selectedModuleBrandAndColl(
         this.props.macroList
      );

      if (
         this.props.selectedFilters.planningChannel &&
         this.props.selectedFilters.planningChannel.constructor === Object
      ) {
         modulesParameters.planningChannel = [
            this.props.selectedFilters.planningChannel.value,
         ];
      }

      const isSelectedFilterChanged = !_.isEqual(
         modulesParameters,
         this.state.modulesParameters
      );

      if (isSelectedFilterChanged) {
         this.setState({ modulesParameters });
         this.props.getActiveFilter(modulesParameters);
      }
   }

   componentWillUnmount() {
      this.props.clearPerfoFilters();
   }

   getStatusOfModule = () => {
      this.setState({
         savablePublishableModulesUpdated: this.props.savablePublishableModules,
      });
   };

   togglePopup = (stateName) =>
      this.setState((prevState) => {
         return { [stateName]: !prevState[stateName] };
      });

   setFilters = (type, values) => {
      // if select all is selected
      for (var i = 0; i < values.length; i++) {
         if (values[i].value === "SELECT_ALL") {
            if (values.length === 1) {
               return this.props.setPerfoFilters(
                  type,
                  this.props.ibicFilters[type]
               );
            }

            if (checkThereIsSelectAll(this.props.perfoSelectedFilters[type])) {
               let newValues = [...values];
               newValues.splice(i, 1);
               return this.props.setPerfoFilters(type, newValues);
            } else {
               return this.props.setPerfoFilters(
                  type,
                  this.props.ibicFilters[type]
               );
            }
         }
      }
      this.props.setPerfoFilters(type, values);
      this.props.changeFilters(type, values);
   };

   updateInfo = () => {
      this.props.updatePerformanceInfo(this.props.perfoSelectedFilters);
   };

   disableCta = () => {
      let temp = false;
      if (this.props.auth) {
         this.props.auth.grants?.some((grant) => {
            if (
                (/admin/i.test(grant.role) || /CREATOR/i.test(grant.role))
                && grant.types.includes("edit")
            ) {
               temp = false;
            } else {
               temp = true;
            }
         });
      }
      return temp;
   };

   handleChangeSaveAllPopup = (e) => {
      const { name, checked } = e.target;

      const rows =
         this.state.checkboxRowsSaveAllPopupState.length > 0 &&
         this.state.checkboxRowsSaveAllPopupState;

      let arr = [];
      if (rows) {
         if (name === "selectAll") {
            arr = rows.map((row) => {
               return { ...row, isChecked: checked };
            });
         } else {
            arr = rows.map((row) => {
               const matchToNameInput = `${row.moduleLabel}`;

               return {
                  ...row,
                  isChecked:
                     row.isChecked && matchToNameInput === name
                        ? false
                        : !row.isChecked && matchToNameInput === name
                           ? true
                           : row.isChecked
                              ? row.isChecked
                              : false,
               };
            });
         }
      }

      const checkedSaveModules = arr.filter((checkedModule) => {
         return checkedModule.isChecked === true;
      });

      this.setState({
         checkboxRowsSaveAllPopupState: arr,
         checkedModulesSaveAll: checkedSaveModules,
      });
   };

   saveAllModules = () => {
      const saveAllModulesDataToSend =
         this.state.checkedModulesSaveAll &&
            this.state.checkedModulesSaveAll.length === 0
            ? this.state.checkboxRowsSaveAllPopupState.filter((el) => {
               return el.isChecked === true;
            })
            : this.state.checkedModulesSaveAll;

      const adaptedData = saveAllModulesDataToSend.map((el) => {
         if (el.isChecked) {
            delete el.isChecked;
         }

         if (el.isEdited) {
            delete el.isEdited;
         }

         return el;
      });

      //14/02
      // const url = "/myStarsBoBFF/module/saveList";
      const url = urlList.SAVE_MODULES_URL;

      fetch(url, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
         body: JSON.stringify(adaptedData),
      })
         .then((response) => {
            if (response.ok) {
               this.setState({
                  isLoading: false,
               });
            }
            if (response.status === 401) {
               this.setState(
                  {
                     isLoading: false,
                  },
                  () => {
                     this.props.logout();
                  }
               );
            }

            if (!response.ok) {
               this.setState({
                  saveAllResponseData: response.statusText,
                  showPopupSaveAllResponse: true,
                  isLoading: false,
               });
            }
            return response.json();
         })
         .then((data) => {
            if (!data.length || data === null || data === undefined) {
               //After saveAll/publishAll service, we invoke the module/modules service.
               //The Working Area will show only returned modules. So, if we have N modules
               //in working area but we pass to module/modules service only the saved/published
               //modules, the other won't show in working area. So, it's necessary pass to
               //module/modules service all modules shown in working area!
               //const modulesToUpdate = this.state.checkboxRowsSaveAllPopupState;
               const modulesToUpdate =
                  this.state.savablePublishableModulesUpdated;
               this.props.getWorkingModule(modulesToUpdate);
               this.setState({
                  showPopupSaveAllResponse: true,
                  saveAllResponseData: ["MODULE/MODULES CORRECTLY SAVED"],
                  isLoading: false,
                  checkedModulesSaveAll: [],
                  checkboxRowsSaveAllPopupState: [],
               });
            }

            if (data.length && data !== null && data !== undefined) {
               this.setState({
                  saveAllResponseData: data,
                  showPopupSaveAllResponse: true,
                  isLoading: false,
               });
            }
         })
         .catch((err) => {
            this.setState(
               {
                  isLoading: false,
               },
               () => {
                  console.error(err);
               }
            );
         });
   };

   publishAllModules = () => {
      const publishAllModulesDataToSend =
         this.state.checkedModulesPublishAll &&
            this.state.checkedModulesPublishAll.length === 0
            ? this.state.checkboxRowsPublishAllPopupState.filter((el) => {
               return el.isChecked === true;
            })
            : this.state.checkedModulesPublishAll;

      const adaptedData = publishAllModulesDataToSend.map((el) => {
         if (el.isChecked) {
            delete el.isChecked;
         }

         if (el.isEdited) {
            delete el.isEdited;
         }

         return el;
      });

      //14/02
      // const url = "/myStarsBoBFF/module/publishList";
      const url = urlList.PUBLISH_MODULES_URL;

      fetch(url, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
         body: JSON.stringify(adaptedData),
      })
         .then((response) => {
            if (response.ok) {
               this.setState({
                  isLoading: false,
               });
            }
            if (response.status === 401) {
               this.setState(
                  {
                     isLoading: false,
                  },
                  () => {
                     this.props.logout();
                  }
               );
               return response.json();
            }

            if (!response.ok) {
               this.setState({
                  publishAllResponseData: response.statusText,
                  showPopupPublishAllResponse: true,
                  isLoading: false,
               });
            }
         })
         .then((data) => {
            if (!data || data === null || data === undefined) {
               //After saveAll/publishAll service, we invoke the module/modules service.
               //The Working Area will show only returned modules. So, if we have N modules
               //in working area but we pass to module/modules service only the saved/published
               //modules, the other won't show in working area. So, it's necessary pass to
               //module/modules service all modules shown in working area!
               //const modulesToUpdate = this.state.checkboxRowsPublishAllPopupState;
               const modulesToUpdate =
                  this.state.savablePublishableModulesUpdated;

               this.props.getWorkingModule(modulesToUpdate);

               this.setState({
                  showPopupPublishAllResponse: true,
                  publishAllResponseData:
                     "The submission process has been started",
                  isLoading: false,
                  checkedModulesPublishAll: [],
                  checkboxRowsPublishAllPopupState: [],
               });
            }

            if (data && data !== null && data !== undefined) {
               this.setState({
                  showPopupPublishAllResponse: true,
                  publishAllResponseData: data.errors,
                  isLoading: false,
               });
            }
         })
         .catch((err) => {
            this.setState(
               {
                  isLoading: false,
               },
               () => {
                  console.error(err);
               }
            );
         });
   };

   handleChangePublishAllPopup = (e) => {
      const { name, checked } = e.target;

      const rows =
         this.state.checkboxRowsPublishAllPopupState.length > 0 &&
         this.state.checkboxRowsPublishAllPopupState;

      let arr = [];
      if (rows) {
         if (name === "selectAll") {
            arr = rows.map((row) => {
               return { ...row, isChecked: checked };
            });
         } else {
            arr = rows.map((row) => {
               const matchToNameInput = `${row.moduleLabel}`;

               return {
                  ...row,
                  isChecked:
                     row.isChecked && matchToNameInput === name
                        ? false
                        : !row.isChecked && matchToNameInput === name
                           ? true
                           : row.isChecked
                              ? row.isChecked
                              : false,
               };
            });
         }
      }

      const checkedPublishModules = arr.filter((checkedModule) => {
         return checkedModule.isChecked === true;
      });

      this.setState({
         checkboxRowsPublishAllPopupState: arr,
         checkedModulesPublishAll: checkedPublishModules,
      });
   }


   rendrenReactableButtonChildren(closeParent) {

      return <>

         <div className="btn-container marginB-half-rem">
            <Button
               // disabled={this.disableCta()}
               btnClass="btn-medium btn-copy-all w100"
               handleClick={() => {
                  this.togglePopup("isCopyPopupOpen");
                  if (closeParent) {
                     closeParent();
                  }
               }}
            >
               {"COPY ALL"}
            </Button>
         </div>
         <div className="btn-container marginB-half-rem ">
            <Button
               // disabled={this.disableCta()}
               btnClass="btn-medium btn-copy-all w100"
               handleClick={() => {
                  this.togglePopup("isSaveAllPopupOpen");
                  if (closeParent) {
                     closeParent();
                  }
               }}
               disabled={
                  this.state.checkboxRowsSaveAllPopupState
                     .length === 0
               }
            >
               {"SAVE ALL"}
            </Button>
         </div>
         <div className="btn-container marginB-half-rem">
            <Button
               // disabled={this.disableCta()}
               btnClass="btn-medium btn-copy-all w100"
               handleClick={() => {
                  this.togglePopup("isPublishAllPopupOpen");
                  if (closeParent) {
                     closeParent();
                  }
               }}
               disabled={
                  (!isSubmitUser(this.props.auth.grants) &&
                     !isAdmin(this.props.auth.grants)) ||
                  this.state.checkboxRowsPublishAllPopupState
                     .length === 0
               }
            >
               {"PUBLISH ALL"}
            </Button>
         </div>

      </>

   }

   render() {
      let { ibicFilters } = this.state;
      let { perfoSelectedFilters } = this.props;
      const saveAllModulesDataToSend =
         this.state.checkedModulesSaveAll &&
            this.state.checkedModulesSaveAll.length === 0
            ? this.state.checkboxRowsSaveAllPopupState.filter((el) => {
               return el.isChecked === true;
            })
            : this.state.checkedModulesSaveAll;
      const publishAllModulesDataToSend =
         this.state.checkedModulesPublishAll &&
            this.state.checkedModulesPublishAll.length === 0
            ? this.state.checkboxRowsPublishAllPopupState.filter((el) => {
               return el.isChecked === true;
            })
            : this.state.checkedModulesPublishAll;

      const enableRetractableButton =
         isSubmitUser(this.props.auth.grants) ||
         isAdmin(this.props.auth.grants) ||
         isEditorUser(this.props.auth.grants);

      const diffPrevMonthLength = this.state.checkboxRowsPublishAllPopupState.filter((el) => {

         const mustHaveList = el.productDtoList.filter((product) => product.mustHave && product.moduleCompositionStatus !== "REMOVED")
         const eligibleList = el.productDtoList.filter((product) => product.eligible && product.moduleCompositionStatus !== "REMOVED")

         const diffPrevMh = el.previousMustHave ? mustHaveList.length !== el.previousMustHave : false
         const diffPrevEl = el.previousEligible ? eligibleList.length !== el.previousEligible : false

         return (diffPrevEl || diffPrevMh)
      })

      const checkedList = diffPrevMonthLength.map((mod) => mod.isChecked)
      const isChecked = () => {
         for (let i = 0; i < checkedList.length; i++) {
            if (checkedList[i]) {
               return true
            }
         }
         return false;
      }
      return (
         ibicFilters && (
            <div className="performance-filters-section">
               <div className="row no-gutters">
                  <div className="col-3">Performance Filters (S.O., PTY)</div>
                  <div className="col-3 select-container zIndex404">
                     <CustomSelect
                        key="Country"
                        placeholder="Stars zone"
                        value={perfoSelectedFilters[perfoFiltersLabel[0]]}
                        options={ibicFilters[perfoFiltersLabel[0]]}
                        isMulti
                        handleChange={(e) => {
                           this.setFilters(perfoFiltersLabel[0], e);
                        }}
                     />
                  </div>
                  <div className="col-3 select-container">
                     <CustomSelect
                        key="Period"
                        placeholder="Period"
                        value={perfoSelectedFilters.monthsRolling}
                        options={ibicFilters[perfoFiltersLabel[1]]}
                        handleChange={(e) => {
                           this.setFilters("monthsRolling", e);
                        }}
                     />
                  </div>
                  <div className="col-3 select-container">
                     <CustomSelect
                        key="Stars Customer"
                        placeholder="Stars Customer"
                        value={perfoSelectedFilters.starsCustomer}
                        isMulti
                        options={ibicFilters[perfoFiltersLabel[2]]}
                        handleChange={(e) => {
                           this.setFilters("starsCustomer", e);
                        }}
                     />
                  </div>
               </div>
               <div className="row no-gutters second-row">
                  <div
                     className="col-3 two-btn-wrapper"
                     style={{ alignItems: "center", justifyContent: "center" }}
                  >
                     <div
                        className="btn-container"
                        style={{ marginLeft: "55%" }}
                     >
                        <RetractableButton
                           // ref={this.retractableButtonRef}
                           disableOpening={!enableRetractableButton}
                           customRender={this.rendrenReactableButtonChildren.bind(this)}
                        >

                        </RetractableButton>
                     </div>

                     <div className="btn-container">
                        <Button
                           btnClass="btn-medium"
                           handleClick={this.updateInfo}
                        >
                           Apply
                        </Button>
                     </div>
                  </div>
                  <div className="col-3 select-container zIndex403">
                     <CustomSelect
                        key="Customer Code"
                        placeholder="Customer Code"
                        value={perfoSelectedFilters.customerCode}
                        isMulti
                        options={ibicFilters[perfoFiltersLabel[3]]}
                        handleChange={(e) => this.setFilters("customerCode", e)}
                     />
                  </div>
                  <div className="col-3 select-container">
                     <CustomSelect
                        key="Distribution Channel"
                        placeholder="Distribution Channel"
                        isMulti
                        options={ibicFilters[perfoFiltersLabel[4]]}
                        value={perfoSelectedFilters.distrChannelActual}
                        handleChange={(e) => {
                           this.setFilters("distrChannelActual", e);
                        }}
                     />
                  </div>
                  <div className="col-3 select-container">
                     <CustomSelect
                        key="Buying Behavior"
                        placeholder="Buying Behavior"
                        isMulti
                        options={ibicFilters[perfoFiltersLabel[5]]}
                        value={perfoSelectedFilters[perfoFiltersLabel[5]]}
                        handleChange={(e) => {
                           this.setFilters([perfoFiltersLabel[5]], e);
                        }}
                     />
                  </div>
               </div>

               {this.state.isCopyPopupOpen && (
                  <PopupCopyAll
                     auth={this.props.auth}
                     type={"module"}
                     data={this.props.macroList}
                     popupClass="popup-copy"
                     close={() => this.togglePopup("isCopyPopupOpen")}
                     handleClick={() => this.togglePopup("isCopyPopupOpen")}
                     btnName={["Cancel", "Save", "Save & Publish"]}
                  />
               )}

               {this.state.isSaveAllPopupOpen && (
                  <Popup
                     close={() => this.togglePopup("isSaveAllPopupOpen")}
                     handleClickSecondary={() =>
                        this.togglePopup("isSaveAllPopupOpen")
                     }
                     handleClick={() => {
                        this.setState(
                           {
                              isLoading: true,
                           },
                           () => {
                              this.saveAllModules();
                           }
                        );
                     }}
                     contextTitle="Save all"
                     titleTop={
                        this.state.checkboxRowsSaveAllPopupState.length > 0 &&
                           !this.state.isLoading
                           ? "Do you want to save the following modules?"
                           : ""
                     }
                     isDoubleButton
                     disabled={
                        saveAllModulesDataToSend &&
                        saveAllModulesDataToSend.length === 0
                     }
                     btnName={!this.state.isLoading ? "Confirm" : null}
                     btnName2={!this.state.isLoading ? "Cancel" : null}
                     popupClass="popup-medium"
                     headerClass="PerformaceFilters__saveAllPopUp__header"
                  >
                     {this.state.checkboxRowsSaveAllPopupState.length > 0 &&
                        !this.state.isLoading && (
                           <div className="PerformaceFilters__saveAllPopUp__label-checkbox--container">
                              <input
                                 id="checkbox-disco-remove-table"
                                 type="checkbox"
                                 className="form-check-input disco-remove-table__checkbox--style PerformaceFilters__saveAllPopUp__checkbox--style"
                                 name={"selectAll"}
                                 checked={
                                    this.state.checkboxRowsSaveAllPopupState
                                       ?.length &&
                                    this.state.checkboxRowsSaveAllPopupState.filter(
                                       (el) => el?.isChecked !== true
                                    ).length < 1
                                 }
                                 onChange={(e) => {
                                    this.handleChangeSaveAllPopup(e);
                                 }}
                              />

                              <span className="PerformaceFilters__saveAllPopUp__label">
                                 Module list (Select all modules)
                              </span>
                           </div>
                        )}

                     {this.state.checkboxRowsSaveAllPopupState.length &&
                        !this.state.isLoading &&
                        this.state.checkboxRowsSaveAllPopupState.map(
                           (el, i) => {
                              return (
                                 <div
                                    key={`checkbox-rows-save-all-popup-${i}`}
                                    className="PerformaceFilters__saveAllPopUp__label-checkbox--container"
                                 >
                                    <input
                                       id="checkbox-disco-remove-table"
                                       type="checkbox"
                                       className="form-check-input disco-remove-table__checkbox--style PerformaceFilters__saveAllPopUp__checkbox--style"
                                       name={el.moduleLabel}
                                       checked={el?.isChecked || false}
                                       onChange={(e) => {
                                          this.handleChangeSaveAllPopup(e);
                                       }}
                                    />
                                    <span className="PerformaceFilters__saveAllPopUp__label">
                                       {el.moduleLabel}
                                    </span>
                                 </div>
                              );
                           }
                        )}

                     {this.state.isLoading && <Loader />}
                  </Popup>
               )}
               {this.state.isPublishAllPopupOpen && (
                  <Popup
                     close={() => this.togglePopup("isPublishAllPopupOpen")}
                     handleClickSecondary={() =>
                        this.togglePopup("isPublishAllPopupOpen")
                     }
                     handleClick={() => {
                        this.setState(
                           {
                              isLoading: true,
                           },
                           () => {
                              this.publishAllModules();
                           }
                        );
                     }}
                     contextTitle="Publish all"
                     titleTop={
                        this.state.checkboxRowsPublishAllPopupState.length >
                           0 && !this.state.isLoading
                           ? "Do you want to publish the following modules?"
                           : ""
                     }
                     disabled={
                        publishAllModulesDataToSend &&
                        publishAllModulesDataToSend.length === 0
                     }
                     isDoubleButton
                     btnName={!this.state.isLoading ? "Confirm" : null}
                     btnName2={!this.state.isLoading ? "Cancel" : null}
                     popupClass="popup-medium"
                     headerClass="PerformaceFilters__saveAllPopUp__header"
                  >
                     <div className="popoup-caution">
                        {diffPrevMonthLength.length > 0 &&
                           isChecked() &&
                           !this.state.isLoading &&
                           <>
                              <h1>CAUTION!</h1>
                              <p>{alertMessage.alertMassivePublish}</p>
                           </>
                        }
                     </div>
                     {this.state.checkboxRowsPublishAllPopupState.length > 0 &&
                        !this.state.isLoading && (
                           <div className="PerformaceFilters__saveAllPopUp__label-checkbox--container">
                              <input
                                 id="checkbox-disco-remove-table"
                                 type="checkbox"
                                 className="form-check-input disco-remove-table__checkbox--style PerformaceFilters__saveAllPopUp__checkbox--style"
                                 name={"selectAll"}
                                 checked={
                                    this.state.checkboxRowsPublishAllPopupState
                                       ?.length &&
                                    this.state.checkboxRowsPublishAllPopupState.filter(
                                       (el) => el?.isChecked !== true
                                    ).length < 1
                                 }
                                 onChange={(e) => {
                                    this.handleChangePublishAllPopup(e);
                                 }}
                              />
                              <span className="PerformaceFilters__saveAllPopUp__label">
                                 Module list (Select all modules)
                              </span>
                           </div>
                        )}

                     {this.state.checkboxRowsPublishAllPopupState.length > 0 &&
                        !this.state.isLoading &&
                        this.state.checkboxRowsPublishAllPopupState.map(
                           (el, i) => {
                              return (
                                 <div
                                    key={`checkbox-rows-publish-all-popup-${i}`}
                                    className="PerformaceFilters__saveAllPopUp__label-checkbox--container"
                                 >
                                    <input
                                       id="checkbox-disco-remove-table"
                                       type="checkbox"
                                       className="form-check-input disco-remove-table__checkbox--style PerformaceFilters__saveAllPopUp__checkbox--style"
                                       name={el.moduleLabel}
                                       checked={el?.isChecked || false}
                                       onChange={(e) => {
                                          this.handleChangePublishAllPopup(e);
                                       }}
                                    />
                                    <span className="PerformaceFilters__saveAllPopUp__label">
                                       {el.moduleLabel}
                                    </span>
                                 </div>
                              );
                           }
                        )}
                     {this.state.isLoading && <Loader />}
                  </Popup>
               )}
               {this.state.showPopupSaveAllResponse && (
                  <Popup
                     popupClass="popup-medium"
                     close={() => {
                        this.togglePopup("showPopupSaveAllResponse");
                        this.togglePopup("isSaveAllPopupOpen");
                     }}
                  >
                     <div className="popupContentFlex">
                        {this.state.saveAllResponseData &&
                           this.state.saveAllResponseData.map((text, i) => {
                              return (
                                 <span
                                    className="PerformaceFilters__saveAllPopUp__message"
                                    key={i.toString()}
                                 >
                                    {text}
                                 </span>
                              );
                           })
                        }
                     </div>
                  </Popup>
               )}

               {this.state.showPopupPublishAllResponse && (
                  <Popup
                     popupClass="popup-medium"
                     close={() => {
                        this.togglePopup("showPopupPublishAllResponse");
                        this.togglePopup("isPublishAllPopupOpen");
                     }}
                  >
                     {this.state.publishAllResponseData &&
                        this.state.publishAllResponseData.includes(";") ? (
                        this.state.publishAllResponseData
                           .split(";")
                           .map((text, i) => {
                              return (
                                 <span
                                    className="PerformaceFilters__saveAllPopUp__message"
                                    key={i.toString()}
                                 >
                                    {text}
                                 </span>
                              );
                           })
                     ) : (
                        <span className="PerformaceFilters__saveAllPopUp__message">
                           {this.state.publishAllResponseData}
                        </span>
                     )}
                  </Popup>
               )}
            </div>
         )
      );
   }
}

const mapStateToProps = (state) => {
   return {
      ibicFilters: state.module.ibicFilters,
      perfoSelectedFilters: state.module.perfoSelectedFilters,
      selectedFilters: state.filter.selectedFilters,
      macroList: state.module.macroList,
      auth: state.authentication,
      savablePublishableModules: state.filter.savablePublishableModules,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getActiveFilter: (filters) => dispatch(actions.getActiveFilter(filters)),
      updatePerformanceInfo: (filters) =>
         dispatch(actions.updatePerformanceInfo(filters)),
      clearPerfoFilters: () => dispatch(actions.clearPerfoFilters()),
      setPerfoFilters: (type, value) =>
         dispatch(actions.setPerfoFilters(type, value)),
      logout: () => dispatch(actions.logout()),
      getWorkingModule: (modulesToUpdate) =>
         dispatch(actions.getWorkingModule(modulesToUpdate, false)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceFilters);
