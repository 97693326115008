import React from "react";
import Popup from "../UI-components/Popup";

const DivWithErrorHandling = ({ showError, closeAlert, children, error }) => {
   return (
      <div>
         {showError && (
            <Popup
               popupClass="popup-small zIndex999"
               close={closeAlert}
               btnName="Close"
               handleClick={closeAlert}
            >
               {error ? (
                  <div>
                     {error.split("\n").map((str) => (
                        <p>{str}</p>
                     ))}
                  </div>
               ) : (
                  <>
                     <p className="error-message">An error occurred!</p>
                     <p className="error-message">
                        Please try again later or contact website's administrator
                     </p>
                  </>
               )}
            </Popup>
         )}
         {children}
      </div>
   );
};

export default DivWithErrorHandling;
