export const setCookie = (cname, cvalue, exdays) => {
   var d;
   if (exdays === 0) {
      d = new Date();
   } else {
      d = new Date(exdays * 1000);
   }
   // d.setTime(d.getTime() + (exdays*1000));
   var expires = "expires=" + d.toUTCString();
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(";");
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
         c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
         return c.substring(name.length, c.length);
         // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb3VudHJ5IjpudWxsLCJzYXBDb2RlSWQiOiIiLCJ1c2VyX25hbWUiOiJtY2FydGFuaSIsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSJdLCJleHAiOjE2MTgxMzk0NTUsImJyYW5kIjpudWxsLCJhdXRob3JpdGllcyI6WyJDT05UUk9MTEVSIiwiUk9MRV9BRE1JTiJdLCJqdGkiOiJkNjM1NzFkMy1jZjYwLTQxNzAtYTNhNy05MGU3N2NlMGU2YmQiLCJjbGllbnRfaWQiOiJlYXN5QXNzb3J0bWVudCJ9.EFRrbBB1HhBFbbP7oYWB76p3QWlgn78x_V4Eg0RDRgo";
      }
   }
   return "";
};
