import * as actionTypes from "../actions/actionTypes";

import { updateObject } from "../../services/Api";

const initialState = {
  loading: false,
  user: null,
  error: null,
  isAuthenticated: false,
  isGAJustInizialized: false,
  grants: null,
  errStatus: null,
};

const authorizationSuccess = (state, action) => {
  return updateObject(state, {
    grants: action.grants.obj.roles,
    errStatus: null,
  });
};

const authorizationFailure = (state, action) => {
  if (action.err.response.status === 401) {
    return updateObject(state, {
      grants: null,
      errStatus: action.err.response.status,
    });
  }

  return updateObject(state, {
    errStatus: action.err.response.status,
  });
};

const resetErrStatus = (state) => {
  return updateObject(state, {
    errStatus: null,
  });
};

const getAuthenticationStart = (state) => {
  return updateObject(state, {
    loading: true,
    isAuthenticated: false,
    error: null,
  });
};

const getAuthenticationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: action.user,
    error: null,
    isAuthenticated: true,
  });
};

const getAuthenticationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error.message || "The credentials are invalid",
    isAuthenticated: false,
  });
};

const setGaCheck = (state, action) => {
  return updateObject(state, { isGAJustInizialized: action.val });
};

const logout = (state) => {
  return updateObject(state, {
    loading: false,
    user: null,
    isAuthenticated: false,
    error: null,
    grants: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AUTHORIZATION_SUCCESS:
      return authorizationSuccess(state, action);
    case actionTypes.GET_AUTHORIZATION_FAILURE:
      return authorizationFailure(state, action);
    case actionTypes.RESET_ERROR_STATUS:
      return resetErrStatus(state, action);
    case actionTypes.LOGIN_START:
      return getAuthenticationStart(state);
    case actionTypes.LOGIN_SUCCESS:
      return getAuthenticationSuccess(state, action);
    case actionTypes.LOGIN_FAILURE:
      return getAuthenticationFail(state, action);
    case actionTypes.SET_GA_CHECK:
      return setGaCheck(state, action);
    case actionTypes.LOGOUT:
      return logout(state, action);

    default:
      return state;
  }
};
export default reducer;
