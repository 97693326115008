/* eslint-disable */
import React, { Component } from "react";
import Logo from "../UI-components/Logo";
import CustomSelect from "../UI-components/CustomSelect";
import Loader from "../UI-components/Loader";
import RadioGrp from "../UI-components/RadioGroup";
import { formatOptions } from "../../services/Api";
import Button from "../UI-components/Button";
import * as urls from "../../config";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

class UploadPage extends Component {
   constructor(props) {
      super(props);

      this.state = {
         isReleaseSelected: false,
         releaseList: null,
         releaseSelected: null,
         radioSelected: null,
         selectedIndex: 0,
         options: ["massive", "delta"],
         files: [],
         isLoaderShown: false,
         isUploadSuccessful: false,
         uploadedFile: null,
         isError: false,
      };

      this.handleUpload = this.handleUpload.bind(this);
      this.sendFile = this.sendFile.bind(this);
   }

   componentDidMount = () => {
      this.getReleaseList();
   };

   componentDidUpdate = (prevProps, prevState) => {
      if (
         prevState.isLoaderShown !== this.state.isLoaderShown &&
         this.state.isLoaderShown === true
      ) {
         if (this.state.files.length === 4) {
            this.sendFile();
         }
      }
   };

   getReleaseList = () => {
      let url = urls.GET_RELEASE_URL;
      fetch(url, { method: "get" })
         .then((response) => response.json())
         .then((json) => {
            if (json) {
               json = formatOptions(json, "releaseId");
               this.setState({ releaseList: json });
            }
         })
         .catch((err) => {
            console.error(err);
         });
   };

   setRadio = (selectedOption, i) => {
      this.setState({
         radioSelected: selectedOption,
         selectedIndex: i,
         isUploadSuccessful: false,
      });
   };

   setRelease = (e) => {
      this.setState({
         releaseSelected: e.value,
         isReleaseSelected: Array.isArray(e) && e.length === 0 ? false : true,
         isUploadSuccessful: false,
      });
   };

   handleSelectedFile = (fileItems, id) => {
      let fileToAdd,
         files = [...this.state.files],
         indexToDelete = null;

      files.map((f, i) => {
         if (f.id === id) {
            indexToDelete = i;
         }
      });

      if (indexToDelete !== null) {
         files.splice(indexToDelete);
      }

      if (fileItems.length > 0) {
         fileToAdd = fileItems[0].file;

         fileToAdd.id = id;
         files.push(fileToAdd);
      }

      this.setState({
         files,
      });
   };

   handleUpload = () => {
      this.setState({
         isLoaderShown: true,
         isUploadSuccessful: false,
      });
   };

   sendFile = () => {
      const data = new FormData();
      this.state.files.map((file, i) => {
         data.append(file.id, file, file.name);
      });
      data.append("releaseId", this.state.releaseSelected);
      data.append("type", this.state.options[this.state.selectedIndex]);

      this.postFiles(data);
   };

   postFiles = (data) => {
      fetch(
         urls.IMPORT_FILE_URL_4_FILE,
         {
            method: "post",
            headers: { Enctype: "multipart/form-data" },
            body: data,
         },
         {
            onUploadProgress: (ProgressEvent) => {
               this.setState({
                  loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
               });
            },
         }
      )
         .then(this.handleErrors)
         .then((response) => {
            this.setState({
               isLoaderShown: false,
               isUploadSuccessful: true,
            });
         })
         .catch((err) => {
            this.setState({
               isLoaderShown: false,
               isError: true,
               msgError: err,
            });
         });
   };

   handleErrors = (response) => {
      if (!response.ok) {
         response.json().then((res) => {
            throw Error(
               "Request rejected with status " +
                  res.status +
                  " Msg: " +
                  res.message
            );
         });
      }
      return response;
   };

   render() {
      let releaseList = this.state.releaseList,
         disabled = !(
            this.state.files.length === 4 && this.state.isReleaseSelected
         ),
         selectedIndex =
            this.state.selectedIndex !== null ? this.state.selectedIndex : 0;

      return (
         <React.Fragment>
            {releaseList ? (
               <div className="upload-page">
                  <header>
                     <Logo />
                     <h1>Upload Files</h1>
                  </header>
                  <div className="component content">
                     <div className="option-container">
                        <div className="option-con">
                           <h3>Select a release</h3>
                           <CustomSelect
                              options={this.state.releaseList}
                              handleChange={this.setRelease}
                           />
                        </div>
                        <div className="option-con">
                           <h3>Select a massive type</h3>
                           <RadioGrp
                              selectedIndex={selectedIndex}
                              options={this.state.options}
                              active={false}
                              handleClick={(selectedOption, i) =>
                                 this.setRadio(selectedOption, i)
                              }
                           />
                        </div>
                     </div>
                     <div className="total-upload-container">
                        <header>
                           <h3>Upload files</h3>
                        </header>
                        <div className="upload-content">
                           <div className="upload-container">
                              <div className="upload-el">
                                 <h4>Module Composition</h4>
                                 <FilePond
                                    removeFile={(f) => {
                                       this.handleSelectedFile(f, "module");
                                    }}
                                    ref={(ref) => (this.pond = ref)}
                                    onupdatefiles={(f) => {
                                       this.handleSelectedFile(f, "module");
                                    }}
                                 />
                              </div>
                              <div className="upload-el">
                                 <h4>Cluster Composition</h4>
                                 <FilePond
                                    removeFile={(f) => {
                                       this.handleSelectedFile(f, "cluster");
                                    }}
                                    ref={(ref) => (this.pond = ref)}
                                    onupdatefiles={(f) => {
                                       this.handleSelectedFile(f, "cluster");
                                    }}
                                 />
                              </div>
                           </div>
                           <div className="upload-container">
                              <div className="upload-el">
                                 <h4>Sku Registry</h4>
                                 <FilePond
                                    removeFile={(f) => {
                                       this.handleSelectedFile(f, "registry");
                                    }}
                                    ref={(ref) => (this.pond = ref)}
                                    onupdatefiles={(f) => {
                                       this.handleSelectedFile(f, "registry");
                                    }}
                                 />
                              </div>
                              <div className="upload-el">
                                 <h4>Customer Segmentation</h4>
                                 <FilePond
                                    removeFile={(f) => {
                                       this.handleSelectedFile(f, "segment");
                                    }}
                                    ref={(ref) => (this.pond = ref)}
                                    onupdatefiles={(f) => {
                                       this.handleSelectedFile(f, "segment");
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                     <Button
                        btnClass="btn-medium"
                        style={{ width: "200px", margin: "0 auto" }}
                        disabled={disabled}
                        handleClick={() => this.handleUpload()}
                     >
                        Upload
                     </Button>
                     {this.state.isLoaderShown ? <Loader /> : null}
                     {this.state.isUploadSuccessful ? (
                        <p className="success">The files have been uploaded</p>
                     ) : null}
                     {this.state.isError ? (
                        <p className="error-mmsg">{this.state.msgError}</p>
                     ) : null}
                  </div>
               </div>
            ) : (
               <div className="full-page">
                  <Loader />
               </div>
            )}
         </React.Fragment>
      );
   }
}
export default UploadPage;
