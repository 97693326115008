/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import Popup from "../UI-components/Popup";
import Tooltip from "../UI-components/Tooltip";
import CustomIcon from "../UI-components/CustomIcon";
import * as actions from "../../store/actions";
import PopupGenericError from "../../components/Error-Handling/WithErrorHandling";



const DiscoRemoveTable = ({
   tableData,
   setRowsToSendMassiveEdit,
   rowsToSendMassiveEdit,
   isChangedTypeView,
   showBoxShadow,
   actualPage,
   loadingListToModule,
   unlockModule,
   reload,
   user,
}) => {
   const [rows, setRows] = useState([]);
   const [openPopup, setOpenPopup]=useState(false);
   const [moduleUnlock, setModuleUnlock]=useState({});
   const [checkError, setCheckError]=useState(false);

   useEffect(() => {
      setRows(
         tableData.map((row) => {
            return {
               ...row,
               isChecked: true,
            };
         })
      );
   }, []);

   useEffect(() => {
      selectedRowsToSend(
         rows.filter((checkedRow) => {
            return checkedRow.isChecked === true;
         })
      );
   }, [rows]);

   useEffect(() => {
      selectedRowsToSend(
         rows.filter((checkedRow) => {
            return checkedRow.isChecked === true;
         })
      );
   }, [isChangedTypeView, tableData, rows]);

   useEffect(() => {
      if (tableData && tableData.length) {
         rememberCheckedRows(tableData);
      }
      // }, [actualPage]);
   }, [tableData]);

   const rememberCheckedRows = (tableData) => {
      if (
         rowsToSendMassiveEdit &&
         rowsToSendMassiveEdit[actualPage] !== undefined &&
         rowsToSendMassiveEdit[actualPage].length &&
         Object.keys(rowsToSendMassiveEdit).includes(String(actualPage))
      ) {
         const reduxArr = rowsToSendMassiveEdit[actualPage];

         const checkRow = (row) => {
            const nameRow = `${row.upc} - ${row.type} - ${row.gedConfirmed} - ${row.gedExpiryDate} - ${row.materialCode} - ${row.moduleName} - ${row.monthId} - ${row.moduleLabel} - ${row.skuCode} - ${row.brandCode} - ${row.commodity}`;

            const nameRowFromRedux = reduxArr.filter((row) => {
               const stringRow = `${row.upc} - ${row.type} - ${row.gedConfirmed} - ${row.gedExpiryDate} - ${row.materialCode} - ${row.moduleName} - ${row.monthId} - ${row.moduleLabel} - ${row.skuCode} - ${row.brandCode} - ${row.commodity}`;

               const hasMatched = nameRow === stringRow;
               return hasMatched;
               // return setIsMatchedRow(hasMatched);
            });

            return nameRowFromRedux;
         };

         let newRows = tableData.map((row) => {
            const isMatched = checkRow(row).length >= 1;
            if (isMatched) {
               return {
                  ...row,
                  isChecked: true,
               };
            } else {
               return row;
            }
         });

         setRows(newRows);
      }
   };

   const selectedRowsToSend = (data) => {
      if (data) {
         const obj = {
            [actualPage]: data,
         };
         return setRowsToSendMassiveEdit(obj);
      } else {
         return [];
      }
   };
   
   const openModule = async(module) => {
         loadingListToModule(true);
         window.open('/myStarsBo/module?moduleName='+module.moduleName+'&monthId='+module.monthId);
         setRows(rows.map(row =>{
               if(row.moduleName === module.moduleName && row.monthId === module.monthId){
                  row.userLock = row.userLock  && row.userLock !== user.user_name ? row.userLock : user.user_name ;
                  row.locked = true;
               }
               return row;
            }));
         loadingListToModule(false);
   };

   const handleChange = (e) => {
      const { name, checked } = e.target;
      let arr = [];
      if (rows) {
         if (name === "selectAll") {
            arr = rows.map((row) => {
               return { ...row, isChecked: checked, page: actualPage };
            });
         } else {
            arr = rows.map((row) => {
               const matchToNameInput = `${row.upc} - ${row.type} - ${row.gedConfirmed} - ${row.gedExpiryDate} - ${row.materialCode} - ${row.moduleName} - ${row.monthId} - ${row.moduleLabel} - ${row.skuCode} - ${row.brandCode} - ${row.commodity}`;

               return {
                  ...row,
                  isChecked:
                     row.isChecked && matchToNameInput === name
                        ? false
                        : !row.isChecked && matchToNameInput === name
                           ? true
                           : row.isChecked
                              ? row.isChecked
                              : false,
                  page: actualPage,
               };
            });
         }
      }
      setRows(arr);

      selectedRowsToSend(
         arr.filter((checkedRow) => {
            return checkedRow.isChecked === true;
         })
      );
   };
   const handleClickLock=(row) => {
      setOpenPopup(true);
      setModuleUnlock({
         moduleName: row.moduleName,
         monthId: row.monthId,
      });
   };

   const handleUnClickLock=() => {
      setOpenPopup(false);
      setModuleUnlock({});
   };

   const DiscoRemoveTableGeneratedRows = () => {
      return rows.map((row, i) => {
         const rowName = `${row.upc} - ${row.type} - ${row.gedConfirmed} - ${row.gedExpiryDate} - ${row.materialCode} - ${row.moduleName} - ${row.monthId} - ${row.moduleLabel} - ${row.skuCode} - ${row.brandCode} - ${row.commodity}`;
         return (
            <tr id={rowName} key={"indexDiscoRemoveTable" + i}>
               <td>
                  <div className="disco-remove-table__row-checkbox--container">
                     <div className="form-check-wrapper">
                        <input
                           id="checkbox-disco-remove-table"
                           type="checkbox"
                           className="form-check-input disco-remove-table__checkbox--style"
                           name={rowName}
                           checked={row?.isChecked || false}
                           onChange={(e) => {
                              handleChange(e);
                           }}
                        />
                     </div>
                  </div>
               </td>
               <td>
                  <div className="disco-remove-table__second-col-container">
                     <span className="disco-remove-table__second-col-container--label">
                        {/* tB:03-2022 */}
                        <div>{row.brandCode ? row.brandCode : null}</div>
                     </span>
                  </div>
               </td>
               <td>
                  <div>{row.commodity ? row.commodity : null}</div>
               </td>
               <td>
                  <span style={(isChangedTypeView && row.type === "Super disco") ?
                     { margin: "0 1vw" } :
                     { margin: "0", marginRight: '2vh' }}>
                     {row.upc ? row.upc : null}
                  </span>
                  {isChangedTypeView && row.type === "Super disco" ? (
                     <>
                        <span
                           data-tip="Super discontinued SKU"
                           className="icon icon-triangle-black"
                        />
                        <ReactTooltip
                           className="disco-remove-table__tooltip--style"
                           effect="solid"
                        />
                     </>
                  ) : null}
               </td>
               <td>
                  <div>{row.materialCode ? row.materialCode : null}</div>
               </td>
               <td>
                  <div>{row.skuCode ? row.skuCode : null}</div>
               </td>
               <td>
               <span style={{display:"inline-flex"}}>
               <div onClick={ () => openModule(row) } style={{cursor:"pointer",textDecoration:"underline"}}>{row.moduleLabel ? row.moduleLabel : null}</div>
               {row.locked ? ( 
                  <div onClick={()=>handleClickLock(row)} style={{paddingLeft:"10px"}}>
                  <Tooltip
                  text={row.userLock + " is editing the module"}
                  tooltipClass="left tooltip-lock"
                  tooltipTextClass="white"
                   >
                  <CustomIcon
                    iconContClass="icon-left"
                    iconClass="icon-close-lock"
                  />
                  </Tooltip>
                  </div>
                  ) : null}  
               </span>
               </td>
               <td>
                  <div>{row.monthId ? row.monthId : null}</div>
               </td>
            </tr>
         );
      });
   };
   return(
      <>
      <table className="disco-remove-table">
         <thead
            style={
               showBoxShadow
                  ? {
                     position: "sticky",
                     top: 0,
                     boxShadow: "0px 0px 20px 3px #000000",
                  }
                  : {
                     position: "sticky",
                     top: 0,
                  }
            }
         >
            <tr>
               <th className="disco-remove-table__col-checkbox--container">
                  <span className="disco-remove-table__col-checkbox--label">
                     SELECT ALL
                  </span>
                  <div
                     id="checkbox-disco-remove"
                     className="form-check-wrapper"
                  >
                     <input
                        id="checkbox-disco-remove-table"
                        className="form-check-input disco-remove-table__checkbox--style"
                        type="checkbox"
                        name="selectAll"
                        checked={
                           rows?.length &&
                           rows.filter((row) => row?.isChecked !== true)
                              .length < 1
                        }
                        onChange={(e) => {
                           handleChange(e);
                        }}
                     />
                  </div>
               </th>
               <th>BRAND</th>
               <th>COMMODITY</th>
               <th style={{ width: '2%' }}>SKU</th>
               <th>MODEL</th>
               <th>GRID</th>
               <th>MODULE</th>
               <th>MONTH</th>
            </tr>
         </thead>
         <tbody>{DiscoRemoveTableGeneratedRows()}</tbody>
      </table>
      {openPopup && !checkError && (
         <Popup
               popupClass="popup-unlocked-module"
               close={() => handleUnClickLock()}
               title=" WARNING"
               handleClickSecondary={() => {
                  unlockModule([moduleUnlock]);
                  setRows(rows.map(row =>{
                     if(row.moduleName === moduleUnlock.moduleName && row.monthId === moduleUnlock.monthId){
                        row.userLock = null;
                        row.locked = false;
                     }
                     return row;
                  }));
                  handleUnClickLock();
               }}
               handleClick={() => {
                  handleUnClickLock();
               }}
               isDoubleButton={true}
               btnName={"No"}
               btnName2={"Yes"}
            >
               {"Do you want unlock this module?"}
            </Popup>
       )}
      {checkError && (
               <PopupGenericError
               showError={checkError}
               closeAlert={() => {
                  setCheckError(false);
               }}
               handleClick={() => {
                  setCheckError(false);
               }}
            />
      )}
      </>
   );
}

const mapStateToProps = (state) => {
   return { rowsToSendMassiveEdit: state.filter.rowsToSendMassiveEdit,
            results: state.filter.results,
            user: state.authentication.user,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setRowsToSendMassiveEdit: (rowsToSendMassiveEdit) =>
         dispatch(actions.setRowsToSendMassiveEdit(rowsToSendMassiveEdit)),
      getModule: (moduleItem) =>
         dispatch(actions.getWorkingModule(moduleItem, true)),
      getActiveFilter: (filters) => 
         dispatch(actions.getActiveFilter(filters)),
      unlockModule: (moduleList) => 
         dispatch(actions.unlockModule(moduleList, true)),

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscoRemoveTable);
