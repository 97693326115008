/* eslint-disable array-callback-return */

import * as actions from "../../../store/actions";

import { groupBy, isEmpty, isNull, isUndefined, orderBy, sumBy } from "lodash";
import { useCallback, useEffect, useState } from "react";

import PerformanceTable from "./PerformanceTable";
import { connect } from "react-redux";

function PaginatedPerformanceTable(props) {
   const [upcList, setUpcList] = useState([]);
   const [headerTable, setHeaderTable] = useState([]);
   const [tableContent, setTableContent] = useState({});

   const {
      isSegmentationView,
      setIsSegmentationView,
      tableData,
      // elementScrollTop,
      showBoxShadowSkus,
      showBoxShadowTableHead,
      percentual,
      //CM added
      currentWorkingModule
   } = props;

   useEffect(() => {
      setIsSegmentationView(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // Update Sorted UpcList
   useEffect(() => {
      if (tableData) {
         // Calculate Data for Segmentation View
         if (isSegmentationView) {
            setUpcList(getSortedUpcListSegmentation());
         }
         // Calculate Data for StarZone View
         if (!isSegmentationView) {
            // upcListNew = getSortedUpcListStarZone();
            setUpcList(getSortedUpcListStarZone());
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [tableData, isSegmentationView]);

   // Update Header Table
   useEffect(() => {
      if (isSegmentationView) {
         setHeaderTable(getSortedHeaderTableSegmentation());
      }
      if (!isSegmentationView) {
         setHeaderTable(getSortedHeaderTableStarZone());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [tableData, isSegmentationView]);


   // eslint-disable-next-line react-hooks/exhaustive-deps
   const getWorkingMustHaveValue = (sku) => {
      let ret=false;
      let found=false;
      if(sku){
         ret=sku.mustHave;
         if(currentWorkingModule && currentWorkingModule.productDtoList){
            for(let i=0;i<currentWorkingModule.productDtoList.length && !found;i++){
               if(currentWorkingModule.productDtoList[i]?.upc === sku.upc){
                  found=true;
                  ret=currentWorkingModule.productDtoList[i].mustHave;
               }
            }
         }
      }
      return ret;
   }

   // Update Table Data
   useEffect(() => {
      if (isSegmentationView) {
         const content = getTableContentTableSegmentation(upcList, headerTable);
         setTableContent(setBackgroundColor(content));
      }
      if (!isSegmentationView) {
         const content = getTableContentStarZone(upcList, headerTable);
         setTableContent(setBackgroundColor(content));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [upcList, tableData, isSegmentationView, headerTable]);

   // Calculate Data for Segmentation View
   const getSortedUpcListSegmentation = useCallback(() => {
      let sortedUpcList = [];
      if (tableData && tableData.upcList) {
         let index = 0;
         sortedUpcList = tableData.upcList.map((upcEl) => {
            index++;

            const mappedUpc = {
               imageUrl: upcEl.imageUrl,
               //skuId: "NoIDAvailable",
               //model: upcEl.upc,
               skuId: upcEl.upc ? upcEl.upc : "dummyUpcId_" + index,
               model: upcEl.model ? upcEl.model : "",
               color: upcEl.color ? upcEl.color : "",
               size: upcEl.size ? upcEl.size : "",
               //  tB:03-2022
               mustHave: getWorkingMustHaveValue(upcEl)//upcEl.mustHave ? upcEl.mustHave : false,
            };

            let sumSellOut = 0;

            if (upcEl.starZoneList) {
               mappedUpc.starZoneList = upcEl.starZoneList;

               upcEl.starZoneList.forEach((starZone) => {
                  if (starZone?.segmentationList) {
                     starZone.segmentationList.forEach((segmentation) => {
                        if (segmentation?.sellOut) {
                           sumSellOut += segmentation.sellOut;
                        }
                     });
                  }
               });
            }
            mappedUpc.summedSellOut = sumSellOut;
            return mappedUpc;
         });

         sortedUpcList = orderBy(sortedUpcList, "summedSellOut", ["desc"]);
      }
      return sortedUpcList;
   }, [tableData, getWorkingMustHaveValue]);

   // Calculate Header Segmentation View
   const getSortedHeaderTableSegmentation = useCallback(() => {
      let sortedHeader = [];

      if (tableData?.upcList) {
         const starZoneMap = {};

         //Assodato che data una precisa starZone il numDoor che si mostra sulla prima tabella (StarZone View)
         //potrebbe essere diverso dalla somma dei numDoor delle relative segmentation della medesima starZone
         //sulla seconda tabella (Segmentation View), quando sulla seconda tabella si ordina per numDoor tot
         //decrescente, il numDoor da considerare per l'ordinamento è quello della prima tabella
         //(tot per starZone) o quello della seconda (tot delle segmentation di una starZone)?
         //20220223: Ordinare per il primo valore (numDoor per StarZone) ==> Allo scopo abilitare tutte le porzioni
         //di codice a seguire comprese nei commenti 'Ordinamento per numDoor per StarZone - Inizio' e
         //'Ordinamento per numDoor per StarZone - Fine'. In caso l'ordinamento dovesse essere in base al secondo
         //valore, commentare le suddette porzioni di codice.
         //NB: La differenza dei valori di numDoor tra la prima e la seconda tabella è concettualmente corretta.
         //In particolare il primo viene mandato direttamente da back end, mentre il secondo calcolato da front end

         //Ordinamento per numDoor per StarZone - Inizio
         const starZoneMap_doorByStarZone = {};
         //Ordinamento per numDoor per StarZone - Fine

         let starZoneArr = [];

         tableData.upcList.forEach((upcEl) => {
            if (upcEl?.starZoneList) {
               upcEl.starZoneList.forEach((starZoneEl) => {
                  if (starZoneEl?.starZone) {
                     //Ordinamento per numDoor per StarZone - Inizio
                     if (!starZoneMap_doorByStarZone[starZoneEl.starZone]) {
                        starZoneMap_doorByStarZone[starZoneEl.starZone] =
                           starZoneEl.numDoor ? starZoneEl.numDoor : 0;
                     }
                     //Ordinamento per numDoor per StarZone - Fine

                     if (!starZoneMap[starZoneEl.starZone]) {
                        starZoneMap[starZoneEl.starZone] = 0;
                     }
                     if (starZoneEl?.segmentationList) {
                        let sumNumDoor = sumBy(
                           starZoneEl.segmentationList,
                           //"numDoor"
                           // tB:03-2022
                           (segmentationItem) => {
                              return segmentationItem &&
                                 segmentationItem.numDoor
                                 ? segmentationItem.numDoor
                                 : 0;
                           }
                        );

                        starZoneMap[starZoneEl.starZone] += sumNumDoor;
                     }
                  }
               });
            }
         });

         starZoneArr = Object.keys(starZoneMap).map((starZoneName) => {
            return {
               name: starZoneName,
               summedNumDoors: starZoneMap[starZoneName],
               //Ordinamento per numDoor per StarZone - Inizio
               starZoneNumDoor: starZoneMap_doorByStarZone[starZoneName]
                  ? starZoneMap_doorByStarZone[starZoneName]
                  : 0,
               //Ordinamento per numDoor per StarZone - Fine
            };
         });

         //Ordinamento per numDoor per StarZone - Inizio
         //Ordinamento per starZone.segmentation.numDoor
         //starZoneArr = orderBy(starZoneArr, "summedNumDoors", ["desc"]);
         //Ordinamento per starZone.numDoor
         starZoneArr = orderBy(starZoneArr, "starZoneNumDoor", ["desc"]);
         //Ordinamento per numDoor per StarZone - Fine

         const starZoneSegmentationMap = {};

         starZoneArr.forEach((starZone) => {
            tableData?.upcList.forEach((upcEl) => {
               if (upcEl?.starZoneList) {
                  const foundStarZone = upcEl.starZoneList.find(
                     (el) => el.starZone === starZone.name
                  );

                  if (foundStarZone) {
                     foundStarZone.segmentationList.forEach(
                        (segmentationEl) => {
                           if (segmentationEl?.segmentation) {
                              let key =
                                 foundStarZone.starZone +
                                 "#" +
                                 segmentationEl.segmentation;

                              /*SUM numDoor BY StarZone.Segmentation   
                              if (!starZoneSegmentationMap[key]) {
                                 starZoneSegmentationMap[key] = 0;
                              }
                              starZoneSegmentationMap[key] += segmentationEl.numDoor;
                              */

                              //No SUM numDoor BY StarZone.Segmentation
                              starZoneSegmentationMap[key] =
                                 segmentationEl.numDoor;
                           }
                        }
                     );
                  }
               }
            });
         });

         sortedHeader = Object.keys(starZoneSegmentationMap).map((seg) => {
            const splittedName = seg.split("#");

            return {
               fullString: seg,
               starZoneName: splittedName[0],
               segmentationName: splittedName[1],
               summedNumDoors: starZoneSegmentationMap[seg],
            };
         });

         // SORTING PARZIALE DELLE SEGMENTATION PER STARZONE
         const groupedArr = groupBy(sortedHeader, "starZoneName");
         let sortedArr = [];

         Object.keys(groupedArr).forEach((arr) => {
            sortedArr = [
               ...sortedArr,
               ...orderBy(
                  groupedArr[arr],
                  ["summedNumDoors", "name"],
                  ["desc"]
               ),
            ];
         });

         sortedHeader = sortedArr;
      }

      return sortedHeader;
   }, [tableData.upcList]);

   // Calculate Table Data Segmentation View
   const getTableContentTableSegmentation = useCallback(
      (sortedUpcList, sortedHeader) => {
         let mappedTableData = [];

         if (sortedUpcList && sortedHeader) {
            sortedUpcList.forEach((upcEl) => {
               sortedHeader.forEach((starZoneEl) => {
                  const upcObj = {
                     //model: upcEl.model,
                     skuId: upcEl.skuId,
                     summedNumDoors: 0,
                     summedSellOut: 0,
                  };

                  const foundStarZone = upcEl.starZoneList.find(
                     (sz) => sz.starZone === starZoneEl.starZoneName
                  );

                  if (foundStarZone && foundStarZone.segmentationList) {
                     const foundSegmentation =
                        foundStarZone.segmentationList.find(
                           (sz) =>
                              sz.segmentation === starZoneEl.segmentationName
                        );

                     if (foundSegmentation) {
                        upcObj.summedNumDoors = foundSegmentation.numDoor;
                        upcObj.summedSellOut = foundSegmentation.sellOut;

                        mappedTableData.push({
                           ...upcObj,
                           segmentationName: starZoneEl.segmentationName,
                           starZoneName: starZoneEl.starZoneName,
                        });
                     } else {
                        mappedTableData.push({
                           ...upcObj,
                           segmentationName: starZoneEl.segmentationName,
                           starZoneName: starZoneEl.starZoneName,
                        });
                     }
                  } else {
                     mappedTableData.push({
                        ...upcObj,
                        segmentationName: starZoneEl.segmentationName,
                        starZoneName: starZoneEl.starZoneName,
                     });
                  }
               });
            });
         }

         let mappedTableDataFinal = [];
         let mappedIndex = {};

         mappedTableData.forEach((row) => {
            //Begin: change from row.model to row.skuId
            if (
               isUndefined(mappedIndex[row.skuId]) ||
               isNull(mappedIndex[row.skuId])
            ) {
               mappedIndex[row.skuId] = mappedTableDataFinal.length;
            }

            if (!mappedTableDataFinal[mappedIndex[row.skuId]]) {
               mappedTableDataFinal[mappedIndex[row.skuId]] = [];
            }

            mappedTableDataFinal[mappedIndex[row.skuId]].push(row);
            //End: change from row.model to row.skuId
         });

         return mappedTableDataFinal;
      },
      []
   );

   // Calculate Data for StarZone View
   const getSortedUpcListStarZone = useCallback(() => {
      let sortedUpcList = [];
      if (tableData && tableData.upcList) {
         let index = 0;
         sortedUpcList = tableData.upcList.map((upcEl) => {
            index++;
            const mappedUpc = {
               imageUrl: upcEl.imageUrl,
               //skuId: "NoIDAvailable",
               //model: upcEl.upc,
               skuId: upcEl.upc ? upcEl.upc : "dummyUpcId_" + index,
               model: upcEl.model ? upcEl.model : "",
               color: upcEl.color ? upcEl.color : "",
               size: upcEl.size ? upcEl.size : "",
               // tB:03-2022
               mustHave: getWorkingMustHaveValue(upcEl)//upcEl.mustHave ? upcEl.mustHave : false,
            };

            let sumSellOut = 0;

            if (upcEl.starZoneList) {
               mappedUpc.starZoneList = upcEl.starZoneList;

               upcEl.starZoneList.forEach((starZone) => {
                  if (starZone?.segmentationList) {
                     starZone.segmentationList.forEach((segmentation) => {
                        if (segmentation?.sellOut) {
                           sumSellOut += segmentation.sellOut;
                        }
                     });
                  }
               });
            }
            mappedUpc.summedSellOut = sumSellOut;
            return mappedUpc;
         });

         sortedUpcList = orderBy(sortedUpcList, "summedSellOut", ["desc"]);
      }

      return sortedUpcList;
   }, [tableData, getWorkingMustHaveValue]);

   // Calculate Header StarZone View
   const getSortedHeaderTableStarZone = useCallback(() => {
      let sortedHeader = [];
      //let setAlreadyAddedSegNumDoorsInSZ = new Set();

      if (tableData?.upcList) {
         const starZoneMap = {};

         tableData.upcList.forEach((upcEl) => {
            if (upcEl?.starZoneList) {
               upcEl.starZoneList.forEach((starZoneEl) => {
                  //20220223: Now, total numDoor for starZone is present in back end response.
                  //Before it isn't in service responde and it was calculated iterating in
                  //segmentationList collection
                  if (starZoneEl?.starZone) {
                     if (!starZoneMap[starZoneEl.starZone]) {
                        starZoneMap[starZoneEl.starZone] = starZoneEl.numDoor
                           ? starZoneEl.numDoor
                           : 0;
                     }
                  }
               });
            }
         });

         sortedHeader = Object.keys(starZoneMap).map((starZoneName, i) => {
            return {
               name: starZoneName,
               summedNumDoors: starZoneMap[starZoneName],
            };
         });

         sortedHeader = orderBy(
            sortedHeader,
            ["summedNumDoors", "name"],
            ["desc"]
         );
      }

      return sortedHeader;
   }, [tableData.upcList]);

   const getTableContentStarZone = useCallback(
      (sortedUpcList, sortedHeader) => {
         let mappedTableData = [];

         if (sortedUpcList && sortedHeader) {
            sortedUpcList.forEach((upcEl) => {
               sortedHeader.forEach((starZoneEl) => {
                  const upcObj = {
                     //model: upcEl.model,
                     skuId: upcEl.skuId,
                     summedNumDoors: 0,
                     summedSellOut: 0,
                  };

                  const foundStarZone = upcEl.starZoneList.find(
                     (sz) => sz.starZone === starZoneEl.name
                  );

                  if (foundStarZone && foundStarZone.segmentationList) {
                     upcObj.summedNumDoors = sumBy(
                        foundStarZone.segmentationList,
                        //"numDoor"
                        (segmentationItem) => {
                           return segmentationItem && segmentationItem.numDoor
                              ? segmentationItem.numDoor
                              : 0;
                        }
                     );
                     upcObj.summedSellOut = sumBy(
                        foundStarZone.segmentationList,
                        //"sellOut"
                        (segmentationItem) => {
                           return segmentationItem && segmentationItem.sellOut
                              ? segmentationItem.sellOut
                              : 0;
                        }
                     );

                     mappedTableData.push({
                        ...upcObj,
                        starZoneName: starZoneEl.name,
                     });
                  } else {
                     mappedTableData.push({
                        ...upcObj,
                        starZoneName: starZoneEl.name,
                     });
                  }
               });
            });
         }

         let mappedTableDataFinal = [];
         let mappedIndex = {};

         mappedTableData.forEach((row) => {
            //Begin: change from row.model to row.skuId
            if (
               isUndefined(mappedIndex[row.skuId]) ||
               isNull(mappedIndex[row.skuId])
            ) {
               mappedIndex[row.skuId] = mappedTableDataFinal.length;
            }

            if (!mappedTableDataFinal[mappedIndex[row.skuId]]) {
               mappedTableDataFinal[mappedIndex[row.skuId]] = [];
            }

            mappedTableDataFinal[mappedIndex[row.skuId]].push(row);
            //End: change from row.model to row.skuId
         });

         return mappedTableDataFinal;
      },
      []
   );

   // Calculate Table Data For every Segmentation and ceil's background color
   const setBackgroundColor = useCallback(
      (mappedTableDataFinal) => {
         let intervals = {
            interval01: {
               min: 0,
               max: 0,
            },
            interval02: {
               min: 0,
               max: 0,
            },
            interval03: {
               min: 0,
               max: 0,
            },
            interval04: {
               min: 0,
               max: 0,
            },
            interval05: {
               min: 0,
               max: 0,
            },
         };

         let arrOfEverySellOut = [];

         upcList &&
            upcList.flatMap((sku, i) => {
               if (mappedTableDataFinal) {
                  let obj = mappedTableDataFinal[i];

                  if (obj !== null && obj !== undefined && obj) {
                     if (Array.isArray(obj)) {
                        if (!isEmpty(obj)) {
                           if (!isUndefined(obj)) {
                              if (!isNull(obj)) {
                                 obj.map((el) => {
                                    return arrOfEverySellOut.push(
                                       el.summedSellOut
                                    );
                                 });
                              }
                           }
                        }
                     }
                  }
               }
            });

         const minValue = Math.min(...arrOfEverySellOut);
         const maxValue = Math.max(...arrOfEverySellOut);

         const diffMaxAndMin = maxValue - minValue;

         const intervalLength = Math.ceil(diffMaxAndMin / 5);

         let previewIntervalMax = 0;

         for (var i = 1; i <= 5; i++) {
            if (i === 1) {
               intervals.interval01.min = minValue + 1;
               intervals.interval01.max = minValue + intervalLength;

               previewIntervalMax = intervals.interval01.max;
            } else {
               let interval = {
                  min: previewIntervalMax + 1,
                  max: previewIntervalMax + intervalLength,
               };
               previewIntervalMax = interval.max;

               if (i === 2) {
                  intervals.interval02 = interval;
               } else if (i === 3) {
                  intervals.interval03 = interval;
               } else if (i === 4) {
                  intervals.interval04 = interval;
               } else if (i === 5) {
                  intervals.interval05 = interval;
               }
            }
         }

         let skuList =
            upcList &&
            upcList.map((sku, i) => {
               return (
                  mappedTableDataFinal &&
                  Array.isArray(mappedTableDataFinal[i]) &&
                  !isEmpty(mappedTableDataFinal[i]) &&
                  !isUndefined(mappedTableDataFinal[i]) &&
                  !isNull(mappedTableDataFinal[i]) &&
                  mappedTableDataFinal[i].map((el) => {
                     let intervalType = "";

                     if (el.summedSellOut === minValue) {
                        intervalType =
                           "PerformanceTable__min-value--background";
                     } else if (el.summedSellOut === maxValue) {
                        intervalType =
                           "PerformanceTable__max-value--background";
                     } else if (
                        intervals &&
                        intervals.interval01 &&
                        el.summedSellOut >= intervals.interval01.min &&
                        el.summedSellOut <= intervals.interval01.max
                     ) {
                        intervalType =
                           "PerformanceTable__first-interval--background";
                     } else if (
                        intervals &&
                        intervals.interval02 &&
                        el.summedSellOut >= intervals.interval02.min &&
                        el.summedSellOut <= intervals.interval02.max
                     ) {
                        intervalType =
                           "PerformanceTable__second-interval--background";
                     } else if (
                        intervals &&
                        intervals.interval03 &&
                        el.summedSellOut >= intervals.interval03.min &&
                        el.summedSellOut <= intervals.interval03.max
                     ) {
                        intervalType =
                           "PerformanceTable__third-interval--background";
                     } else if (
                        intervals &&
                        intervals.interval04 &&
                        el.summedSellOut >= intervals.interval04.min &&
                        el.summedSellOut <= intervals.interval04.max
                     ) {
                        intervalType =
                           "PerformanceTable__fourth-interval--background";
                     } else if (
                        intervals &&
                        intervals.interval05 &&
                        el.summedSellOut >= intervals.interval05.min &&
                        el.summedSellOut <= intervals.interval05.max
                     ) {
                        intervalType =
                           "PerformanceTable__fifth-interval--background";
                     }

                     return { ...el, typeInterval: intervalType };
                  })
               );
            });

         let skuListFinal = [];
         let skuIndex = {};

         skuList.forEach((row) => {
            //Begin: change from row.model to row.skuId
            if (
               isUndefined(skuIndex[row.skuId]) ||
               isNull(skuIndex[row.skuId])
            ) {
               skuIndex[row.skuId] = skuListFinal.length;
            }

            if (!skuListFinal[skuIndex[row.skuId]]) {
               skuListFinal[skuIndex[row.skuId]] = [];
            }

            skuListFinal[skuIndex[row.skuId]].push(row);
            //End: change from row.model to row.skuId
         });

         const mappedSkuList1 = skuListFinal.flatMap((el) => {
            return el;
         });

         const mappedSkuList2 = mappedSkuList1.flatMap((el) => {
            return el;
         });

         //const skuListBySku = groupBy(mappedSkuList2, "model");
         const skuListBySku = groupBy(mappedSkuList2, "skuId");

         return skuListBySku;
      },
      [upcList]
   );

   return (
      <PerformanceTable
         // elementScrollTop={elementScrollTop}
         showBoxShadowTableHead={showBoxShadowTableHead}
         showBoxShadowSkus={showBoxShadowSkus}
         upcList={upcList}
         headerTable={headerTable}
         tableContent={tableContent}
         percentual={percentual}
      />
   );
}

const mapStateToProps = (state) => {
   return {
      isSegmentationView: state.performancePopup.isSegmentationView,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setIsSegmentationView: (isSegmentationView) =>
         dispatch(actions.setIsSegmentationView(isSegmentationView)),
   };
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(PaginatedPerformanceTable);
