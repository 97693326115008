import { useEffect, useState, useCallback } from "react";
import CustomSelect from "./CustomSelect";

export function PaginatedSelect(props) {
   const [currentPage, setCurrentPage] = useState(1);
   const [data, setData] = useState(props.options);

   const paginate = useCallback(
      () => data.slice(0, 50 * currentPage),
      [currentPage, data]
   );

   const [paginatedOptions, setPaginatedOptions] = useState(paginate());

   useEffect(() => {
      setData(props.options);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      setData(props.options);
   }, [props.options]);

   useEffect(() => {
      setPaginatedOptions(paginate());
   }, [currentPage, paginate]);

   const incrementPagination = () => setCurrentPage(currentPage + 1);

   const filterElementByText = (searchText) => {
      if (searchText && searchText.length) {
         setPaginatedOptions(
            data.filter(
               (opt) =>
                  opt.value
                     .toString()
                     .toLowerCase()
                     .indexOf(searchText.toLowerCase()) >= 0
            )
         );
      } else {
         setPaginatedOptions(paginate());
      }
   };

   return (
      <CustomSelect
         {...props}
         options={paginatedOptions}
         onScrollBottom={incrementPagination}
         onSearch={filterElementByText}
      />
   );
}
