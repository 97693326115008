import React from "react";

export const Switch = ({ label, isChecked, onChange }) => {
   //label prop must be an array of 2 strings like =======> ['Label1','Label2']
   return (
      <div className="switch-container">
         <span className="switch-label">{isChecked ? label[1] : label[0]}</span>
         <label className="switch">
            <input onChange={onChange} type="checkbox" checked={isChecked} />
            <span className="slider round" />
         </label>
      </div>
   );
};
