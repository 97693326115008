import React, { useEffect, useState } from "react";

import Button from "../../UI-components/Button";
import ManageBrandPopupContent from "./ManageBrandPopupContent";
import { SAVE_BRANDS } from "../../../config";
import axios from "axios";
import { cloneDeep } from "lodash"
import { getCookie } from "../../../services/cookie";

const ManageBrandPopup = ({
    close,
    currentBrand,   
    users,
    updateBrandList,
    handleQueryUser,
    queryUser
}) => {

    const initialState = {
        brand: currentBrand.brand,
        permissionMap: cloneDeep(currentBrand.permissionMap)
    }

    const [selectedTab, setSelectedTab] = useState("view");
    const [grants, setGrants] = useState(initialState)
    const [saveGrants, setSaveGrants] = useState(false)
    const [modifiedGrants, setModifiedGrants] = useState(false)
    const [message, setMessage] = useState("")

    const allVisibleUsersAreSelected = () => {
        const visibleUserIds = users.map(user => user.userId)
        return visibleUserIds.every(userId => grants.permissionMap[selectedTab].includes(userId))
    }

    const handleSetGrant = (grantType, grantCode) => {

        const newGrants = cloneDeep(grants)

        if (grantCode === "ALL") {
            if (allVisibleUsersAreSelected()) {
                const visibleUserIds = users.map(user => user.userId)
                newGrants.permissionMap[selectedTab] = newGrants.permissionMap[selectedTab].filter(userId => {
                    return !visibleUserIds.includes(userId)
                })
            }
            else {
                if(selectedTab === "edit"){
                    newGrants.permissionMap["view"] = [...newGrants.permissionMap[selectedTab], ...users.map(user => user.userId)];
                }
                newGrants.permissionMap[selectedTab] = [...newGrants.permissionMap[selectedTab], ...users.map(user => user.userId)];
            }
            setGrants(newGrants)
            setModifiedGrants(true)
        }
        else {
            if (!newGrants.permissionMap[selectedTab].includes(grantCode)) {
                if(selectedTab === "edit"){
                    newGrants.permissionMap["view"].push(grantCode) // se aggiungo l'utente in edit, sarà aggiunto anche in view
                }
                newGrants.permissionMap[selectedTab].push(grantCode)
            } else {
                newGrants.permissionMap[selectedTab] = newGrants.permissionMap[selectedTab].filter(userId => {
                    return userId !== grantCode
                })
            }

            setGrants(newGrants)
            setModifiedGrants(true)
        }
    }

    const handleSaveBrand = () => {
        setSaveGrants(true)
    }

    const clearMessage = () => {
        setTimeout(() => {
            setMessage("")
        }, 3000)
    }

    useEffect(() => {
        if(saveGrants){
            axios.post(SAVE_BRANDS, grants, {
                headers:{
                    xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken")
                }
            })
            .then((response) => {
                updateBrandList(response.data)
                setMessage("Grants updated correctly")
            })
            .catch(() => {
                setMessage("Error during grants update")
            })
            .finally(() => {
                setSaveGrants(false)
                setModifiedGrants(false)
                clearMessage()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveGrants, grants])

    return (
        <div className="manage-user-popup-wrapper">
            <div className="popup-background" onClick={close} />
            <div className="popup-container-user">
                <div className="close-popup" onClick={close}>
                    <span className="icon-plus" />
                </div>
                <div className="popup-header">
                    <div className="section-container" style={{height: "100%"}}>
                        <div className="header-container">
                            <div>
                                <h3>{currentBrand.brand ? currentBrand.brand : null}</h3>
                            </div>
                            <div>
                                <Button
                                    btnClass="btn-medium"
                                    handleClick={handleSaveBrand}
                                    disabled={saveGrants || !modifiedGrants}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                        <div className="tab-container" style={{height: "100%"}}>
                            <div className="tab-header">
                                <div
                                    onClick={() => {
                                        setSelectedTab("view")
                                    }}
                                    className={`single-tab ${selectedTab === "view" ? "single-tab-active" : ""} `}
                                >
                                    <h4>View</h4>
                                </div>
                                <div
                                    onClick={() => {
                                        setSelectedTab("edit")
                                    }}
                                    className={`single-tab ${selectedTab === "edit" ? "single-tab-active" : ""} `}
                                >
                                    <h4>Edit</h4>
                                </div>
                                <div
                                    onClick={() => {
                                        setSelectedTab("submit")
                                    }}
                                    className={`single-tab ${selectedTab === "submit" ? "single-tab-active" : ""} `}
                                >
                                    <h4>Submit</h4>
                                </div>
                                <div className="single-tab" />
                            </div>
                            <div style={{
                                marginTop: "2rem"
                            }}>
                                {
                                    message &&
                                        <h3><strong>{message}</strong></h3>
                                }
                            </div>
                            <ManageBrandPopupContent
                                userList={users}
                                tabType={selectedTab}
                                handleSetGrant={handleSetGrant}
                                grants={grants}
                                handleQueryUser={handleQueryUser}
                                queryUser={queryUser}
                                allSelected={allVisibleUsersAreSelected}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageBrandPopup