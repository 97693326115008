import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../services/Api";

const initialState = {
  userList: null,
  prevPath: null,
  isLockModuleLoading: false,
  isLockMacroLoading: false,
  isLockClusterLoading: false,
  lockModule: null,
  lockMacro: null,
  lockClusterList: null,
  isCreatingUser: false,

  //manage user
  brandList: null,
  starsZoneList: null,
  ruleList: null,
  currentUser: null,
  userGrantsCopy: {},
  saveLoading: false,
  statusUser: null,
};

/* USER */
const getUserList = (state, action) => {
  return updateObject(state, { userList: action.userList });
};

/* LOCK */
const lockModuleRequest = (state) => {
  return updateObject(state, {
    isLockModuleLoading: true,
    lockModule: null,
  });
};

const lockModuleSuccess = (state, action) => {
  return updateObject(state, {
    isLockModuleLoading: false,
    lockModule: action.response,
  });
};

const lockModuleFailure = (state) => {
  return updateObject(state, { isLockModuleLoading: false });
};

const lockMacroRequest = (state) => {
  return updateObject(state, {
    isLockMacroLoading: true,
    lockMacro: null,
  });
};

const lockMacroSuccess = (state, action) => {
  return updateObject(state, {
    isLockMacroLoading: false,
    lockMacro: action.response,
  });
};

const lockMacroFailure = (state) => {
  return updateObject(state, {
    isLockMacroLoading: false,
  });
};

const lockClusterRequest = (state) => {
  return updateObject(state, {
    isLockClusterLoading: true,
    lockCluster: null,
  });
};

const lockClusterSuccess = (state, action) => {
  return updateObject(state, {
    isLockClusterLoading: false,
    lockCluster: action.response,
  });
};

const lockClusterFailure = (state) => {
  return updateObject(state, { isLockClusterLoading: false });
};

const createCreatorRequest = (state) => {
  return updateObject(state, {
    isCreatingUser: true,
  });
};

const createCreatorSuccess = (state, action) => {
  const userCreateResult = {
    type: "success",
    message: "The user has been added",
  };

  action.callback && action.callback(userCreateResult);
  return updateObject(state, {
    isCreatingUser: false,
    lockCluster: action.response,
  });
};

const createCreatorFailure = (state, action) => {
  let userCreateResult;

  if (action.response.message === "Error on user creation") {
    userCreateResult = {
      type: "error",
      message:
        "The user cannot be created because it is not on the Luxottica list",
    };
  } else {
    userCreateResult = {
      type: "warning",
      message: "The user is already present and can be edited",
    };
  }

  action.callback && action.callback(userCreateResult);

  return updateObject(state, { isCreatingUser: false });
};

const saveUserGrants = (state, action) => {
  state.currentUser.grants = action.grants;

  return action.isSavingDB
    ? updateObject(state, {
        currentUser: state.currentUser,
        userGrantsCopy: {
          ...state.userGrantsCopy,
          [state.currentUser.userId]: action.grants,
        },
      })
    : updateObject(state, {
        currentUser: state.currentUser,
      });
};

const getCurrentUser = (state, action) => {
  if (action.user) {
    const isSavedUser = Object.keys(state.userGrantsCopy).includes(
      action.user.userId
    );
    if (isSavedUser) {
      action.user.grants = state.userGrantsCopy[action.user.userId];
    }
    return updateObject(state, {
      currentUser: action.user,
      userGrantsCopy: {
        ...state.userGrantsCopy,
        [action.user.userId]: action.user.grants,
      },
    });
  } else {
    return updateObject(state, {
      currentUser: action.user,
    });
  }
};

const closeCurrentUser = (state, action) => {
  return updateObject(state, {
    userGrantsCopy: action.grants,
  });
};

const setSaveLoading = (state, action) => {
  return updateObject(state, {
    saveLoading: action.payload,
  });
};

const getManageBrandList = (state, action) => {
  return updateObject(state, {
    brandList: action.brandList,
  });
};

const getManageStarsZoneList = (state, action) => {
  return updateObject(state, {
    starsZoneList: action.starsZoneList,
  });
};

const getStatusUser = (state, action) => {
  const userList = [...state.userList];
  const curretUserIndex = userList.findIndex(
    (user) => user.userId === state.currentUser.userId
  );
  userList[curretUserIndex].status = action.statusUser;

  return updateObject(state, {
    currentUser: { ...state.currentUser, status: action.statusUser },
    userList,
  });
};

const getRuleList = (state, action) => {
  return updateObject(state, {
    ruleList: action.ruleList,
  });
};

const saveUserFailure = (state) => {
  return state;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERLIST:
      return getUserList(state, action);
    case actionTypes.CREATE_USER_CREATOR_REQUEST:
      return createCreatorRequest(state);
    case actionTypes.CREATE_USER_CREATOR_SUCCESS:
      return createCreatorSuccess(state, action);
    case actionTypes.CREATE_USER_CREATOR_FAILURE:
      return createCreatorFailure(state, action);
    case actionTypes.LOCK_MODULE_REQUEST:
      return lockModuleRequest(state);
    case actionTypes.LOCK_MODULE_SUCCESS:
      return lockModuleSuccess(state, action);
    case actionTypes.LOCK_MODULE_FAILURE:
      return lockModuleFailure(state);
    case actionTypes.UNLOCK_MODULE_REQUEST:
      return lockModuleRequest(state);
    case actionTypes.UNLOCK_MODULE_SUCCESS:
      return lockModuleSuccess(state, action);
    case actionTypes.UNLOCK_MODULE_FAILURE:
      return lockModuleFailure(state);
    case actionTypes.LOCK_MACRO_REQUEST:
      return lockMacroRequest(state);
    case actionTypes.LOCK_MACRO_SUCCESS:
      return lockMacroSuccess(state, action);
    case actionTypes.LOCK_MACRO_FAILURE:
      return lockMacroFailure(state);
    case actionTypes.UNLOCK_MACRO_REQUEST:
      return lockMacroRequest(state);
    case actionTypes.UNLOCK_MACRO_SUCCESS:
      return lockMacroSuccess(state, action);
    case actionTypes.UNLOCK_MACRO_FAILURE:
      return lockMacroFailure(state);
    case actionTypes.LOCK_CLUSTER_REQUEST:
      return lockClusterRequest(state);
    case actionTypes.LOCK_CLUSTER_SUCCESS:
      return lockClusterSuccess(state, action);
    case actionTypes.LOCK_CLUSTER_FAILURE:
      return lockClusterFailure(state);
    case actionTypes.UNLOCK_CLUSTER_REQUEST:
      return lockClusterRequest(state);
    case actionTypes.UNLOCK_CLUSTER_SUCCESS:
      return lockClusterSuccess(state, action);
    case actionTypes.UNLOCK_CLUSTER_FAILURE:
      return lockClusterFailure(state);
    case actionTypes.SAVE_USER_GRANT:
      return saveUserGrants(state, action);
    case actionTypes.GET_CURRENT_USER:
      return getCurrentUser(state, action);
    case actionTypes.CLOSE_CURRENT_USER:
      return closeCurrentUser(state, action);
    case actionTypes.SET_SAVE_LOADING:
      return setSaveLoading(state, action);
    case actionTypes.GET_MANAGE_BRANDLIST:
      return getManageBrandList(state, action);
    case actionTypes.GET_MANAGE_STARSZONELIST:
      return getManageStarsZoneList(state, action);
    case actionTypes.GET_STATUS_USER:
      return getStatusUser(state, action);
    case actionTypes.GET_RULE_LIST:
      return getRuleList(state, action);
    case actionTypes.SAVE_USER_FAILURE:
      return saveUserFailure(state);
    default:
      return state;
  }
};

export default reducer;
