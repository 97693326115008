import React, { Component } from "react";

class ClusterAnalysisModuleList extends Component {
    state = {
        moduleOpen: false,
        moduleList: [],
        moduleCheck:[],
        modulesOpen:[],
        cluster:"",
    }
    componentDidMount() {
        let modules = Object.entries(this.props.modules);
        this.setState({ moduleList: modules, moduleCheck: modules, modulesOpen: this.props.macroList,cluster: this.props.cluster });
    }

    componentDidUpdate(prevProps) {
        
    }
    searchModules=(e) => {  
        if(e.target.value!=null) {
            this.setState({ moduleList: this.state.moduleCheck.filter(module => module[0].toUpperCase().includes(e.target.value.toUpperCase())), })
        }
    }

    changeStatusList = () => {
        let modules = Object.entries(this.props.modules);
        this.setState({ moduleOpen: !this.state.moduleOpen ,moduleList: modules,})
    }
    isPresent = (moduleName) => {
        return this.state.modulesOpen.some(moduleOpen=>moduleOpen === moduleName)?"moduleOpenGreen": "moduleOpen"
    }
    render() {
        return (
             this.state.moduleList.length === 0 ? null : this.state.moduleOpen?
                (<div className={"listModulesOpen"} key={"listModulesOpen "+this.state.cluster}>
                    <input className="searchModules" type="text" id="searchModules" placeholder="Search a module..." onChange={this.searchModules}/>
                    <br/><br/>
                    <div className={"menuModulesOpen"}
                        key={"menuModulesOpen "+this.state.cluster}
                        onClick={this.changeStatusList}>
                        - Close Module List 
                    </div>
                    <br />
                    {this.state.moduleList.map((module) => {
                        return  (<div className={this.isPresent(module[0])} key={this.state.cluster}>
                                    {module[0]}
                                </div>)
                    })}
                </div>)
                :(<div className={"ModulesClosed"}
                        key={"ModulesClosed "+this.state.cluster}
                        onClick={this.changeStatusList}>
                            + Open Module List
                </div>)
        )
    }
}

export default ClusterAnalysisModuleList;
