import React, { useState } from "react";
import "./RetractableButton.scss";

export default function RetractableButton({
   children,
   disableOpening = false,
   customRender,
}) {
   const [openRetractableButton, setOpenRetractableButton] = useState(false);

   return (
      <>
         {openRetractableButton && (
            <div
               className="popup-background overlay"
               onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setOpenRetractableButton(!openRetractableButton);
               }}
            />
         )}
         <div className="shareButton__container">
            <div
               style={{
                  //    height: openRetractableButton ? "20vh" : "40px",
                  //    justifyContent: "center",
                  //    zIndex: openRetractableButton ? 700 : 1,
                  //    width: "7vw",
                  //    whiteSpace: "nowrap",
                  background: openRetractableButton ? "white" : "transparent",
                  border: openRetractableButton ? "1px solid" : "none",
               }}
               className="shareButton__wrapper"
            >
               {openRetractableButton &&
                  (customRender
                     ? customRender(() => setOpenRetractableButton(false))
                     : children)}

               {openRetractableButton ? (
                  <span
                     className="retractableButton__close--icon icon-close_icon"
                     onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setOpenRetractableButton(!openRetractableButton);
                     }}
                  />
               ) : (
                  <button
                     className="button btn-medium retractableButton__label"
                     onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setOpenRetractableButton(!openRetractableButton);
                     }}
                     disabled={disableOpening}
                  >
                     ACTION ALL
                  </button>
               )}
            </div>
         </div>
      </>
   );
}
