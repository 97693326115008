import React from "react";
import loaderImg from "../../img/Vector_loader.gif";

const loader = (props) => (
  <div className="loader-container">
    <img src={loaderImg} alt="Logo loader" />
    {!!props.exportLoading ? <h4>Exporting...</h4> : <h4>Loading...</h4>}
  </div>
);
export default loader;
