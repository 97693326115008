import React from "react";
import CustomIcon from "../../UI-components/CustomIcon";

const moduleJar = (props) => {
  let moduleItem = props.moduleItem;
  let name = moduleItem ? moduleItem.moduleLabel : "";
  let clusterCompositions= props.clusterComposition;
  let classListJar =
    (props.isDragStartSearch ? " dragStart" : "") +
    (props.draggable ? "" : " no-draggable ") +
    (moduleItem.wip ? " wip" : "") +
    (moduleItem.clusterCompositionStatus === "NEW" ? " new" : "")+
    (clusterCompositions?.some(m=>m===name)? " disabled ": "" ) +
    (props.isACommonModule ? " not-allowed " : "");
  
  //   ||(moduleItem.boStatus === "ADDED" &&
  //   moduleItem.boType !== "ALTERNATIVE" &&
  //   moduleItem.foType === "ALTERNATIVE")
  
  return (
    <React.Fragment>
      {moduleItem ? (
        <div
          className={"module-jar" + classListJar}
          // draggable={props.draggable}
          onDragStart={props.onDragStart}
          data-id={props.dataId}
        >
          <header className="header-jar">{name}</header>
          <div className={"body-jar"+ (clusterCompositions?.some(m=>m===name)? " disabled": "")} >
            <span onClick={() => props.goToSingleModule(moduleItem)}>
              <strong>{props.moduleItem.productCount} SKU</strong>
            </span>
          </div>

          {props.isAlt ? (
            <footer className={props.hideIcons ? "d-none" : ""}>
              {props.draggable ? (
                <CustomIcon
                  iconClass="icon-cestino"
                  handleClick={() => props.remove(moduleItem)}
                />
              ) : null}
              <span className="macro">{moduleItem.macroFamily}</span>
              <CustomIcon iconClass="icon-alternative not-pointer" />
            </footer>
          ) : (
            <React.Fragment>
              {props.isRemoved ? (
                <footer className={props.hideIcons ? "d-none" : ""}>
                  <CustomIcon
                    iconClass="icon-import"
                    handleClick={() => props.addToClusterComp(moduleItem)}
                  />
                </footer>
              ) : (
                <React.Fragment>
                  {props.draggable ? (
                    <footer className={props.hideIcons ? "d-none" : ""}>
                      <CustomIcon
                        iconClass="icon-cestino"
                        handleClick={() => props.remove(moduleItem)}
                      />
                    </footer>
                  ) : null}
                  {props.isAdded || props.isSwitched ? (
                    <footer className="label-preview-container">
                      {props.isAdded ? <span>ADDED</span> : null}
                      {props.isSwitched ? <span>SWITCHED</span> : null}
                    </footer>
                  ) : null}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default moduleJar;
