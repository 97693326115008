import React, { Component } from "react";
import Button from "../UI-components/Button";
import CustomIcon from "../UI-components/CustomIcon";
import Loader from "../UI-components/Loader";
import { connect } from "react-redux";

class exportItem extends Component {
   constructor(props) {
      super(props);
      this.state = {
         selectedModules: [],
         singleItem: props.item,
         selectedClusters: [],
         allModules: [],
         allClusters: [],
         reloadingItem: props.reloadingItem,
      };
   }

   componentDidUpdate = (prevProps) => {
      if (this.props.type === "module") {
         if (prevProps.exportFinished !== this.props.exportFinished) {
            this.setState({
               selectedModules: [],
               allModules: [],
         })};
      } else {
         if (prevProps.exportFinished !== this.props.exportFinished) {
            this.setState({
            selectedClusters: [],
            allClusters: [],
         })};
      }
   };    

   render() {
      let {
         type,
         results,
         isLoadingExport,
         exportingModules,
         exportingClusters,
         exportClusterPopup,
         exportEl,
      } = this.props;
      

      return (   
         <div className="list">
            {type === "module"
               ? results &&
               Array.isArray(results) &&
               (
                  <div
                     className="lists-item-container"
                  >
                  
                  <div className={`lists-item ${ this.props.selected ? "selected" : ""}`}
                       onClick={() => this.props.selectItem(this.state.singleItem, "module")}
                  >
                     <div className="text">{this.state.singleItem ? this.state.singleItem.moduleLabel : ""}</div>
                     <div className="text">{this.state.singleItem ? this.state.singleItem.monthId : ""}</div>
                  </div>

                  <CustomIcon
                           iconContClass="icon-container-export"
                           iconClass="icon-arrow2" 
                  />
                  <Button
                     btnClass="btn-medium"
                     disabled={isLoadingExport && this.state.singleItem &&
                           exportingModules.includes(this.state.singleItem.moduleName)}
                     handleClick={() => {
                        exportEl(this.state.singleItem);
                        exportClusterPopup();
                     }}
                  
                  >
                     Export
                  </Button>      
                  </div>
               )
               : results &&
               Array.isArray(results) &&
               (
                  <div
                     className="lists-item-container"
                     key={this.state.singleItem.clusterId + this.state.singleItem.monthId}
                  >
                     <div className={`lists-item ${ this.props.selected ? "selected" : ""}`}
                          onClick={() => this.props.selectItem(this.state.singleItem, "cluster")}
                  >
                        <div className="text">{this.state.singleItem ? this.state.singleItem.clusterLabel : ""}</div>
                        <div className="text">{this.state.singleItem ? this.state.singleItem.monthId : ""}</div>
                     </div>
                     <CustomIcon
                        iconContClass="icon-container"
                        iconClass="icon-arrow2" />

                     <Button
                        btnClass="btn-medium"
                        disabled={isLoadingExport && this.state.singleItem &&
                           exportingClusters.includes(this.state.singleItem.clusterId)}
                        handleClick={() => {
                           exportEl(this.state.singleItem);
                           exportClusterPopup();
                        } }
                     >
                        Export
                     </Button>
                  </div>
               )}
            {isLoadingExport && (
               <div className="results-container component export-loader">
                  <Loader exportLoading />
               </div>
            )}
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      isLoadingExportCluster: state.cluster.isLoading,
      isLoadingExportModule: state.module.isLoading,
      selectedFilters: state.filter.exportSelectedFilters,
   };
};

export default connect(mapStateToProps)(exportItem);
