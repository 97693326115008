/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { connect } from "react-redux";
// import * as _ from "lodash";
import * as actions from "../../store/actions";
import CustomSearch from "../UI-components/CustomSearch";
import CustomInput from "../UI-components/CustomInput";
import Button from "../UI-components/Button";
import CustomSelect from "../UI-components/CustomSelect";
import CustomIcon from "../UI-components/CustomIcon";
import { Message } from "../UI-components/FlashMessage";
import Loader from "../UI-components/Loader";
import Popup from "../UI-components/Popup";
import ManageUserPopup from "./ManageUserPopup/ManageUserPopup";
import {
   escapeRegexCharacters,
   formatOptions,
   handleSearchResults,
} from "../../services/Api";

class UserComponent extends Component {
   constructor(props) {
      super(props);

      this.state = {
         userList: null,
         countryList: null,
         isCountryPopupOpen: false,
         addEmailDisabled: true,
         newUserEmail: "",
         valueSearch: "",
         countryNewUser: null,
         countryNewUserDisabled: true,
         addNewUser: false,
         showUserPopup: false,
         currentUser: null,
         flashMessage: [],
      };

      this.handleSearch = this.handleSearch.bind(this);
   }

   componentDidMount = () => {
      this.props.getCountryList();
      this.props.getUserList();
      this.props.getManageBrandList();
      this.props.getManageStarsZoneList();
      this.props.getRuleList();

      // if (this.props.countryList)
      //   this.setState({ countryList: this.props.countryList });
      // if (this.props.userList)
      //   this.setState({ userList: _.cloneDeep(this.props.userList) });
   };

   componentDidUpdate = (prevProps) => {
      if (prevProps.countryList !== this.props.countryList)
         this.setState({ countryList: this.props.countryList });
      if (prevProps.userList !== this.props.userList)
         this.setState({ userList: this.props.userList });

      if (
         prevProps.isCreatingUser !== this.props.isCreatingUser &&
         !this.props.isCreatingUser
      ) {
         this.props.getUserList();
      }
   };

   togglePopup = (stateName) =>
      this.setState((prevState) => {
         return { [stateName]: !prevState[stateName] };
      });

   handleSearch = (e) => {
      let userList = [...this.props.userList];
      let userObj = handleSearchResults(e, userList, "user");

      this.setState({
         userList: userObj.listItem,
         valueSearch: userObj.valueSearch,
      });
   };

   setSelectStatus = (e) => {
      const status = e.value;
      const escapedValue = escapeRegexCharacters(status.trim());
      const regex = new RegExp("\\b" + escapedValue, "i");
      let userList = this.props.userList.filter((item) => {
         return regex.test(item.status);
      });
      this.setState({ userList });
   };

   handleClick = () => this.togglePopup("isCountryPopupOpen");

   closePopup = () => {
      this.togglePopup("isCountryPopupOpen");
      this.setState({
         countryNewUser: null,
      });
   };

   setCountryNewUser = (e) => {
      this.setState({
         countryNewUser: e.value,
         countryNewUserDisabled: false,
      });
   };

   confirmNewUserHandler = () => {
      let email = this.state.newUserEmail;
      let country = this.state.countryNewUser;
      let newUser = {
         email: email,
         country: country,
      };
      this.props.createCreator(newUser);
      this.setState({
         isCountryPopupOpen: false,
         newUserEmail: "",
      });
   };

   checkUserEmail = (email) => {
      let emailIsValid = false;
      var re =
         /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      emailIsValid = re.test(String(email).toLowerCase());
      emailIsValid
         ? this.setState({ addEmailDisabled: false })
         : this.setState({ addEmailDisabled: true });
   };

   setNewUserMail = (ev) => {
      let newMail = ev.target.value;
      this.checkUserEmail(newMail);
      this.setState({
         newUserEmail: newMail,
      });
   };

   toggleAddNewUser = () => {
      this.setState({
         addNewUser: !this.state.addNewUser,
      });
   };

   toggleShowUserPopup = (user) => {
      this.setState({
         showUserPopup: !this.state.showUserPopup,
         currentUser: user,
      });

      if (!this.state.showUserPopup) {
         this.props.getCurrentUser(user);
      } else {
         this.props.getCurrentUser(null);
      }
   };

   showMessage = (userCreateResult) => {
      this.setState((prevState) => {
         prevState.flashMessage.unshift(
            <Message
               key={(prevState.flashMessage + 1).toString()}
               type={userCreateResult.type}
               message={userCreateResult.message}
            />
         );
      });
      setTimeout(() => {
         this.setState({
            flashMessage: [],
         });
      }, 4000);
   };

   handleAddUser = () => {
      this.toggleAddNewUser();

      this.props.createCreator(
         { email: this.state.newUserEmail.toLowerCase() },
         this.showMessage
      );
   };

   render() {
      let userList = this.state.userList;
      let countryList = this.state.countryList;

      let stateList = ["enabled", "disabled", "New"];
      const collectionFormatArray = stateList.map((status) => {
         return { label: status, value: status };
      });

      return (
         <div className="col-12 user-manage-container">
            <div className="table-container">
               <div
                  className={
                     "table-header" +
                     (this.state.addNewUser ? " table-header--long" : "")
                  }
               >
                  <div className="add-new-container">
                     <Button
                        btnClass="btn-add-user"
                        handleClick={this.toggleAddNewUser}
                     >
                        Add new
                     </Button>
                  </div>
                  <div className="search-container-table">
                     <div className="input-container inner-box-email">
                        <div className="inner-input">
                           <p className="user-p">Email</p>
                           <CustomSearch
                              inputClass="input-big"
                              handleChange={this.handleSearch}
                              value={this.state.valueSearch}
                              placeholder="Search user"
                           />
                        </div>
                        <div className="input-container  inner-box-email">
                           <div className="inner-input">
                              <p className="user-p">Status</p>

                              <CustomSelect
                                 placeholder="Select Status"
                                 handleChange={this.setSelectStatus}
                                 options={formatOptions(
                                    collectionFormatArray,
                                    "value"
                                 )}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
                  {this.state.addNewUser && (
                     <div className="add-user">
                        <ul className="table-item add-user-container user-header-item">
                           <li className="email">
                              <div className="inner-icon-black">
                                 <CustomIcon
                                    iconClass="icon-user_gray"
                                    iconContClass="input-search"
                                 />
                              </div>
                              <CustomInput
                                 inputClass="input-naked"
                                 type="email"
                                 placeholder="insert email of new user"
                                 handleChange={this.setNewUserMail}
                                 value={this.state.newUserEmail}
                              />
                           </li>
                           <li className="new-text">New</li>
                           <li className="add">
                              <div className="inner-add">
                                 <CustomIcon
                                    iconClass="icon-close_icon user-table-icon"
                                    handleClick={this.toggleAddNewUser}
                                 />
                                 <CustomIcon
                                    iconClass="icon-check-mark-icon user-table-icon"
                                    handleClick={this.handleAddUser}
                                 />
                              </div>
                           </li>
                        </ul>
                     </div>
                  )}
               </div>
               {!!this.state.flashMessage.length && this.state.flashMessage[0]}

               <div
                  className={
                     "table-body" +
                     (this.state.addNewUser ? " table-body--long" : "")
                  }
               >
                  {userList ? (
                     <div className="table-body-container">
                        {userList.map((user) => (
                           <ul
                              className="table-item user-item"
                              key={user.email}
                           >
                              <li className="email">
                                 <div className="inner-icon-black">
                                    <CustomIcon iconClass="icon-user_black" />
                                 </div>
                                 <div className="inner-email">{user.email}</div>
                              </li>
                              <li>{user.status}</li>
                              <li className="add">
                                 <div className="inner-add-bottom">
                                    <div
                                       className="icon-container-bottom"
                                       onClick={() => {
                                          this.toggleShowUserPopup(user);
                                       }}
                                    >
                                       <CustomIcon iconClass="icon-arrow2 user-table-icon" />
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        ))}
                     </div>
                  ) : (
                     <div className="full-page">
                        <Loader />
                     </div>
                  )}
               </div>
               {this.state.isCountryPopupOpen ? (
                  <Popup
                     title="Select a Country"
                     close={this.closePopup}
                     countryList={this.state.countryLIst}
                     btnName="Confirm User"
                     disabled={this.state.countryNewUserDisabled}
                     handleClick={this.confirmNewUserHandler}
                  >
                     {countryList ? (
                        <CustomSelect
                           placeholder="Select country"
                           options={formatOptions(
                              this.state.countryList,
                              "value"
                           )}
                           handleChange={this.setCountryNewUser}
                        />
                     ) : null}
                  </Popup>
               ) : null}
               {this.state.showUserPopup ? (
                  <ManageUserPopup
                     brandList={this.props.brandList}
                     close={this.toggleShowUserPopup}
                     starsZoneList={this.props.starsZoneList}
                  />
               ) : null}
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      userList: state.user.userList,
      countryList: state.filter.countryAllList,
      isCreatingUser: state.user.isCreatingUser,
      brandList: state.user.brandList,
      starsZoneList: state.user.starsZoneList,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getRuleList: () => dispatch(actions.getRuleList()),
      getCountryList: () => dispatch(actions.getAllCountries()),
      getUserList: () => dispatch(actions.getUserList()),
      createCreator: (param, callback) =>
         dispatch(actions.createCreator(param, callback)),
      getCurrentUser: (user) => dispatch(actions.getCurrentUser(user)),
      getManageBrandList: () => dispatch(actions.getManageBrandList()),
      getManageStarsZoneList: () => dispatch(actions.getManageStarsZoneList()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
