/* eslint-disable array-callback-return */
import React, { Component } from "react";
import Popup from "../UI-components/Popup";
import CustomInput from "../UI-components/CustomInput";
import CustomIcon from "../UI-components/CustomIcon";
import CustomSelect from "../UI-components/CustomSelect";
import { escapeRegexCharacters } from "../../services/Api";

class ImportModulePopup extends Component {
  state = {
    valueSearch: "",
    valueFamily: "",
    selectedModules: [],
    moduleListResult: null,
  };
  componentDidMount = () => {
    this.setState({
      moduleListResult: [...this.props.moduleListResult],
    });
    if (this.props.macroList) {
      if (this.props.macroList.length === 1) {
        this.setState({
          valueFamily: {
            label: this.props.macroList[0].macroFamily,
            value: this.props.macroList[0].macroFamily,
          },
        });
      }
    }
  };

  checkIfSelected = (singleItem) => {
    return this.state.selectedModules.filter((moduleItem) => {
      if (moduleItem.moduleName === singleItem.moduleName) {
        return true;
      }
    });
  };

  handleSearch = (e) => {
    let valueSearch = e.target.value,
      moduleListResult;

    const escapedValue = escapeRegexCharacters(valueSearch.trim());
    if (escapedValue === "")
      moduleListResult = { ...this.props.moduleListResult };

    const regex = new RegExp("\\b" + escapedValue, "i");

    moduleListResult = this.props.moduleListResult.filter((moduleItem) => {
      return regex.test(moduleItem.moduleName);
    });

    this.setState({
      moduleListResult,
      valueSearch,
    });
  };

  handleFamily = (value) => this.setState({ valueFamily: value });

  handleClick = (singleItem) => {
    let selectedModules = [...this.state.selectedModules],
      singleModule = {
        moduleName: singleItem.moduleName,
        monthId: singleItem.monthId,
      },
      counter = 0;

    if (selectedModules.length > 0) {
      //togli elemento se c'è già
      selectedModules = selectedModules.filter((moduleItem) => {
        if (moduleItem.moduleName === singleModule.moduleName) {
          counter++;
          return false;
        } else return true;
      });

      if (counter === 0) {
        selectedModules.push(singleModule);
      }
    } else {
      selectedModules.push(singleModule);
    }

    this.setState({ selectedModules });
  };

  handleImport = () => {
    this.props.importModules(
      this.state.valueFamily.value,
      this.state.selectedModules
    );
    this.props.close();
  };

  render() {
    const checkImport =
      this.state.selectedModules.length > 0 &&
      (this.state.valueFamily.length > 0 ||
        Object.keys(this.state.valueFamily).length > 0);
    let options = this.props.macroList
      ? this.props.macroList.map((macro) => {
          return {
            label: macro.macroFamily,
            value: macro.macroFamily,
          };
        })
      : [];
    return (
      <React.Fragment>
        {this.state.moduleListResult ? (
          <Popup
            close={this.props.close}
            title="Import existent module"
            btnName="Import"
            disabled={!checkImport}
            handleClick={this.handleImport.bind(this)}
            popupClass="popup-medium"
          >
            {/* <CustomInput handleChange={this.props.handleSearch} value={this.props.valueSearch} placeholder="Search a module"/> */}
            <div className="header-import">
              {options.length > 1 ? (
                <CustomSelect
                  selectClass="select-popup"
                  placeholder="Choose Family"
                  options={options}
                  value={this.state.valueFamily}
                  handleChange={this.handleFamily}
                />
              ) : (
                <p>
                  {" "}
                  Macrofamily: <span className="bold">
                    {options[0].label}
                  </span>{" "}
                </p>
              )}
              <CustomInput
                inputClass="input-big"
                handleChange={this.handleSearch}
                value={this.state.valueSearch}
                placeholder="Search a module"
              />
            </div>
            <ul className="list-small">
              {this.state.moduleListResult.map((moduleItem) => {
                let checkArray = this.checkIfSelected(moduleItem);
                return (
                  <li
                    className={
                      "component clickable " +
                      (checkArray.length > 0 ? "selected" : "")
                    }
                    onClick={() => this.handleClick(moduleItem)}
                    key={moduleItem.moduleLabel}
                  >
                    {moduleItem.moduleLabel}
                    <CustomIcon iconClass="icon-import" />
                  </li>
                );
              })}
            </ul>
          </Popup>
        ) : null}
      </React.Fragment>
    );
  }
}
export default ImportModulePopup;
