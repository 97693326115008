import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
import { formatOptions, orderByDate } from '../../services/Api';
import { getCookie } from '../../services/cookie';
import { showError } from './errorActions';
import { handleErrorsJson } from '../../services/Api';

/* GET RELEASE */
export const getReleaseList = () => {
    return dispatch => {   
        let url = urlList.GET_RELEASE_URL;
       
        fetch(url, {
            method:'get',
            headers: {"xAuthLuxotticaToken": getCookie('xAuthLuxotticaToken') }
        })
        .then( res => handleErrorsJson(res, dispatch) )
        .then( json => {               
            if(json) json = formatOptions(json, 'releaseId');                
            dispatch(getReleaseSuccess(json));
        })
        .catch(err => {
            dispatch(showError(err))                
        });         
    }      
};

const getReleaseSuccess = releaseList => {
    let orderedReleaseList = orderByDate('asc', releaseList, 'creationDate');    
    return {
        type: actionTypes.SET_RELEASELIST_SUCCESS,
        releaseList: orderedReleaseList.sortedList
    };
};

/**** NEW RELEASE ***/
export const createNewRelease = release => {
    let params = {
        releaseId: release
    };  

    return dispatch => {   
        let url = urlList.CREATE_RELEASE;
        dispatch(createReleaseRequest());

        fetch(url, { 
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
                "xAuthLuxotticaToken": getCookie('xAuthLuxotticaToken')  
            },
            body: JSON.stringify(params)
        })
        .then(res => handleErrorsJson(res, dispatch))           
        .then( json => {
            dispatch(createReleaseSuccess(json));
        })
        .catch(err => {
            dispatch(showError(err));
            dispatch(createReleaseFailure());               
        }); 
    }         
}   
const createReleaseRequest = () => {    
    return {
        type: actionTypes.CREATE_RELEASE_REQUEST,        
    };
};

const createReleaseSuccess = releaseList => {
    let orderedReleaseList = orderByDate('asc', releaseList, 'creationDate');    
    return {
        type: actionTypes.CREATE_RELEASE_SUCCESS,
        releaseList: orderedReleaseList.sortedList
    };
};

const createReleaseFailure = () => {
    return {
        type: actionTypes.CREATE_RELEASE_FAILURE,        
    };
};


/** CHANGE STATUS RELEASE */
export const changeStatusRelease = release => {
    
    let params = {
        releaseId: release.releaseId
    }
    let url = urlList.OPEN_CLOSE_RELEASE;

    return (dispatch, getState) => {   
        fetch(url, { 
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
                "xAuthLuxotticaToken": getCookie('xAuthLuxotticaToken') 
            },
            body: JSON.stringify(params)
        })
        .then( res => handleErrorsJson(res, dispatch) )
        .then( json => {          
                    
            let selectedRel = getState().filter.selectedFilters.release;
            if(selectedRel && selectedRel.releaseId === release.releaseId) {
                let status;
                json.forEach(modRelease => {
                    if(release.releaseId === modRelease.releaseId) {
                        status = modRelease.status
                    }
                })
                dispatch(setReleaseStatus(status));
                dispatch(changeSelectedFilters(status));
            }

            dispatch(getReleaseSuccess(json));
        })
        .catch(err => {
            dispatch(showError(err))                
        }); 
    }        
}

/** PUBLISH RELEASE FO*/
export const publishRelease = releaseId => {
    let params = {releaseId: releaseId};
   
    return dispatch => {   
        let url = urlList.PUBLISH_FO_RELEASE;

        fetch(url, { 
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
                "xAuthLuxotticaToken": getCookie('xAuthLuxotticaToken') 
                },
            body: JSON.stringify(params)
        })
        .then( res => handleErrorsJson(res, dispatch) )
        .then( json => { 
            dispatch(getReleaseSuccess(json));
        })
        .catch(err => {
            dispatch(showError(err))                
        });  
    }         
} 

/** PUBLISH RELEASE VIEWER */
export const publishViewerRelease = releaseId => {
    let params = {releaseId: releaseId};
   
    return dispatch => {   
        let url = urlList.PUBLISH_VIEWER_RELEASE;

        fetch(url, { 
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
                "xAuthLuxotticaToken": getCookie('xAuthLuxotticaToken') 
                },
            body: JSON.stringify(params)
        })
        .then( res => handleErrorsJson(res, dispatch) )
        .then( json => { 
            dispatch(getReleaseSuccess(json));
        })
        .catch(err => {
            dispatch(showError(err))                
        });  
    }         
} 

/* SET STATUS RELEASE IN APP */
export const setReleaseStatus = status => {    
    return {
        type: actionTypes.SET_STATUS_RELEASE,
        status: status
    }
}

/* CHANGE STATUS RELEASE IN SELECTED FILTERS */
export const changeSelectedFilters = status => {    
    return {
        type: actionTypes.CHANGE_SELECTED_FILTERS,
        status: status
    }
}
