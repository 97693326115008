import React from "react"

import Chip from "../../UI-components/Chip"
import CustomInput from "../../UI-components/CustomInput"

const UserChip = ({
    user,
    handleSetGrant,
    selected
}) => {
    return (
        <div
            className="chip-container"
        >
            <Chip
                label={`${user.firstName} ${user.lastName}`}
                code={user.userId}
                handleClick={handleSetGrant}
                type="user"
                icon="icon-plus"
                active={selected}
            />
        </div>
    )
}

const ManageBrandPopupContent = ({
    userList,
    tabType,
    handleSetGrant,
    grants,
    handleQueryUser,
    queryUser,
    allSelected
}) => {
 
    const isUserEnabledForTab = (user, tabType) => {
        const enabledUsersInTab = grants.permissionMap[tabType];
        return enabledUsersInTab.includes(user.userId);
    }

    return (
        <div className="tab-content">
            <div className="full-section">
                <div className="small-column">
                    <p style={{ marginTop: "8px" }}>User</p>
                </div>
                <div className="large-column" style={{
                    maxHeight: "none",
                    marginBottom: "6rem",
                    padding: "0px 12px"
                }}>
                    {
                        <>
                            <div style={{
                                width: "100%",
                                marginBottom: "2rem"
                            }}>
                                <CustomInput
                                    placeholder="Search user"
                                    inputClass="input-big"
                                    handleChange={handleQueryUser}
                                    value={queryUser}
                                />
                            </div>
                            {userList.map(user => {
                                return (
                                    <UserChip
                                        user={user}
                                        handleSetGrant={handleSetGrant}
                                        key={user.userId}
                                        selected={isUserEnabledForTab(user, tabType)}
                                    />
                                )
                            })}
                        </>
                    }
                </div>
                <div className="chip-all">
                    <Chip
                        classChip="chip "
                        label="ALL"
                        handleClick={handleSetGrant}
                        type="user"
                        code="ALL"
                        icon="icon-plus"
                        active={allSelected()}
                    />
                </div>
            </div>
        </div>
    )
}

export default ManageBrandPopupContent