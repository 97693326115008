import React from "react";
import CustomIcon from "../../UI-components/CustomIcon";

const listItem = ({
  listItemClass,
  handleClick,
  children,
  iconClass,
  isDisabled,
}) => {
  return (
    <li
      className={`lists-item ${listItemClass}${isDisabled ? " disabled" : ""}`}
      onClick={() => {
        !isDisabled && handleClick();
      }}
    >
      <div className="text">{children}</div>
      <CustomIcon iconClass={iconClass} />
    </li>
  );
};

export default listItem;
