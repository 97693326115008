import * as actionTypes from "./actionTypes";

export const showError = (message, showMessage = false) => {
  return {
    type: actionTypes.SHOW_ERROR_MESSAGE,
    message,
    showMessage,
  };
};

export const closeAlert = () => {
  return {
    type: actionTypes.HIDE_ERROR_MESSAGE,
  };
};
