export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";

/** GET FILTERS */
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_SELECTED_FILTERS_DISCONTINUED =
   "SET_SELECTED_FILTERS_DISCONTINUED";
//NOT FILTER
export const SET_ROWS_TO_SEND_MASSIVE_EDIT = "SET_ROWS_TO_SEND_MASSIVE_EDIT";
export const SET_RESET_ROWS_TO_SEND_MASSIVE_EDIT =
   "SET_RESET_ROWS_TO_SEND_MASSIVE_EDIT";
export const SET_SAVABLE_PUBLISHABLE_MODULES =
   "SET_SAVABLE_PUBLISHABLE_MODULES";

export const GET_INITIALFILTERS_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_RELEASE_SUCCESS = "GET_RELEASE_SUCCESS";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_PLANNING_SUCCESS = "GET_PLANNING_SUCCESS";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const SET_EXPORT_FILTER_ISLOADING = "SET_EXPORT_FILTER_ISLOADING";

export const TOGGLE_FILTER_HOME = "TOGGLE_FILTER_HOME";

export const GET_EXPORT_FILTER_SUCCESS = "GET_EXPORT_FILTER_SUCCESS";

export const GET_EXPORT_SEARCH_RESULTS_REQUEST =
   "GET_EXPORT_SEARCH_RESULTS_REQUEST";
export const GET_EXPORT_SEARCH_RESULTS_SUCCESS =
   "GET_EXPORT_SEARCH_RESULTS_SUCCESS";
export const GET_EXPORT_SEARCH_RESULTS_FAILURE =
   "GET_EXPORT_SEARCH_RESULTS_FAILURE";
export const RESET_EXPORT_SEARCH = "RESET_EXPORT_SEARCH";
export const CLEAN_EXPORT_FILTERS = "CLEAN_EXPORT_FILTERS";
export const SET_EXPORT_SELECTED_FILTERS = "SET_EXPORT_SELECTED_FILTERS";
export const CLEAN_SELECTED_EXPORT_FILTERS = "CLEAN_SELECTED_EXPORT_FILTERS";

export const IS_LOADING_EXPORT_MODULE_REQUEST =
   "IS_LOADING_EXPORT_MODULE_REQUEST";
export const IS_LOADING_EXPORT_MODULE_SUCCESS =
   "IS_LOADING_EXPORT_MODULE_SUCCESS";
export const IS_LOADING_EXPORT_MODULE_FAILURE =
   "IS_LOADING_EXPORT_MODULE_FAILURE";

export const IS_LOADING_EXPORT_CLUSTER_REQUEST =
   "IS_LOADING_EXPORT_CLUSTER_REQUEST";
export const IS_LOADING_EXPORT_CLUSTER_SUCCESS =
   "IS_LOADING_EXPORT_CLUSTER_SUCCESS";
export const IS_LOADING_EXPORT_CLUSTER_FAILURE =
   "IS_LOADING_EXPORT_CLUSTER_FAILURE";

export const GET_ALL_LIA_SUCCESS = "GET_ALL_LIA_SUCCESS";

/** LIST RESULTS HOME **/
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MACRO_SUCCESS = "GET_MACRO_SUCCESS";
export const GET_CLUSTERS_SUCCESS = "GET_CLUSTERS_SUCCESS";
export const GET_HOME_FILTER_FAILURE_TO_MANY_EL =
   "GET_HOME_FILTER_FAILURE_TO_MANY_EL";

export const GET_MODULE_FILTER_SUCCESS = "GET_MODULE_FILTER_SUCCESS";

export const GET_FILTERS_OPTIONS_SUCCESS = "GET_FILTERS_OPTIONS_SUCCESS";
export const GET_STORE_ID_SUCCESS = "GET_STORE_ID_SUCCESS";
export const GET_CODICE_STORE_SAP_REQUEST = "GET_CODICE_STORE_SAP_REQUEST";
export const GET_CODICE_STORE_SAP_SUCCESS = "GET_CODICE_STORE_SAP_SUCCESS";

export const DARKEN_SEARCH_RESULTS = "DARKEN_SEARCH_RESULTS";
export const GET_ALL_MACRO_SUCCESS = "GET_ALL_MACRO_SUCCESS";

export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const TOGGLE_POPUP_UNLOCK_MODULE = "TOGGLE_POPUP_UNLOCK_MODULE";

/** PARAM TO GET INFO */
export const SET_CLUSTER = "SET_CLUSTER";

/*** CREATE NEW MODULE */
export const CREATE_NEW_MODULE = "CREATE_NEW_MODULE";
export const ADD_NEW_MODULE = "ADD_NEW_MODULE";

/** CREATE MACRO */
export const CREATE_MACROFAMILY = "CREATE_MACROFAMILY";

/** CLUSTER COMPOSITION */
export const SET_NEW_CLUSTER = "SET_NEW_CLUSTER";

// export const GET_ALT_MODULE_REQUEST = "GET_ALT_MODULE_REQUEST";
// export const GET_ALT_MODULE_SUCCESS = "GET_ALT_SUCCESS";

export const GET_CLUSTER_REQUEST = "GET_CLUSTER_REQUEST";
export const GET_SINGLE_CLUSTER_SUCCESS = "GET_SINGLE_CLUSTER_SUCCESS";
export const GET_WORKING_CLUSTER_SUCCESS = "GET_WORKING_CLUSTER_SUCCESS";
export const GET_CLUSTER_FAILURE = "GET_CLUSTER_FAILURE";

export const SAVE_CLUSTER_STARTED = "SAVE_CLUSTER_STARTED";
export const SAVE_CLUSTER_SUCCESS = "SAVE_CLUSTER_SUCCESS";
export const SAVE_CLUSTER_LIST_SUCCESS = "SAVE_CLUSTER_LIST_SUCCESS";
export const SAVE_CLUSTER_FAILURE = "SAVE_CLUSTER_FAILURE";

export const DELETE_CLUSTER_STARTED = "DELETE_CLUSTER_STARTED";
export const DELETE_CLUSTER_SUCCESS = "DELETE_CLUSTER_SUCCESS";
export const DELETE_CLUSTER_FAILURE = "DELETE_CLUSTER_FAILURE";

export const PUBLISH_CLUSTER_STARTED = "PUBLISH_CLUSTER_STARTED";
export const PUBLISH_CLUSTER_SUCCESS = "PUBLISH_CLUSTER_SUCCESS";
export const PUBLISH_CLUSTER_LIST_SUCCESS = "PUBLISH_CLUSTER_LIST_SUCCESS";
export const PUBLISH_CLUSTER_FAILURE = "PUBLISH_CLUSTER_FAILURE";
export const CHECK_PUBLISH_CLUSTER_STARTED = "CHECK_PUBLISH_CLUSTER_STARTED";
export const CHECK_PUBLISH_CLUSTER_SUCCESS = "CHECK_PUBLISH_CLUSTER_SUCCESS";
export const CHECK_PUBLISH_CLUSTER_FAILURE = "CHECK_PUBLISH_CLUSTER_FAILURE";

export const IS_NOT_SAVED = "IS_NOT_SAVED";

export const SEARCH_MODULES_START = "SEARCH_MODULES_START";
export const SEARCH_MODULES_SUCCESS = "SEARCH_MODULES_SUCCESS";
export const SEARCH_MODULES_FAILURE = "SEARCH_MODULES_FAILURE";

export const CLEAR_CLUSTER_STATE = "CLEAR_CLUSTER_STATE";

export const GET_PERC_OF_REFRESH_CLUSTER_SUCCESS ="GET_PERC_OF_REFRESH_CLUSTER_SUCCESS";
export const GET_PERC_OF_REFRESH_CLUSTER_LIST_SUCCESS ="GET_PERC_OF_REFRESH_CLUSTER_LIST_SUCCESS";
export const GET_CLUSTER_NAME_SUCCESS = "GET_CLUSTER_NAME_SUCCESS";

export const GET_TEMPLATE = "GET_TEMPLATE";

/** WORKING AREA */
export const GET_WORKING_MODULE_REQUEST = "GET_WORKING_MODULE_REQUEST";
export const GET_WORKING_MODULE_SUCCESS = "GET_WORKING_MODULE_SUCCESS";
export const GET_WORKING_MODULE_FAILURE = "GET_WORKING_MODULE_FAILURE";

export const GET_NEWNESS_LEGO_REQUEST = "GET_NEWNESS_LEGO_REQUEST";
export const GET_NEWNESS_LEGO_SUCCESS = "GET_NEWNESS_LEGO_SUCCESS";
export const GET_NEWNESS_LEGO_FAILULE = "GET_NEWNESS_LEGO_FAILULE";

export const GET_NEWNESS_NOT_LEGO_REQUEST = "GET_NEWNESS_NOT_LEGO_REQUEST";
export const GET_NEWNESS_NOT_LEGO_SUCCESS = "GET_NEWNESS_NOT_LEGO_SUCCESS";
export const GET_NEWNESS_NOT_LEGO_FAILULE = "GET_NEWNESS_NOT_LEGO_FAILULE";

export const CLEAR_PERFO_FILTERS = "CLEAR_PERFO_FILTERS";
export const SET_PERFO_FILTERS = "SET_PERFO_FILTERS";
export const SET_CURRENTLY_VISIBLE_NEWNESS_TAB =
   "SET_CURRENTLY_VISIBLE_NEWNESS_TAB";
export const SET_DEFAULT_CURRENT_NEWNESS_TAB =
   "SET_DEFAULT_CURRENT_NEWNESS_TAB";

export const GET_ACTIVE_SKU_START = "GET_ACTIVE_SKU_START";
export const GET_ACTIVE_SKU_SUCCESS = "GET_ACTIVE_SKU_SUCCESS";
export const GET_ACTIVE_SKU_FAILURE = "GET_ACTIVE_SKU_FAILURE";

export const GET_ACTIVE_SUCCESS = "GET_ACTIVE_SUCCESS";

export const GET_MODULES_BY_PRODUCTS_REQUEST =
   "GET_MODULES_BY_PRODUCTS_REQUEST";
export const GET_MODULES_BY_PRODUCTS_SUCCESS =
   "GET_MODULES_BY_PRODUCTS_SUCCESS";
export const GET_MODULES_BY_PRODUCTS_NEWNESS_SUCCESS =
   "GET_MODULES_BY_PRODUCTS_NEWNESS_SUCCESS";
export const RESET_GET_MODULES_BY_PRODUCT = "RESET_GET_MODULES_BY_PRODUCT";

export const DRAG_SKU = "DRAG_SKU";
export const UPDATE_MACROLIST = "UPDATE_MACROLIST";

export const SAVE_MODULE_REQUEST = "SAVE_MODULE_REQUEST";
export const SAVE_MODULE_SUCCESS = "SAVE_MODULE_SUCCESS";
export const SAVE_MODULE_FAILURE = "SAVE_MODULE_FAILURE";

export const DELETE_MODULE_REQUEST = "DELETE_MODULE_REQUEST";
export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS";
export const DELETE_MODULE_FAILURE = "DELETE_MODULE_FAILURE";

export const DELETE_SELECTED_MODULES_REQUEST =
   "DELETE_SELECTED_MODULES_REQUEST";
export const DELETE_SELECTED_MODULES_SUCCESS =
   "DELETE_SELECTED_MODULES_SUCCESS";

export const PUBLISH_MODULE_REQUEST = "PUBLISH_MODULE_REQUEST";
export const PUBLISH_MODULE_SUCCESS = "PUBLISH_MODULE_SUCCESS";
export const PUBLISH_MODULE_FAILURE = "PUBLISH_MODULE_FAILURE";

export const RESET_SAVE_AND_PUBLISH = "RESET_SAVE_AND_PUBLISH";

export const CLEAR_MODULE_STATE = "CLEAR_MODULE_STATE";

export const IS_GET_BY_MACRO = "IS_GET_BY_MACRO";
export const IS_GET_BY_MODULE = "IS_GET_BY_MODULE";
export const HIGHLIGHT_SKU_WA = "HIGHLIGHT_SKU_WA";
export const HIGHLIGHT_SKU_ACTIVE = "HIGHLIGHT_SKU_ACTIVE";
export const CLEAN_HIGHLIGHT_ACTIVE = "CLEAN_HIGHLIGHT_ACTIVE";
export const CLEAN_HIGHLIGHT_WA = "CLEAN_HIGHLIGHT_WA";
export const CLEAN_SCROLL_HIGHLIGHT_ACTIVE = "CLEAN_SCROLL_HIGHLIGHT_ACTIVE";
export const CLEAN_SCROLL_HIGHLIGHT_WA = "CLEAN_SCROLL_HIGHLIGHT_WA";
export const CLEAR_HIGHLIGHTED = "CLEAR_HIGHLIGHTED";

/** PERC OF REFRESH **/
export const GET_PERC_DATA_REQUEST = "GET_PERC_DATA_REQUEST";
export const GET_PERC_DATA_SUCCESS = "GET_PERC_DATA_SUCCESS";
export const GET_PERC_DATA_FAILURE = "GET_PERC_DATA_FAILURE";

export const ADD_MODULE_TO_ADD_TO_LIST = "ADD_MODULE_TO_ADD_TO_LIST";

/*** STATISTICS ***/
export const SET_FILTERS_STAT = "SET_FILTERS_STAT";
export const SET_STAT = "SET_STAT";
export const GET_CLUSTER_BY_STATS_START = "GET_CLUSTER_BY_STATS_START";
export const RESET_SEARCH_RESULTS_STATS = "RESET_SEARCH_RESULTS_STATS"; 
export const GET_RANGE_FACING_SUCCESS = "GET_RANGE_FACING_SUCCESS";
export const GET_RANGE_FACING_CLUSTERS_SUCCESS = "GET_RANGE_FACING_CLUSTERS_SUCCESS";

/*** APPROVE CLUSTER ***/
export const GET_APPROVE_TABLE_LIST_REQUEST = "GET_APPROVE_TABLE_LIST_REQUEST";
export const GET_APPROVE_TABLE_LIST_SUCCESS = "GET_APPROVE_TABLE_LIST_SUCCESS";
export const GET_APPROVE_TABLE_LIST_FAILURE = "GET_APPROVE_TABLE_LIST_FAILURE";

export const APPROVE_CLUSTER_SUCCESS = "APPROVE_CLUSTER_SUCCESS";
export const REJECT_CLUSTER_SUCCESS = "REJECT_CLUSTER_SUCCESS";

export const GET_SINGLE_MODULE_SUCCESS = "GET_SINGLE_MODULE_SUCCESS";

export const RESET_STATE = "RESET_STATE";

/*** MANAGE PAGE ***/
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const CHANGE_SELECTED_FILTERS = "CHANGE_SELECTED_FILTERS";
export const GET_MANAGE_BRANDLIST = "GET_MANAGE_BRANDLIST";
export const GET_MANAGE_STARSZONELIST = "GET_MANAGE_STARSZONELIST";
export const GET_STATUS_USER = "GET_STATUS_USER";
export const GET_RULE_LIST = "GET_RULE_LIST";

/*** RELEASE ***/
export const SET_STATUS_RELEASE = "SET_STATUS_RELEASE";
export const SET_RELEASELIST_SUCCESS = "SET_RELEASELIST_SUCCESS";

export const CREATE_RELEASE_REQUEST = "CREATE_RELEASE_REQUEST";
export const CREATE_RELEASE_SUCCESS = "CREATE_RELEASE_SUCCESS";
export const CREATE_RELEASE_FAILURE = "CREATE_RELEASE_FAILURE";

/* AUTHENTICATION */
export const GET_AUTHORIZATION_SUCCESS = "GET_AUTHORIZATION_SUCCESS";
export const GET_AUTHORIZATION_FAILURE = "GET_AUTHORIZATION_FAILURE";
export const RESET_ERROR_STATUS = "RESET_ERROR_STATUS";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const SET_GA_CHECK = "SET_GA_CHECK";
export const RESET_ERROR_LOGIN = "RESET_ERROR_LOGIN";

/* LOCK */
export const LOCK_MODULE_REQUEST = "LOCK_MODULE_REQUEST";
export const LOCK_MODULE_SUCCESS = "LOCK_MODULE_SUCCESS";
export const LOCK_MODULE_FAILURE = "LOCK_MODULE_FAILURE";

export const LOCK_MACRO_REQUEST = "LOCK_MACRO_REQUEST";
export const LOCK_MACRO_SUCCESS = "LOCK_MACRO_SUCCESS";
export const LOCK_MACRO_FAILURE = "LOCK_MACRO_FAILURE";

export const LOCK_CLUSTER_REQUEST = "LOCK_CLUSTER_REQUEST";
export const LOCK_CLUSTER_SUCCESS = "LOCK_CLUSTER_SUCCESS";
export const LOCK_CLUSTER_FAILURE = "LOCK_CLUSTER_FAILURE";

export const UNLOCK_MODULE_REQUEST = "UNLOCK_MODULE_REQUEST";
export const UNLOCK_MODULE_SUCCESS = "UNLOCK_MODULE_SUCCESS";
export const UNLOCK_MODULE_FAILURE = "UNLOCK_MODULE_FAILURE";

export const UNLOCK_MACRO_REQUEST = "UNLOCK_MACRO_REQUEST";
export const UNLOCK_MACRO_SUCCESS = "UNLOCK_MACRO_SUCCESS";
export const UNLOCK_MACRO_FAILURE = "UNLOCK_MACRO_FAILURE";

export const UNLOCK_CLUSTER_REQUEST = "UNLOCK_CLUSTER_REQUEST";
export const UNLOCK_CLUSTER_SUCCESS = "UNLOCK_CLUSTER_SUCCESS";
export const UNLOCK_CLUSTER_FAILURE = "UNLOCK_CLUSTER_FAILURE";

/** USERS **/
export const GET_USERLIST = "GET_USERLIST";
export const CREATE_USER_CREATOR_REQUEST = "CREATE_USER_CREATOR_REQUEST";
export const CREATE_USER_CREATOR_SUCCESS = "CREATE_USER_CREATOR_SUCCESS";
export const CREATE_USER_CREATOR_FAILURE = "CREATE_USER_CREATOR_FAILURE";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const CLOSE_CURRENT_USER = "CLOSE_CURRENT_USER";
export const SAVE_USER_GRANT = "SAVE_USER_GRANT";
export const SET_SAVE_LOADING = "SET_SAVE_LOADING";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";

/* UPLOAD FILES */
export const SET_RELEASELIST_UPLOAD_SUCCESS = "SET_RELEASELIST_UPLOAD_SUCCESS";

export const GET_UPLOAD_STATUS_REQUEST = "GET_UPLOAD_STATUS_REQUEST";
export const GET_UPLOAD_STATUS_SUCCESS = "GET_UPLOAD_STATUS_SUCCESS";
export const GET_UPLOAD_STATUS_FAILURE = "GET_UPLOAD_STATUS_FAILURE";

export const IMPORT_FILES_REQUEST = "IMPORT_FILES_REQUEST";
export const IMPORT_FILES_SUCCESS = "IMPORT_FILES_SUCCESS";
export const IMPORT_FILES_FAILURE = "IMPORT_FILES_FAILURE";

// MF added
/* Massive Edit*/
export const MASSIVE_EDIT_MODULES = "MASSIVE_EDIT_MODULES";

/* PerformanceTypeOfView */

export const SET_IS_SEGMENTATION_VIEW = "SET_IS_SEGMENTATION_VIEW";
