import React from "react";

const customIcon = (props) => (
  <div
    className={
      "icon-container " +
      (props.BS ? " down" : "") +
      (props.disabled ? " disabled " : "") +
      props.iconContClass
    }
    onClick={props.handleClick}
    onDoubleClick={props.doubleClick}
  >
    <span
      className={
        "icon " +
        props.iconClass +
        (props.isRemovedSkuOpen ? " open" : "") +
        (props.disabled ? " disabled" : "")
      }
    >
      {props.iconClass === "info-label" ? "i" : null}
    </span>
    {props.removedLength > 0 ? (
      <span className="removed-sku-number"> {props.removedLength} </span>
    ) : null}
  </div>
);

export default customIcon;

//+ (props.isEditable ? ' ' : ' not-click-event')
