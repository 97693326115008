import Button from "./Button";
import React from "react";

const Popup = (props) => {
   return (
      <div>
         <div className="popup-background" onClick={props.close} />
         <div
            className={
               "popup-container " + (props.popupClass ? props.popupClass : "")
            }
         >
            {props.contextTitle ? (
               <div
                  style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                  }}
               >
                  <span
                     style={{ position: "absolute", top: "30px", left: "35px" }}
                  >
                     {props.contextTitle}
                  </span>
                  <div className="close-popup" onClick={props.close}>
                     <span className="icon-plus" />
                  </div>
               </div>
            ) : (
               <div className="close-popup" onClick={props.close}>
                  <span className="icon-plus" />
               </div>
            )}

            <div
               className={
                  "popup-header " + (props.headerClass ? props.headerClass : "")
               }
            >
               {props.titleTop && <h3>{props.titleTop}</h3>}
               {props.title && <h3>{props.title}</h3>}
               {props.subtitle ? (
                  <span style={{ position: "relative", top: "40px" }}>
                     {props.subtitle}
                  </span>
               ) : null}
            </div>
            <div
               className={
                  "popup-content " +
                  (props.isFlex ? " flex" : "") +
                  (props.children ? "" : "no-content")
               }
            >
               {props.children}
               {props.moduleList && props.moduleList.length > 0 && (
                  <ul>
                     {props.moduleList.map((singleModule) => {
                        return <li>{singleModule}</li>;
                     })}
                  </ul>
               )}
            </div>
            {props.btnName && (
               <div className="footer-popup">
                  {props.isDoubleButton && (
                     <Button
                        btnClass="btn-medium"
                        handleClick={
                           !!props.handleClickSecondary
                              ? props.handleClickSecondary
                              : props.close
                        }
                     >
                        {props.btnName2}
                     </Button>
                  )}
                  <Button
                     btnClass="btn-medium"
                     handleClick={props.handleClick}
                     disabled={props.disabled}
                  >
                     {props.btnName}
                  </Button>
               </div>
            )}
         </div>
      </div>
   );
};
export default Popup;
