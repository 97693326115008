import React, { Component } from "react";
import { connect } from "react-redux";

class LiaList extends Component {
  render() {
    let newList = this.props.clusterList.map(cluster => {
      return (
        <div key={cluster.clusterId}>
          <p style={{
            fontWeight: "bold",
            margin: "16px 0"
          }}>{cluster.clusterLabel}</p>
          <div className="label-lia">
            <p>LIA ID</p>
            <p>LIA LABEL</p>
          </div>
          {
            cluster.lias.map(clusterLia => {
              return (
                <ul className="ul-lia" key={clusterLia.liaCode}>
                  <li>{clusterLia.liaCode}</li>
                  <li>{clusterLia.planningChannelId}</li>
                </ul>
              );
            })
          }
        </div>
      );
    })

    return (
      <div className="outer-lia">
        {newList}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clusterList: state.cluster.clusterList
  };
};

export default connect(mapStateToProps)(LiaList);
