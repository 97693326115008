import React from 'react';
import Slider  from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatCamelCase } from '../../services/Api';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const rangeContainer = (props) => (
		<div className="range-container">                
				<h2>{formatCamelCase(props.category)}</h2>
				{props.filterList.map(filter => 
					<div key={filter} className="slider-container">
						<h3>{filter}</h3>
						<Range 
							allowCross={false} 
							className={props.filtersValues[props.category] !== null && props.filtersValues[props.category][filter] ? "active" : "grey"} 
							value={props.filtersValues[props.category] ? props.filtersValues[props.category][filter] : props.defaultValue} 
							onChange={v => props.handleChange(v, filter, props.category)} 
							tipProps={{prefixCls: 'rc-slider-tooltip'}}
							tipFormatter={value => `${value}%`}
						/>
					</div>
				)}		
		</div>
	)

export default rangeContainer;