import React, { useState } from 'react';

import RadioGrp from "../../../components/UI-components/RadioGroup";

const OrderModulePanel = ({ orderModules }) => {
    const [selected,setSelected] = useState(0);

    const orderList=[
        {
            data:'moduleName',
            text:'Module Name'
        },
        {
            data:'door',
            text:'Distribution'
        },
        {
            data:'length',
            text:'N° Sku'
        }
    ];

    return (
        <div className='filterPanel__section'>
            <div className='filterPanel__firstDiv'>
                Order Module by
            </div>
            <RadioGrp
                    containerClass="filterPanel_radioGroup"
                    options={orderList.map(p=>p.text)}
                    active={false}
                    selectedIndex={selected}
                    handleClick={(e)=>{
                        const finded=orderList.findIndex(x=>x.text === e);
                        let valueToSet=null;
                        let valueToPropagate=null;
                        if(finded>=0){
                            valueToSet=finded;
                            valueToPropagate=orderList.find(x=>x.text === e).data;
                        }
                        setSelected(valueToSet);
                        orderModules(valueToPropagate);
                    }}
                />
            {/*
                orderList.map((oi) => <Button 
                                        key={'module_filter_sort_' +oi.data } 
                                        handleClick={()=>handleSelectedOrder(oi.data)} 
                                        btnClass={'orderPanel__btn' + (selected===oi.data?' active':'') }>
                                            {oi.text} 
                                        </Button>)*/
            }
            
        </div>
    )
}
export default OrderModulePanel