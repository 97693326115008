/* eslint-disable */
import React, { Component } from "react";
import CustomSelect from "../../UI-components/CustomSelect";
import CustomIcon from "../../UI-components/CustomIcon";
import Loader from "../../UI-components/Loader";
import Popup from "../../UI-components/Popup";
import PreviewCluster from "../PreviewCluster";
import Tooltip from "../../UI-components/Tooltip";
import DatePicker from "react-date-picker";
import * as _ from "lodash";
import {
   formatFiltersOptionsArray,
   formatCamelCase,
   filterResults,
   formattedDateGivenAnInput,
} from "../../../services/Api";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";

let orderLine = [
   "country",
   "userSuggest",
   "dateSuggest",
   "originalAssortment",
   "assortmentId",
   "period",
   "starsCustomer",
   "customerSegmentation",
   "storeJda",
   "status",
];

class TableComponent extends Component {
   state = {
      results: null,
      filteredResults: null,
      selectedFilters: {},
      filters: null,
      isPopupOpen: false,
      isPreviewOpen: false,
      rejectMsg: "",
      valueDateSuggest: null,
      panelsOpen: [],
      clusterLine: null,
   };

   componentDidMount = () => {
      this.props.getTableInfo();

      if (this.props.filters)
         this.setState({
            filters: this.props.filters,
         });

      if (this.props.results) {
         this.setState({
            results: this.props.results,
            filteredResults: this.props.results,
         });
      }
   };

   componentDidUpdate = (prevProps) => {
      if (prevProps.filters !== this.props.filters)
         this.setState({
            filters: this.props.filters,
         });

      if (prevProps.results !== this.props.results) {
         let clusterLine = { ...this.state.clusterLine };

         if (this.state.isPreviewOpen) {
            this.props.results.map((ass) => {
               if (ass.assortmentId === this.state.clusterLine.assortmentId) {
                  clusterLine = { ...ass };
               }
            });
         }

         this.setState({
            results: this.props.results,
            filteredResults: this.props.results,
            clusterLine,
         });
      }
   };

   componentWillUnmount = () => {
      this.props.resetState();
   };

   handleFilters = (filterArray, filterName) => {
      let allResults = _.cloneDeep(this.state.results),
         selectedFilters = _.cloneDeep(this.state.selectedFilters),
         filterObj = filterResults(
            allResults,
            selectedFilters,
            filterArray,
            filterName
         );

      this.setState({
         selectedFilters: filterObj.selectedFilters,
         filteredResults: filterObj.filteredResults,
      });
   };

   onChangeDatePicker = (date) => {
      let allResults = _.cloneDeep(this.state.results),
         selectedFilters = _.cloneDeep(this.state.selectedFilters),
         publishDate = date
            ? [
                 {
                    filterName: "dateSuggest",
                    value: date.toDateString(),
                 },
              ]
            : [];
      let filterObj = filterResults(
         allResults,
         selectedFilters,
         publishDate,
         "dateSuggest"
      );

      this.setState({
         selectedFilters: filterObj.selectedFilters,
         filteredResults: filterObj.filteredResults,
         valueDateSuggest: date,
      });
   };

   /* APPROVE - REJECT CLUSTER */
   handleAction = (action, singleLine) => {
      if (action === "reject") {
         this.setState({
            clusterToReject: singleLine,
         });
         return this.toggleRejectPopup();
      }

      this.props.approveCluster(singleLine, this.openPopup);
   };

   openPopup = () => {
      this.setState({
         isRejectPopupOpen: false,
         isPopupOpen: true,
         rejectMsg: "",
      });

      this.props.getTableInfo();
   };

   closePopup = () => {
      this.setState({
         isPopupOpen: false,
      });
   };

   toggleRejectPopup = () =>
      this.setState((prevState) => {
         return { isRejectPopupOpen: !prevState.isRejectPopupOpen };
      });

   handleChangeText = (e) => this.setState({ rejectMsg: e.target.value });

   rejectCluster = () => {
      this.props.rejectCluster(
         this.state.clusterToReject,
         this.state.rejectMsg,
         this.openPopup
      );
   };

   /* SEE PREVIEW */
   seePreview = (clusterLine, cluster, key) => {
      let isSuggested = false;
      if (key === "suggestedCluster") isSuggested = true;

      let selectedCluster = {
         assortmentId: cluster,
         releaseId: clusterLine.releaseId,
         country: clusterLine.country,
         isSuggested,
      };
      this.setState({
         clusterLine,
         selectedCluster,
         isPreviewOpen: true,
      });
      this.props.resetState();
   };

   closePreview = () => {
      this.props.resetState();
      this.setState({
         isPreviewOpen: false,
      });
   };

   openPanel = (index) => {
      let panelsOpen = [...this.state.panelsOpen];
      let stringIndex = panelsOpen.indexOf("line-" + index);

      if (stringIndex !== -1) {
         panelsOpen.splice(stringIndex, 1);
      } else {
         panelsOpen.push("line-" + index);
      }

      this.setState({
         panelsOpen,
      });
   };

   printPanel = (singleLine, index) => {
      let starsUnique = [],
         storeUnique = [],
         segmentUnique = [];

      singleLine.stores.map((store) => {
         starsUnique.push(store.starsCustomer);
         storeUnique.push(store.storeJda);
         segmentUnique.push(store.customerSegmentation);
      });

      starsUnique = _.uniq(starsUnique);
      storeUnique = _.uniq(storeUnique);
      segmentUnique = _.uniq(segmentUnique);

      let print = (
         <div
            key={singleLine.assortmentId + index}
            className={
               "line-panel component " +
               (this.state.panelsOpen.includes("line-" + index) ? "open" : "")
            }
         >
            <div className="row no-gutters">
               <div className="col-4">
                  <h3>Stars Customer</h3>
                  <ul>
                     {starsUnique.map((stars, index) => (
                        <li key={stars + index}>{stars}</li>
                     ))}
                  </ul>
               </div>
               <div className="col-4">
                  <h3>Customer Segmentation</h3>
                  <ul>
                     {segmentUnique.map((segment, index) => (
                        <li key={segment + index}>{segment}</li>
                     ))}
                  </ul>
               </div>
               <div className="col-4">
                  <h3>Store Id</h3>
                  <ul>
                     {storeUnique.map((store, index) => (
                        <li key={store + index}>{store}</li>
                     ))}
                  </ul>
               </div>
            </div>
         </div>
      );

      return print;
   };

   render() {
      return (
         <div className="table-container">
            <div className="table-header">
               <ul>
                  {this.state.filters
                     ? this.state.filters.map((filter) => (
                          <li key={filter.filterName}>
                             <div className="title-container">
                                {" "}
                                {formatCamelCase(filter.filterName)}{" "}
                             </div>
                             {filter.filterName !== "dateSuggest" ? (
                                <CustomSelect
                                   isMulti
                                   placeholder={formatCamelCase(
                                      filter.filterName
                                   )}
                                   options={formatFiltersOptionsArray(
                                      filter.options,
                                      filter.filterName
                                   )}
                                   handleChange={(e) =>
                                      this.handleFilters(e, filter.filterName)
                                   }
                                />
                             ) : (
                                <DatePicker
                                   onChange={this.onChangeDatePicker}
                                   value={this.state.valueDateSuggest}
                                   format="dd/MM/y"
                                   calendarIcon={null}
                                />
                             )}
                          </li>
                       ))
                     : null}
               </ul>
            </div>
            <div className="table-body">
               <div className="table-body-container">
                  {this.props.isListLoading ? (
                     <Loader />
                  ) : this.state.filteredResults ? (
                     this.state.filteredResults.map((singleLine, index) => (
                        <React.Fragment key={index}>
                           <ul
                              className={
                                 "table-item" +
                                 (this.state.panelsOpen.includes(
                                    "line-" + index
                                 )
                                    ? " open"
                                    : "")
                              }
                              key={
                                 singleLine.assortmentId +
                                 singleLine.country +
                                 singleLine.releaseId
                              }
                           >
                              <CustomIcon
                                 iconContClass={
                                    "expand-line " +
                                    (this.state.panelsOpen.includes(
                                       "line-" + index
                                    )
                                       ? " open"
                                       : "")
                                 }
                                 iconClass="icon-arrow1"
                                 handleClick={() => this.openPanel(index)}
                              />
                              {orderLine.map((key) => {
                                 let item = singleLine[key];
                                 if (item && item.constructor === Array) {
                                    item = item.join();
                                 }
                                 if (
                                    key === "status" &&
                                    item.toUpperCase() === "PENDING"
                                 ) {
                                    return (
                                       <li
                                          key={singleLine.assortmentId + key}
                                          className="actions"
                                       >
                                          <CustomIcon
                                             iconClass="icon-check-mark-icon"
                                             handleClick={() =>
                                                this.handleAction(
                                                   "approve",
                                                   singleLine
                                                )
                                             }
                                          />
                                          <CustomIcon
                                             iconClass="icon-close_icon"
                                             handleClick={() =>
                                                this.handleAction(
                                                   "reject",
                                                   singleLine
                                                )
                                             }
                                          />
                                       </li>
                                    );
                                 }
                                 if (
                                    key === "status" &&
                                    item.toUpperCase() === "REJECTED"
                                 ) {
                                    return (
                                       <li
                                          key={singleLine.assortmentId + key}
                                          className="rejected"
                                       >
                                          {singleLine.message ? (
                                             <Tooltip
                                                text={singleLine.message}
                                                tooltipClass="tooltip-table large normal left"
                                             >
                                                <p>{item}</p>
                                             </Tooltip>
                                          ) : (
                                             <p>{item}</p>
                                          )}
                                       </li>
                                    );
                                 }
                                 if (
                                    key === "originalAssortment" ||
                                    key === "assortmentId"
                                 )
                                    return (
                                       <li
                                          key={singleLine.assortmentId + key}
                                          className="clickable"
                                          onClick={() =>
                                             this.seePreview(
                                                singleLine,
                                                singleLine[key],
                                                key
                                             )
                                          }
                                       >
                                          <Tooltip
                                             text={item}
                                             tooltipClass="tooltip-table large normal right"
                                          >
                                             <p>{singleLine[key]}</p>
                                          </Tooltip>
                                       </li>
                                    );
                                 if (key === "dateSuggest") {
                                    return (
                                       <li key={singleLine.assortmentId + key}>
                                          <Tooltip
                                             text={formattedDateGivenAnInput(
                                                item
                                             )}
                                             tooltipClass="tooltip-table large normal right"
                                          >
                                             <p>
                                                {formattedDateGivenAnInput(
                                                   item
                                                )}
                                             </p>
                                          </Tooltip>
                                       </li>
                                    );
                                 }
                                 return (
                                    <li
                                       key={singleLine.assortmentId + key}
                                       className={
                                          key === "status"
                                             ? "voted " + item.toLowerCase()
                                             : ""
                                       }
                                    >
                                       <p>{item}</p>
                                    </li>
                                 );
                              })}
                           </ul>
                           {this.printPanel(singleLine, index)}
                        </React.Fragment>
                     ))
                  ) : null}
               </div>
            </div>
            {this.state.isPopupOpen ? (
               <Popup
                  close={this.closePopup}
                  handleClick={this.closePopup}
                  btnName="Close"
                  popupClass="popup-small"
               >
                  <p>{this.props.msgStatus}</p>
               </Popup>
            ) : null}
            {this.state.isRejectPopupOpen ? (
               <Popup
                  close={this.toggleRejectPopup}
                  handleClick={this.rejectCluster}
                  btnName="Confirm"
                  title="You are rejecting the cluster."
               >
                  <p>
                     {" "}
                     If you want, you can insert the reason for rejecting in the
                     text-box below.
                  </p>
                  <textarea
                     line="3"
                     column="40"
                     autoFocus={true}
                     style={{ width: "70%" }}
                     onChange={this.handleChangeText}
                     value={this.state.rejectMsg}
                  />
               </Popup>
            ) : null}
            {this.state.isPreviewOpen ? (
               <PreviewCluster
                  closePreview={this.closePreview}
                  selectedCluster={this.state.selectedCluster}
                  clusterLine={this.state.clusterLine}
                  filters={this.state.filters}
                  seePreview={(singleLine, cluster, key) =>
                     this.seePreview(singleLine, cluster, key)
                  }
                  handleClick={(action, singleLine) =>
                     this.handleAction(action, singleLine)
                  }
               />
            ) : null}
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      filters: state.cluster.filters,
      results: state.cluster.results,
      msgStatus: state.cluster.msgStatus,
      selectedCluster: state.cluster.selectedCluster,
      isListLoading: state.cluster.isListLoading,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getTableInfo: () => dispatch(actions.getApproveTableInfo()),
      approveCluster: (cluster, callback) =>
         dispatch(actions.approveCluster(cluster, callback)),
      rejectCluster: (cluster, rejectMsg, callback) =>
         dispatch(actions.rejectCluster(cluster, rejectMsg, callback)),
      resetState: () => dispatch(actions.resetState()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);

// let tableCell = document.querySelectorAll('li');
// let tableCellWidth = 0;
// for(var i=0; i < tableCell.length; i++){
//     tableCellWidth = tableCell[i].clientWidth;
// }
// let tooltipArray = document.querySelectorAll('.tooltip-text:after');
// for(var i=0; i < tooltipArray.length; i++){
//     tooltipArray[i].style.left = - tableCellWidth / 2  +"px";
// }
