import * as actions from "../../store/actions";

import React, { Component } from "react";
import {
   formattedDateGivenAnInput,
   handleSearchResults,
} from "../../services/Api";

import CustomSearch from "../UI-components/CustomSearch";
import Loader from "../UI-components/Loader";
import {MODULE_EXTRA_SELL_IN} from "../../config";
import Popup from "../UI-components/Popup";
import { connect } from "react-redux";
import { formatNumber } from "../../services/Api";
import { getCookie } from "../../services/cookie";
import CustomInput from "../UI-components/CustomInput";

class SkuInfoPopup extends Component {
   state = {
      filteredModuleList: null,
      productModuleList: null,
      valueSearch: "",
      isListOpen: false,
      extraSellIn: null,
      isLoadingExtraSellIn: false,
      fetchExtraSellInData: true,
   };

   componentDidMount() {
      if (this.props.fetchExtraSellInData || this.props.fetchExtraSellInData === undefined) {
         this.getExtraSellInData();
      }
      this.setState({
         productModuleList: this.props.productModuleList,
         filteredModuleList: this.props.productModuleList,
      });
   }

   componentDidUpdate(prevProps) {
      if (prevProps.productModuleList !== this.props.productModuleList) {
         this.setState({
            productModuleList: this.props.productModuleList,
            filteredModuleList: this.props.productModuleList,
         });
      }
   }

   getExtraSellInData = () => {
      const url = MODULE_EXTRA_SELL_IN;
      this.setState(() => {
         return { isLoadingExtraSellIn: true };
      });

      const moduleName = this.props.moduleDetails?.moduleName || "";
      const monthId = this.props.moduleDetails?.monthId || "";
      const materialCode = this.props.skuClicked?.materialCode || "";
      const skuCode = this.props.skuClicked?.skuCode || "";

      const dataToSend = {
         moduleName,
         monthId,
         materialCode,
         skuCode,
      };

      fetch(url, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
         body: JSON.stringify(dataToSend),
      })
         .then((response) => {
            if (response.ok) {
               this.setState(() => {
                  return {
                     isLoadingExtraSellIn: false,
                  };
               });
               return response.json();
            }
            if (response.status === 401) {
               this.setState(() => {
                  return {
                     isLoadingExtraSellIn: false,
                  };
               });
               this.props.logout();
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               return this.setState(() => {
                  return {
                     extraSellIn: data,
                     isLoadingExtraSellIn: false,
                  };
               });
            }
         })
         .catch((err) => {
            this.setState(() => {
               return {
                  isLoadingExtraSellIn: false,
               };
            });
            return console.error(err);
         });
   };

   toggleModuleList = () =>
      this.setState((prevState) => {
         return { isListOpen: !prevState.isListOpen };
      });

   handleSearchModule = (e) => {
      let filteredObj = handleSearchResults(
         e,
         this.state.productModuleList,
         "module"
      );
      this.setState({
         filteredModuleList: filteredObj.listItem,
         valueSearch: filteredObj.valueSearch,
      });
   };

   getMinValue = () => {
      return this.props.moduleDetails?.productDtoList
         .filter(sku => sku.skuId === this.props.skuClicked.skuId)[0]
         .minValue
   }

   render() {
      let { isListOpen } = this.state;

      //    let gedExpireDateFormatt = this.props.skuClicked,
      //      gedExpireDate = formattedDateGivenAnInput(
      //        parseInt(gedExpireDateFormatt.gedExpireDate)
      //      );

      let skuClicked = this.props.skuClicked,
         dateInserted = formattedDateGivenAnInput(
            parseInt(skuClicked.insertDate)
         );
   
      const fetchExtraSellInData =
         this.props.fetchExtraSellInData !== undefined ?
            this.props.fetchExtraSellInData :
            this.state.fetchExtraSellInData;
      return (
         <Popup
            popupClass="popup-sku-list popup-large"
            title={
               skuClicked.model + " " + skuClicked.color + " " + skuClicked.size
            }
            titleTop={skuClicked.upc}
            close={this.props.close}
         >
            <div className="large-cl">
               <p className="bold"> List of module containing the sku: </p>
               {isListOpen ? (
                  <div>
                     <CustomSearch
                        value={this.state.valueSearch}
                        handleChange={this.handleSearchModule}
                        inputClass="input-medium"
                        placeholder="Search Module Name"
                     />
                     <div>
                        <span
                           className="toggle-button"
                           onClick={this.toggleModuleList}
                        >
                           - Close List Modules
                        </span>
                        {skuClicked && this.state.productModuleList !== null ? (
                           <ul>
                              {this.state.filteredModuleList.map(
                                 (singleModule) => {
                                    return (
                                       <li key={singleModule.moduleName}>
                                          {singleModule.moduleName} -{" "}
                                          <span className="bold">
                                             {formatNumber(singleModule.door)} D
                                          </span>
                                       </li>
                                    );
                                 }
                              )}
                           </ul>
                        ) : (
                           <Loader />
                        )}
                     </div>
                  </div>
               ) : (
                  <div>
                     <span
                        className="toggle-button"
                        onClick={this.toggleModuleList}
                     >
                        + Open List Modules
                     </span>
                  </div>
               )}
            </div>
            <div className="medium-cl">
               {skuClicked.gedExpireDate && (
                  <p>
                     <span className="bold">Ged date: </span>
                     {skuClicked.gedExpireDate}
                  </p>
               )}
               {skuClicked.releaseDate && (
                  <p>
                     <span className="bold">Release Date: </span>
                     {skuClicked.releaseDate}
                  </p>
               )}
               {skuClicked.insertDate && (
                  <p>
                     <span className="bold">Date Added: </span>
                     {dateInserted}
                  </p>
               )}
               {skuClicked.srp && (
                  <p>
                     <span className="bold">Srp:</span>{" "}
                     {formatNumber(skuClicked.srp)}
                  </p>
               )}
               {skuClicked.whsprice && (
                  <p>
                     <span className="bold">WHS Price:</span>{" "}
                     {formatNumber(skuClicked.whsprice)}
                  </p>
               )}
               {
                  <p>
                     <span className="bold">Min Value:</span>{" "}
                     <CustomInput
                        value={this.getMinValue()}
                        handleChange={(e) => {this.props.handleMinValueChange(e, skuClicked.skuId)}}
                     />
                  </p>
               }
               <div className="row">
                  <div className="col-6">
                     <p>
                        <span className="bold">Sell-in:</span>{" "}
                        {skuClicked.sellIn
                           ? formatNumber(skuClicked.sellIn)
                           : 0}
                     </p>
                     <p>
                        <span className="bold">Sell-out:</span>{" "}
                        {skuClicked.sellOut
                           ? formatNumber(skuClicked.sellOut)
                           : 0}
                     </p>
                     <p>
                        <span className="bold">Out Of Stars:</span>{" "}
                        {skuClicked.outOfStars
                           ? formatNumber(skuClicked.outOfStars)
                           : 0}
                     </p>
                     {/*SL*/}
                     <p style={{ whiteSpace: "nowrap" }}>
                        <span className="bold">Extra sell in:</span>{" "}
                        {fetchExtraSellInData ?

                           (!this.state.isLoadingExtraSellIn
                              ? typeof this.state.extraSellIn === "number"
                                 ? formatNumber(this.state.extraSellIn)
                                 : 0
                              : <span>Loading...</span>
                           ) : '-'
                        }
                     </p>
                  </div>
                  <div className="col-6">
                     <p>
                        <span className="bold">Rank Sell-in:</span>{" "}
                        {skuClicked.rankSellIn
                           ? formatNumber(skuClicked.rankSellIn)
                           : 0}
                     </p>
                     <p>
                        <span className="bold">Rank Sell-out:</span>{" "}
                        {skuClicked.rankSellOut
                           ? formatNumber(skuClicked.rankSellOut)
                           : 0}
                     </p>
                     <p>
                        <span className="bold">Rank Out Of Stars:</span>{" "}
                        {skuClicked.rankOutOfStars
                           ? formatNumber(skuClicked.rankOutOfStars)
                           : 0}
                     </p>
                  </div>
               </div>
            </div>
         </Popup>
      );
   }
}

const mapStateToProps = (state) => {
   return {};
};

const mapDispatchToProps = (dispatch) => {
   return {
      logout: () => dispatch(actions.logout()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkuInfoPopup);
