/* eslint-disable array-callback-return */

import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";

import {
   formatExportFilters,
   formatFilters,
   formatFiltersOptionsArray,
   formatOptionsArray,
   formatOptionsArrayUnordered,
   handleErrorsJson,
} from "../../services/Api";

import axios from "axios";
import {
   exportType,
} from "../../services/constList";
import { getCookie } from "../../services/cookie";
import { showError } from "./errorActions";

export const showLoader = () => {
   return {
      type: actionTypes.SHOW_LOADER,
   };
};

export const hideLoader = () => {
   return {
      type: actionTypes.HIDE_LOADER,
   };
};

/** FILTER HOME */
export const setSelectedFilters = (selectedFilters) => {
   return (dispatch) => {
      dispatch({
         type: actionTypes.SET_SELECTED_FILTERS,
         selectedFilters,
      });
   };
};

//MIRKOO
//Filter discontinued
export const setSelectedFiltersDiscontinued = (selectedFiltersDiscontinued) => {
   return (dispatch) => {
      dispatch({
         type: actionTypes.SET_SELECTED_FILTERS_DISCONTINUED,
         selectedFiltersDiscontinued,
      });
   };
};

//NOT FILTER
export const setSavablePublishableModules = (savablePublishableModules) => {
   return (dispatch) => {
      dispatch({
         type: actionTypes.SET_SAVABLE_PUBLISHABLE_MODULES,
         savablePublishableModules,
      });
   };
};

export const setRowsToSendMassiveEdit = (rowsToSendMassiveEdit) => {
   return (dispatch) => {
      dispatch({
         type: actionTypes.SET_ROWS_TO_SEND_MASSIVE_EDIT,
         rowsToSendMassiveEdit,
      });
   };
};

export const setResetRowsToSendMassiveEdit = (rowsToSendMassiveEdit) => {
   return (dispatch) => {
      dispatch({
         type: actionTypes.SET_RESET_ROWS_TO_SEND_MASSIVE_EDIT,
         rowsToSendMassiveEdit,
      });
   };
};

export const toggleHomeFilters = (value) => {
   return {
      type: actionTypes.TOGGLE_FILTER_HOME,
      value,
   };
};

export const getAllLia = (success) => {
   return (dispatch) => {
      let url = urlList.GET_ALL_LIA;
      axios({
         url,
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
      })
         .then((res) => {
            dispatch(success(res.data));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};
export const getAllLiaList = () => {
   return (dispatch) => {
      dispatch(getAllLia(getAllLiaListSuccess));
   };
};

export const getAllLiaListSuccess = (allLia) => {
   return {
      type: actionTypes.GET_ALL_LIA_SUCCESS,
      allLia,
   };
};

/*** GET INITIAL FILTERS */
export const getInitialFilters = (success) => {
   return (dispatch) => {
      let url = urlList.GET_INITIALFILTERS_URL;
      axios({
         url,
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
      })
         .then((res) => {
            const initialFilters = {};
            Object.keys(res.data).map((key) => {
               if (key === "months") {
                  initialFilters[key] = formatOptionsArrayUnordered(
                     res.data[key]
                  );
               } else {
                  initialFilters[key] = formatOptionsArray(res.data[key]);
               }
            });
            dispatch(success(initialFilters));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

/** HOME INITIAL FILTERS **/
export const getInitialFiltersList = () => {
   return (dispatch) => {
      dispatch(getInitialFilters(getInitialFiltersSuccess));
   };
};

export const getInitialFiltersSuccess = (initialFiltersList) => {
   return {
      type: actionTypes.GET_INITIALFILTERS_SUCCESS,
      initialFiltersList,
   };
};

/*** POST BRAND */
export const getBrands = (params, success) => {
   return (dispatch) => {
      let url = urlList.GET_BRANDLIST_URL;
      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            if (json) json = formatOptionsArray(json);
            dispatch(success(json));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

/** HOME PAGE BRANDS **/
export const getBrandList = (selectedFilters) => {
   let params = {
      releaseId: [selectedFilters.release.value],
   };

   return (dispatch) => {
      dispatch(getBrands(params, getBrandSuccess));
   };
};

export const getBrandSuccess = (brandList) => {
   return {
      type: actionTypes.GET_BRAND_SUCCESS,
      brandList,
   };
};

/*** POST PLANNING CHANNEL ***/
export const getPlanningChannelGeneral = (params, success) => {
   return (dispatch) => {
      let url = urlList.GET_PLANNINGLIST_URL;
      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            if (json) json = formatOptionsArray(json);
            dispatch(success(json));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getPlanningChannel = (selectedFilters) => {
   let params = {
      releaseId: [selectedFilters.release],
   };

   return (dispatch) => {
      dispatch(getPlanningChannelGeneral(params, getPlanningSuccess));
   };
};

const getPlanningSuccess = (planningChannel) => {
   return {
      type: actionTypes.GET_PLANNING_SUCCESS,
      planningChannel,
   };
};

/*** POST COUNTRY ***/
export const getCountryGeneral = (params, success) => {
   return (dispatch) => {
      let url = urlList.GET_COUNTRYLIST_URL;
      fetch(url, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         // body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            if (json) json = formatOptionsArray(json);
            dispatch(success(json));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getCountry = (selectedFilters) => {
   let params = {
      releaseId: [selectedFilters.release.value],
   };

   return (dispatch) => dispatch(getCountryGeneral(params, getCountrySuccess));
};

export const getCountrySuccess = (countryList) => {
   return {
      type: actionTypes.GET_COUNTRY_SUCCESS,
      countryList,
   };
};

export const getAllCountries = () => {
   return (dispatch) => dispatch(getCountryGeneral({}, getAllCountriesSuccess));
};

export const getAllCountriesSuccess = (countryList) => {
   return {
      type: actionTypes.GET_ALL_COUNTRIES,
      countryList,
   };
};

/** SEARCH RESULTS */
export const sendHomeFilters = (selectedFilters) => {
   let planningChannel = selectedFilters.planningChannel.length
      ? selectedFilters.planningChannel.map((pc) => pc.value)
      : [];
   let brand = selectedFilters.brand.length
      ? selectedFilters.brand.map((brand) => brand.value)
      : [];

   let params = {
      monthId: selectedFilters.months ? [selectedFilters.months.value] : [],
      brand,
      planningChannel,
      collection:
         selectedFilters.commodities.length > 0
            ? selectedFilters.commodities.map((col) => col.value)
            : [],
      country: selectedFilters.countryList.map((country) => country.value),
   };

   // let paramCluster = formatFilters(selectedFilters);

   return (dispatch) => {
      let urlModules = urlList.GET_MODULELIST_URL;
      let urlMacros = urlList.GET_MACROLIST_URL;
      let urlCluster = urlList.GET_CLUSTERLIST_URL;

      const callId = Math.random();

      postSearchResults(
         params,
         urlModules,
         dispatch,
         getModuleListSuccess,
         null,
         "moduleList",
         callId
      );
      postSearchResults(
         params,
         urlMacros,
         dispatch,
         getMacroSuccess,
         null,
         "macroFamilyList",
         callId
      );
      postSearchResults(
         params,
         urlCluster,
         dispatch,
         getClusterListSuccess,
         null,
         "clusterList",
         callId
      );
   };
};

export const sendHomeFilterss = (moduleList, callId) => {
   return (dispatch) => {
      dispatch(getModuleListSuccess(moduleList, callId),)
   };
};

export const getModuleListSuccess = (moduleList, callId) => {
   return {
      type: actionTypes.GET_MODULES_SUCCESS,
      moduleList,
      callId,
   };
};

export const getMacroSuccess = (macroFamilyList, callId) => {
   return {
      type: actionTypes.GET_MACRO_SUCCESS,
      macroFamilyList,
      callId,
   };
};

export const getClusterListSuccess = (clusterList, callId) => {
   return {
      type: actionTypes.GET_CLUSTERS_SUCCESS,
      clusterList,
      callId,
   };
};

// eslint-disable-next-line no-unused-vars
const showErrTooManyEl = (err, listType, callId) => {
   return {
      type: actionTypes.GET_HOME_FILTER_FAILURE_TO_MANY_EL,
      err,
      listType,
      callId,
   };
};

const postSearchResults = (
   params,
   url,
   dispatch,
   callback,
   failure = null,
   listType = null,
   callId = 1
) => {
   dispatch(showLoader());
   axios({
      url,
      method: "POST",
      headers: {
         "Content-Type": "application/json",
         xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
      },
      data: JSON.stringify(params),
   })
      .then((json) => {
         if (json.data) dispatch(callback(json.data, callId));
         dispatch(hideLoader());
      })
      .catch((err) => {
         if (err.response && err.response.status === 413) {
            dispatch(hideLoader());
            dispatch(callback(err.response, callId));
            dispatch(showErrTooManyEl(err.response, listType, callId));
         } else {
            dispatch(showError(err));
            dispatch(callback(err.response, callId));
            if (failure) {
               dispatch(failure());
               dispatch(callback(err.response, callId));
               dispatch(showErrTooManyEl(err.response, listType, callId));
            }
         }
      });
};

export const getFilteredList = (type, params) => {
   return (dispatch) => {
      let url;
      switch (type) {
         case "clusters":
            url = urlList.GET_CLUSTERLIST_URL;
            break;
         case "macroFamily":
            url = urlList.GET_MACROLIST_URL;
            break;
         case "modules":
            url = urlList.GET_MODULELIST_URL;
            break;
         default:
            url = urlList.GET_CLUSTERLIST_URL;
            break;
      }

      axios({
         url,
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         data: params,
      })
         .then((res) => {
            switch (type) {
               case "cluster":
                  dispatch(getClusterListSuccess(res.data));
                  break;
               case "macroFamily":
                  dispatch(getMacroSuccess(res.data));
                  break;
               case "modules":
                  dispatch(getModuleListSuccess(res.data));
                  break;
               default:
                  dispatch(getClusterListSuccess(res.data));
                  break;
            }
         })
         .catch((err) => {
            console.error(err);
            // if (err.response && err.response.status === 413) {
            //   dispatch(hideLoader());
            //   dispatch(showErrTooManyEl(err.response, listType));
            // } else {
            //   dispatch(showError(err));
            //   if (failure) dispatch(failure());
            // }
         });
   };
};

/*** GET CLUSTER FILTERS ***/
export const getClusterFilters = (params, success) => {
   return (dispatch) => {
      let url = urlList.GET_CLUSTER_FILTERS_OPTIONS;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((options) => {
            dispatch(success(options));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getClusterFiltersOptions = (selectedFilters) => {
   let planningChannel = selectedFilters.planningChannel.length
      ? selectedFilters.planningChannel.map((pc) => pc.value)
      : [];
   let brand = selectedFilters.brand.length
      ? selectedFilters.brand.map((brand) => brand.value)
      : [];

   let params = formatFilters(selectedFilters);

   params.planningChannel = planningChannel;
   params.brand = brand;

   return (dispatch) =>
      dispatch(getClusterFilters(params, getFilterOptionsSuccess));
};

const getFilterOptionsSuccess = (options) => {
   Object.keys(options).map((k) => {
      options[k] = formatFiltersOptionsArray(options[k], k);
   });

   return {
      type: actionTypes.GET_FILTERS_OPTIONS_SUCCESS,
      filtersOptionsCluster: options,
   };
};

/*** GET STORE id ***/
export const getStoreIdFiltered = (
   selectedFilters,
   starsCustomer = [],
   customerSegmentation = []
) => {
   const planningChannel = selectedFilters.planningChannel.map(
      (pc) => pc.value
   );
   const brand = selectedFilters.brand.map((brand) => brand.value);
   const collection = selectedFilters.commodities.map((col) => col.value);

   selectedFilters.starsCustomer = starsCustomer;
   selectedFilters.customerSegmentation = customerSegmentation;
   selectedFilters.planningChannel = planningChannel;
   selectedFilters.brand = brand;
   selectedFilters.commodities = collection;

   return (dispatch) =>
      dispatch(getClusterFilters(selectedFilters, getStoreIdFilteredSuccess));
};

const getStoreIdFilteredSuccess = (options) => {
   let storeId = formatFiltersOptionsArray(options["storeId"], "storeId");
   return {
      type: actionTypes.GET_STORE_ID_SUCCESS,
      storeId,
   };
};

/*** GET CODICE STORE SAP JDA ***/
export const getCodiceStoreSap = (selectedFilters, storeJda = []) => {
   let params = formatFilters(selectedFilters);
   params.storeJda = storeJda;

   return (dispatch) => {
      dispatch(getCodiceStoreSapRequest());
      dispatch(getClusterFilters(params, getCodiceStoreSapSuccess));
   };
};

const getCodiceStoreSapRequest = () => {
   return {
      type: actionTypes.GET_CODICE_STORE_SAP_REQUEST,
   };
};

const getCodiceStoreSapSuccess = (options) => {
   return {
      type: actionTypes.GET_CODICE_STORE_SAP_SUCCESS,
      codiceStoreSap: options["codiceStoreSap"],
   };
};

/*** EXPORT PAGE FILTERS ***/
export const getFiltersExport = () => {
   return (dispatch, getState) => {
      const isLoading = (isLoading) => {
         return {
            type: actionTypes.SET_EXPORT_FILTER_ISLOADING,
            isLoading,
         };
      };

      dispatch(isLoading(true));

      let url = urlList.GET_ALL_FILTERS_CLUSTER;

      fetch(url, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
      })
         .then((res) => handleErrorsJson(res, dispatch))
         // .then((options) => {
         //    let exportFilters = getState().filter.exportFilters;
         //    let newExport = JSON.parse(JSON.stringify(exportFilters));
         //    let exportFilterRel =
         //       typeSelected === MODULE
         //          ? exportFilterModuleRel
         //          : exportFilterClusterRel;

         //    exportFilterRel[filterType].map((filter) => {
         //       if (filter === "newModule") {
         //          let optionsArray = options[filter].map((opt) => {
         //             return {
         //                label: opt === "true" ? "NEW" : "CARRY OVER",
         //                value: opt,
         //             };
         //          });
         //          return (newExport[filter] = optionsArray);
         //       }

         //       if (options[filter]) {
         //          newExport[filter] = formatOptionsArray(options[filter]);
         //       }
         //    });

         //    dispatch(success(newExport));
         //    // dispatch(isLoading(false));
         // })

         .then((data) => {
            if (data && data !== null && data !== undefined) {
               const res = data;
               dispatch(success(res));
            }

            dispatch(success(data));
            // dispatch(isLoading(false));
         })

         .catch((err) => {
            dispatch(showError(err));
            // dispatch(isLoading(false));
         })
         .finally(() => {
            dispatch(isLoading(false));
         });

      const success = (exportFilters) => {
         return {
            type: actionTypes.GET_EXPORT_FILTER_SUCCESS,
            exportFilters,
         };
      };
   };
};

export const cleanExportFilters = () => {
   return {
      type: actionTypes.CLEAN_EXPORT_FILTERS,
   };
};

export const cleanSelectedExportFilters = () => {
   return {
      type: actionTypes.CLEAN_SELECTED_EXPORT_FILTERS,
   };
};
export const setExportSelectedFilters = (
   // tB:03-2022
   type,
   option
   // typeSelected
   // getData
) => {
   // const { MODULE } = exportType;

   return (dispatch, getState) => {
      let exportSelectedFilters = JSON.parse(
         JSON.stringify(getState().filter.exportSelectedFilters)
      );

      exportSelectedFilters[type] = option;

      // Logic to reset all select

      // let exportFilterRel =
      //    typeSelected === MODULE
      //       ? exportFilterModuleRel
      //       : exportFilterClusterRel;

      // exportFilterRel[type].map((filter) => {
      //    exportSelectedFilters[filter] = [];
      // });

      // if (getData) {
      //    dispatch(getFiltersExport(type, exportSelectedFilters, typeSelected));
      // }

      dispatch({
         exportSelectedFilters,
         type: actionTypes.SET_EXPORT_SELECTED_FILTERS,
      });
   };
};

/** GET EXPORT SEARCH RESULTS */
/** SEARCH RESULTS */
export const getExportSearchResults = (
   type,
   filters,
   status,
   moduleType = []
) => {
   let params = formatExportFilters(filters, type, status, moduleType);
   console.log(params)
   const { MODULE } = exportType;

   return (dispatch) => {
      let urlModules =
         type === MODULE
            ? urlList.GET_MODULELIST_DRY_URL
            : urlList.GET_CLUSTERLIST_DRY_URL;

      dispatch(getExportSearchResultsRequest());
      postSearchResults(
         params,
         urlModules,
         dispatch,
         getExportSearchResultsSuccess,
         getExportSearchResultsFailure
      );
   };
};

export const getExportSearchResultsRequest = () => {
   return {
      type: actionTypes.GET_EXPORT_SEARCH_RESULTS_REQUEST,
   };
};

export const getExportSearchResultsSuccess = (results) => {
   return {
      type: actionTypes.GET_EXPORT_SEARCH_RESULTS_SUCCESS,
      results,
   };
};

export const getExportSearchResultsFailure = () => {
   return {
      type: actionTypes.GET_EXPORT_SEARCH_RESULTS_FAILURE,
   };
};

export const resetExportSearch = () => {
   return {
      type: actionTypes.RESET_EXPORT_SEARCH,
   };
};

/* CLEAR HOME SEARCH RESULTS */
export const clearSearch = () => {
   return {
      type: actionTypes.CLEAR_SEARCH,
   };
};

/* OSCURARE LA RICERCA */
export const darkenSearchResults = () => {
   return {
      type: actionTypes.DARKEN_SEARCH_RESULTS,
   };
};

export const setMassiveEditParam = (params) => {
   return {
      type: actionTypes.MASSIVE_EDIT_MODULES,
      data: params,
   };
};
