import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../services/Api";
import { cloneDeep } from "lodash"

const initialState = {
  action: null,
  cluster: null,
  clusterList: null,
  isNewCluster: false,
  isActionSuccessful: false,
  searchModulesResult: null, 
  percRefresh: null,
  percRefreshList: null,
  rangeFacing: null,
  rangeFacingList: null,
  isSearchMLoading: false,
  isLoadingSave: false,
  errorSave: null,
  isLoadingPublish: false,
  errorPublish: false,
  errorCheckPublish: false,
  // isAltLoading: false,
  isCheckingPublish: false,
  //APPROVE
  filters: null,
  results: null,
  msgStatus: "",
  selectedCluster: null,
  singleModule: null,
  isListLoading: false,
  isLoadingCluster: false,
  isLoadingExportCluster: false,
  exportingClusters: [],
  lia: [],
  templates: [], 
};

const sendCluster = (state) => {
  return updateObject(state, { isNewCluster: false });
};

const getClusterRequest = (state) => { 
  return updateObject(state, {
    isLoadingCluster: true,
  });
};

const getCluster = (state, action) => {
  return updateObject(state, {
    cluster: action.cluster,
    isActionSuccessful: false,
    isLoadingCluster: false,
  });
};

const getClusterList = (state, action) => {
  return updateObject(state, {
    clusterList: action.clusterList,
    cluster: action.clusterList[0],
    isActionSuccessful: false,
    isLoadingCluster: false,
  });
};

const getClusterFailure = (state) => {
  return updateObject(state, {
    isLoadingCluster: false,
  });
};

/* NEW CLUSTER */
const setNewCluster = (state, action) => {
  const updatedProperties = { clusterList: [action.cluster], isNewCluster: true };
  return updateObject(state, updatedProperties);
};

// const getAltModulesRequest = state => {
//     return updateObject( state, {
//         isAltLoading: true
//     });
// }

// const setAltModules = (state, action) => {
//     let cluster = {...state.cluster, modules: action.modules}
//     return updateObject( state, {
//         cluster,
//         isAltLoading: false
//     });
// }

/** SEARCH MODULES **/
const searchModulesStart = (state) => {
  return updateObject(state, { isSearchMLoading: true });
};

const setSearchModulesResult = (state, action) => {
  return updateObject(state, {
    searchModulesResult: action.searchModulesResult,
    isSearchMLoading: false,
  });
};

const searchModulesFailure = (state) => {
  return updateObject(state, { isSearchMLoading: false });
};

const clearClusterState = (state) => {
  return updateObject(state, {
    action: null,
    cluster: null,
    isNewCluster: false,
    isActionSuccessful: false,
    searchModulesResult: null,
    percRefresh: null,
    isSearchMLoading: false,
    isLoadingSave: false,
    isLoadingPublish: false,
    rangeFacing: null,
  });
};

/* PERC OF REFRESH */
const setPercOfRefresh = (state, action) => {
  return updateObject(state, { percRefresh: action.percRefresh });
};

const setPercOfRefreshClusterList = (state, action) => {
  return updateObject(state, { 
    percRefreshList: action.percRefreshList 
  });
}

/** SAVE **/
const saveClusterStarted = (state) => {
  return updateObject(state, { isLoadingSave: true });
};

const saveCluster = (state, action) => {
  state.cluster.status = action.cluster.status;
  state.cluster.openMonthList = action.cluster.openMonthList;
  return updateObject(state, {
    cluster: state.cluster,
    isActionSuccessful: true,
    action: "save",
    isLoadingSave: false,
  });
};

const saveClusterList = (state, action) => {
  const responseList = action.clusterList.clusterDtoResponseList
  let clusterListCopy = cloneDeep(state.clusterList)

  clusterListCopy = clusterListCopy.map(cluster => {
    const clusterResponse = responseList.filter(response => response.clusterId === cluster.clusterId)[0]
    cluster.status = clusterResponse.status
    cluster.openMonthList = clusterResponse.openMonthList
    
    return cluster
  })

  return updateObject(state, {
    clusterList: clusterListCopy,
    isActionSuccessful: true,
    action: "save",
    isLoadingSave: false,
  });
} 

const saveClusterFailure = (state, action) => {
  return updateObject(state, {
    isLoadingSave: false,
    errorSave: action.error,
  });
};

/** DELETE **/
const deleteClusterStarted = (state) => {
  return state;
};

const deleteClusterSuccess = (state, action) => {
  return state;
};

const deleteClusterFailure = (state, action) => {
  return updateObject(state, {
    errorSave: action.error,
  });
};

/** CHECK PUBLISH **/
const checkPublishClusterStarted = (state) => {
  return updateObject(state, {
    isCheckingPublish: true,
    errorCheckPublish: null,
  });
};

const checkPublishCluster = (state) => {
  return updateObject(state, {
    isCheckingPublish: false,
  });
};

const checkPublishClusterFailure = (state, action) => {
  return updateObject(state, {
    isCheckingPublish: false,
    errorCheckPublish: action.error,
  });
};

/** PUBLISH **/
const publishClusterStarted = (state) => {
  return updateObject(state, {
    isLoadingPublish: true,
    errorPublish: null,
  });
};

const publishCluster = (state, action) => {
  state.cluster.status = action.cluster.status;

  return updateObject(state, {
    cluster: state.cluster,
    isActionSuccessful: true,
    action: "publish",
    isLoadingPublish: false,
  });
};

const publishClusterList = (state, action) => {
  const newClusterList = action.response.clusterDtoResponseList
  const newClusterListObj = {} // clusterId - status

  newClusterList.forEach(cluster => {
    newClusterListObj[cluster.clusterId] = cluster.status
  })

  state.clusterList = state.clusterList.map(cluster => {
    if(newClusterListObj[cluster.clusterId]){
      cluster.status = newClusterListObj[cluster.clusterId]
    }
    return cluster
  })

  return updateObject(state, {
    clusterList: state.clusterList,
    isActionSuccessful: true,
    action: "publish",
    isLoadingPublish: false,
  });
};

const publishClusterFailure = (state, action) => {
  return updateObject(state, {
    isLoadingPublish: false,
    errorPublish: action.error,
  });
};

const isNotSaved = (state) => {
  return updateObject(state, {
    isActionSuccessful: false,
    action: null,
  });
};

/** APPROVE */
const setSingleModule = (state, action) => {
  return updateObject(state, { singleModule: action.singleModule });
};

const approveClusterSuccess = (state) => {
  return updateObject(state, {
    msgStatus: "You have approved the cluster",
  });
};

const rejectClusterSuccess = (state) => {
  return updateObject(state, {
    msgStatus: "You have rejected the cluster",
  });
};

const resetState = (state) => {
  return updateObject(state, { singleModule: null });
};

const getTableListRequest = (state) => {
  return updateObject(state, {
    isListLoading: true,
    isError: false,
  });
};

const getTableListSuccess = (state, action) => {
  return updateObject(state, {
    results: action.results,
    filters: action.filters,
    isListLoading: false,
  });
};

const getTableListFailure = (state) => {
  return updateObject(state, {
    isListLoading: false,
    isError: true,
  });
};

const getRangeFacingSuccess = (state, action) => {
  return updateObject(state, {
    rangeFacing: action.rangeFacing,
  });
};

const getRangeFacingClustersSuccess = (state, action) => {
  return updateObject(state, {
    rangeFacingList: action.rangeFacing,
  });
}

const exportClusterRequest = (state, action) => {
  return updateObject(state, {
    isLoadingExportCluster: true,
    exportingClusters: action.clusters,
  });
};

const exportClusterSuccess = (state) => {
  return updateObject(state, {
    isLoadingExportCluster: false,
    exportingClusters: [],
  });
};

const exportClusterFailure = (state) => {
  return updateObject(state, {
    isLoadingExportCluster: false,
    exportingClusters: [],
  });
};

const getAllLiaSuccess = (state, action) => {
  return updateObject(state, {
    lia: action.allLia,
  });
};

const getTemplate = (state, action) => {
  console.log(action);
  return updateObject(state, {
    templates: action.templates,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CLUSTER:
      return sendCluster(state, action);
    case actionTypes.SET_NEW_CLUSTER:
      return setNewCluster(state, action);
    case actionTypes.GET_CLUSTER_REQUEST:
      return getClusterRequest(state);
    case actionTypes.GET_SINGLE_CLUSTER_SUCCESS:
      return getCluster(state, action);
    case actionTypes.GET_WORKING_CLUSTER_SUCCESS:
      return getClusterList(state, action);
    case actionTypes.GET_CLUSTER_FAILURE:
      return getClusterFailure(state);
    case actionTypes.SEARCH_MODULES_START:
      return searchModulesStart(state);
    case actionTypes.SEARCH_MODULES_SUCCESS:
      return setSearchModulesResult(state, action);
    case actionTypes.SEARCH_MODULES_FAILURE:
      return searchModulesFailure(state);
    case actionTypes.GET_PERC_OF_REFRESH_CLUSTER_SUCCESS:
      return setPercOfRefresh(state, action);
    case actionTypes.GET_PERC_OF_REFRESH_CLUSTER_LIST_SUCCESS:
      return setPercOfRefreshClusterList(state, action);
    case actionTypes.SAVE_CLUSTER_STARTED:
      return saveClusterStarted(state);
    case actionTypes.SAVE_CLUSTER_SUCCESS:
      return saveCluster(state, action);
    case actionTypes.SAVE_CLUSTER_LIST_SUCCESS:
      return saveClusterList(state, action);
    case actionTypes.SAVE_CLUSTER_FAILURE:
      return saveClusterFailure(state, action);

    case actionTypes.DELETE_CLUSTER_STARTED:
      return deleteClusterStarted(state);
    case actionTypes.DELETE_CLUSTER_SUCCESS:
      return deleteClusterSuccess(state, action);
    case actionTypes.DELETE_CLUSTER_FAILURE:
      return deleteClusterFailure(state, action);

    case actionTypes.PUBLISH_CLUSTER_STARTED:
      return publishClusterStarted(state);
    case actionTypes.PUBLISH_CLUSTER_SUCCESS:
      return publishCluster(state, action);
    case actionTypes.PUBLISH_CLUSTER_LIST_SUCCESS:
      return publishClusterList(state, action);
    case actionTypes.PUBLISH_CLUSTER_FAILURE:
      return publishClusterFailure(state, action);
    case actionTypes.CHECK_PUBLISH_CLUSTER_STARTED:
      return checkPublishClusterStarted(state);
    case actionTypes.CHECK_PUBLISH_CLUSTER_SUCCESS:
      return checkPublishCluster(state);
    case actionTypes.CHECK_PUBLISH_CLUSTER_FAILURE:
      return checkPublishClusterFailure(state, action);
    case actionTypes.IS_NOT_SAVED:
      return isNotSaved(state);
    case actionTypes.CLEAR_CLUSTER_STATE:
      return clearClusterState(state);
    // case actionTypes.GET_ALT_MODULE_REQUEST: return getAltModulesRequest(state);
    // case actionTypes.GET_ALT_MODULE_SUCCESS: return setAltModules(state, action);
    case actionTypes.GET_SINGLE_MODULE_SUCCESS:
      return setSingleModule(state, action);
    case actionTypes.GET_APPROVE_TABLE_LIST_REQUEST:
      return getTableListRequest(state);
    case actionTypes.GET_APPROVE_TABLE_LIST_SUCCESS:
      return getTableListSuccess(state, action);
    case actionTypes.GET_APPROVE_TABLE_LIST_FAILURE:
      return getTableListFailure(state);
    case actionTypes.APPROVE_CLUSTER_SUCCESS:
      return approveClusterSuccess(state);
    case actionTypes.REJECT_CLUSTER_SUCCESS:
      return rejectClusterSuccess(state);
    case actionTypes.RESET_STATE:
      return resetState(state);
    case actionTypes.GET_RANGE_FACING_SUCCESS:
      return getRangeFacingSuccess(state, action);
    case actionTypes.GET_RANGE_FACING_CLUSTERS_SUCCESS:
        return getRangeFacingClustersSuccess(state, action);
    case actionTypes.IS_LOADING_EXPORT_CLUSTER_REQUEST:
      return exportClusterRequest(state, action);
    case actionTypes.IS_LOADING_EXPORT_CLUSTER_SUCCESS:
      return exportClusterSuccess(state);
    case actionTypes.IS_LOADING_EXPORT_CLUSTER_FAILURE:
      return exportClusterFailure(state);
    case actionTypes.GET_ALL_LIA_SUCCESS:
      return getAllLiaSuccess(state, action);
    case actionTypes.GET_TEMPLATE:
      return getTemplate(state, action);
    default:
      return state;
  }
};

export default reducer;
