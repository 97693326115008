import { CLIENT_CLASSIFICATION_CREATE, CLIENT_CLASSIFICATION_DELETE } from "../../../config";
import React, { useCallback, useEffect, useState } from "react";

import Button from "../../UI-components/Button";
import { CLIENT_CLASSIFICATION_ALL } from "../../../config";
import CustomInput from "../../UI-components/CustomInput";
import Loader from "../../UI-components/Loader";
import Popup from "../../UI-components/Popup";
import axios from "axios";
import classes from '../../../styles/modules/popupClientClassification.module.scss'
import { getCookie } from "../../../services/cookie";

const ClassificationRow = ({
    classificationName,
    classificationId,
    openConfirmationDeletePopup
}) => {

    return (
        <div className={classes.clientClassificationRow}>
            <span>{classificationName}</span>
            <div onClick={() => openConfirmationDeletePopup({
                classificationId: classificationId,
                classificationLabel: classificationName
            })}>
                <span className="icon-cestino" />
            </div>
        </div>
    )
}

const NewClientClassification = ({
    addRowInClassificationList
}) => {

    const [classificationName, setClassificationName] = useState("")
    const [classificationNameError, setClassificationNameError] = useState("")
    const [addClassification, setAddClassification] = useState(false)

    const handleChangeClassificationName = (e) => {
        const name = e.target.value
        const regex = RegExp("^[0-9A-Z_-]*$")

        setClassificationName(name)

        if (regex.test(name)) {
            setClassificationNameError("")
        }
        else {
            const errorMessage = "Admitted characters: A-Z, 0-9, _, -"
            setClassificationNameError(errorMessage)
        }
    }

    const addClientClassification = () => {
        setAddClassification(true)
    }

    const disableCreateButton = () => {
        return classificationName === "" || classificationNameError !== ""
    }

    useEffect(() => {
        if (addClassification) {
            // fai la chiamata
            if (classificationName !== '' && classificationNameError === '') {
                axios.post(`${CLIENT_CLASSIFICATION_CREATE}?classificationLabel=${classificationName}`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken")
                        }
                    })
                    .then(response => {
                        setClassificationName("")
                        addRowInClassificationList(response.data)
                    })
                    .catch(err => {
                        setClassificationNameError(err.response.data)
                    })
                    .finally(() => {
                        setAddClassification(false)
                    })
            }
            else {
                if (classificationNameError === '')
                    setClassificationNameError('Insert a valid client classification name')
            }
            setAddClassification(false)
        }
    }, [addClassification, classificationName, addRowInClassificationList, classificationNameError])

    return (
        <div className={classes['clientClassificationForm-container']}>
            <div className={classes.clientClassificationForm}>
                <CustomInput
                    type="text"
                    placeholder="Insert a new classification label"
                    inputClass={`input-big ${classificationNameError !== "" ? "input-error" : ""}`}
                    value={classificationName}
                    handleChange={handleChangeClassificationName}
                    maxLength={15}
                />
                <Button 
                    btnClass="btn-medium"
                    handleClick={addClientClassification}
                    disabled={disableCreateButton()}
                >
                    Create
                </Button>
            </div>
            {
                <span className={classes.errorMessage}>
                    {classificationNameError}
                </span>
            }
        </div>
    )
}

const AddClientClassificationPopup = ({
    onClose
}) => {

    const [isLoadingClassificationList, setIsLoadingClassificationList] = useState(true)
    const [classificationList, setClassificationList] = useState([])
    //const [showConfirmationDeletePopup, setShowConfirmationDeletePopup] = useState(false)
    const [classificationToBeDeleted, setClassificationToBeDeleted] = useState({})

    const sortClassificationLabel = (class1, class2) => {
        if(class1.classificationLabel > class2.classificationLabel) return 1
        else if(class1.classificationLabel < class2.classificationLabel) return -1
        else return 0
    }

    useEffect(() => {
        if (isLoadingClassificationList) {
            axios({
                url: CLIENT_CLASSIFICATION_ALL,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken")
                }
            })
                .then((response) => {
                    response.data.sort(sortClassificationLabel)
                    setClassificationList(response.data)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setIsLoadingClassificationList(false)
                })
        }
    }, [isLoadingClassificationList])

    const addClassification = useCallback((classification) => {
        setClassificationList(currentList => [...currentList, classification].sort(sortClassificationLabel))
    }, [])

    const removeClassification = useCallback((classificationId) => {
        setClassificationList(currentList => currentList.filter(classification => {
            return classification.classificationId !== classificationId
        }))
    }, [])

    const openConfirmationDeletePopup = (classification) => {
        setClassificationToBeDeleted({ ...classification })
    }

    const closeConfirmationDeletePopup = () => {
        setClassificationToBeDeleted({})
    }

    return (

        <Popup
            title={"Client Classification List"}
            popupClass={"popup-large"}
            close={onClose}
        >
            {
                Object.keys(classificationToBeDeleted).length > 0 ?
                    <ConfirmationDeleteContent
                        removeRowInClassificationList={removeClassification}
                        closeConfirmationDeletePopup={closeConfirmationDeletePopup}
                        classificationToBeDeleted={classificationToBeDeleted}
                    />
                    :
                    <ClassificationListContent
                        isLoadingClassificationList={isLoadingClassificationList}
                        addRowInClassificationList={addClassification}
                        classificationList={classificationList}
                        openConfirmationDeletePopup={openConfirmationDeletePopup}
                    />
            }
        </Popup>
    )
}

const ClassificationListContent = ({
    isLoadingClassificationList,
    addRowInClassificationList,
    classificationList,
    openConfirmationDeletePopup
}) => {
    return (
        isLoadingClassificationList ?
            <div className={classes['loader-container']}>
                <Loader />
            </div>
            :
            <>
                <NewClientClassification
                    addRowInClassificationList={addRowInClassificationList}
                />
                {
                    classificationList.length > 0 ?
                        classificationList.map(elem => {
                            return (
                                <ClassificationRow
                                    classificationName={elem.classificationLabel}
                                    classificationId={elem.classificationId}
                                    key={elem.classificationId}
                                    openConfirmationDeletePopup={openConfirmationDeletePopup}
                                />
                            )
                        })
                    :
                        <p>There are no client classification available</p>
                }
            </>
    )
}

const ErrorContainer = ({
    message
}) => {
    return (
        <p style={{
            color: "red"
        }}>
            {message}
        </p>
    )
}

const ConfirmationDeleteContent = ({
    removeRowInClassificationList,
    classificationToBeDeleted,
    closeConfirmationDeletePopup
}) => {

    const [loading, setLoading] = useState(false)
    const [removeClassificationError, setRemoveClassificationError] = useState('')

    useEffect(() => {
        if (loading) {
            axios.delete(`${CLIENT_CLASSIFICATION_DELETE}?classificationId=${classificationToBeDeleted.classificationId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken")
                    }
                })
                .then(() => {
                    removeRowInClassificationList(classificationToBeDeleted.classificationId)
                    closeConfirmationDeletePopup()
                    setLoading(false)
                })
                .catch(err => {
                    setRemoveClassificationError(err.response.data)
                    setLoading(false)
                })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
        }}>
            {
                !removeClassificationError &&
                <>
                    <p>Are you sure you want to delete {classificationToBeDeleted.classificationLabel}?</p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}>
                        <Button
                            handleClick={closeConfirmationDeletePopup}
                            style={{
                                padding: "0.5rem 2rem"
                            }}
                            disabled={loading}
                        >
                            No
                        </Button>
                        <Button
                            handleClick={() => setLoading(true)}
                            style={{
                                padding: "0.5rem 2rem",
                                backgroundColor: "white",
                                color: "black"
                            }}
                            disabled={loading}
                        >
                            Yes
                        </Button>
                    </div>
                </>
            }
            {
                loading &&
                <Loader />
            }
            {
                removeClassificationError &&
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2rem"
                    }}  
                >
                    <ErrorContainer
                        message={removeClassificationError}
                    />
                    <Button
                        handleClick={closeConfirmationDeletePopup}
                        style={{
                            height: "2rem",
                            width: "5rem",
                            
                        }}
                        disabled={loading}
                    >
                        Ok
                    </Button>
                </div>
            }
        </div>
    )
}

export default AddClientClassificationPopup