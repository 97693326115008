import React from "react";
import CustomIcon from "../UI-components/CustomIcon";

const listItem = (props) => (
  <li
    className={`lists-item ${props.listItemClass ? props.listItemClass : ""}`}
    onClick={props.handleClick}
  >
    {props.locked && (
      <CustomIcon iconContClass="icon-left mr-2" iconClass="icon-close-lock" />
    )}
    {props.statistic && (
      <div className={"brand-logo-container " + props.brand} />
    )}
    <div className="text">{props.children}</div>
    <CustomIcon iconClass={props.iconClass} />
  </li>
);

export default listItem;
