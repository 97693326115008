/* eslint-disable array-callback-return */

import * as _ from "lodash";
import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";

import {
   formatFilters,
   formatFiltersOptionsArray,
   formatOptionsOrdered,
   handleErrorsJson,
} from "../../services/Api";
import { lockMacro, lockModule } from "./userActions";

import axios from "axios";
import { getCookie } from "../../services/cookie";
import { sendHomeFilters } from "./filterActions";
import { showError } from "./errorActions";

/* MOCK */
// import moduleList from '../../Mock/moduleList';
// import newnessList from '../../Mock/newness';
// import activeFilters from '../../Mock/activeFilters';
// import activeSku from '../../Mock/activeSku';
// import newModules from '../../Mock/importModules';
// import clusterPerc from '../../Mock/clusterPerc';

/** SET INFO NEW MODULE */
//creo un nuovo modulo da Home page
export const setNewModule = (macroList) => {
   return {
      type: actionTypes.CREATE_NEW_MODULE,
      macroList,
   };
};

/*** GET ALL MACROFAMILY LIST ***/
export const getAllMacroFamily = () => {
   return (dispatch) => {
      let url = urlList.GET_MACROLIST_URL;

      fetch(url, { 
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify({}),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((options) => {
            let optionsOrdered = formatOptionsOrdered(options, "macroFamily");
            dispatch(getAllMacroFamilySuccess(optionsOrdered));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getAllMacroFamilySuccess = (allMacroList) => {
   return {
      type: actionTypes.GET_ALL_MACRO_SUCCESS,
      allMacroList,
   };
};

/** NEWNESS LEGO */
export const getNewnessLego = (monthId, flagNew, filters) => {
   let params = formatFilters(filters);
   params.flagNew = flagNew;
   params.monthId = [monthId];
   // params.macroFamily = ["TEST"];

   return (dispatch, getState) => {
      //check if any of the performance filters is selected, if so add them to params
      let perfoFilters = getState().module.perfoSelectedFilters;
      params.ibicFilters = addPerfoFilters(perfoFilters);

      dispatch(getNewnessLegoRequest());
      let url = urlList.GET_NEWNESS_LEGO_URL;
      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            // let newnessSkuList = fromModuleListToSkuList(newnessList)
            // dispatch(getNewnessSuccess(newnessSkuList)); //mock

            //order skus by pty
            moduleList.forEach((moduleI) => {
               moduleI.products.sort((a, b) => {
                  return parseFloat(b.pty) - parseFloat(a.pty);
               });
            });
            dispatch(getNewnessLegoSuccess(moduleList));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(getNewnessLegoFailure());
         });
   };
};

const getNewnessLegoRequest = () => {
   return {
      type: actionTypes.GET_NEWNESS_LEGO_REQUEST,
   };
};

const getNewnessLegoSuccess = (newnessLegoList) => {
   return {
      type: actionTypes.GET_NEWNESS_LEGO_SUCCESS,
      newnessLegoList,
   };
};

const getNewnessLegoFailure = () => {
   return {
      type: actionTypes.GET_NEWNESS_LEGO_FAILULE,
   };
};

/** NEWNESS NOT LEGO */
export const getNewnessNotLego = (monthId, filters) => {
   let params = formatFilters(filters);
   params.monthId = [monthId];
   params.currentMonthId = [filters.months.value];

   return (dispatch, getState) => {
      //check if any of the performance filters is selected, if so add them to params
      let perfoFilters = getState().module.perfoSelectedFilters;
      params.ibicFilters = addPerfoFilters(perfoFilters);

      dispatch(getNewnessNotLegoRequest());
      let url = urlList.GET_NEWNESS_NOT_LEGO_URL;
      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((skuList) => {
            skuList.sort((a, b) => {
               return parseFloat(b.pty) - parseFloat(a.pty);
            });

            dispatch(getNewnessNotLegoSuccess(skuList));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(getNewnessNotLegoFailure());
         });
   };
};
const getNewnessNotLegoRequest = () => {
   return {
      type: actionTypes.GET_NEWNESS_NOT_LEGO_REQUEST,
   };
};

const getNewnessNotLegoSuccess = (newnessNotLegoList) => {
   return {
      type: actionTypes.GET_NEWNESS_NOT_LEGO_SUCCESS,
      newnessNotLegoList,
   };
};

const getNewnessNotLegoFailure = () => {
   return {
      type: actionTypes.GET_NEWNESS_NOT_LEGO_FAILULE,
   };
};

// const fromModuleListToSkuList = (moduleList) => {
//    let skuArray = [];
//    for (let i = 0; i < moduleList.length; i++) {
//       moduleList[i].products.map((p) => {
//          skuArray.push(p);
//       });
//    }

//    return skuArray;
// };

/* GET MODULES IN WHICH THE SKU IS CONTAINED */
export const getModulesByProductsNewness = (sku, filters) => {
   let monthId = filters.months.value;

   let params = {
      monthId,
      model: sku.model,
      color: sku.color,
      size: sku.size,
   };

   return (dispatch) => {
      let url = urlList.GET_MODULE_LIST_NEWNESS;
      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            dispatch(getModulesByProductNewnessSuccess(moduleList));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getModulesByProductNewnessSuccess = (productModuleList) => {
   return {
      type: actionTypes.GET_MODULES_BY_PRODUCTS_NEWNESS_SUCCESS,
      productModuleList,
   };
};

/* GET MODULES IN WHICH THE SKU IS CONTAINED */
export const getModulesByProducts = (sku, filters) => {
   let monthId = filters.months.value;

   let params = {
      monthId,
      model: sku.model,
      color: sku.color,
      size: sku.size,
      materialCode: sku.materialCode,
      skuCode: sku.skuCode,
   };

   return (dispatch) => {
      let url = urlList.GET_LIST_MODULES_BY_PROD;
      dispatch(getModulesByProductRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            dispatch(getModulesByProductSuccess(moduleList));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getModulesByProductRequest = () => {
   return {
      type: actionTypes.GET_MODULES_BY_PRODUCTS_REQUEST,
   };
};

export const getModulesByProductSuccess = (productModuleList) => {
   return {
      type: actionTypes.GET_MODULES_BY_PRODUCTS_SUCCESS,
      productModuleList,
   };
};

export const resetGetModulesByProduct = () => {
   return {
      type: actionTypes.RESET_GET_MODULES_BY_PRODUCT,
   };
};

/*** WORKING MODULE */

export const isGetByModule = () => {
   return {
      type: actionTypes.IS_GET_BY_MODULE,
   };
};

export const getWorkingModuleWithLock = (moduleList, callbackSuccess, modeViewer = false) => {
   return (dispatch) => {
      dispatch(isGetByModule());
      if(modeViewer){
         dispatch(getWorkingModule(moduleList, false, callbackSuccess));
      } else {
         dispatch(
            lockModule(moduleList, () => dispatch(getWorkingModule(moduleList, false, callbackSuccess)))
         );
      }
   };
};

export const getWorkingModule = (moduleList, isJustModule = false, callbackSuccess = null) => {
   let params = {
      moduleDto: moduleList.map((moduleItem) => {
         return {
            moduleName: moduleItem.moduleName,
            monthId: moduleItem.monthId,
         };
      }),
   };

   return async(dispatch, getState) => {
      // check if any of the performance filters is selected, if so add them to params
      let perfoFilters = getState().module.perfoSelectedFilters;
      params.ibicFilters = addPerfoFilters(perfoFilters);

      let url = urlList.GET_WORKING_MODULE;
      dispatch(getWorkingModuleRequest());
      await fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            if (isJustModule) dispatch(getSingleModuleSuccess(moduleList[0]));
            else {
               let macrolist = mapModulesToMacro(moduleList);
               dispatch(getWorkingModuleSuccess(macrolist));
            }
            if(callbackSuccess) {
               callbackSuccess()
            }
         })
         .catch((err) => {
            console.error(err);
            dispatch(showError(err));
            dispatch(getWorkingModuleFailure());
         });
   };
};

export const getWorkingModuleRequest = () => {
   return {
      type: actionTypes.GET_WORKING_MODULE_REQUEST,
   };
};

export const getWorkingModuleSuccess = (macroList) => {
   return {
      type: actionTypes.GET_WORKING_MODULE_SUCCESS,
      macroList,
   };
};

export const getWorkingModuleFailure = () => {
   return {
      type: actionTypes.GET_WORKING_MODULE_FAILURE,
   };
};

export const getSingleModuleSuccess = (singleModule) => {
   return {
      type: actionTypes.GET_SINGLE_MODULE_SUCCESS,
      singleModule,
   };
};

/*** WORKING MODULE BY MACRO */
export const isGetByMacro = () => {
   return {
      type: actionTypes.IS_GET_BY_MACRO,
   };
};

export const getWorkingModuleMacroWithLock = (filters, macro) => {
   return (dispatch) => {
      dispatch(isGetByMacro());
      dispatch(
         lockMacro(macro, () => dispatch(getWorkingModuleMacro(filters, macro)))
      );
   };
};

export const getWorkingModuleMacro = (filters, macro) => {
   let macroString = macro.map((m) => {
      return m.macroFamily? m.macroFamily:null;
   });

   let planningChannel = filters.planningChannel
      ? filters.planningChannel.map((pl) => pl.value)
      : [];
   let brand = filters.brand ? filters.brand.map((brand) => brand.value) : [];

   let collection = filters.collection ? filters.collection.map((c) => {
      return c.value;
   }) : [];

   let params = {
      monthId: [filters.months.value],
      brand,
      planningChannel,
      macroFamily: macroString,
      collection,
   };

   return (dispatch, getState) => {
      //check if any of the performance filters is selected, if so add them to params
      let perfoFilters = getState().module.perfoSelectedFilters;
      params.ibicFilters = addPerfoFilters(perfoFilters);

      let url = urlList.GET_MODULES_FILTERED_PTY;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            let macrolist = mapModulesToMacro(moduleList);
            dispatch(getWorkingModuleSuccess(macrolist));
         })
         .catch((err) => {
            console.error(err);
            dispatch(showError(err));
         });
   };
};

/*** ADD MODULE TO LIST ***/
export const addModule = (modules, currentMacrolist) => {
   let moduleDto = modules.map((moduleI) => {
      return {
         moduleName: moduleI.moduleName,
         monthId: moduleI.monthId,
      };
   });

   let params = {
      moduleDto,
   };

   return (dispatch, getState) => {
      let perfoFilters = getState().module.perfoSelectedFilters;
      params.ibicFilters = addPerfoFilters(perfoFilters);
      let url = urlList.GET_WORKING_MODULE;

      console.log(params.ibicFilters)

      dispatch(
         lockModule(moduleDto, async() => {
            await fetch(url, {
               method: "post",
               headers: {
                  "Content-Type": "application/json",
                  xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
               },
               body: JSON.stringify(params),
            })
               .then((res) => handleErrorsJson(res, dispatch))
               .then((moduleList) => {
                  moduleList = mapModulesToMacro(moduleList);
                  let macroList = addToModuleList(moduleList, currentMacrolist);

                  dispatch(getWorkingModuleSuccess(macroList));
               })
               .catch((err) => {
                  dispatch(showError(err));
               });
         })
      );
      // dispatch(setModulesToGet())
   };
};

/*** ADD MACRO TO LIST ***/
export const addMacro = (filters, macro, currentMacrolist) => {
   let objToSend = {
      monthId: [filters.months.value],
      brand:
         filters.brand !== null && filters.brand.value
            ? [filters.brand.value]
            : [],
      planningChannel:
         filters.planningChannel !== null && filters.planningChannel.value
            ? [filters.planningChannel.value]
            : null,
      macroFamily: [macro.value],
      collection: filters.collection.length > 0 ? filters.collection : [],
   };

   return (dispatch) => {
      let url = urlList.GET_MODULES_FILTERED_PTY;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(objToSend),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            moduleList = mapModulesToMacro(moduleList);
            let macroList = addToModuleList(moduleList, currentMacrolist);

            dispatch(getWorkingModuleSuccess(macroList));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

/*** ADD NEW MODULE TO LIST */
//quando aggiungo un modulo dalla module composition
export const addNewModule = (newModule, familyList) => {
   return (dispatch) => {
      let macroList = addToModuleList(newModule, familyList);
      dispatch(getWorkingModuleSuccess(macroList));
   };
};

/* IMPORT MODULES IN MACRO */
export const importModules = (family, newModulesList) => {
   newModulesList.map((m) => {
      m.macroFamily = family;
   });

   return (dispatch, getState) => {
      let url = urlList.IMPORT_MODULE_URL;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(newModulesList),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            dispatch(addModule(moduleList, getState().module.macroList));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

/* UPDATE MACRO */

export const updateMacroList = (macroList, moduleI) => {
   return (dispatch, getState) => {
      let modulesChangedList = changeListModulesChanged(getState, moduleI);

      dispatch({
         type: actionTypes.UPDATE_MACROLIST,
         macroList,
         modulesChangedList,
      });
   };
};

/*** CREATE MACRO ***/
export const createMacroFamily = (macro) => {
   let macroList = [
      {
         macroFamily: macro,
         moduleList: [],
      },
   ];

   return {
      type: actionTypes.CREATE_MACROFAMILY,
      macroList,
   };
};

/* SAVE MODULE */
export const saveModule = (
   mod,
   isCreationByEditor = false,
   macrofamilyName
) => {
   return (dispatch, getState) => {
      dispatch(saveModuleRequest());
      const url = urlList.SAVE_MODULE_URL;
      axios({
         url,
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         data: JSON.stringify(mod),
      })
         .then((moduleI) => {
            const module = {
               moduleDto: [
                  {
                     moduleName: moduleI.data.moduleName,
                     monthId: moduleI.data.monthId,
                  },
               ],
            };
            isCreationByEditor
               ? dispatch(saveModuleSuccess())
               : dispatch(
                  getModuleUpdated(saveModuleSuccess, module, macrofamilyName)
               );

            if (getState().module.isNewModule) {
               dispatch(lockModule(module.moduleDto));
            }
         })
         .catch((err) => {
            if (err.response.status === 422) {
               dispatch(saveModuleFailure(err.response.data, mod.moduleName));
            } else {
               dispatch(saveModuleFailure(null));
               dispatch(showError(err));
            }
         });
   };
};

const saveModuleRequest = () => {
   return {
      type: actionTypes.SAVE_MODULE_REQUEST,
   };
};

const saveModuleSuccess = (macroList, moduleNameSaved, modulesChangedList) => {
   return {
      type: actionTypes.SAVE_MODULE_SUCCESS,
      macroList,
      moduleNameSaved,
      modulesChangedList,
   };
};

const saveModuleFailure = (error, moduleNameSaved = null) => {
   return {
      type: actionTypes.SAVE_MODULE_FAILURE,
      error,
      moduleNameSaved,
   };
};

const getModuleUpdated = (callback, params, macrofamilyName) => {
   return async(dispatch, getState) => {
      let perfoFilters = getState().module.perfoSelectedFilters;
      params.ibicFilters = addPerfoFilters(perfoFilters);

      let url = urlList.GET_WORKING_MODULE;

      await fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            let moduleI = moduleList[0];
            let { macroList } = getState().module;
            let updatedMacroList = [...macroList];
            let macroFamilyIndex;
            let moduleIndex;

            updatedMacroList.map((macroFamily, i) => {
               if (macroFamily.macroFamily === macrofamilyName) {
                  macroFamilyIndex = i;
               }
            });

            updatedMacroList[macroFamilyIndex].moduleList.map((module, i) => {
               if (module.moduleName === moduleI.moduleName) {
                  moduleIndex = i;
               }
            });

            updatedMacroList[macroFamilyIndex].moduleList[moduleIndex] =
               moduleI;

            let modulesChangedList = clearMacroList(getState, moduleI);
            dispatch(
               callback(
                  updatedMacroList,
                  moduleI.moduleName,
                  modulesChangedList
               )
            );
         })
         .catch((err) => {
            console.error(err);
            dispatch(showError(err));
         });
   };
};

/* DELETE MODULE */
export const deleteModule = (mod, filters) => {
   return (dispatch, getState) => {

      dispatch(deleteModuleRequest());
      let url = urlList.SAVE_MODULE_URL;

      axios({
         url,
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         data: JSON.stringify(mod),
      })
         .then(() => {
            dispatch(deleteModuleSuccess());
            dispatch(sendHomeFilters(filters));
         })
         .catch((err) => {
            console.error(err);
            dispatch(showError(err));
         });
   };
};

const deleteModuleRequest = () => {
   return {
      type: actionTypes.DELETE_MODULE_REQUEST,
   };
};

const deleteModuleSuccess = () => {
   return {
      type: actionTypes.DELETE_MODULE_SUCCESS,
   };
};

/* DELETE SELECTED MODULES */
export const deleteSelectedModules = (mod, filters) => {
   return (dispatch, getState) => {
      let url = urlList.SAVE_MODULES_URL;
      dispatch(deleteSelectedModulesRequest());
      dispatch(lockModule((mod), () => {

         axios({
            url,
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            data: JSON.stringify(mod),
         })
            .then(() => {
               dispatch(deleteSelectedModulesSuccess());
               dispatch(sendHomeFilters(filters));
            })
            .catch((err) => {
               console.error(err);
               dispatch(showError(err));
            });
      }))
   };
};

const deleteSelectedModulesRequest = () => {
   return {
      type: actionTypes.DELETE_SELECTED_MODULES_REQUEST,
   };
};

const deleteSelectedModulesSuccess = () => {
   return {
      type: actionTypes.DELETE_SELECTED_MODULES_SUCCESS,
   };
};

/* PUBLISH MODULE */
export const publishModule = (mod, macrofamilyName) => {
   return (dispatch) => {
      dispatch(publishModuleRequest());
      let url = urlList.PUBLISH_MODULE_URL;
      axios({
         url,
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         data: JSON.stringify(mod),
      })
         .then((moduleI) => {
            const module = {
               moduleDto: [
                  {
                     moduleName: moduleI.data.moduleName,
                     monthId: moduleI.data.monthId,
                  },
               ],
            };
            dispatch(
               getModuleUpdated(publishModuleSuccess, module, macrofamilyName)
            );
         })
         .catch((err) => {
            if (err.response.status === 422) {
               dispatch(
                  publishModuleFailure(err.response.data, mod.moduleName)
               );
            } else {
               dispatch(publishModuleFailure(null));
               dispatch(showError(err));
            }
         });
   };
};

const publishModuleRequest = () => {
   return {
      type: actionTypes.PUBLISH_MODULE_REQUEST,
   };
};

const publishModuleSuccess = (
   macroList,
   moduleNameSaved,
   modulesChangedList
) => {
   return {
      type: actionTypes.PUBLISH_MODULE_SUCCESS,
      macroList,
      moduleNameSaved,
      modulesChangedList,
   };
};

const publishModuleFailure = (error, moduleNameSaved = null) => {
   return {
      type: actionTypes.PUBLISH_MODULE_FAILURE,
      error: error.response,
      moduleNameSaved,
   };
};

export const resetSaveAndPublish = () => {
   return {
      type: actionTypes.RESET_SAVE_AND_PUBLISH,
   };
};

/** CLEAR STATE WHEN UNMOUNTING */
export const clearModuleState = () => {
   return {
      type: actionTypes.CLEAR_MODULE_STATE,
   };
};

/* SEARCH MODULES */
export const searchModules = (param) => {
   return (dispatch) => {
      let url = urlList.GET_MODULES_FILTERED;
      dispatch(searchModulesStart());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(param),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((moduleList) => {
            dispatch(searchModulesSuccess(moduleList));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(searchModulesFailure());
         });
   };
};

const searchModulesStart = () => {
   return {
      type: actionTypes.SEARCH_MODULES_START,
   };
};

const searchModulesSuccess = (searchModulesResult) => {
   return {
      type: actionTypes.SEARCH_MODULES_SUCCESS,
      searchModulesResult,
   };
};

const searchModulesFailure = () => {
   return {
      type: actionTypes.SEARCH_MODULES_FAILURE,
   };
};

export const getModuleFilters = (filters, noformat = false) => {
   let params = filters;
   if(!noformat){
      let planningChannel = filters.planningChannel.length
      ? filters.planningChannel.map((pl) => pl.value)
      : [];
   let brand = filters.brand.length
      ? filters.brand.map((brand) => brand.value)
      : [];

   params = formatFilters(filters);
   params.planningChannel = planningChannel;
   params.brand = brand;
   }
   
   return (dispatch) => {
      let url = urlList.GET_FILTERS_MODULE;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((filtersObj) => {
            Object.keys(filtersObj).map((k) => {
               filtersObj[k] = formatFiltersOptionsArray(filtersObj[k], k);
            });
            filtersObj["newModule"].forEach((filter) => {
               if (filter.value === "true") filter.label = "NEWNESS";
               if (filter.value === "false") filter.label = "CARRY OVER";
            });
            dispatch(getModuleFiltersSuccess(filtersObj));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

const getModuleFiltersSuccess = (homeFilters) => {
   return {
      type: actionTypes.GET_MODULE_FILTER_SUCCESS,
      homeFilters,
   };
};

export const updatePerformanceInfo = (filters) => {
   return (dispatch, getState) => {
      let currentTab = getState().module.currentlyVisibleNewnessTab,
         selectedFilters = getState().filter.selectedFilters,
         month = getState().filter.selectedFilters.months,
         isGetByMacro = getState().module.isGetByMacro,
         macroList = getState().module.macroList;

      if (currentTab === 0 || currentTab === 2) {
         let monthToSearch =
            currentTab === 0 ? month.monthIdPrecPrec : month.monthIdPrec;
         dispatch(
            getNewnessLego(monthToSearch, currentTab !== 0, selectedFilters)
         );
      } else {
         let monthToSearch =
            currentTab === 1 ? month.monthIdPrecPrec : month.monthIdPrec;
         dispatch(getNewnessNotLego(monthToSearch, selectedFilters));
      }

      if (isGetByMacro) {
         dispatch(getWorkingModuleMacro(selectedFilters, macroList));
      } else {
         let moduleList = [];

         macroList.map((macro) => {
            moduleList = [...moduleList, ...macro.moduleList];
         });

         dispatch(getWorkingModule(moduleList));
      }
   };
};

export const clearPerfoFilters = () => {
   return {
      type: actionTypes.CLEAR_PERFO_FILTERS,
   };
};

export const setPerfoFilters = (typeFilter, value) => {
   return {
      type: actionTypes.SET_PERFO_FILTERS,
      value,
      typeFilter,
   };
};

export const setCurrentlyVisibleNewnessTab = (value) => {
   return {
      type: actionTypes.SET_CURRENTLY_VISIBLE_NEWNESS_TAB,
      value,
   };
};

export const setDefaultCurrentTab = () => {
   return {
      type: actionTypes.SET_DEFAULT_CURRENT_NEWNESS_TAB,
   };
};

export const exportModules = (modules, selectedFilters, status, exportFormat, template, photo,user) => {
   const starsZone = selectedFilters.country.map(filter => filter.value)
   const starsCustomer = selectedFilters.starsCustomer.map(filter => filter.value)
   const cluster = selectedFilters.clusterId.map(filter => filter.value)
   const planningChannel = selectedFilters.planningChannel.map(filter => filter.value)

   let params = modules.map((m) => {
      return {
         moduleName: m.moduleName,
         monthId: m.monthId,
         starZones: starsZone,
         starsCustomer: starsCustomer,
         clusterIdFilter: cluster,
         planningChannel: planningChannel,
      };
   });
   let paramsToSave = modules.map((m) => {
      return m.moduleName;
   });

   return (dispatch) => {
      let url;
      switch (exportFormat) {
         case "xls":
            url = urlList.GET_MODULES_EXPORT_XLS;
            break;
         case "xls/photo":
            url = urlList.GET_MODULES_EXPORT_XLS_PHOTO;
            break;
         case "pdf":
            url = urlList.GET_MODULES_EXPORT_PDF;
            break;
         case "xls_massive":
            url = urlList.GET_MODULES_EXPORT_XLS;
            break;
         case "xls/photo_massive":
            url = urlList.GET_MODULES_EXPORT_XLS_PHOTO;
            break;
         case "pdf_massive":
            url = urlList.GET_MODULES_MASSIVE_EXPORT_PDF;
            break;
         default:
      }
      let paramsInGet = status.map((st) => st.toUpperCase()).join();

      dispatch(request(paramsToSave));
      let finalUrl;
      if (exportFormat === "xls" || exportFormat === "xls/photo" || exportFormat === "xls_massive" || exportFormat === "xls/photo_massive") {
         finalUrl = url + "?templateId=" + template + "&status=" + paramsInGet + "&withPhoto=" + photo;
      } else if (exportFormat === "pdf_massive") {
         finalUrl = url + "?userId="+user.toLowerCase();
      } else {
         finalUrl = url;
      }
      fetch(finalUrl, {
         //fetch(url + "?userId=" + getCurrentUser().user , {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => {
            if (!res.ok) {
               throw Error(
                  "Request rejected with status " +
                  res.status +
                  " Msg: " +
                  res.message
               );
            } else return res.blob();
         })
         .then((blob) => {
            var a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            if (exportFormat === "xls_massive" || exportFormat === "xls/photo_massive") {
               a.download = "massive-export.xlsx";
            } else
            if (modules.length === 1) {
               if (exportFormat === "xls" || exportFormat === "xls/photo") {
                  a.download = modules[0].moduleLabel + ".xlsx";
               }
               if (exportFormat === "pdf") {
                  a.download = modules[0].moduleLabel + ".pdf";
               }
            }
            if (exportFormat !== "pdf_massive") {
               a.click();
            }
            dispatch(success());
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(failure());
         });
   };

   function request(modules) {
      return {
         type: actionTypes.IS_LOADING_EXPORT_MODULE_REQUEST,
         modules,
      };
   }

   function success() {
      return {
         type: actionTypes.IS_LOADING_EXPORT_MODULE_SUCCESS,
      };
   }

   function failure() {
      return {
         type: actionTypes.IS_LOADING_EXPORT_MODULE_FAILURE,
      };
   }
};

export const togglePopupUnlock = (value, item = null, typeItem = null) => {
   return {
      type: actionTypes.TOGGLE_POPUP_UNLOCK_MODULE,
      value,
      item,
      typeItem,
   };
};

const checkifModuleAlreadyPresent = (currentModuleList, modulesToCheck) => {
   return _.unionBy(modulesToCheck, currentModuleList, "moduleName");
};

const addToModuleList = (newModuleList, familyList) => {
   let macrolist = [...familyList];

   newModuleList.map((m) => {
      let counter = 0;
      macrolist.map((macro) => {
         if (macro.macroFamily === m.macroFamily) {
            counter++;
            macro.moduleList = checkifModuleAlreadyPresent(
               macro.moduleList,
               m.moduleList
            );
         }
      });
      if (counter === 0) {
         let newMacro = {
            macroFamily: m.macroFamily,
            moduleList: m.moduleList,
         };
         macrolist.push(newMacro);
      }
   });

   return macrolist;
};

const mapModulesToMacro = (moduleList) => {
   for (let i = 0; i < moduleList.length; i++) {
      moduleList[i].length = moduleList[i].productDtoList.length;
   }
   return _(moduleList)
      .groupBy("macroFamily")
      .map((modules, macroFamily) => ({
         moduleList: modules,
         macroFamily,
      }))
      .value();
};

const changeListModulesChanged = (getState, moduleI) => {
   let modulesChangedList = getState().module.modulesChangedList,
      presentIndex = null;

   modulesChangedList &&
      modulesChangedList.map((moduleName, index) => {
         if (moduleName === moduleI.moduleName) {
            presentIndex = index;
         }
      });

   if (presentIndex === null) {
      modulesChangedList.push(moduleI.moduleName);
   }

   return modulesChangedList;
};

const clearMacroList = (getState, moduleI) => {
   let modulesChangedList = getState().module.modulesChangedList,
      presentIndex = null;

   modulesChangedList.map((moduleName, index) => {
      if (moduleName === moduleI.moduleName) {
         presentIndex = index;
      }
   });

   if (presentIndex !== null) {
      modulesChangedList.splice(presentIndex, 1);
   }

   return modulesChangedList;
};

export const addPerfoFilters = (filters) => {
   let newParams = {};
   Object.keys(filters).map((key) => {
      if (key === "monthsRolling") {
         return (newParams[key] = filters[key].value
            ? [filters[key].value]
            : [6]);
      }
      if (filters[key] !== null && filters[key].length > 0) {
         let valuesArray = filters[key].map((filter) => filter.value);

         newParams[key] = valuesArray;
      }
   });

   return newParams;
};
