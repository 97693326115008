/* eslint-disable array-callback-return */

import * as _ from "lodash";
import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";

import { 
   getArrayFromObj,
   handleErrorsJson,
} from "../../services/Api";

import axios from "axios";
import { getCookie } from "../../services/cookie";
import { lockCluster, lockClusters } from "./userActions";
import { sendHomeFilters } from "./filterActions";
import { showError } from "./errorActions";

const formatLias = (lias) => {
   return lias.map((lia) => ({
      liaCode: lia.value,
      planningChannelId: lia.planningChannelId,
   }));
};

/** CREATE NEW CLUSTER */
export const setNewCluster = (filters, filtersToSet) => {
   let cluster = {
      clusterId: filtersToSet.clusterId,
      clusterLabel: filtersToSet.clusterLabel,
      brandCode: filters.brand.value,
      collection: filters.collection.value,
      monthId: filters.months.value,
      modules: [],
      lias: formatLias(filters.lias),
      rangeFacing: null,
      status: "",
      stores: filtersToSet.stores ? filtersToSet.stores : [],
      country: [],
      starZones: filters.starZones,
   };
   return {
      type: actionTypes.SET_NEW_CLUSTER,
      cluster,
   };
};

/** GET ALT MODULES */
// export const getAltModules = () => {
// 	return (dispatch, getState) => {
// 		dispatch(getAltModulesRequest());
// 		let url = urlList.GET_MODULES_FILTERED;
// 		let { selectedFilters } = getState().filter;
// 		let params = {
// 			releaseId: [selectedFilters.release.value],
// 			brand:
// 				selectedFilters.brand !== null && selectedFilters.brand.value
// 					? [selectedFilters.brand.value]
// 					: [],
// 			planningChannel:
// 				selectedFilters.planningChannel !== null &&
// 				selectedFilters.planningChannel.value
// 					? [selectedFilters.planningChannel.value]
// 					: null,
// 			collection:
// 				selectedFilters.collection.length > 0 ? selectedFilters.collection : [],
// 			// country: getValue(filters.countryList),
// 		};

// 		fetch(url, {
// 			method: "post",
// 			headers: {
// 				"Content-Type": "application/json",
// 				xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
// 			},
// 			body: JSON.stringify(params),
// 		})
// 			.then((res) => handleErrorsJson(res, dispatch))
// 			.then((moduleList) => {
// 				// set status and type &6 check not bestseller
// 				moduleList = setStatus(moduleList);
// 				dispatch(getAltModulesSuccess(moduleList));
// 			})
// 			.catch((err) => {
// 				dispatch(showError(err));
// 			});
// 	};
// };
// const getAltModulesRequest = () => { 
// 	return {
// 		type: actionTypes.GET_ALT_MODULE_REQUEST,
// 	};
// };

// const getAltModulesSuccess = (modules) => {
// 	return {
// 		type: actionTypes.GET_ALT_MODULE_SUCCESS,
// 		modules,
// 	};
// };

/** GET CLUSTER */
export const getClusterWithLock = (clusterList, callbackSuccess, modeViewer = true) => {
   if(modeViewer){
      return (dispatch) => {
         dispatch(getClusterList(clusterList , callbackSuccess, false));
      };
   }else{      
      return (dispatch) => {
         dispatch(lockClusters(clusterList, () => dispatch(getClusterList(clusterList , callbackSuccess, false ))));
      };
   }
};

export const getCluster = (cluster, callbackSuccess) => {
   let params = {
      clusterId: cluster.clusterId,
      monthId: cluster.monthId,
   };

   return (dispatch) => {
      let url = urlList.GET_CLUSTER;
      dispatch(getClusterRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((cluster) => {
            if(callbackSuccess) {
               callbackSuccess()
            }
            dispatch(getSingleClusterSuccess(cluster));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(getClusterFailure(err));
         });
   };
};

/*
export const getClusterList = (clusterList, callbackSuccess, isJustCluster = true) => {
   
   let params = {
      clusterDto: clusterList.map((cluster) => {
         return {
            clusterId: cluster.clusterId,
            monthId: cluster.monthId,
         };
      }),
   };

   return (dispatch) => {
      let url = urlList.GET_CLUSTER;
      dispatch(getClusterRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((clusterList) => {
            if(isJustCluster) dispatch(getSingleClusterSuccess(clusterList[0]))
            else{
               dispatch(getWorkingClusterSuccess(clusterList));
            }
            if(callbackSuccess) {
               callbackSuccess()
            }
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(getClusterFailure(err));
         });
   };
};
*/

export const getClusterList = (clusterList, callbackSuccess, isJustCluster = true) => {

   const params = clusterList.map(cluster => {
      return({
         clusterId: cluster.clusterId,
         monthId: cluster.monthId
      })
   })

   return (dispatch) => {
      let url = urlList.GET_CLUSTER;
      dispatch(getClusterRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
      .then((res) => handleErrorsJson(res, dispatch))
      .then(response => {
         if(isJustCluster) dispatch(getSingleClusterSuccess(response[0]))
         else{
            dispatch(getWorkingClusterSuccess(response));
         }
         if(callbackSuccess) {
            callbackSuccess()
         }
      })
      .catch((err) => {
         dispatch(showError(err));
         dispatch(getClusterFailure(err));
      });
   }
};

const getClusterRequest = () => {
   return {
      type: actionTypes.GET_CLUSTER_REQUEST,
   };
};

const getWorkingClusterSuccess = (clusterList) => {
   return {
      type: actionTypes.GET_WORKING_CLUSTER_SUCCESS,
      clusterList,
   };
}

const getSingleClusterSuccess = (cluster) => {
   return {
      type: actionTypes.GET_SINGLE_CLUSTER_SUCCESS,
      cluster,
   };
};


const getClusterFailure = (error) => {
   return {
      type: actionTypes.GET_CLUSTER_FAILURE,
      error,
   };
};

/** SAVE CLUSTER **/

export const saveCluster = (cluster, princ, rem) => {
   let princArray = getArrayFromObj(princ);
   let firstArray = [...princArray, ...rem];
   cluster.modules = _.uniqBy(firstArray, "moduleName");

   return (dispatch) => {
      let url = urlList.SAVE_CLUSTER;
      dispatch(saveClusterStarted());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(cluster),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((cluster) => {
            dispatch(saveClusterSuccess(cluster));
         })
         .catch((err) => {
            if (err.status === 422) {
               dispatch(saveClusterFailure("clusterNotLocked"));
            } else {
               dispatch(showError(err));
               dispatch(saveClusterFailure(null));
            }
         });
   };
};

const buildClusterSaveErrorMessage = (errorsMap) => {
   let errors = [];
   Object.keys(errorsMap).forEach(errorType => {
      switch (errorType){
         case 'moduleNotAllowedInCluster':
            const clusterWithError = errorsMap[errorType].join(", ")
            errors.push(`Error in cluster ${clusterWithError}: cannot add selected module`)
            break;
         default:
            errors.push(`Error: please contact the administrator`)
            break
      }
   })

   errors = errors.join("\n\r")
   return errors
}

export const saveClusterList = (clusterList, princ, rem) => {
   // sono tutti i moduli di tutti i cluster. Devo rioridinarli

   let princArray = getArrayFromObj(princ); // tutti i moduli princ di tutti i cluster della lista

   clusterList = clusterList.map(cluster => {
      const clusterPrincModules = princArray.filter(module => module.clusterId === cluster.clusterId)
      const clusterRemovedModules = rem.filter(module => module.clusterId === cluster.clusterId)
      const clusterModules = clusterPrincModules.concat(clusterRemovedModules)
      cluster.modules = clusterModules
      return cluster
   })

   return (dispatch) => {
      let url = urlList.SAVE_CLUSTER;
      dispatch(saveClusterStarted());
      dispatch(getWorkingClusterSuccess(clusterList)) // per mantenere la lista aggiornata da un salvataggio all'altro

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(clusterList),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((clusterList) => {
            if(Object.keys(clusterList?.errorsMap).length > 0){
               const formattedError = buildClusterSaveErrorMessage(clusterList.errorsMap, clusterList)
               dispatch(showError(formattedError, true))
               dispatch(saveClusterFailure(null));
            }
            else if(clusterList.unauthorizedList?.length > 0) {
               const clusterLabels = clusterList.unauthorizedList.map(cluster => cluster.clusterLabel);
               const clusterLabelsString = clusterLabels.join(", ");
               const error = `Cannot edit cluster${clusterLabels.length > 1 ? 's' : ''} ${clusterLabelsString}`
               dispatch(showError(error, true))
            }
            else{
               dispatch(saveClusterListSuccess(clusterList));
            }
         })
         .catch((err) => {
            if (err.status === 422) {
               dispatch(saveClusterFailure("clusterNotLocked"));
            } else {
               dispatch(showError(err));
               dispatch(saveClusterFailure(null));
            }
         });      
   };
};

const saveClusterStarted = () => {
   return {
      type: actionTypes.SAVE_CLUSTER_STARTED,
   };
};


const saveClusterSuccess = (cluster) => {
   return {
      type: actionTypes.SAVE_CLUSTER_SUCCESS,
      cluster,
   };
};

const saveClusterListSuccess = (clusterList) => {
   return {
      type: actionTypes.SAVE_CLUSTER_LIST_SUCCESS,
      clusterList,
   };
};

const saveClusterFailure = (error) => {
   return {
      type: actionTypes.SAVE_CLUSTER_FAILURE,
      error,
   };
};

/** DELETE CLUSTER **/
export const deleteCluster = (cluster, filters) => {
   return (dispatch) => {
      let url = urlList.SAVE_CLUSTER;
      dispatch(deleteClusterStarted());
      dispatch(lockCluster(cluster, () => {

         axios({
            url,
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            data: JSON.stringify(cluster),
         })
            .then(() => {
               dispatch(deleteClusterSuccess());
               dispatch(sendHomeFilters(filters));
            })
            .catch((err) => {
               if (err.status === 422) {
                  dispatch(deleteClusterFailure("clusterNotLocked"));
               } else {
                  dispatch(showError(err));
                  dispatch(deleteClusterFailure(null));
               }
            });
      }))
   };
};

const deleteClusterStarted = () => {
   return {
      type: actionTypes.DELETE_CLUSTER_STARTED,
   };
};

const deleteClusterSuccess = () => {
   return {
      type: actionTypes.DELETE_CLUSTER_SUCCESS,
   };
};

const deleteClusterFailure = (error) => {
   return {
      type: actionTypes.DELETE_CLUSTER_FAILURE,
      error,
   };
};

/** PUBLISH CLUSTER **/
/*
export const publishCluster = (cluster, princ, rem) => {
   let princArray = getArrayFromObj(princ);
   let firstArray = [...princArray, ...rem];
   cluster.modules = _.uniqBy(firstArray, "moduleName");

   return (dispatch) => {
      let url = urlList.PUBLISH_CLUSTER;
      dispatch(publishClusterStarted());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(cluster),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((cluster) => {
            dispatch(publishClusterSuccess(cluster));
         })
         .catch((err) => {
            if (err.status === 422) {
               dispatch(publishClusterFailure("clusterNotLocked"));
            } else {
               dispatch(showError(err));
               dispatch(publishClusterFailure());
            }
         });
   };
};
*/

export const publishCluster = (clusterList, princ, rem) => {
   let princArray = getArrayFromObj(princ);
   let firstArray = [...princArray, ...rem];
  
   let clusterListUniqueModules = clusterList.map(cluster => {
      // modules for this cluster
      let clusterModules = firstArray.filter(module => module.clusterId === cluster.clusterId)
      cluster.modules = _.uniqBy(clusterModules, "moduleName");
      return cluster
   })

   return (dispatch) => {
      let url = urlList.PUBLISH_CLUSTER;
      dispatch(publishClusterStarted());
      dispatch(getWorkingClusterSuccess(clusterListUniqueModules)) // aggiorno la lista di moduli per ogni cluster

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(clusterListUniqueModules),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            dispatch(publishClusterListSuccess(response));
         })
         .catch((err) => {
            if (err.status === 422) {
               dispatch(publishClusterFailure("clusterNotLocked"));
            } else {
               dispatch(showError(err));
               dispatch(publishClusterFailure());
            }
         });
   }
}

const publishClusterStarted = () => {
   return {
      type: actionTypes.PUBLISH_CLUSTER_STARTED,
   };
};

/*
const publishClusterSuccess = (cluster) => {
   return {
      type: actionTypes.PUBLISH_CLUSTER_SUCCESS,
      cluster,
   };
};
*/

const publishClusterListSuccess = (response) => {
   return {
      type: actionTypes.PUBLISH_CLUSTER_LIST_SUCCESS,
      response,
   };
};

const publishClusterFailure = (error) => {
   return {
      type: actionTypes.PUBLISH_CLUSTER_FAILURE,
      error,
   };
};

/*
export const checkForPublish = (cluster, princ, rem, callback) => {
   let princArray = getArrayFromObj(princ);
   let firstArray = [...princArray, ...rem];
   cluster.modules = _.uniqBy(firstArray, "moduleName");
   cluster.country = cluster.country.filter(
      (singleCountry) => singleCountry !== null
   );

   return (dispatch) => {
      let url = urlList.CHECK_PUBLISH_CLUSTER;
      dispatch(checkPublishStarted());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(cluster),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            callback(response);
            dispatch(checkPublishSuccess());
         })
         .catch((err) => {
            if (err.status === 422) {
               dispatch(checkPublishFailure("clusterNotLocked"));
            } else {
               dispatch(showError(err));
               dispatch(checkPublishFailure());
            }
         });
   };
};
*/

export const checkForPublish = (clusterList, princ, rem, callback) => {
   let princArray = getArrayFromObj(princ);
   let firstArray = [...princArray, ...rem];
  
   let clusterListUniqueModules = clusterList.map(cluster => {
      // modules for this cluster
      let clusterModules = firstArray.filter(module => module.clusterId === cluster.clusterId)
      cluster.modules = _.uniqBy(clusterModules, "moduleName");
      // remove null country
      cluster.country = cluster.country.filter((singleCountry) => singleCountry !== null);
      return cluster
   })

   return (dispatch) => {
      let url = urlList.CHECK_PUBLISH_CLUSTER;
      dispatch(checkPublishStarted());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(clusterListUniqueModules),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            callback(response);
            dispatch(checkPublishSuccess());
         })
         .catch((err) => {
            if (err.status === 422) {
               dispatch(checkPublishFailure("clusterNotLocked"));
            } else {
               dispatch(showError(err));
               dispatch(checkPublishFailure());
            }
         });

   }
}

const checkPublishStarted = () => {
   return {
      type: actionTypes.CHECK_PUBLISH_CLUSTER_STARTED,
   };
};

const checkPublishSuccess = () => {
   return {
      type: actionTypes.CHECK_PUBLISH_CLUSTER_SUCCESS,
   };
};

const checkPublishFailure = (error) => {
   return {
      type: actionTypes.CHECK_PUBLISH_CLUSTER_FAILURE,
      error,
   };
};

export const clearClusterState = () => {
   return {
      type: actionTypes.CLEAR_CLUSTER_STATE,
   };
};

export const isNotSaved = () => {
   return {
      type: actionTypes.IS_NOT_SAVED,
   };
};

/* APPROVE */
export const getApproveTableInfo = () => {
   return (dispatch) => {
      let url = urlList.GET_LIST_SUGGESTED_CLUSTER;
      dispatch(getApproveTableListRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify({
            country: [],
         }),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((options) => {
            let flatObj = fromObjStoresToObj(options);
            let filters = getFiltersFromAssortmentList(flatObj);
            dispatch(getApproveTableListSuccess(flatObj, filters));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(getApproveTableListFailure());
         });
   };
};

const getApproveTableListRequest = () => {
   return {
      type: actionTypes.GET_APPROVE_TABLE_LIST_REQUEST,
   };
};

const getApproveTableListSuccess = (results, filters) => {
   return {
      type: actionTypes.GET_APPROVE_TABLE_LIST_SUCCESS,
      results,
      filters,
   };
};

const getApproveTableListFailure = () => {
   return {
      type: actionTypes.GET_APPROVE_TABLE_LIST_FAILURE,
   };
};

export const approveCluster = (cluster, callback) => {
   return (dispatch) => {
      fetch(urlList.ACCEPT_CLUSTER, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(cluster),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            dispatch(approveClusterSuccess(response));
            callback();
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

const approveClusterSuccess = () => {
   return {
      type: actionTypes.APPROVE_CLUSTER_SUCCESS,
   };
};

export const rejectCluster = (cluster, message, callback) => {
   let params = {
      ...cluster,
      message,
   };
   return (dispatch) => {
      fetch(urlList.REJECT_CLUSTER, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            dispatch(rejectClusterSuccess(response));
            callback();
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

const rejectClusterSuccess = () => {
   return {
      type: actionTypes.REJECT_CLUSTER_SUCCESS,
   };
};

export const getClusterName = (filters, callback) => {
   let params = {
      releaseId: filters.release.value,
      planningChannel: filters.planningChannel.value,
      collection: filters.collection,
      brand: filters.brand.value,
      country: filters.countryList[0].value,
   };

   return (dispatch) => {
      fetch(urlList.GET_CLUSTER_NAME, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            dispatch(getClusterNameSuccess(response));
            callback(response);
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

const getClusterNameSuccess = () => {
   return {
      type: actionTypes.GET_CLUSTER_NAME_SUCCESS,
   };
};

export const resetState = () => {
   return {
      type: actionTypes.RESET_STATE,
   };
};


export const getTemplate = (type) => {
   return (dispatch) => {
      dispatch(getTemplateByType(type));
   }
};


export const getTemplateByType = (type) => {
   const url = urlList.GET_TEMPLATE_EXPORT + "?templatesType=" + type;
   return (dispatch) => {
   axios(url, {
      method: "GET",
      headers: {
         "Content-Type": "application/json",
         xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
      },
   })
      .then((response) => {
         // if (response.ok) {
         //    return response.json();
         // }
        
         
      const options = response.data.map((template) => ({
         value: template.templateId,
         label: template.templateLabel,
       }));
         dispatch(request(options));
      })
   }
};

const request = (templates) => {
   return {
      type: actionTypes.GET_TEMPLATE,
      templates,
   };
}

export const exportCluster = (clusters, selectedFilters, status, exportFormat, template, photo,user) => {
   const starsZone = selectedFilters.country && selectedFilters.country.map(filter => filter.value)
   const starsCustomer = selectedFilters.starsCustomer && selectedFilters.starsCustomer.map(filter => filter.value)
   const storeId = selectedFilters.storeJda && selectedFilters.storeJda.map(filter => filter.value)
   const sapNumbers = selectedFilters.codiceStoreSap && selectedFilters.codiceStoreSap.map(filter => filter.value)
   const segmentations = selectedFilters.customerSegmentation && selectedFilters.customerSegmentation.map(filter => filter.value)
   const planningChannel = selectedFilters.planningChannel.map(filter => filter.value)

   let params = clusters.map((m) => {
      return {
         clusterId: m.clusterId,
         monthId: m.monthId,
         starZones: starsZone,
         starsCustomer: starsCustomer,
         storeId: storeId,
         codiceStoreSap: sapNumbers,
         customerSegmentation: segmentations,
         planningChannel: planningChannel,

      };
   });

   let clustersToSave = clusters.map((m) => {
      return m.assortmentId + m.country;
   });

   return (dispatch) => {
      let url;
      switch (exportFormat) {
         case "xls":
            url = urlList.GET_CLUSTER_EXPORT_XLS;
            break;
         case "xls/photo":
            url = urlList.GET_CLUSTER_EXPORT_XLS_PHOTO;
            break;
         case "pdf":
            url = urlList.GET_CLUSTER_EXPORT_PDF;
            break;
         case "xls_massive":
            url = urlList.GET_CLUSTER_EXPORT_XLS;
            break;
         case "xls/photo_massive":
            url = urlList.GET_CLUSTER_EXPORT_XLS_PHOTO;
            break;
         case "pdf_massive":
            url = urlList.GET_CLUSTER_MASSIVE_EXPORT_PDF;
            break;
         default:
      }
      let paramsInGet = status.map((st) => st.toUpperCase()).join();

      dispatch(request(clustersToSave));
      let finalUrl;
      if (exportFormat === "xls" || exportFormat === "xls/photo" || exportFormat === "xls_massive" || exportFormat === "xls/photo_massive") {
         finalUrl = url + "?templateId=" + template + "&status=" + paramsInGet + "&withPhoto=" + photo;
      } else if (exportFormat === "pdf_massive") {
         finalUrl = url + "?userId="+user.toLowerCase();
      } else {
         finalUrl = url;
      }
      fetch(finalUrl, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => {
            if (!res.ok) {
               throw Error(
                  "Request rejected with status " +
                  res.status +
                  " Msg: " +
                  res.message
               );
            } else return res.blob();
         })
         .then((blob) => {
            var a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            if (exportFormat === "xls_massive" || exportFormat === "xls/photo_massive") {
               a.download = "massive-export.xlsx";
            } else
            if (clusters.length === 1) {
               if (exportFormat === "xls" || exportFormat === "xls/photo") {
                  a.download = clusters[0].clusterLabel + ".xlsx";
               }
               if (exportFormat === "pdf") {
                  a.download = clusters[0].clusterLabel + ".pdf";
               }
            }
            if (exportFormat !== "pdf_massive") {
               a.click();
            }
            dispatch(success());
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(failure());
         });
   };

   function request(clusters) {
      return {
         type: actionTypes.IS_LOADING_EXPORT_CLUSTER_REQUEST,
         clusters,
      };
   }

   function success() {
      return {
         type: actionTypes.IS_LOADING_EXPORT_CLUSTER_SUCCESS,
      };
   }

   function failure() {
      return {
         type: actionTypes.IS_LOADING_EXPORT_CLUSTER_FAILURE,
      };
   }
};

// const setStatus = (moduleList) => {
//   let filtered = moduleList.filter((m) => {
//     return !isBestModule(m.macroFamily);
//   });

//   filtered.map((m) => {
//     m.boStatus = "ADDED";
//     m.boType = "ALTERNATIVE";
//     m.fostatus = "ADDED";
//     m.foType = "ALTERNATIVE";
//   });

//   return filtered;
// };

const fromObjStoresToObj = (lineList) => {
   return lineList.map((line) => {
      if (line.stores.length > 0) {
         let starsCustomer = [],
            storeId = [],
            customerSegmentation = [];

         line.stores.map((store) => {
            if (store.starsCustomer) starsCustomer.push(store.starsCustomer);
            if (store.storeId) storeId.push(store.storeId);
            if (store.customerSegmentation)
               customerSegmentation.push(store.customerSegmentation);
         });

         return {
            ...line,
            starsCustomer,
            storeId,
            customerSegmentation,
         };
      } else {
         return {
            ...line,
            starsCustomer: [],
            storeId: [],
            customerSegmentation: [],
         };
      }
   });
};

const getFiltersFromAssortmentList = (assortmentList) => {
   let filterObj = {
      country: [],
      userSuggest: [],
      dateSuggest: [],
      originalAssortment: [],
      assortmentId: [],
      period: [],
      starsCustomer: [],
      customerSegmentation: [],
      storeId: [],
      status: [],
   };

   let filterArray = [];

   assortmentList.map((ass) => {
      Object.keys(filterObj).map((key) => {
         let value = ass[key];
         if (value && value.constructor === Array) {
            return filterObj[key].push(...value);
         }
         filterObj[key].push(value);
      });
   });

   Object.keys(filterObj).map((key) => {
      let filter = filterObj[key];
      let uniq = _.uniq(filter);

      filterArray.push({
         filterName: key,
         options: uniq,
      });
   });

   return filterArray;
};
