import { useMemo } from "react";

import { UploadsTableRow } from "../UploadsTableRow";
import "./UploadsTable.scss";

const UploadsTable = ({ uploads, onShowResponseClick }) => {
  const UploadsTableRows = useMemo(() => {
    if (!Array.isArray(uploads) || !uploads.length) {
      return (
        <tr>
          <td colSpan="100%">No Data Available</td>
        </tr>
      );
    }

    return uploads.map((upload) => (
      <UploadsTableRow
        key={upload.id.toString()}
        {...upload}
        onShowResponseClick={onShowResponseClick}
      />
    ));
  }, [uploads, onShowResponseClick]);

  return (
    <table className="upload-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>User name</th>
          <th>Import</th>
          <th>Status</th>
          <th>Progress</th>
          <th>Response</th>
        </tr>
      </thead>
      <tbody>{UploadsTableRows}</tbody>
    </table>
  );
};

export default UploadsTable;
