/* eslint-disable array-callback-return */

import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";

import { formatFilters, handleErrorsJson } from "../../services/Api";

import { getCookie } from "../../services/cookie";
import { showError } from "./errorActions";

export const getStatFilters = (filters) => {
   const collection = (filters.collection||[]).map((filter) => filter.value);
   const brand = filters.brand.map((selBrand) => selBrand.value);

   const params = {
      monthId: filters.months.value,
      brand,
      collection,
   };

   return (dispatch) => {
      const url = urlList.GET_STATS_FILTERS_BY_BRAND;
      fetch(url, {
         method: "POST",
         headers: {
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            "Content-Type": "application/json",
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((filters) => {
            dispatch(getFiltersSuccess(filters));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getFiltersSuccess = (filters) => {
   return {
      type: actionTypes.SET_FILTERS_STAT,
      filters,
   };
};

/* GET ASSORTMENT BY STATISTICS */
export const getStats = (selectedFilters, statsFilters) => {
   let arrayFilters = [];

   Object.keys(statsFilters).map((k) => {
      if (statsFilters[k] !== null) {
         let singleFilter = statsFilters[k];

         Object.keys(singleFilter).map((f) => {
            let searchValue, minRange, maxRange;

            searchValue = f;
            minRange = singleFilter[f][0];
            maxRange = singleFilter[f][1];

            let obj = {
               field: k,
               searchValue,
               minRange,
               maxRange,
            };

            arrayFilters.push(obj);
         });
      }
   });

   let params = formatFilters(selectedFilters);
   let planningChannel = params.planningChannel ? params.planningChannel : [];

   params.planningChannel = planningChannel;
   params.filters = arrayFilters;

   return (dispatch) => {
      dispatch(getClusterByStatsStart());

      let url = urlList.GET_CLUSTER_BY_STATS;
      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            dispatch(setStatsSuccess(json));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};
export const getClusterByStatsStart = () => {
   return {
      type: actionTypes.GET_CLUSTER_BY_STATS_START,
   };
};

export const setStatsSuccess = (results) => {
   return {
      type: actionTypes.SET_STAT,
      results,
   };
};

/* RESET RESULTS */
export const resetSearchResults = () => {
   return {
      type: actionTypes.RESET_SEARCH_RESULTS_STATS,
   };
};

/** CLUSTER ANALYSIS ---- MODULE COMP */
export const getPercData = (macroList, filters) => {

   let params = getModulesInfo(macroList);

   return async(dispatch) => {
      dispatch(getPercDataRequest());
      let url = urlList.GET_PERC_OF_REFRESH;
      await fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((clusterAnaList) => {
            dispatch(getPercDataSuccess(clusterAnaList,clusterAnaList.length));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(getPercDataFailure());
         });
   };
};

export const getPercDataRequest = () => {
   return {
      type: actionTypes.GET_PERC_DATA_REQUEST,
   };
};

const getPercDataSuccess = (percData, totAssortmentData) => {
   return {
      type: actionTypes.GET_PERC_DATA_SUCCESS,
      percData,
      totAssortmentData,
   };
};

const getPercDataFailure = () => {
   return {
      type: actionTypes.GET_PERC_DATA_FAILURE,
   };
};

const getModulesInfo = (macroList) => {
   let moduleArray = [];
   if(macroList.length===0){
      return;
   }
   for (let i = 0; i < macroList.length; i++) {
      moduleArray.push(...macroList[i].moduleList);
   }
   return moduleArray;
};

/* PERC OF REFRESH --- CLUSTER */
export const getPercOfRefreshCluster = (param) => {
   return (dispatch) => {
      let url = urlList.GET_PERC_OF_REFRESH_SINGLE_CLUSTER;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(param),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((percRefresh) => {
            dispatch(getPercOfRefreshClusterSuccess(percRefresh));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getPercOfRefreshClusterList = (params) => {

   return (dispatch) => {
      let url = urlList.GET_PERC_OF_REFRESH_SINGLE_CLUSTER;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((percRefresh) => {
            dispatch(getPercOfRefreshClusterListSuccess(percRefresh));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

const getPercOfRefreshClusterSuccess = (percRefresh) => {
   return {
      type: actionTypes.GET_PERC_OF_REFRESH_CLUSTER_SUCCESS,
      percRefresh,
   };
};

const getPercOfRefreshClusterListSuccess = (percRefreshList) => {
   return {
      type: actionTypes.GET_PERC_OF_REFRESH_CLUSTER_LIST_SUCCESS,
      percRefreshList,
   };
};

/* RANGE FACING */
export const getRangeFacing = (param) => {

   return (dispatch) => {
      let url = urlList.GET_RANGE_FACING_CLUSTER;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(param),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((range) => {
            dispatch(getRangeFacingSuccess(range.rangeFacing));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getRangeFacingClusters = (clusterList) => {

   const params = clusterList.map(cluster => {
      return({
         clusterId: cluster.clusterId,
         monthId: cluster.monthId
      })
   })

   return (dispatch) => {
      let url = urlList.GET_RANGE_FACING_CLUSTER;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            dispatch(getRangeFacingClustersSuccess(response));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

const getRangeFacingSuccess = (rangeFacing) => {
   return {
      type: actionTypes.GET_RANGE_FACING_SUCCESS,
      rangeFacing,
   };
};

const getRangeFacingClustersSuccess = (rangeFacing) => {
   return {
      type: actionTypes.GET_RANGE_FACING_CLUSTERS_SUCCESS,
      rangeFacing,
   };
};
