import React from 'react';

const customInput = (props) => {

    const handleFocus = () => {
        //OnFocusevent
    };
    const handleBlur = () => {
        //OnBlurEvent
    }

    return (
        <input
            type={props.type}
            className={"search inputStyle " + props.inputClass}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={props.value}
            maxLength={props.maxLength}
            min={props.min ? props.min : null}
        />
    )
}
export default customInput;