import React, { useEffect, useState } from "react";
import Button from "../UI-components/Button";
import Popup from "../UI-components/Popup";
import * as _ from "lodash";
import axios from "axios";
import { getCookie } from "../../services/cookie";
import Loader from "../UI-components/Loader";
import {MODULE_COPY_COMPOSITION} from "../../config";

export const PopupCopyAll = (props) => {
   const [monthList, setMonthList] = useState([]);
   const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);
   const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
   const [isSuccess, setIsSuccess] = useState(false);
   const [statusError, setStatusError] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [isError, setIsError] = useState(false);
   const [messageError, setMessageError] = useState([]);

   const postCopy = (objToSend) => {
      axios({
         url: MODULE_COPY_COMPOSITION,
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         data: objToSend,
      })
         .then((response) => {
            if (
               response.status === 200 &&
               response.data.errors &&
               response.data.errors.length > 0
            ) {
               setIsLoading(false);
               setMessageError(_.uniq(response.data.errors));
               return setStatusError(true);
            }
            setIsLoading(false);
            return setIsSuccess(true);
         })
         .catch((error) => {
            console.error(error);
            setIsError(true);
         });
   };

   const handleSave = () => {
      setIsLoading(true);
      setIsSavePopupOpen(false);
      postCopy(mapMonthToSend(monthList, false));
   };

   const handlePublish = () => {
      setIsLoading(true);
      setIsPublishPopupOpen(false);
      postCopy(mapMonthToSend(monthList, true));
   };

   const selectedModulesFromPrevPage = props.data.flatMap((el) => {
      return el.moduleList;
   });

   useEffect(() => {
      const openMonthListFinal = selectedModulesFromPrevPage && selectedModulesFromPrevPage.map((el) => {
         return el.openMonthList.map((month) => {
            return month;
         });
      });

      let monthGroup = _.uniqBy(
         [].concat.apply([], openMonthListFinal).map((el) => {
            return { possMonth: el };
         }),
         "possMonth"
      );

      const objDateLabel = monthGroup.map((el) => {
         const yearAndMonth = el.possMonth.split("M")[1].split("_");
         const month = yearAndMonth[0];
         const year = yearAndMonth[1];
         const date = new Date(`${year}-${month}`);

         return {
            date: _.orderBy(date, ["desc"]),
            possMonth: el.possMonth,
         };
      });

      if (props.type === "module") {
         setMonthList(objDateLabel);
      } else {
         setMonthList();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChange = (e) => {
      const { name, checked } = e.target;
      let tempMonthList = [];
      if (monthList) {
         if (name === "allSelect") {
            tempMonthList = monthList.map((month) => {
               return { ...month, isChecked: checked };
            });
         } else {
            tempMonthList = monthList.map((month) => {
               return {
                  ...month,
                  isChecked:
                     month.isChecked && month.possMonth === name
                        ? false
                        : !month.isChecked && month.possMonth === name
                           ? true
                           : month.isChecked
                              ? month.isChecked
                              : false,
               };
            });
         }
      }
      setMonthList(tempMonthList);
   };

   const mapMonthToSend = (monthList, toPublish) => {
      const newDestinationMonthList =
         monthList &&
         monthList
            .filter((monthChecked) => {
               return monthChecked.isChecked === true;
            })
            .map((el) => {
               return el.possMonth;
            });

      let multiObj = selectedModulesFromPrevPage.map((el) => {
         return {
            destinationMonthList: newDestinationMonthList
               ? newDestinationMonthList
               : [],
            moduleName: el && el.moduleName,
            monthId: el && el.monthId,
         };
      });

      let objToSend = {
         modules: multiObj,
         toPublish: toPublish,
      };

      return objToSend;
   };

   let selectMonth = monthList
      .filter((monthChecked) => {
         return monthChecked.isChecked === true;
      })
      .map((el) => {
         return el.possMonth;
      })
      .join(", ");

   const disableCta =
      props.auth &&
      props.auth.grants.some((grant) => {
         if (
            (/admin/i.test(grant.role) || /CREATOR/i.test(grant.role))
             && grant.types.includes("edit")
         ) {
            return true;
         } else {
            return false;
         }
      });

   const disableEditorCta =
      props.auth &&
      props.auth.grants.some((grant) => {
         if ((/admin/i.test(grant.role) || /CREATOR/i.test(grant.role))
            && grant.types.includes("submit")) {
            return true;
         } else {
            return false;
         }
      });

   return (
      <>
         <div className="popup-background" onClick={props.close} />
         <div
            className={
               "popup-container " + (props.popupClass ? props.popupClass : "")
            }
         >
            {isLoading ? (
               <Loader />
            ) : (
               <div className="copy-content-container">
                  <div className="copy-title-container">
                     <span className="copy-title">{"Copy all module"}</span>
                  </div>
                  {monthList && monthList?.length ? (
                     <>
                        <div className="copy-subtitle-container">
                           <span className="copy-subtitle">
                              Select one or more months
                           </span>
                        </div>
                        <div
                           className="form-check-container"
                           style={{ overflowY: "auto" }}
                        >
                           <div className="form-check-wrapper">
                              <input
                                 type="checkbox"
                                 className="form-check-input"
                                 id="copy-all-checkbox"
                                 name="allSelect"
                                 checked={
                                    monthList?.length &&
                                    monthList.filter(
                                       (month) => month?.isChecked !== true
                                    ).length < 1
                                 }
                                 onChange={handleChange}
                              />
                              <label className="form-check-label ms-2 copy-check-label">
                                 Select all
                              </label>
                           </div>
                           {monthList?.length &&
                              monthList.map((month, index) => (
                                 <div
                                    className="copy-check-container"
                                    key={index}
                                 >
                                    <input
                                       type="checkbox"
                                       className="copy-check-input"
                                       id="copy-all-checkbox"
                                       name={month.possMonth}
                                       checked={month?.isChecked || false}
                                       onChange={handleChange}
                                    />
                                    <label className="copy-check-label">
                                       {month.possMonth}
                                    </label>
                                 </div>
                              ))}
                        </div>
                     </>
                  ) : (
                     <div>There are no available months</div>
                  )}
                  <div className="copy-btn-container">
                     <Button
                        btnClass="btn-lia btn-medium"
                        handleClick={() => props.handleClick()}
                        disabled={props.disabled}
                     >
                        {props.btnName[0]}
                     </Button>
                     {monthList && monthList?.length ? (
                        <>
                           <Button
                              btnClass="btn-lia btn-medium"
                              handleClick={() => setIsSavePopupOpen(true)}
                              disabled={
                                 disableCta && selectMonth ? false : true
                              }
                           >
                              {props.btnName[1]}
                           </Button>
                           <Button
                              btnClass="btn-lia btn-medium"
                              handleClick={() => setIsPublishPopupOpen(true)}
                              disabled={
                                 disableEditorCta && selectMonth ? false : true
                              }
                           >
                              {props.btnName[2]}
                           </Button>
                        </>
                     ) : null}
                  </div>
               </div>
            )}
         </div>
         {isSavePopupOpen && (
            <Popup
               popupClass="popup-save-copy"
               close={() => setIsSavePopupOpen(false)}
               handleClick={() => {
                  handleSave();
               }}
               handleClickSecondary={() => {
                  setIsSavePopupOpen(false);
               }}
               isDoubleButton={true}
               btnName={"Confirm"}
               btnName2={"Cancel"}
            >
               {"Are you sure you want to save the module for: "}
               {selectMonth}
            </Popup>
         )}
         {isPublishPopupOpen && (
            <Popup
               popupClass="popup-publish-copy"
               close={() => setIsPublishPopupOpen(false)}
               handleClick={() => {
                  handlePublish();
               }}
               handleClickSecondary={() => {
                  setIsPublishPopupOpen(false);
               }}
               isDoubleButton={true}
               btnName={"Confirm"}
               btnName2={"Cancel"}
            >
               {"Are you sure you want to publish the module for"}
               {selectMonth}
            </Popup>
         )}
         {isSuccess && (
            <Popup
               popupClass={"popup-small"}
               close={() => {
                  setIsSuccess(false);
                  props.close();
               }}
            >
               {props.type === "module" && (
                  <div>
                     {"Copied the modules "}
                     {props.data.flatMap((el) => {
                        return el.moduleName;
                     })}
                     {" for the months: "}
                     {selectMonth};
                  </div>
               )}
            </Popup>
         )}
         {statusError && (
            <Popup
               popupClass={"popup-small"}
               close={() => {
                  setIsSuccess(false);
                  props.close();
               }}
            >
               {messageError &&
                  messageError.length &&
                  messageError.map((el) => {
                     return (
                        <div>
                           <div>{el}</div>
                        </div>
                     );
                  })}
            </Popup>
         )}
         {isError && (
            <Popup
               popupClass={"popup-small"}
               close={() => {
                  setIsError(false);
                  props.close();
               }}
            >
               {props.type === "module" && (
                  <div>
                     {"Unable to copy the module "}
                     {props.data &&
                        props.data.flatMap((el) => {
                           return el.moduleName;
                        })}
                     {" for the months: "}
                     {selectMonth};
                  </div>
               )}
            </Popup>
         )}
      </>
   );
};

export default PopupCopyAll;
