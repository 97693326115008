import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";

import { getCookie, setCookie } from "../../services/cookie";

import axios from "axios";
import { handleErrorsLogin } from "../../services/Api";

export const getAuthorizationGrant = () => {
   const url = urlList.GET_AUTHORIZATION_GRANT;

   return (dispatch) => {
      axios({
         url,
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
      })
         .then((res) => {
            dispatch(authorizationSuccess(res.data));
         })
         .catch((err) => {
            dispatch(authorizationFailure(err));
         });
   };
};

const authorizationSuccess = (grants) => {
   return {
      type: actionTypes.GET_AUTHORIZATION_SUCCESS,
      grants,
   };
};

const authorizationFailure = (err) => {
   return {
      type: actionTypes.GET_AUTHORIZATION_FAILURE,
      err,
   };
};

export const resetErrStatus = () => {
   return {
      type: actionTypes.RESET_ERROR_STATUS,
   };
};

export const getAuthentication = (username, password) => {
   return async (dispatch) => {
      dispatch(getAuthenticationStart());

      var requestOptions = {
         method: "POST",
         mode: "cors",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            username,
            password,
         }),
      };

      try {
         const oauth = await fetch(urlList.OAUTH_AUTHENTICATION, requestOptions)
            .then(handleErrorsLogin)
            .then((res) => res.json())
            .then((res) => {
               return res;
            });

         const grants = await axios({
            url: urlList.GET_AUTHORIZATION_GRANT,
            method: "GET",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: oauth.access_token,
            },
         });

         if (!grants.data.obj.roles.length) {
            const grantError = { code: 401, message: "User has no grants" };
            throw grantError;
         }

         if (oauth && oauth.access_token) {
            authenticate(oauth.access_token, dispatch);
         }
      } catch (e) {
         dispatch(getAuthenticationFail(e));
      }
   };
};

const authenticate = (completeToken, dispatch) => {
   let token = completeToken.split(".");
   let decoded = window.atob(token[1]);
   let json = JSON.parse(decoded);
   let user = {
      ...json,
      token: completeToken,
      roles: json.authorities,
      expirationDate: json.exp,
   };

   if (user === undefined) {
      dispatch(getAuthenticationFail());
   } else if (
      user.roles !== undefined ||
      user.roles !== null ||
      !user.roles.length
   ) {
      setCookie("xAuthLuxotticaToken", user.token, user.expirationDate);
      dispatch(getAuthenticationSuccess(user));

      dispatch(
         checkTokenTimeout(
            new Date(user.expirationDate * 1000).getTime() -
               new Date().getTime()
         )
      );
   }
};

const getAuthenticationStart = () => {
   return { type: actionTypes.LOGIN_START };
};
const getAuthenticationSuccess = (user) => {
   return { type: actionTypes.LOGIN_SUCCESS, user };
};
const getAuthenticationFail = (error) => {
   return { type: actionTypes.LOGIN_FAILURE, error };
};

export const logout = () => {
   localStorage.removeItem("state");
   setCookie("xAuthLuxotticaToken", "", new Date());
   return {
      type: actionTypes.LOGOUT,
   };
};

export const checkAuthenticationState = () => {
   return (dispatch, getState) => {
      let token = getCookie("xAuthLuxotticaToken");
      let { user } = getState().authentication;
      if (!user && token != null && token !== "") {
         authenticate(token, dispatch);
         user = getState().authentication.user;
      }
      if (user) {
         const expirationDate = new Date(user.expirationDate * 1000);
         if (expirationDate <= new Date()) {
            dispatch(logout());
         } else {
            dispatch(getAuthenticationSuccess(user));
            dispatch(
               checkTokenTimeout(
                  expirationDate.getTime() - new Date().getTime()
               )
            );
         }
      }
   };
};

export const checkTokenTimeout = (expirationTime) => {
   return (dispatch) => {
      setTimeout(() => {
         dispatch(logout());
      }, expirationTime);
   };
};

export const resetError = () => {
   return {
      type: actionTypes.RESET_ERROR_LOGIN,
   };
};

export const getInfoUserFromToken = () => {
   let token = getCookie("xAuthLuxotticaToken");
   return (dispatch) => {
      authenticate(token, dispatch);
   };
};

export const setGACheck = (val) => {
   return {
      type: actionTypes.SET_GA_CHECK,
      val,
   };
};
