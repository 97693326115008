import React, { Component } from 'react';
 
export const RadioBtn = (props) => (
    <div className={"radio-btn-group "+ (props.disabled ? 'disabled': '')} onClick={() => props.handleClick(props.index)}>
        <div className={"radiobtn" + (props.isChecked ? " checked " : " unchecked ") + (props.className ? props.className : '')} data-value={props.value}>
            <label className={"radiobtn" + (props.isChecked ? " checked" : " unchecked")}>
                {props.text}
                {props.isChecked && 
                    <span className="checkmark_tick">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                    </span>}
            </label>
        </div>
    </div>
)

class RadioGrp extends Component {

    state = {
          selectedIndex: null,
          selectedValue: null,
          options: null,
        };

    componentDidMount = () => {
        if(this.props.options) {
            this.setState({
                options: [...this.props.options],
                selectedIndex: this.props.selectedIndex
            })
        }       
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.options !== this.props.options)
            this.setState({
                options: [...this.props.options],
                selectedIndex: this.props.selectedIndex
            })
    }   
    

    toggleRadioBtn = (index) => {
        let selectedOption = this.state.options[index];
        this.props.handleClick(selectedOption, index);
        this.setState({
            selectedIndex: index,
            selectedValue: selectedOption
        });
        
    }

    render() {
        const { options } = this.state;
        // eslint-disable-next-line array-callback-return
        const allOptions = options ? options.map((option, i) => {
            if(option!==null){
                return <RadioBtn className={this.props.className} key={i} isChecked={(this.props.selectedIndex === i)} text={option} value={option} disabled={this.props.disabled} index={i} handleClick={this.toggleRadioBtn} />
            }
        }) : '';

        return (
            <div className={"radio-btn-container" + (this.props.active ? ' active': '') + (this.props.containerClass ? ' '+this.props.containerClass: '')}>{allOptions}</div>
        );
    }
}

export default RadioGrp;
