/* eslint-disable array-callback-return */

import * as actions from "../../../store/actions";

import React, { Component } from "react";

import Button from "../../UI-components/Button";
import CheckBoxLia from "./CheckboxLia";
import Popup from "../../UI-components/Popup";
import { connect } from "react-redux";

class PopupLia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      list: [],
    };
  }

  setList = (list) => {
    this.setState({ list });
  };

  componentDidMount() {
    this.props.getAllLiaList();
  }

  componentDidUpdate() {
    if (this.props.allLia.length && !this.state.list.length) {
      const checkedLiasCode = this.props.clusterLias.map(lia => lia.liaCode)
      const inilitialList = this.props.allLia.map(lia => {
        if(checkedLiasCode.includes(lia.liaCode)){
          return({
            ...lia,
            isChecked: true
          })
        }
        return lia
      })
      this.setState({ list: inilitialList });
    }
  }

  togglePopupLia = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  closePopupLia = () => {
    this.togglePopupLia();
    this.setState({
      list: [],
    });
  };

  confirmPopupLia = () => {
    this.togglePopupLia();
    this.props.setClusterLias(
      this.state.list.filter((lia) => lia.isChecked === true),
      this.props.clusterId
    );
    this.props.setLiaListEdited(true);
  };

  render() {
    let isDisabled = this.state.list.filter((lia) => {
      return lia.isChecked && lia.isChecked === true;
    }).length;

    return (
      <div className="wrapper-lia">
        <Button
          btnClass="btn-lia btn-medium"
          icon="icon-arrow2"
          iconAfter
          handleClick={this.togglePopupLia.bind(this)}
          disabled={this.props.allLia && !this.props.allLia.length}
        >
          {this.props.clusterLias && this.props.clusterLias.length + " LIA"}
        </Button>
        {this.state.showPopup ? (
          <Popup
            title="Choose Lia"
            close={this.closePopupLia}
            popupClass="popup-medium"
            isDoubleButton
            btnName2="cancel"
            btnName="Confirm"
            disabled={!isDisabled}
            handleClick={this.confirmPopupLia}
          >
            <CheckBoxLia setList={this.setList} popupState={this.state} clusterLias={this.props.clusterLias}/>
            {!isDisabled && (
              <div>
                <span className="error-lia">
                  At least one lia must be selected
                </span>
              </div>
            )}
          </Popup>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allLia: state.cluster.lia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLiaList: () => dispatch(actions.getAllLiaList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupLia);
