/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ListItem from "./ListItem";
import ListItemHome from "./ListItemHome";
import CustomSearch from "../../UI-components/CustomSearch";
import CustomSelect from "../../UI-components/CustomSelect";
import Button from "../../UI-components/Button";
import Popup from "../../UI-components/Popup";
import AddModulePopup from "../Popup/AddModulePopup";
import * as actions from "../../../store/actions";

import {
   handleSearchResults,
   intersectArray,
   canCreate,
   canDelete,
} from "../../../services/Api";
import LinkedButton from "../../UI-components/LinkedButton";

class ListModule extends Component {
   state = {
      isAddModuleOpen: false,
      deletePopupOpen: false,
      selectedModules: [],
      listItem: [],
      valueSearch: "",
      publishDate: null,
      options: {
         clusterLabel: [],
         starsCustomer: [],
         starZones: [],
         macroFamily: [],
         newModule: [],
         status: [],
      },
      valuesFilters: {
         clusterLabel: null,
         starsCustomer: null,
         starZones: null,
         macroFamily: null,
         newModule: null,
         status: null,
         moduleLabel: null,
      },
      filteredLists: {
         clusterLabel: [],
         starsCustomer: [],
         starZones: [],
         macroFamily: [],
         newModule: [],
         status: [],
         moduleLabel: null,
      },
      isModuleClicked: false,
   };

   componentDidMount = () => {
      if (this.props.listItem)
         this.setState({ listItem: [...this.props.listItem] });

      this.props.getModuleFilters(this.props.selectedFilters);
   };

   componentDidUpdate = (prevProps) => {
      if (prevProps.listItem !== this.props.listItem)
         this.setState({ listItem: [...this.props.listItem] });

      if (prevProps.options !== this.props.options) {
         this.setState({
            options: _.cloneDeep(this.props.options),
         });
      }

      if (
         prevProps.isLockModuleLoading !== this.props.isLockModuleLoading &&
         !this.props.isLockModuleLoading &&
         this.state.isModuleClicked
      ) {
         this.props.history.push("/module");
         this.setState({
            isModuleClicked: false,
         });
      }
   };

   selectModules = (singleItem) => {
      let selectedModules = [...this.state.selectedModules],
         counter = 0;

      if (selectedModules.length > 0) {
         //togli elemento se c'è già
         selectedModules = selectedModules.filter((moduleItem) => {
            if (moduleItem.moduleName === singleItem.moduleName) {
               counter++;
               return false;
            } else return true;
         });

         if (counter === 0) {
            selectedModules.push(singleItem);
         }
      } else {
         selectedModules.push(singleItem);
      }

      this.setState({ selectedModules }, () => this.notifyMassiveEditParams());
   };

   selectAll = () => {
      this.setState(
         {
            selectedModules: this.intersectList() || this.state.listItem,
         },
         () => this.notifyMassiveEditParams()
      );
   };

   deselectAll = () =>
      this.setState({ selectedModules: [] }, () =>
         this.notifyMassiveEditParams()
      );

   checkIfSelected = (singleItem) => {
      return this.state.selectedModules.filter((moduleItem) => {
         if (moduleItem.moduleName === singleItem.moduleName) {
            return true;
         }
      });
   };

   //MF added
   notifyMassiveEditParams = () => {
      this.props.massiveEditParams(this.state.selectedModules);
   };

   printListItem = (listItem) => {
      const notDeletedItems = listItem.filter(
         (module) => module.status !== "DELETED"
      );
      return (
         notDeletedItems &&
         notDeletedItems.map((singleItem) => {
            let checkArray = this.checkIfSelected(singleItem);
            return (
               <ListItemHome
                  iconClassTrash="icon-cestino"
                  item={singleItem}
                  type="module"
                  key={
                     singleItem.moduleName +
                     singleItem.clusterLabel +
                     singleItem.monthId
                  }
                  listItemClass={checkArray.length > 0 ? "selected" : ""}
                  handleClick={() => this.selectModules(singleItem)}
                  iconClass="icon-arrow2"
                  iconClassWarning="icon-triangle-black"
                  deleteItem={this.handleDeleteModules}
               >
                  {singleItem.moduleLabel}
               </ListItemHome>
            );
         })
      );
   };

   goToModuleComp = () => {
      this.props.getWorkingModuleWithLock(this.state.selectedModules);
      this.setState({
         isModuleClicked: true,
      });
   };

   togglePopup = () =>
      this.setState((prevState) => {
         return {
            isAddModuleOpen: !prevState.isAddModuleOpen,
         };
      });

   handleSearch = (e) => {
      let valueObj = handleSearchResults(e, this.props.listItem, "module");

      this.setState({
         listItem: valueObj.listItem,
         valueSearch: valueObj.valueSearch,
      });
   };

   onChangeDatePicker = (publishDate) => {
      let listObj;

      if (publishDate === null) {
         let valueSearch = this.state.valueSearch;
         let { listItem } = handleSearchResults(
            valueSearch,
            this.props.listItem,
            "module"
         );
         //check date
         listObj = handleSearchResults(publishDate, listItem, "dateRange");
      } else {
         listObj = handleSearchResults(
            publishDate,
            this.state.listItem,
            "dateRange"
         );
      }

      this.setState({
         listItem: listObj.listItem,
         publishDate,
      });
   };

   handleChangeOptions = (valueArray, type) => {
      let listItems = this.state.listItem;

      switch (type) {
         case "clusterLabel":
            let clusterOptions = valueArray.map((data) => {
               return data.label;
            });
            let filterCluster = listItems.filter((listItem) =>
               listItem.clusters.some((cluster) =>
                  clusterOptions.includes(cluster.clusterLabel)
               )
            );
            this.setState({
               filteredLists: {
                  ...this.state.filteredLists,
                  clusterLabel: filterCluster,
               },
               valuesFilters: {
                  ...this.state.valuesFilters,
                  clusterLabel: valueArray,
               },
            });
            break;

         case "starsCustomer":
            let starsOptions = valueArray.map((data) => {
               return data.label;
            });

            let filterStars = listItems.filter((listItem) =>
               listItem.clusters.some((cluster) =>
                  cluster.starsCustomer.some((customer) =>
                     starsOptions.includes(customer)
                  )
               )
            );

            this.setState({
               filteredLists: {
                  ...this.state.filteredLists,
                  starsCustomer: filterStars,
               },
               valuesFilters: {
                  ...this.state.valuesFilters,
                  starsCustomer: valueArray,
               },
            });
            break;

         case "macroFamily":
            let familyOptions = valueArray.map((data) => {
               return data.label;
            });

            let filterfamily = listItems.filter((listItem) => {
               return familyOptions.includes(listItem.macroFamily);
            });

            this.setState({
               filteredLists: {
                  ...this.state.filteredLists,
                  macroFamily: filterfamily,
               },
               valuesFilters: {
                  ...this.state.valuesFilters,
                  macroFamily: valueArray,
               },
            });
            break;
         case "newModule":
            let newModuleOptions = valueArray.map((data) => {
               return data.value;
            });

            let filterNewModule = listItems.filter((listItem) =>
               newModuleOptions.some(
                  (option) => option === listItem.newModule.toString()
               )
            );

            this.setState({
               filteredLists: {
                  ...this.state.filteredLists,
                  newModule: filterNewModule,
               },
               valuesFilters: {
                  ...this.state.valuesFilters,
                  newModule: valueArray,
               },
            });

            break;
         case "status":
            let statusOptions = valueArray.map((data) => {
               return data.label;
            });

            let filterStatus = listItems.filter((listItem) =>
               statusOptions.includes(listItem.status)
            );

            this.setState({
               filteredLists: {
                  ...this.state.filteredLists,
                  status: filterStatus,
               },
               valuesFilters: {
                  ...this.state.valuesFilters,
                  status: valueArray,
               },
            });
            break;
         case "starZones":
            let starZonesOptions = valueArray.map((data) => {
               return data.label;
            });

            let filterStarZones = listItems.filter((item) =>
               item.starZones.some((zone) => starZonesOptions.includes(zone))
            );

            this.setState({
               filteredLists: {
                  ...this.state.filteredLists,
                  starZones: filterStarZones,
               },
               valuesFilters: {
                  ...this.state.valuesFilters,
                  starZones: valueArray,
               },
            });
            break;
         case "valueSearch":
            let filterModuleLabel = listItems.filter((listItem) =>
               listItem.moduleLabel
                  .toLowerCase()
                  .includes(valueArray.target.value.toLowerCase())
            );
            this.setState({
               valueSearch: valueArray.target.value,
               filteredLists: {
                  ...this.state.filteredLists,
                  moduleLabel: filterModuleLabel,
               },
               valuesFilters: {
                  ...this.state.valuesFilters,
                  moduleLabel: valueArray.target.value,
               },
            });

            break;
         default:
      }
   };

   toggleOpenPopupDelete = () =>
      this.setState((prevState) => {
         return {
            deletePopupOpen: !prevState.deletePopupOpen,
         };
      });

   handleDeleteModules = (modules) => {
      const selectedModules = _.cloneDeep(
         modules.constructor === Object ? [modules] : modules
      );

      // Change the module status to DELETED
      selectedModules.map((module) => {
         module.status = "DELETED";
         module.userLock = this.props.user.user_name;
         module.productDtoList = [];
      });

      // Update the modules
      const selectedFilters = this.props.selectedFilters;

      this.props.deleteSelectedModules(selectedModules, selectedFilters);
   };

   intersectList = () => {
      const activeLists = Object.keys(this.state.valuesFilters)
         .map((valuesFiltersKeys) => {
            const isActive =
               this.state.valuesFilters[valuesFiltersKeys] &&
               this.state.valuesFilters[valuesFiltersKeys].length;

            return isActive && this.state.filteredLists[valuesFiltersKeys];
         })
         .filter((list) => list);

      const intersectList = activeLists.length
         ? intersectArray(...activeLists)
         : null;

      return intersectList;
   };

   render() {
      let {
         valuesFilters,
         valueSearch,
         publishDate,
         selectedModules,
         isAddModuleOpen,
         options,
         deletePopupOpen,
      } = this.state;
      let listItem = null;

      const deletableModules = this.state.selectedModules.filter((module) => {
         return this.props.auth.grants.some((grant) => {
            if (grant.role.toLowerCase() === "admin") {
               return true;
            }
            if (!grant.rules.includes("delete")) {
               return false;
            }

            const hasBrand = grant.brands.includes(module.brand);
            const hasStarsZones = grant.starsZones.some((starsZone) =>
               module.starZones.includes(starsZone)
            );
            return hasBrand && hasStarsZones;
         });
      });

      const isAdmin = this.props.auth.grants && this.props.auth.grants.some((grant) => {
         return grant.role.toLowerCase() === "admin" ? true : false;
      });

      listItem = this.printListItem(
         this.intersectList() ? this.intersectList() : this.state.listItem
      );

      return (
         <React.Fragment>
            <div className="list-component col-4 module">
               <div className="list-content component">
                  <div className="list-container-element">
                     <header>
                        <h3>Module Selection</h3>
                        <CustomSearch
                           placeholder="Module Label"
                           handleChange={(e) =>
                              this.handleChangeOptions(e, "valueSearch")
                           }
                           value={valueSearch}
                        />
                     </header>
                     <div className="filter-list">
                        <div className="row no-gutters">
                           <div className="col-6">
                              <DateRangePicker
                                 onChange={this.onChangeDatePicker}
                                 value={publishDate}
                                 format="dd/MM/y"
                                 calendarIcon={null}
                              />
                              <CustomSelect
                                 placeholder="Cluster"
                                 options={options.clusterLabel}
                                 isMulti
                                 handleChange={(e) =>
                                    this.handleChangeOptions(e, "clusterLabel")
                                 }
                                 value={valuesFilters.clusterLabel}
                              />
                              <CustomSelect
                                 placeholder="Stars zone"
                                 options={options.starZones}
                                 isMulti
                                 handleChange={(e) =>
                                    this.handleChangeOptions(e, "starZones")
                                 }
                                 value={valuesFilters.starZones}
                              />
                              <CustomSelect
                                 placeholder="Status"
                                 options={options.status}
                                 isMulti
                                 handleChange={(e) =>
                                    this.handleChangeOptions(e, "status")
                                 }
                                 value={valuesFilters.status}
                              />
                           </div>
                           <div className="col-6">
                              <CustomSelect
                                 placeholder="Family"
                                 options={options.macroFamily}
                                 isMulti
                                 handleChange={(e) =>
                                    this.handleChangeOptions(e, "macroFamily")
                                 }
                                 value={valuesFilters.macroFamily}
                              />
                              <CustomSelect
                                 placeholder="Type"
                                 options={options.newModule}
                                 isMulti
                                 handleChange={(e) =>
                                    this.handleChangeOptions(e, "newModule")
                                 }
                                 value={valuesFilters.newModule}
                              />
                              <CustomSelect
                                 placeholder="Stars Customer"
                                 options={options.starsCustomer}
                                 isMulti
                                 handleChange={(e) =>
                                    this.handleChangeOptions(e, "starsCustomer")
                                 }
                                 value={valuesFilters.starsCustomer}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="main-content">
                        <ul className="list">
                           <ListItem
                              handleClick={() => {
                                 this.togglePopup("isAddModuleOpen");
                              }}
                              isDisabled={
                                 this.props.auth &&
                                 this.props.auth.grants &&
                                 !canCreate(
                                    this.props.auth.grants,
                                    this.props.selectedFilters.brand
                                 )
                              }
                              listItemClass={
                                 "list-item-black" +
                                 (this.props.isEditable ? "" : " close")
                              }
                              iconClass="icon-plus"
                           >
                              Create New Module
                           </ListItem>
                           {listItem}
                        </ul>
                     </div>
                     <div className="module-footer">
                        <div className="outer-box-btn">
                           <div className="btn-box">
                              <Button
                                 btnClass="btn-medium"
                                 disabled={selectedModules.length === 0}
                                 handleClick={this.goToModuleComp}
                              >
                                 Open Modules
                              </Button>
                              <Button
                                 btnClass="btn-medium"
                                 handleClick={this.selectAll}
                              >
                                 Select All
                              </Button>
                           </div>
                           <div className="btn-box">
                              <Button
                                 btnClass="btn-medium"
                                 disabled={selectedModules.length === 0}
                                 handleClick={this.deselectAll}
                              >
                                 Deselect All
                              </Button>
                              <Button
                                 btnClass="btn-medium"
                                 disabled={
                                    !(
                                       selectedModules.length &&
                                       this.props.auth &&
                                       this.props.auth.grants &&
                                       canDelete(
                                          this.props.auth.grants,
                                          this.props.selectedFilters.brand
                                       )
                                    )
                                 }
                                 handleClick={() =>
                                    this.toggleOpenPopupDelete(
                                       "deletePopupOpen"
                                    )
                                 }
                              >
                                 Delete All
                              </Button>
                           </div>
                        </div>
                     </div>
                     {isAdmin ? (
                        <LinkedButton
                           link="massive-edit-selected-wi"
                           disabled={selectedModules.length === 0}
                           btnClass="btn-medium btn-massive-edit mass-edit"
                           btnContainerClass="btn-massive-edit"
                        >
                           Massive edit
                        </LinkedButton>
                     ) : null}
                  </div>
               </div>
            </div>
            {isAddModuleOpen && (
               <AddModulePopup
                  setNewModule={this.props.setNewModule}
                  redirect={() => this.props.history.push("/module")}
                  togglePopup={() => this.togglePopup("isAddModuleOpen")}
                  macroList={this.props.allMacroList}
                  getOptions={this.props.getOptions}
                  moduleList={this.props.listItem}
                  {...this.props.selectedFilters}
                  filterHome={this.props.filterHome}
               />
            )}
            {deletePopupOpen && (
               <Popup
                  popupClass="popup-medium"
                  title="Are you sure you want to delete the following modules?"
                  isDoubleButton
                  btnName="Confirm"
                  btnName2="Cancel"
                  close={this.toggleOpenPopupDelete}
                  disabled={!deletableModules.length}
                  handleClick={() => {
                     this.toggleOpenPopupDelete();
                     this.handleDeleteModules(deletableModules);
                  }}
               >
                  <div className="box-single-item">
                     {deletableModules.length ? (
                        deletableModules.map((singleItem, i) => {
                           return (
                              <li key={i.toString()}>
                                 {singleItem.moduleLabel}
                              </li>
                           );
                        })
                     ) : (
                        <p>
                           You don't have de authorization to delete any of the
                           selected modules
                        </p>
                     )}
                  </div>
               </Popup>
            )}
         </React.Fragment>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      allMacroList: state.filter.allMacroList,
      selectedFilters: state.filter.selectedFilters,
      options: state.module.homeFilters,
      lockModule: state.user.lockModule,
      isLockModuleLoading: state.user.isLockModuleLoading,
      filterHome: state.filter.filterHome,
      user: state.authentication.user,
      auth: state.authentication,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      deleteModule: (mod, type) => dispatch(actions.deleteModule(mod, type)),
      deleteSelectedModules: (mod, type) =>
         dispatch(actions.deleteSelectedModules(mod, type)),
      getOptions: () => dispatch(actions.getAllMacroFamily()),
      setNewModule: (newMod) => dispatch(actions.setNewModule(newMod)),
      getModuleFilters: (filters) =>
         dispatch(actions.getModuleFilters(filters)),
      getWorkingModuleWithLock: (selectedModules) =>
         dispatch(actions.getWorkingModuleWithLock(selectedModules)),
      massiveEditParams: (params) =>
         dispatch(actions.setMassiveEditParam(params)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListModule);
