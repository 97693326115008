import React from "react";

const Checkbox = ({
   children,
   handleClick,
   checked,
   value,
   disabled,
   classContainer,
}) => {
   return (
      <span
         className={
            "checkbox" +
            (checked ? " active" : "") +
            (disabled ? " check-disabled " : "") +
            (classContainer ? " classContainer " : "")
         }
         onClick={() => handleClick(value, checked)}
         disabled={disabled}
      >
         {checked && (
            <span className="checkmark_tick">
               <div className="checkmark_stem" />
               <div className="checkmark_kick" />
            </span>
         )}
         {children}
      </span>
   );
};

export default Checkbox;
