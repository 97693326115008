/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "../UI-components/Button";
import CustomInput from "../UI-components/CustomInput";
import CustomSelect from "../UI-components/CustomSelect";
import CustomIcon from "../UI-components/CustomIcon";
import Loader from "../UI-components/Loader";
import { connect } from "react-redux";
import * as _ from "lodash";
import * as actions from "../../store/actions";
import Popup from "../UI-components/Popup";
import {
   formatCamelCase,
   filterResults,
   orderByDate,
   formattedDateGivenAnInput,
   formatFiltersOptionsObjArray,
} from "../../services/Api";

class ReleaseComponent extends Component {
   constructor(props) {
      super(props);

      this.state = {
         actionOnStatus: null,
         isAddReleaseDisabled: true,
         isAllReleaseClose: false,
         isAddNewReleasePopupOpen: false,
         isPublishReleaseOpen: false,
         newReleaseId: "",
         filterList: filters,
         tableHeader: [
            RELEASE_HEADER,
            DATE_HEADER,
            STATUS_HEADER,
            ACTION_STATUS_HEADER,
            VISIBILITY_HEADER,
            ACTION_FO_HEADER,
            VISIBILITY_VIEWER_HEADER,
            ACTION_VIEWER_HEADER,
         ],
         orderDate: "desc",
         selectedFilters: {},
         releaseList: null,
         releaseListFiltered: null,
      };

      this.handleFilters = this.handleFilters.bind(this);
      this.orderByDate = this.orderByDate.bind(this);
   }

   componentDidMount = () => {
      this.props.getReleaseList();
      let filterList = this.addReleaseFilters(this.state.filterList);
      this.setState({
         filterList,
         releaseList: this.props.releaseList,
         releaseListFiltered: this.props.releaseList,
      });
   };

   componentDidUpdate = (prevProps) => {
      if (prevProps.releaseList !== this.props.releaseList) {
         this.checkReleaseStatus([...this.props.releaseList]);

         this.setState({
            releaseList: this.props.releaseList,
            releaseListFiltered: this.props.releaseList,
         });
      }
      if (prevProps.releaseList !== this.props.releaseList) {
         let filterList = this.addReleaseFilters(this.state.filterList);

         this.setState({
            filterList,
         });
      }
   };

   componentWillUnmount = () => {
      // if (window.confirm('Do you really want to exit from the page?'))
      //     this.props.history.replace('/')
   };

   addReleaseFilters = (filters) => {
      let filterList = [...filters];
      for (let i = 0; i < filterList.length; i++) {
         if (filterList[i].filterName === RELEASE_HEADER) {
            filterList[i].options = formatFiltersOptionsObjArray(
               [...this.props.releaseList],
               "releaseId"
            );
         }
      }

      return filterList;
   };

   togglePopup = (stateName) =>
      this.setState((prevState) => {
         return { [stateName]: !prevState[stateName] };
      });

   orderByDate = () => {
      let orderedObj = orderByDate(
         this.state.orderDate,
         [...this.state.releaseListFiltered],
         "creationDate"
      );

      this.setState({
         releaseListFiltered: orderedObj.sortedList,
         orderDate: orderedObj.newOrder,
      });
   };

   /* CHECK IF ALL RELEASE ARE CLOSED */
   checkReleaseStatus = (releaseList) => {
      let counterOpenRelease = 0;

      if (releaseList && releaseList.length) {
         releaseList.map((release) => {
            if (release.status.toLowerCase() === "open") {
               counterOpenRelease++;
            }
         });
         if (counterOpenRelease === 0) {
            this.setState({
               isAllReleaseClose: true,
               isAddReleaseDisabled: false,
            });
         } else {
            this.setState({
               isAllReleaseClose: false,
               isAddReleaseDisabled: true,
            });
         }
      }
   };

   /* FILTER RELEASE LIST */
   handleFilters = (filterArray, filterName) => {
      let allResults = _.cloneDeep(this.state.releaseList),
         selectedFilters = _.cloneDeep(this.state.selectedFilters),
         filterObj = filterResults(
            allResults,
            selectedFilters,
            filterArray,
            filterName
         );

      this.setState({
         selectedFilters: filterObj.selectedFilters,
         releaseListFiltered: filterObj.filteredResults,
      });
   };

   /* CREATE NEW RELEASE */
   setNewRelease = (e) => this.setState({ newReleaseId: e.target.value });

   confirmNewRelease = () => {
      this.props.createNewRelease(this.state.newReleaseId);
      this.togglePopup("isAddNewReleasePopupOpen");
   };

   closePopup = () => {
      this.setState({ newReleaseId: "" });
      this.togglePopup("isAddNewReleasePopupOpen");
   };

   /* REOPEN - CLOSE RELEASE */
   changeStatus = (action, selectedRelease) => {
      this.setState({
         actionOnStatus: action,
         releaseToChangeStatus: selectedRelease,
      });
      this.togglePopup("isChangeStatusPopupOpen");
   };

   confirmChangeStatus = (action) => {
      let release = {
         action,
         releaseId: this.state.releaseToChangeStatus,
      };
      this.props.changeStatusRelease(release);
      this.togglePopup("isChangeStatusPopupOpen");
   };

   /** PUBLISH RELEASE */
   openPublishPopup = (releaseId, type) => {
      this.setState({
         releaseToPublish: releaseId,
         publishFor: type,
      });
      this.togglePopup("isPublishReleaseOpen");
   };

   confirmPublish = () => {
      if (this.state.publishFor === "FO") {
         this.props.publishRelease(this.state.releaseToPublish);
      } else {
         this.props.publishViewerRelease(this.state.releaseToPublish);
         this.togglePopup("isPublishReleaseOpen");
      }
   };

   render() {
      let releaseList = this.state.releaseListFiltered;
      let filterList = this.state.filterList;

      const printStatus = (status, releaseId) => {
         if (status.toLowerCase() === "open")
            return (
               <div className="btn-container">
                  <Button
                     btnClass="btn-medium"
                     handleClick={() => this.changeStatus("close", releaseId)}
                  >
                     Close
                  </Button>
               </div>
            );
         else
            return (
               <div className="btn-container">
                  <Button
                     btnClass="btn-medium"
                     handleClick={() => this.changeStatus("re-open", releaseId)}
                  >
                     Re Open
                  </Button>
               </div>
            );
      };

      let counterHeader = 0;
      const printSelect = (placeholder, filters) => {
         if (filters) {
            let filterName = filters[counterHeader].filterName;
            let filterOptions = filters[counterHeader].options;
            counterHeader++;

            return (
               <CustomSelect
                  key={filterName}
                  isMulti
                  placeholder={formatCamelCase(placeholder)}
                  options={filterOptions}
                  handleChange={(e) => this.handleFilters(e, filterName)}
               />
            );
         }
      };

      return (
         <div className="col-12  user-manage-container">
            {releaseList ? (
               <div className="table-container manage-release-container">
                  <div className="table-header">
                     <ul>
                        {this.state.tableHeader.map(
                           (releaseListHeader, index) => (
                              <li key={releaseListHeader} index={index}>
                                 <div className="title-container">
                                    {formatCamelCase(releaseListHeader)}
                                 </div>
                                 {releaseListHeader !== ACTION_FO_HEADER &&
                                 releaseListHeader !== DATE_HEADER &&
                                 releaseListHeader !== ACTION_STATUS_HEADER &&
                                 releaseListHeader !== ACTION_VIEWER_HEADER
                                    ? printSelect(releaseListHeader, filterList)
                                    : null}
                                 {releaseListHeader === "Creation Date" ? (
                                    <div
                                       className="ordered"
                                       onClick={this.orderByDate}
                                    >
                                       {this.state.orderDate}
                                       <CustomIcon
                                          iconClass="icon-arrow1"
                                          iconContClass={this.state.orderDate}
                                       />
                                    </div>
                                 ) : null}
                              </li>
                           )
                        )}
                     </ul>
                  </div>
                  <div
                     className={
                        "add-release-container" +
                        (this.state.isAddReleaseDisabled ? " disabled" : "")
                     }
                     onClick={() => {
                        this.togglePopup("isAddNewReleasePopupOpen");
                     }}
                  >
                     <h4>Create new Release</h4>
                     <CustomIcon
                        iconClass="icon-plus user-table-icon"
                        iconContClass="icon-left"
                        disabled={this.state.isAddReleaseDisabled}
                     />
                  </div>
                  <div className="table-body">
                     <div className="table-body-container">
                        {!this.props.isCreatingRelease ? (
                           releaseList.map((release) => (
                              <ul
                                 key={release.releaseId}
                                 className="table-item user-item"
                              >
                                 <li>{release.releaseId}</li>
                                 <li>
                                    {formattedDateGivenAnInput(
                                       release.creationDate
                                    )}
                                 </li>
                                 <li>{release.status}</li>
                                 <li>
                                    {printStatus(
                                       release.status,
                                       release.releaseId
                                    )}
                                 </li>
                                 <li>
                                    <p>{release.foVisibility}</p>
                                    {release.foVisibility.toUpperCase() ===
                                    "PUBLISHED" ? (
                                       <p className="publish-date">
                                          {formattedDateGivenAnInput(
                                             release.foPublicationDate
                                          )}
                                       </p>
                                    ) : null}
                                 </li>
                                 <li>
                                    <div className="btn-container">
                                       <Button
                                          btnClass="btn-medium"
                                          handleClick={() => {
                                             this.openPublishPopup(
                                                release.releaseId,
                                                "FO"
                                             );
                                          }}
                                          disabled={
                                             release.foVisibility.toLowerCase() ===
                                             "published"
                                          }
                                       >
                                          Publish
                                       </Button>
                                    </div>
                                 </li>
                                 <li>
                                    <p>{release.viewerVisibility}</p>
                                    {release.viewerVisibility.toUpperCase() ===
                                    "PUBLISHED" ? (
                                       <p className="publish-date">
                                          {formattedDateGivenAnInput(
                                             release.viewerPublicationDate
                                          )}
                                       </p>
                                    ) : null}
                                 </li>
                                 <li>
                                    <div className="btn-container">
                                       <Button
                                          btnClass="btn-medium"
                                          handleClick={() => {
                                             this.openPublishPopup(
                                                release.releaseId,
                                                "VIEWER"
                                             );
                                          }}
                                          disabled={
                                             release.viewerVisibility.toLowerCase() ===
                                             "published"
                                          }
                                       >
                                          Publish
                                       </Button>
                                    </div>
                                 </li>
                              </ul>
                           ))
                        ) : (
                           <Loader />
                        )}
                     </div>
                  </div>
               </div>
            ) : (
               <div className="full-page">
                  <Loader />
               </div>
            )}
            {this.state.isAddNewReleasePopupOpen ? (
               <Popup
                  popupClass="popup-small"
                  title="Create a new release"
                  close={this.closePopup}
                  btnName="Create Release"
                  disabled={this.state.newReleaseName === ""}
                  handleClick={this.confirmNewRelease}
               >
                  <CustomInput
                     placeholder="Release Name"
                     handleChange={this.setNewRelease}
                  />
               </Popup>
            ) : null}
            {this.state.isPublishReleaseOpen ? (
               <Popup
                  isFlex
                  popupClass="popup-small"
                  btnName2="No"
                  btnName="Yes"
                  title="Publish Release"
                  isDoubleButton
                  close={() => this.togglePopup("isPublishReleaseOpen")}
                  handleClick={this.confirmPublish}
               >
                  <h4>
                     Are you sure you want to publish the release
                     {this.state.publishFor === "FO" ? (
                        <React.Fragment> in Front-Office? </React.Fragment>
                     ) : (
                        <React.Fragment> for viewer users?</React.Fragment>
                     )}
                  </h4>
               </Popup>
            ) : null}
            {this.state.isChangeStatusPopupOpen ? (
               <Popup
                  popupClass="popup-small"
                  isDoubleButton
                  btnName2="No"
                  btnName="Yes"
                  title={this.state.actionOnStatus + " Release"}
                  close={() => this.togglePopup("isChangeStatusPopupOpen")}
                  handleClick={() => {
                     this.confirmChangeStatus(this.state.actionOnStatus);
                  }}
               >
                  <h4>
                     Are you sure you want to {this.state.actionOnStatus} the
                     release ?
                  </h4>
               </Popup>
            ) : null}
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      newRelease: state.release.newRelease,
      releaseList: state.release.releaseList,
      isCreatingRelease: state.release.isCreatingRelease,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getReleaseList: () => dispatch(actions.getReleaseList()),
      createNewRelease: (release) =>
         dispatch(actions.createNewRelease(release)),
      changeStatusRelease: (release) =>
         dispatch(actions.changeStatusRelease(release)),
      publishRelease: (release) => dispatch(actions.publishRelease(release)),
      publishViewerRelease: (release) =>
         dispatch(actions.publishViewerRelease(release)),
   };
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withRouter(ReleaseComponent));

const RELEASE_HEADER = "Release";
const DATE_HEADER = "Creation Date";
const STATUS_HEADER = "Status";
const ACTION_STATUS_HEADER = "Action On Status";
const VISIBILITY_HEADER = " Visibility on Front-Office";
const ACTION_FO_HEADER = "Action On Front-Office";
const VISIBILITY_VIEWER_HEADER = " Visibility For Viewer";
const ACTION_VIEWER_HEADER = "Action On Viewer";

const filters = [
   {
      filterName: RELEASE_HEADER,
      options: [],
   },
   {
      filterName: STATUS_HEADER,
      options: [
         { label: "OPEN", value: "OPEN", filterName: "status" },
         { label: "CLOSED", value: "CLOSED", filterName: "status" },
      ],
   },
   {
      filterName: VISIBILITY_HEADER,
      options: [
         { label: "PUBLISHED", value: "PUBLISHED", filterName: "foVisibility" },
         {
            label: "UNPUBLISHED",
            value: "UNPUBLISHED",
            filterName: "foVisibility",
         },
      ],
   },
   {
      filterName: VISIBILITY_VIEWER_HEADER,
      options: [
         {
            label: "PUBLISHED",
            value: "PUBLISHED",
            filterName: "viewerVisibility",
         },
         {
            label: "UNPUBLISHED",
            value: "UNPUBLISHED",
            filterName: "viewerVisibility",
         },
      ],
   },
];
