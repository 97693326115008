/* eslint-disable react-hooks/exhaustive-deps */

import * as actions from "../../store/actions";

import { useEffect, useState } from "react";

import CustomIcon from "../UI-components/CustomIcon";
import Popup from "../UI-components/Popup";
import PopupGenericError from "../../components/Error-Handling/WithErrorHandling";
import Tooltip from "../UI-components/Tooltip";
import { connect } from "react-redux";

const PendingAreaTable = ({
   tableData,
   setRowsToSendMassiveEdit,
   isChangedTypeView,
   showBoxShadow,
   actualPage,
   isModules,
   unlockModule,
   unlockCluster,
   user,
}) => {
   const [rows, setRows] = useState([]);
   const [openPopup, setOpenPopup]=useState(false);
   const [objUnlock, setObjUnlock]=useState({});
   const [checkError, setCheckError]=useState(false);


   useEffect(() => {
      setRows(
         tableData.map((row) => {
            return {
               ...row,
               //isChecked: true,
            };
         })
      );
   }, []);

   useEffect(() => {
      selectedRowsToSend(
         rows.filter((checkedRow) => {
            return checkedRow.isChecked === true;
         })
      );
   }, [rows]);

   useEffect(() => {
      selectedRowsToSend(
         rows.filter((checkedRow) => {
            return checkedRow.isChecked === true;
         })
      );
   }, [isChangedTypeView, tableData, rows]);

   /* useEffect(() => {
      if (tableData && tableData.length) {
         rememberCheckedRows(tableData);
      }
      // }, [actualPage]);
   }, [tableData]);  */

   /* const rememberCheckedRows = (tableData) => {
      if (
         rowsToSendMassiveEdit &&
         rowsToSendMassiveEdit[actualPage] !== undefined &&
         rowsToSendMassiveEdit[actualPage].length &&
         Object.keys(rowsToSendMassiveEdit).includes(String(actualPage))
      ) {
         const reduxArr = rowsToSendMassiveEdit[actualPage];

         const checkRow = (row) => {
            const nameRow = `${row.upc} - ${row.type} - ${row.gedConfirmed} - ${row.gedExpiryDate} - ${row.materialCode} - ${row.moduleName} - ${row.monthId} - ${row.moduleLabel} - ${row.skuCode} - ${row.brandCode} - ${row.commodity}`;

            const nameRowFromRedux = reduxArr.filter((row) => {
               const stringRow = `${row.upc} - ${row.type} - ${row.gedConfirmed} - ${row.gedExpiryDate} - ${row.materialCode} - ${row.moduleName} - ${row.monthId} - ${row.moduleLabel} - ${row.skuCode} - ${row.brandCode} - ${row.commodity}`;

               const hasMatched = nameRow === stringRow;
               return hasMatched;
               // return setIsMatchedRow(hasMatched);
            });

            return nameRowFromRedux;
         };

         let newRows = tableData.map((row) => {
            const isMatched = checkRow(row).length >= 1;
            if (isMatched) {
               return {
                  ...row,
                  isChecked: true,
               };
            } else {
               return row;
            }
         });

         setRows(newRows);
      }
   }; */

   const selectedRowsToSend = (data) => {
      if (data) {
         const obj = {
            [actualPage]: data,
         };
         return setRowsToSendMassiveEdit(obj);
      } else {
         return [];
      }
   }; 

   const openModule = (obj) => {
         if(isModules){
            window.open('/myStarsBo/module?moduleName='+obj.moduleName+'&monthId='+obj.monthId);
            setRows(rows.map(row =>{
               if(row.moduleName === obj.moduleName && row.monthId === obj.monthId){
                  row.userLock = row.userLock  && row.userLock !== user.user_name ? row.userLock : user.user_name ;
                  row.locked = true;
               }
               return row;
            }));         }
         else {
               window.open('/myStarsBo/cluster?clusterId='+obj.clusterId+'&monthId='+obj.monthId);
               setRows(rows.map(row =>{
                  if(row.clusterId === obj.clusterId && row.monthId === obj.monthId){
                     row.userLock = row.userLock  && row.userLock !== user.user_name ? row.userLock : user.user_name ;
                     row.locked = true;
                  }
                  return row;
               }));             
         }
   };

   const handleClickLock=(row) => {
      setOpenPopup(true);
      if(isModules){
         setObjUnlock({
            moduleName: row.moduleName,
            monthId: row.monthId,
         });
      }
      else{
         setObjUnlock({
            clusterId: row.clusterId,
            monthId: row.monthId,
         });
      }
   };

   const handleUnClickLock=() => {
      setOpenPopup(false);
      setObjUnlock({});
   };

   const PendingAreaTableGeneratedRows = () => {
      return rows.map((row, i) => {
         const rowName = `${row.brandCode} - ${row.commodity} - ${row.status} - ${row.moduleLabel} - ${row.monthId}`;
         return (
            <tr id={rowName} key={"indexPendingAreaTable" + i}>
               {/* <td>
                  <div className="pending-area-table__row-checkbox--container">
                     <div className="form-check-wrapper">
                        <input
                           id="checkbox-pending-area-table"
                           type="checkbox"
                           className="form-check-input pending-area-table__checkbox--style"
                           name={rowName}
                           checked={row?.isChecked || false}
                           onChange={(e) => {
                              handleChange(e);
                           }} 
                        />
                     </div>
                  </div>
               </td>  */}
               <td>
                  <div className="pending-area-table__second-col-container">
                     <span className="pending-area-table__second-col-container--label">
                        {/* tB:03-2022 */}
                        <div>{row.brandCode ? row.brandCode : null}</div>
                     </span>
                  </div>
               </td>
               <td>
                  <div>{row.commodity ? row.commodity : null}</div>
               </td>
               {/* <td>
                  <span style={(isChangedTypeView && row.type === "Super disco") ?
                     { margin: "0 1vw" } :
                     { margin: "0", marginRight: '2vh' }}>
                     {row.upc ? row.upc : null}
                  </span>
                  {isChangedTypeView && row.type === "Super disco" ? (
                     <>
                        <span
                           data-tip="Super discontinued SKU"
                           className="icon icon-triangle-black"
                        />
                        <ReactTooltip
                           className="pending-area-table__tooltip--style"
                           effect="solid"
                        />
                     </>
                  ) : null}
               </td> */}
               {/* <td>
                  <div>{row.materialCode ? row.materialCode : null}</div>
               </td>
               <td>
                  <div>{row.skuCode ? row.skuCode : null}</div>
               </td> */}
                <td>
                  <div>{row.status ? row.status : null}</div>
               </td>
               <td>
                  <span style={{display:"inline-flex"}}>
                  <div onClick={ () => openModule(row) } style={{cursor:"pointer", textDecoration:"underline"}}>{row.moduleLabel ? row.moduleLabel : row.clusterLabel? row.clusterLabel : null}</div>
                  {row.locked ? ( 
                  <div onClick={()=>handleClickLock(row)} style={{paddingLeft:"10px"}}>
                  <Tooltip
                  text={row.userLock + " is editing the "+(isModules? "module":"cluster")}
                  tooltipClass="left tooltip-lock"
                  tooltipTextClass="white"
                   >
                  <CustomIcon
                    iconContClass="icon-left"
                    iconClass="icon-close-lock"
                  />
                  </Tooltip>
                  </div>
                  ) : null} 
                  </span>
               </td>
               <td>
                  <div>{row.monthId ? row.monthId : null}</div>
               </td>
            </tr>
         );
      });
   };
   return (
      <>
      <table className="pending-area-table">
         <thead
            style={
               showBoxShadow
                  ? {
                     position: "relative",
                     top: 0,
                     boxShadow: "0px 0px 20px 3px #000000",
                  }
                  : {
                     position: "relative",
                     top: 0,
                  }
            }
         >
            <tr>
              {/*  <th className="pending-area-table__col-checkbox--container">
                  <span className="pending-area-table__col-checkbox--label">
                     SELECT ALL
                  </span>
                  <div
                     id="checkbox-pending-area"
                     className="form-check-wrapper"
                  >
                     <input
                        id="checkbox-pending-area-table"
                        className="form-check-input pending-area-table__checkbox--style"
                        type="checkbox"
                        name="selectAll"
                        checked={
                           rows?.length &&
                           rows.filter((row) => row?.isChecked !== true)
                              .length < 1
                        }
                        onChange={(e) => {
                           handleChange(e);
                        }} 
                     />
                  </div>
               </th>  */}
               <th>BRAND</th>
               <th>COMMODITY</th>
               <th>STATUS</th>
              {/*  <th style={{ width: '2%' }}>SKU</th>
               <th>MODEL</th>
               <th>GRID</th> */}
               {  isModules  ? <th>MODULE</th> : <th>CLUSTER</th> }
               <th>MONTH</th>
            </tr>
         </thead>
         <tbody>{PendingAreaTableGeneratedRows()}</tbody>
      </table>
      {openPopup && !checkError && (
         <Popup
               popupClass="popup-unlocked-module"
               close={() => handleUnClickLock()}
               title=" WARNING"
               handleClickSecondary={() => {
                  if(isModules){
                     unlockModule([objUnlock]);
                     setRows(rows.map(row =>{
                        if(row.moduleName === objUnlock.moduleName && row.monthId === objUnlock.monthId){
                           row.userLock = null;
                           row.locked = false;
                        }
                        return row;
                     }));   
                  }
                  else{
                     unlockCluster(objUnlock);
                     setRows(rows.map(row =>{
                        if(row.clusterId === objUnlock.clusterId && row.monthId === objUnlock.monthId){
                           row.userLock = null;
                           row.locked = false;
                        }
                        return row;
                     }));   
                  }
                  handleUnClickLock();
               }}
               handleClick={() => {
                  handleUnClickLock();
               }}
               isDoubleButton={true}
               btnName={"No"}
               btnName2={"Yes"}
            >
               {"Do you want unlock this "+(isModules?"module":"cluster")+"?"}
            </Popup>
       )}
       {checkError && (
                        <PopupGenericError
                        showError={checkError}
                        closeAlert={() => {
                           setCheckError(false);
                        }}
                        handleClick={() => {
                           setCheckError(false);
                        }}
                     />
       )}
       </>
   );
};

const mapStateToProps = (state) => {
   return { rowsToSendMassiveEdit: state.filter.rowsToSendMassiveEdit, 
      isClusterComplete: state.filter.isClusterComplete,
      error: state.filter.error,
      user: state.authentication.user,
   };
}; 

const mapDispatchToProps = (dispatch) => {
   return {
      setRowsToSendMassiveEdit: (rowsToSendMassiveEdit) =>
         dispatch(actions.setRowsToSendMassiveEdit(rowsToSendMassiveEdit)),
      getWorkingModuleWithLock: (selectedModules, callbackSuccess) =>
         dispatch(actions.getWorkingModuleWithLock(selectedModules, callbackSuccess)),
      getActiveFilter: (filters) => 
         dispatch(actions.getActiveFilter(filters)),
      getModuleListSuccess: (modules,callId) =>
         dispatch(actions.getModuleListSuccess(modules, callId)),
      setSelectedFilters: (filters) =>
         dispatch(actions.setSelectedFilters(filters)),
      getClusterWithLock: (cluster) =>
         dispatch(actions.getClusterWithLock(cluster)),
      getClusterListSuccess: (clusters,callId) =>
         dispatch(actions.getClusterListSuccess(clusters, callId)),
      getFilteredList: (type, params) =>
         dispatch(actions.getFilteredList(type, params)),
      unlockModule: (moduleList) => 
         dispatch(actions.unlockModule(moduleList,true)),
      unlockCluster:(cluster) =>
         dispatch(actions.unlockCluster(cluster,true)),   
   };
}; 

export default connect(mapStateToProps, mapDispatchToProps)(PendingAreaTable);
