import * as actions from "../../../store/actions";

import React, { useEffect, useLayoutEffect, useState } from "react";

import Loader from "../../../components/UI-components/Loader";
import {MODULE_PERFORMANCES} from "../../../config";
import PaginatedPerformanceTable from "../PerformanceTable/PaginatedPerformanceTable";
import { addPerfoFilters } from "../../../store/actions/moduleActions";
import { connect } from "react-redux";
import { getCookie } from "../../../services/cookie";

function PerformancePopup({
   showPopup,
   isSegmentationView,
   selectedModule,
   logout,
   perfoSelectedFilters,
}) {
   const [showBoxShadowTableHead, setShowBoxShadowTableHead] = useState(false);
   const [showBoxShadowSkus, setShowBoxShadowSkus] = useState(false);
   // const [elementScrollTop, setElementScrollTop] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [percentual, setPercentual] = useState(0);
   const [messageFromResponse, setMessageFromResponse] = useState("");

   // eslint-disable-next-line no-unused-vars
   const [tableDataResponse, setTableDataResponse] = useState("");

   useEffect(() => {
      // Uncomment when the API will ready
      setIsLoading(true);
      getTableData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const tableContainerElementDOM = document.getElementById(
         "PerformancePopup__table--container"
      );

      const renderedTableContainerElementDOM = tableContainerElementDOM
         ? tableContainerElementDOM
         : null;

      renderedTableContainerElementDOM &&
         renderedTableContainerElementDOM.scrollTo(0, 0);
      setShowBoxShadowTableHead(false);
      setShowBoxShadowSkus(false);
   }, [isSegmentationView]);

   //Tonino Mock
   // eslint-disable-next-line
   const tableDataToninoMock = {
      moduleName: "RB_ICONS1",
      monthId: "M11_2021",
      upcList: [
         {
            upc: "a",
            color: "1234a",
            size: "52",
            model: "model",
            mustHave: true,
            imageUrl:
               "https://myluxottica-im.luxottica.com/RB/0RB4264/0RB4264__876_6O_222x111.jpg",
            starZoneList: [
               {
                  starZone: "ST01",
                  numDoor: "10",
                  segmentationList: [
                     {
                        segmentation: "ST01.SEG01",
                        numDoor: 3,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST01.SEG02",
                        numDoor: 3,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST01.SEG03",
                        numDoor: 3,
                        sellOut: 20.0,
                     },
                  ],
               },
               {
                  starZone: "ST02",
                  numDoor: "20",
                  segmentationList: [
                     {
                        segmentation: "ST02.SEG01",
                        numDoor: 2,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST02.SEG02",
                        numDoor: 2,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST02.SEG03",
                        numDoor: 2,
                        sellOut: 20.0,
                     },
                  ],
               },
               {
                  starZone: "ST03",
                  numDoor: "30",
                  segmentationList: [
                     {
                        segmentation: "ST03.SEG01",
                        numDoor: 1,
                        sellOut: 30.0,
                     },
                     {
                        segmentation: "ST03.SEG02",
                        numDoor: 1,
                        sellOut: 30.0,
                     },
                     {
                        segmentation: "ST03.SEG03",
                        numDoor: 1,
                        sellOut: 30.0,
                     },
                  ],
               },
            ],
         },
         {
            upc: "b",
            color: "1234b",
            size: "52",
            model: "model",
            // tB:03-2022
            mustHave: true, // shows MH or EL label
            imageUrl:
               "https://myluxottica-im.luxottica.com/RB/0RB4165/0RB4165__622_T3_222x111.jpg",
            starZoneList: [
               {
                  starZone: "ST01",
                  numDoor: "10",
                  segmentationList: [
                     {
                        segmentation: "ST01.SEG01",
                        numDoor: 3,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST01.SEG02",
                        numDoor: 3,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST01.SEG03",
                        numDoor: 3,
                        sellOut: 30.0,
                     },
                  ],
               },
               {
                  starZone: "ST02",
                  numDoor: "20",
                  segmentationList: [
                     {
                        segmentation: "ST02.SEG01",
                        numDoor: 2,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST02.SEG02",
                        numDoor: 2,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST02.SEG03",
                        numDoor: 2,
                        sellOut: 30.0,
                     },
                  ],
               },
               {
                  starZone: "ST03",
                  numDoor: "30",
                  segmentationList: [
                     {
                        segmentation: "ST03.SEG01",
                        numDoor: 1,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST03.SEG02",
                        numDoor: 1,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST03.SEG03",
                        numDoor: 1,
                        sellOut: 30.0,
                     },
                  ],
               },
            ],
         },
         {
            upc: "c",
            color: "1234c",
            size: "52",
            mustHave: false,
            model: "model",
            imageUrl:
               "https://myluxottica-im.luxottica.com/RB/0RB4165/0RB4165__622_T3_222x111.jpg",
            starZoneList: [
               {
                  starZone: "ST01",
                  numDoor: "10",
                  segmentationList: [
                     {
                        segmentation: "ST01.SEG01",
                        numDoor: 3,
                        sellOut: 100.0,
                     },
                     {
                        segmentation: "ST01.SEG02",
                        numDoor: 3,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST01.SEG03",
                        numDoor: 3,
                        sellOut: 30.0,
                     },
                  ],
               },
               {
                  starZone: "ST02",
                  numDoor: "20",
                  segmentationList: [
                     {
                        segmentation: "ST02.SEG01",
                        numDoor: 2,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST02.SEG02",
                        numDoor: 2,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST02.SEG03",
                        numDoor: 2,
                        sellOut: 30.0,
                     },
                  ],
               },
               {
                  starZone: "ST03",
                  numDoor: "30",
                  segmentationList: [
                     {
                        segmentation: "ST03.SEG01",
                        numDoor: 1,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST03.SEG02",
                        numDoor: 1,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST03.SEG03",
                        numDoor: 1,
                        sellOut: 30.0,
                     },
                  ],
               },
            ],
         },
         {
            upc: "d",
            color: "1234d",
            size: "52",
            model: "model",
            mustHave: true,
            imageUrl:
               "https://myluxottica-im.luxottica.com/RB/0RB4165/0RB4165__622_T3_222x111.jpg",
            starZoneList: [
               {
                  starZone: "ST01",
                  numDoor: "10",
                  segmentationList: [
                     {
                        segmentation: "ST01.SEG01",
                        numDoor: 3,
                        sellOut: 120.0,
                     },
                     {
                        segmentation: "ST01.SEG02",
                        numDoor: 3,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST01.SEG03",
                        numDoor: 3,
                        sellOut: 30.0,
                     },
                  ],
               },
               {
                  starZone: "ST02",
                  numDoor: "20",
                  segmentationList: [
                     {
                        segmentation: "ST02.SEG01",
                        numDoor: 2,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST02.SEG02",
                        numDoor: 2,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST02.SEG03",
                        numDoor: 2,
                        sellOut: 30.0,
                     },
                  ],
               },
               {
                  starZone: "ST03",
                  numDoor: "30",
                  segmentationList: [
                     {
                        segmentation: "ST03.SEG01",
                        numDoor: 1,
                        sellOut: 10.0,
                     },
                     {
                        segmentation: "ST03.SEG02",
                        numDoor: 1,
                        sellOut: 20.0,
                     },
                     {
                        segmentation: "ST03.SEG03",
                        numDoor: 1,
                        sellOut: 30.0,
                     },
                  ],
               },
            ],
         },
      ],
   };

   const getTableData = () => {
      const url = MODULE_PERFORMANCES;

      const moduleDto = {
         moduleName: selectedModule.moduleName,
         monthId: selectedModule.monthId,
      };

      const ibicFilters = addPerfoFilters(perfoSelectedFilters);

      if (!ibicFilters.zonaPerMysa) {
         ibicFilters.zonaPerMysa = [];
      }

      const objToSend = {
         moduleDto,
         ibicFilters,
      };

      fetch(url, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
         body: JSON.stringify(objToSend),
      })
         .then((response) => {
            if (response.ok) {
               setIsLoading(false);
               return response.json();
            }
            if (response.status === 401) {
               setIsLoading(false);
               logout();
               return response.json();
            }
            if (!response.ok && response.status !== 401) {
               setIsLoading(false);
               setMessageFromResponse(response.statusText);
               // setMessageFromResponse("Ooops, something went wrong");
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               setIsLoading(false);
               setTableDataResponse(data);
            }
         })
         .catch((err) => {
            console.error(err);
            setIsLoading(false);
         });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   };

   useLayoutEffect(() => {
      const tableContainerElementDOM = document.getElementById(
         "PerformancePopup__table--container"
      );

      const renderizedTableContainerElementDOM = tableContainerElementDOM
         ? tableContainerElementDOM
         : null;

      const detect = () => {
         const isTabledScrolledEnoughToShowShadow =
            renderizedTableContainerElementDOM.scrollTop >= 10 ? true : false;

         setShowBoxShadowTableHead(isTabledScrolledEnoughToShowShadow);
         // setElementScrollTop(renderizedTableContainerElementDOM.scrollTop);

         const perc =
            (document.body.scrollTop +
               renderizedTableContainerElementDOM.scrollTop) /
            (renderizedTableContainerElementDOM.scrollHeight -
               renderizedTableContainerElementDOM.clientHeight);

         setPercentual(perc * 100);
      };

      renderizedTableContainerElementDOM !== null &&
         renderizedTableContainerElementDOM.addEventListener("scroll", detect);

      return () => {
         renderizedTableContainerElementDOM !== null &&
            renderizedTableContainerElementDOM.removeEventListener(
               "scroll",
               detect
            );
      };
   });

   useLayoutEffect(() => {
      const tableContainerElementDOM = document.getElementById(
         "PerformancePopup__table--container"
      );

      const renderizedTableContainerElementDOM = tableContainerElementDOM
         ? tableContainerElementDOM
         : null;

      const detectHorizontalScroll = () => {
         const isTabledScrolledEnoughToShowShadow =
            renderizedTableContainerElementDOM.scrollLeft >= 10 ? true : false;

         setShowBoxShadowSkus(isTabledScrolledEnoughToShowShadow);
      };

      renderizedTableContainerElementDOM !== null &&
         renderizedTableContainerElementDOM.addEventListener(
            "scroll",
            detectHorizontalScroll
         );

      return () => {
         renderizedTableContainerElementDOM !== null &&
            renderizedTableContainerElementDOM.removeEventListener(
               "scroll",
               detectHorizontalScroll
            );
      };
   });

   //Error 0216: In caso di piu' upc e starZone, se un upc non
   //presentava dati per una starZone presente invece in altri,
   //sulla StarZone view l'upc privo di una starZone presentava per
   //quest'ultima i dati della starZone immediatamente precedente
   //const tableData = tableData0216;

   // Tonino Mock
   // const tableData = tableDataToninoMock;

   // const emptyMock = {
   //    moduleName: "RB_ICONS_A_CORE_METAL",
   //    monthId: "M07_2021",
   //    upcList: [],
   // };
   // const tableData = emptyMock;

   // Real response
   const tableData = tableDataResponse;

   const showMessageError =
      typeof messageFromResponse === "string" &&
      !tableData &&
      !isLoading &&
      messageFromResponse !== "" &&
      !messageFromResponse.includes(";");

   const showSplittedMessageError =
      typeof messageFromResponse === "string" &&
      !tableData &&
      !isLoading &&
      messageFromResponse !== "" &&
      messageFromResponse.includes(";");

   const emptyUpcList = !tableData?.upcList?.length || !tableData.upcList;

   return (
      <React.Fragment>
         <div
            className={"PerformancePopup__background--overlay"}
            onClick={() => {
               showPopup();
            }}
         />
         <div className="PerformancePopup__container">
            <div
               className="PerformancePopup__icon--close--container"
               onClick={() => {
                  showPopup();
               }}
            >
               <span className="icon-plus" />
            </div>
            <div className="PerformancePopup__content--container">
               <div className="PerformancePopup__title--container">
                  <span className="PerformancePopup__title--label">
                     {!isSegmentationView
                        ? "Data visualization for stars zone"
                        : "Data visualization for buying behavior"}
                  </span>
                  <span className="PerformancePopup__module--label">
                     Module: {selectedModule.moduleLabel}
                  </span>
               </div>
               {isLoading && (
                  <div className="PerformancePopup__loader--container">
                     <Loader />
                  </div>
               )}
               {tableData &&
                  !isLoading &&
                  messageFromResponse === "" &&
                  !emptyUpcList && (
                     <div
                        id="PerformancePopup__table--container"
                        className="PerformancePopup__table--container"
                     >
                        <PaginatedPerformanceTable
                           tableData={tableData}
                           showBoxShadowTableHead={showBoxShadowTableHead}
                           showBoxShadowSkus={showBoxShadowSkus}
                           // elementScrollTop={elementScrollTop}
                           percentual={percentual}
                           //CM added 25/03
                           currentWorkingModule={selectedModule}
                        />
                     </div>
                  )}

               {showMessageError && !emptyUpcList && (
                  <div className="PerformancePopup__message--container">
                     <p>{messageFromResponse}</p>
                  </div>
               )}

               {!emptyUpcList &&
                  showSplittedMessageError &&
                  messageFromResponse.split(";").map((error, i) => {
                     return (
                        <div className="PerformancePopup__message--container">
                           <p key={i.toString()}>{error}</p>
                        </div>
                     );
                  })}

               {emptyUpcList &&
                  !isLoading &&
                  !showMessageError &&
                  !showSplittedMessageError && (
                     <p className="PerformancePopup__no-data--label">
                        No performance data available
                     </p>
                  )}
            </div>
         </div>
      </React.Fragment>
   );
}

const mapStateToProps = (state) => {
   return {
      isSegmentationView: state.performancePopup.isSegmentationView,
      perfoSelectedFilters: state.module.perfoSelectedFilters,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      logout: () => dispatch(actions.logout()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformancePopup);
