import React, { useEffect, useState } from "react";
import Button from "../UI-components/Button";
import Popup from "../UI-components/Popup";
import axios from "axios";
import { getCookie } from "../../services/cookie";
import * as _ from "lodash";
import Loader from "../UI-components/Loader";
import {CLUSTER_COPY_COMPOSITION, MODULE_COPY_COMPOSITION} from "../../config";
import { unionBy } from "lodash"

export const PopupCopy = (props) => {

   const clusterIdAvailableMonths = (clusterList) => {
      const clusterIdMonths = {}
      
      clusterList.forEach(cluster => {
         clusterIdMonths[cluster.clusterId] = cluster.openMonthList
      })
      return clusterIdMonths
   }

   let mapToCopy =
      props.type === "module"
         ? {
              destinationMonthList:
                 props.data && props.data.openMonthList
                    ? props.data.openMonthList
                    : [],
              moduleName:
                 props.data && props.data.moduleName
                    ? props.data.moduleName
                    : "",
              monthId:
                 props.data && props.data.monthId ? props.data.monthId : "",
           }
         : 
            /*
            SINGLE CLUSTER - LOGIC
            {
              clusterId:
                 props.data && props.data.clusterId ? props.data.clusterId : "",
              destinationMonthList:
                 props.data && props.data.openMonthList
                    ? props.data.openMonthList
                    : [],
              monthId:
                 props.data && props.data.monthId ? props.data.monthId : "",
           };
           */
            {
               clusterIdList:
                  props.data && props.data.filter(cluster => cluster.clusterId).length > 0 ? props.data.filter(cluster => cluster.clusterId) : [],
               destinationMonthList:
                  props.data && props.data.filter(cluster => cluster.openMonthList).length > 0 ? clusterIdAvailableMonths(props.data) : {}, // clusterId - monthList
               monthId:
                  props.data && props.data.filter(cluster => cluster.monthId).length > 0 ? props.data
                     .filter(cluster => cluster.monthId)
                     .map(cluster => cluster.monthId)[0] // seleziono il mese del primo cluster (tanto sono tutti uguali)
                  : ""
            }

   const [monthList, setMonthList] = useState([]);
   const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);
   const [isSuccess, setIsSuccess] = useState(false);
   const [isError, setIsError] = useState(false);
   const [statusError, setStatusError] = useState(false);
   const [messageError, setMessageError] = useState([]);
   const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   /* ---------------------------------------------------------------- */

   const postCopy = (objToSend) => {
      axios({
         url:
            props.type === "module"
               ? MODULE_COPY_COMPOSITION
               : CLUSTER_COPY_COMPOSITION,
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         data: objToSend,
      })
         .then((response) => {
            if (
               response.status === 200 &&
               response.data.errors &&
               response.data.errors.length > 0
            ) {
               setIsLoading(false);
               setMessageError(_.uniq(response.data.errors));
               return setStatusError(true);
            }
            setIsLoading(false);
            return setIsSuccess(true);
         })
         .catch((error) => {
            console.error(error);
            setIsError(true);
         });
   };

   const handleSave = () => {
      setIsLoading(true);
      setIsSavePopupOpen(false);
      postCopy(mapMonthToSend(monthList, false));
      //console.log(mapMonthToSend(monthList, false))
   };

   const handlePublish = () => {
      setIsLoading(true);
      setIsPublishPopupOpen(false);
      postCopy(mapMonthToSend(monthList, true));
   };
   /* ---------------------------------------------------------------- */

   useEffect(() => {
      if (props.type === "module"){
         const months = mapToCopy.destinationMonthList.map((month) => {
            return {
               possMonth: month,
            };
         });

         const objDateLabel = months.map((el) => {
            const yearAndMonth = el.possMonth.split("M")[1].split("_");
            const month = yearAndMonth[0];
            const year = yearAndMonth[1];
            const date = new Date(`${year}-${month}`);

            return {
               date: _.orderBy(date, ["desc"]),
               possMonth: el.possMonth,
            };
         });
         setMonthList(objDateLabel);
      }
      else{
         const clusterIdMonths = mapToCopy.destinationMonthList
         let unionMonthList = []
         const months = {}

         Object.keys(clusterIdMonths).forEach(clusterId => {
            months[clusterId] = clusterIdMonths[clusterId].map(month => {
               return {
                  possMonth: month,
               };
            })
         })

         Object.keys(months).forEach(clusterId => {
            months[clusterId] = months[clusterId].map((el) => {
               const yearAndMonth = el.possMonth.split("M")[1].split("_");
               const month = yearAndMonth[0];
               const year = yearAndMonth[1];
               const date = new Date(`${year}-${month}`);
      
               return {
                  date: _.orderBy(date, ["desc"]), //????
                  possMonth: el.possMonth,
               };
            });

            unionMonthList = unionBy([...unionMonthList, ...months[clusterId]], "possMonth")
         })

         setMonthList(unionMonthList);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   /*
   const allClustersHaveAvailableModules = () => {
      let result = true
      Object.keys(monthList).forEach(clusterId => {
         result = result && monthList[clusterId].length > 0
      })
      return result
   }
   */

   /*
   const showErrorMessage = () => {
      const clusterIdList = Object.keys(monthList)
      let message = `There are no available months for ${clusterIdList.length > 1 ? "clusters" : "cluster"} `

      return (
         <>
            <p>{message}</p> 
            <ul style={{
               listStyle: "disc",
               marginLeft: "16px"
            }}>
               {
                  clusterIdList.map(clusterId => {
                     return <li key={clusterId} style={{ textAlign: "left" }}>{clusterId}</li>
                  })
               }
            </ul>
         </>
      )
   }
   */

   const handleChange = (e) => {
      const { name, checked } = e.target;
      let tempMonthList = [];
      if (monthList) {
         if (name === "allSelect") {
            tempMonthList = monthList.map((month) => {
               return { ...month, isChecked: checked };
            });
         } else {
            tempMonthList = monthList.map((month) => {
               return {
                  ...month,
                  isChecked:
                     month.isChecked && month.possMonth === name
                        ? false
                        : !month.isChecked && month.possMonth === name
                        ? true
                        : month.isChecked
                        ? month.isChecked
                        : false,
               };
            });
         }
      }
      setMonthList(tempMonthList);
   };

   const mapMonthToSend = (monthList, toPublish) => {
      const newDestinationMonthList = monthList
         .filter((monthChecked) => {
            return monthChecked.isChecked === true;
         })
         .map((el) => {
            return el.possMonth;
         });

      let objToSend =
         props.type === "module"
            ? {
                 modules: [
                    {
                       destinationMonthList: newDestinationMonthList
                          ? newDestinationMonthList
                          : [],
                       moduleName: mapToCopy.moduleName,
                       monthId: mapToCopy.monthId,
                    },
                 ],
                 toPublish: toPublish,
              }
            : {
                 /*
                 clusters: [
                    {
                       clusterId: mapToCopy.clusterId,
                       destinationMonthList: newDestinationMonthList
                          ? newDestinationMonthList
                          : [],
                       monthId: mapToCopy.monthId,
                    },
                 ],
                 */
                  clusters: props.data.map(cluster => {
                     return ({
                        clusterId: cluster.clusterId,
                        destinationMonthList: newDestinationMonthList,
                        monthId: mapToCopy.monthId
                     })
                  }),
                  toPublish: toPublish,
              };
      return objToSend;
   };

   let selectMonth = monthList
      .filter((monthChecked) => {
         return monthChecked.isChecked === true;
      })
      .map((el) => {
         return el.possMonth;
      })
      .join(", ");

   const disableCta =
      props.auth &&
      props.auth.grants.some((grant) => {
         if (
             (/admin/i.test(grant.role) || /CREATOR/i.test(grant.role))
             && grant.types.includes("edit")
         ) {
            return true;
         } else {
            return false;
         }
      });

   const disableEditorCta =
      props.auth &&
      props.auth.grants.some((grant) => {
         if ((/admin/i.test(grant.role) || /CREATOR/i.test(grant.role))
             && grant.types.includes("submit")) {
            return true;
         } else {
            return false;
         }
      });

   return (
      <>
         <div className="popup-background" onClick={() => props.close()} />
         <div
            className={
               "popup-container " + (props.popupClass ? props.popupClass : "")
            }
         >
            {isLoading ? (
               <Loader />
            ) : (
               <div className="copy-content-container">
                  <div className="copy-title-container">
                     <span className="copy-title">
                        {props.type === "module"
                           ? "Copy module"
                           : "Copy cluster"}
                     </span>
                  </div>
                  {monthList && monthList?.length ? (
                     <>
                        <div className="copy-subtitle-container">
                           <span className="copy-subtitle">
                              Select one or more month
                           </span>
                        </div>
                        <div
                           className="form-check-container"
                           style={{ overflowY: "auto" }}
                        >
                           <div className="form-check-wrapper">
                              <input
                                 type="checkbox"
                                 id="copy-all-checkbox"
                                 className="form-check-input"
                                 name="allSelect"
                                 checked={
                                    monthList?.length &&
                                    monthList.filter(
                                       (month) => month?.isChecked !== true
                                    ).length < 1
                                 }
                                 onChange={handleChange}
                              />
                              <label className="form-check-label ms-2 copy-check-label">
                                 Select all
                              </label>
                           </div>
                           {monthList && monthList?.length &&
                              monthList.map((month, index) => {
                                 return(
                                    <div
                                       className="copy-check-container"
                                       key={index}
                                    >
                                       <input
                                          type="checkbox"
                                          id="copy-all-checkbox"
                                          className="copy-check-input"
                                          name={month.possMonth}
                                          checked={month?.isChecked || false}
                                          onChange={handleChange}
                                       />
                                       <label className="copy-check-label">
                                          {month.possMonth}
                                       </label>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     </>
                  ) : (
                     <div>There are no available months</div>
                  )}
                  <div className="copy-btn-container">
                     <Button
                        btnClass="btn-lia btn-medium"
                        handleClick={() => {
                           props.handleClick();
                        }}
                        disabled={props.disabled}
                     >
                        {props.btnName[0]}
                     </Button>
                     {monthList && monthList?.length ? (
                        <>
                           <Button
                              btnClass="btn-lia btn-medium"
                              handleClick={() => setIsSavePopupOpen(true)}
                              disabled={
                                 disableCta && selectMonth ? false : true
                              }
                           >
                              {props.btnName[1]}
                           </Button>
                           <Button
                              btnClass="btn-lia btn-medium"
                              handleClick={() => setIsPublishPopupOpen(true)}
                              disabled={
                                 disableEditorCta && selectMonth ? false : true
                              }
                           >
                              {props.btnName[2]}
                           </Button>
                        </>
                     ) : null}
                  </div>
               </div>
            )}
         </div>

         {isSavePopupOpen && (
            <Popup
               popupClass="popup-save-copy"
               close={() => setIsSavePopupOpen(false)}
               handleClick={() => {
                  handleSave();
               }}
               handleClickSecondary={() => {
                  setIsSavePopupOpen(false);
               }}
               isDoubleButton={true}
               btnName={"Confirm"}
               btnName2={"Cancel"}
            >
               {"Are you sure you want to save the module for: "}
               {selectMonth}
            </Popup>
         )}
         {isPublishPopupOpen && (
            <Popup
               popupClass="popup-publish-copy"
               close={() => setIsPublishPopupOpen(false)}
               handleClick={() => {
                  handlePublish();
               }}
               handleClickSecondary={() => {
                  setIsPublishPopupOpen(false);
               }}
               isDoubleButton={true}
               btnName={"Confirm"}
               btnName2={"Cancel"}
            >
               {"Are you sure you want to publish the module for: "}
               {selectMonth}
            </Popup>
         )}
         {isSuccess && (
            <Popup
               popupClass={"popup-small"}
               close={() => {
                  setIsSuccess(false);
                  props.close();
               }}
            >
               {props.type === "module" ? (
                  <div>
                     {"Copied module "} {mapToCopy.moduleName}{" "}
                     {"for the months: "}
                     {selectMonth};
                  </div>
               ) : (
                  <div>
                     {"Copied cluster "} {props.data.clusterLabel}{" "}
                     {"for the months: "}
                     {selectMonth};
                  </div>
               )}
            </Popup>
         )}
         {statusError && (
            <Popup
               popupClass={"popup-small"}
               close={() => {
                  setIsSuccess(false);
                  props.close();
               }}
            >
               {messageError &&
                  messageError.length &&
                  messageError.map((el) => {
                     return (
                        <div>
                           <div>{el}</div>
                        </div>
                     );
                  })}
            </Popup>
         )}
         {isError && (
            <Popup
               popupClass={"popup-small"}
               close={() => {
                  setIsError(false);
                  props.close();
               }}
            >
               {props.type === "module" ? (
                  <div>
                     {"Unable to copy the module "} {mapToCopy.moduleName}
                     {"for the months: "}
                     {selectMonth};
                  </div>
               ) : (
                  <div>
                     {"Unable to copy the cluster "} {props.data.clusterLabel}
                     {"for the months: "}
                     {selectMonth};
                  </div>
               )}
            </Popup>
         )}
      </>
   );
};

export default PopupCopy;
