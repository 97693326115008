import React from "react";

const CheckboxManageUser = ({
  label,
  id = label,
  checked = false,
  setChecked,
  onChange = (e) => {
    setChecked(e.target.checked);
  },
  disabled = false,
}) => {
  return (
    <label
      className={
        `${["checkbox-container"]}` +
        (disabled ? ` ${["checkbox-disabled"]}` : "")
      }
    >
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="checkbox-input"
        disabled={disabled}
      />
      <label className="checkbox-text" htmlFor={id}>
        {label}
      </label>
      <span className="checkmark" />
    </label>
  );
};

export default CheckboxManageUser;
