import React from "react";
import CustomIcon from "../../components/UI-components/CustomIcon";

const Chip = ({ label, active, handleClick, type, code, disabled, icon }) => {
  const activeClass = active ? " active" : "";
  const disabledClass = disabled ? " disabled" : "";
  return (
    <div
      className={`${"chip"}${activeClass}${disabledClass}`}
      onClick={() => handleClick(type, code, !active)}
    >
      <span>{label}</span>
      <CustomIcon iconClass={icon} />
    </div>
  );
};

export default Chip;
