import React from 'react';
 
const customSearch = (props) => {
    return (
        <input 
            className={"search " + props.inputClass} 
            placeholder={props.placeholder}
            onChange={props.handleChange} 
            value={props.value} 
            maxLength={props.maxLength}/>
    )
}
export default customSearch;