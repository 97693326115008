import './ModuleActionsFilters.scss'

import React, { useState } from 'react';

import DiscontinuationPanel from '../DiscontinuationPanel/DiscontinuationPanel';
import FilterPanel from '../FilterPanel/FilterPanel';
import HideShowPanel from '../HideShowPanel/HideShowPanel';
import OrderModulePanel from '../OrderModulePanel/OrderModulePanel';
import OrderPanel from '../OrderPanel/OrderPanel';

const ModuleActionsFilters = ({
    moduleList,
    filterSku,
    highlightSku,
    discontinuationSku,
    //CM added
    orderSKUs,
    orderModules,
    hideDetailsPhoto,
    hideDetailsGC,
    onToggle,
}) => {
    const [showActions, setShowActions] = useState(false);



    const products = [];
    if (moduleList) {
        moduleList.forEach(module => {
            if (module && module.productDtoList) {
                module.productDtoList.forEach(prod => {
                    if (prod)
                        products.push(prod);
                })
            }

        })
    }
    if (!products) return null;

    return (
        <div>
            <div onClick={() => {
                onToggle(!showActions);
                setShowActions(!showActions);
            }}>
                <span className='moduleActionsFilter__togglebtn'>{!showActions ? '+ Open Filters' : '- Close Filters'}</span>
            </div>
            <div className='moduleActionsFilter__section' style={!showActions ? { display: 'none' } : null}>
                <FilterPanel
                    products={products}
                    title='Filter'
                    filterSku={filterSku}
                />
                <FilterPanel
                    isHighlight
                    products={products}
                    title='Highlight'
                    filterSku={highlightSku}
                />
                <DiscontinuationPanel
                    title='Discontinuation'
                    filterSku={discontinuationSku}
                />
                <OrderPanel
                    orderSKUs={orderSKUs}
                />
                <HideShowPanel
                    hideDetailsPhoto={hideDetailsPhoto}
                    hideDetailsGc={hideDetailsGC}
                />
                <OrderModulePanel
                    orderModules={orderModules}
                />
            </div>

        </div>
    )
}
export default ModuleActionsFilters



