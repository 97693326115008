import React, { Component } from "react";
import { connect } from "react-redux";

import Popup from "../../UI-components/Popup";
import CustomIcon from "../../UI-components/CustomIcon";
import Tooltip from "../../UI-components/Tooltip";
import { canDelete, formattedDateGivenAnInput } from "../../../services/Api";
import * as actions from "../../../store/actions";

class ListItemHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePopupOpen: false,
      warningPopupOpen: false,
    };
  }

  handleClickLock = (e) => {
    e.stopPropagation();
    this.props.togglePopupUnlock(true, this.props.item, this.props.type);
  };

  handleClickThash = (e) => {
    e.stopPropagation();
    // this.props.togglePopupUnlock(true, this.props.item, this.props.type)
  };

  toggleOpenPopupDelete = () => {
    this.setState({ deletePopupOpen: !this.state.deletePopupOpen });
  };

  toggleClickWarning = (e) => {
    e.stopPropagation();
    this.setState({ warningPopupOpen: !this.state.warningPopupOpen });
  };

  render() {
    let {
      listItemClass,
      handleClick,
      children,
      iconClass,
      iconClassWarning,
      iconClassTrash,
      item,
      type,
      deleteItem,
    } = this.props;
    let date = formattedDateGivenAnInput(item.publicationDate),
      isHide = !item.publicationDate;
    let user = item.userLock ? item.userLock : "A user";

    const rpasErrors = this.props.item.rpasErrors;

    const handleClickThash = (e) => {
      e.stopPropagation();
      this.toggleOpenPopupDelete();
    };

    return (
      <>
        <li style={{
          zIndex: 1
        }} className={"lists-item " + listItemClass} onClick={handleClick}>
          <div className="left-icons">
            {item.locked && (
              <div className="lock-container" onClick={this.handleClickLock}>
                <Tooltip
                  text={user + " is editing the " + type}
                  tooltipClass="left tooltip-lock"
                  tooltipTextClass="white"
                >
                  <CustomIcon
                    iconContClass="icon-left"
                    iconClass="icon-close-lock"
                  />
                </Tooltip>
              </div>
            )}
            {this.props.auth &&
              this.props.auth.grants &&
              canDelete(
                this.props.auth.grants,
                this.props.item[type === "cluster" ? "brandCode" : "brand"],
                this.props.item.starZones
              ) && (
                <CustomIcon
                  iconClass={iconClassTrash}
                  handleClick={handleClickThash}
                />
              )}
          </div>

          <Tooltip
            text={date}
            tooltipClass={"top tooltip-home" + (isHide ? " hide-tooltip" : "")}
            tooltipTextClass="white"
          >
            <div className="text ">{children}</div>
          </Tooltip>

          <div className="right-icon">
            {rpasErrors && (
              <CustomIcon
                iconClass={iconClassWarning}
                handleClick={this.toggleClickWarning}
              />
            )}
            <CustomIcon iconClass={iconClass} />
          </div>
        </li>
        {this.state.deletePopupOpen && (
          <Popup
            popupClass="popup-small"
            title={`Are you sure you want to delete the following ${type}?`}
            isDoubleButton
            btnName="Confirm"
            btnName2="Cancel"
            close={this.toggleOpenPopupDelete}
            handleClick={() => {
              this.toggleOpenPopupDelete();
              deleteItem(item);
            }}
          >
            <div className="box-single-item">
              {type === "module" ? item.moduleLabel : item.clusterLabel}
            </div>
          </Popup>
        )}
        {this.state.warningPopupOpen && (
          <Popup
            popupClass="popup-medium"
            title="Warning"
            close={this.toggleClickWarning}
          >
            {rpasErrors.includes(";") ? (
              rpasErrors.split(";").map((error, i) => {
                return <p key={i.toString()}>{error}</p>;
              })
            ) : (
              <p>{rpasErrors}</p>
            )}
          </Popup>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPopupUnlockOpen: state.module.isPopupUnlockOpen,
    auth: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    togglePopupUnlock: (value, item, type) =>
      dispatch(actions.togglePopupUnlock(value, item, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItemHome);
