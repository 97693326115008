import { useEffect, useState } from "react";

import Button from "../UI-components/Button";
import { isFunction } from "lodash";

const emptyRowModel = {
   cluster: [""],
   add: [""],
   remove: [""],
   addLia: [""],
   removeLia: [""],
   month: [""],
};

const initialState = {
   cluster: [""],
   add: [""],
   remove: [""],
   addLia: [""],
   removeLia: [""],
   month: [""],
};

const MassiveEditClusterWITable = ({ onEditRow, resetTableData }) => {
   const [inputRows, setInputRows] = useState([initialState]);

   useEffect(() => {
      setInputRows([
         {
            cluster: [""],
            add: [""],
            remove: [""],
            addLia: [""],
            removeLia: [""],
            month: [""],
         },
      ]);
   }, []);

   useEffect(() => {
      if (resetTableData) {
         setInputRows([
            {
               cluster: [""],
               add: [""],
               remove: [""],
               addLia: [""],
               removeLia: [""],
               month: [""],
            },
         ]);
      }
   }, [resetTableData]);

   const checkIsEmpty = () => {
      const isEmpty =
         inputRows.length >= 1 &&
         JSON.stringify(inputRows[0]) === JSON.stringify(emptyRowModel);
      return isEmpty;
   };

   const checkIfModuleTable = () => {
      return false;
   };

   const handleChange = (selectType, optionsSelected, index) => {
      const mapped = inputRows.map((row, rowIndex) => {
         if (rowIndex === index) {
            row[selectType] = [optionsSelected];
         }
         return row;
      });
      setInputRows(mapped);
   };

   const addRow = () => {
      setInputRows((prevState) => [
         {
            cluster: [""],
            add: [""],
            remove: [""],
            addLia: [""],
            removeLia: [""],
            month: [""],
         },
         ...prevState,
      ]);
   };

   const removeRow = (index) => {
      setInputRows((prevState) => {
         const x = prevState.filter(
            (p, i) => prevState.length === 1 || i !== index
         );

         if (x.length === 1 && prevState.length === 1) {
            return [
               {
                  cluster: [""],
                  add: [""],
                  remove: [""],
                  addLia: [""],
                  removeLia: [""],
                  month: [""],
               },
            ];
         }
         return x;
      });
   };

   useEffect(() => {
      if (isFunction(onEditRow))
         onEditRow(
            inputRows,
            checkIsEmpty(),
            checkIfModuleTable(),
            !disableCtasCluster()
         );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputRows]);

   const disableCtasCluster = () => {
      const mappedmonthAndClustersFromRows =
         inputRows &&
         inputRows.map((row) => {
            const monthAndClusterNotEmpty =
               row.month.includes("") || row.cluster.includes("");

            const checkAlmostOneNotEmpty =
               row.add.includes("") &&
               row.addLia.includes("") &&
               row.remove.includes("") &&
               row.removeLia.includes("");

            return {
               monthAndClusterNotEmpty,
               checkAlmostOneNotEmpty,
            };
         });

      const result = mappedmonthAndClustersFromRows.every((value) => {
         return !value.checkAlmostOneNotEmpty && !value.monthAndClusterNotEmpty;
      });

      return result;
   };

   const MassiveEditWITableGeneratedRows = () => {
      return inputRows.map((row, i) => {
         return (
            <tr key={i}>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your cluster..."
                     onChange={(event) =>
                        handleChange("cluster", event.target.value, i)
                     }
                     value={row.cluster}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your modules..."
                     onChange={(event) =>
                        handleChange("add", event.target.value, i)
                     }
                     value={row.add}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your modules..."
                     onChange={(event) =>
                        handleChange("remove", event.target.value, i)
                     }
                     value={row.remove}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your Lia..."
                     onChange={(event) =>
                        handleChange("addLia", event.target.value, i)
                     }
                     value={row.addLia}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your Lia..."
                     onChange={(event) =>
                        handleChange("removeLia", event.target.value, i)
                     }
                     value={row.removeLia}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your month..."
                     value={row.month}
                     onChange={(event) =>
                        handleChange("month", event.target.value, i)
                     }
                  />
               </td>
               <td>
                  {i === 0 ? (
                     <div style={{ display: "flex" }}>
                        <Button handleClick={addRow}>+</Button>
                        <Button handleClick={() => removeRow(i)}>-</Button>
                     </div>
                  ) : (
                     <div style={{ display: "flex" }}>
                        <Button btnClass="hideBtn" handleClick={() => {}}>
                           +
                        </Button>
                        <Button handleClick={() => removeRow(i)}>-</Button>
                     </div>
                  )}
               </td>
            </tr>
         );
      });
   };

   return (
      <table className="massive-edit-module-table">
         <thead>
            <tr>
               <th>CLUSTER</th>
               <th>ADD MODULE</th>
               <th>REMOVE MODULE</th>
               <th>ADD LIA</th>
               <th>REMOVE LIA</th>
               <th>MONTH</th>
            </tr>
         </thead>
         <tbody>{MassiveEditWITableGeneratedRows()}</tbody>
      </table>
   );
};

export default MassiveEditClusterWITable;
