import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../services/Api";

const initialState = {
   isSegmentationView: false,
};

const setIsSegmentationView = (state, action) => {
   return updateObject(state, {
      isSegmentationView: action.isSegmentationView,
   });
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case actionTypes.SET_IS_SEGMENTATION_VIEW:
         return setIsSegmentationView(state, action);

      default:
         return state;
   }
};

export default reducer;
