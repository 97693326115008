import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/Api';

const initialState = {
   filters: null,
   results: null,
   isLoaderShown: false
};

const getFilters = (state, action) => {
    return updateObject(state, { filters: action.filters});
};

const getClusterByStatsStart = state => {
    return updateObject(state, { isLoaderShown: true});
}

const setStats = (state, action) => {
    return updateObject(state, { results: action.results, isLoaderShown: false});
}

const resetSearchResults = state => {
    return updateObject(state, { results: null});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_FILTERS_STAT: return getFilters(state, action); 
        case actionTypes.SET_STAT: return setStats(state, action);
        case actionTypes.GET_CLUSTER_BY_STATS_START: return getClusterByStatsStart(state);
        case actionTypes.RESET_SEARCH_RESULTS_STATS: return resetSearchResults(state)
        default:
            return state;
    }
};

export default reducer;