/* eslint-disable array-callback-return */

import * as _ from "lodash";
import * as actions from "../../store/actions";

import React, { Component } from "react";
import {
   canCreate,
   formatOptionsArray,
   getMacroInPage,
   isSameSku,
   selectedAllFilters,
} from "../../services/Api";

import AddModule from "./AddModule";
import AddModulePopup from "../Homepage/Popup/AddModulePopup";
import Button from "../UI-components/Button";
import ImportModulePopup from "./ImportModulePopup";
import Loader from "../UI-components/Loader";
import Module from "./Module";
import ModuleActionsFilters from "./ModuleActionsFilter/ModuleActionsFilters";
import PerformanceFilters from "./PerformanceFilters";
import PopupExport from "../UI-components/PopupExport";
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { connect } from "react-redux";

const ordersPossibilities = {
   sellOut: ["sellOut", "pty", "model"],
   pty: ["pty", "sellOut", "model"],
   skuId: ["model", "sellOut", "pty"]
};

class WorkingArea extends Component {
   constructor(props) {
      super(props);

      this.state = {
         isAddModuleOpen: false,
         isImportModuleOpen: false,
         isExportModuleOpen: false,
         macroList: null,

         //CM added for added filters
         skuOrder: ordersPossibilities.sellOut,
         moduleOrder: 'moduleName',
         hideDetailsPhoto: null,
         hideDetailsGC: null,
         filterList: null,
         discontinuation: null,
         highlightList: null,
         dndSort: [],
         filtersModuleExport: {},
         exportClusterPopup: false,
         selectedModuleType: [],
         selectValue: "",
         selectedModules: [],
         selectedClusters: [],
         selectedOption: [],
         template:[],
         typeIndex: 0,
         exportResults: [],
         isExportingAll: false,
         exportFormat: null,
         isExportingSelected: false,
         isAllSelected: false,
         isAllDeselected: true,
         isNoItemSelected: true,
         isCustomSearch: false,
         isComboboxEnabled: false,
         isSelectedTemplate: false,
         isPdfExportSelected: false,
         exportFinished: false,
         el: null,
      };

      this.togglePopup = this.togglePopup.bind(this);
   }
   componentDidMount = () => {
      if (this.props.macroList) {
         let macroList = this.orderModules([...this.props.macroList]);
         this.setState({
            macroList,
         });
      }
   };

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.macroList !== this.props.macroList) {
         let macroList = this.props.macroList;
         this.setState({
            macroList,
         });
      }
      if (prevState.macroList !== this.state.macroList) {
         let macroList = this.state.macroList;
         this.setState({
            macroList,
         });
      }
   }
   redirectToHome = () => {
      this.props.history.push("/");
   };

   togglePopup = (stateName) =>
      this.setState((prevState) => {
         return { [stateName]: !prevState[stateName] };
      });

   handlePopup = () => {
      this.setState({
         exportClusterPopup: !this.state.exportClusterPopup,
         isPdfExportSelected: false,
         exportFormat:null,
      });
   };

   updateMacroList = (moduleI) => {
      let macroList = [...this.props.macroList];
      macroList.map((macro) => {
         macro.moduleList.map((m) => {
            if (m.macroFamily === moduleI.macroFamily) {
               m.products = moduleI.products;
            }
         });
      });

   };

   getMacroInPage = () => {
      if (this.state.macroList && this.state.macroList.length > 0) {
         let macroList = [...this.state.macroList];

         return macroList.map((m) => {
            return m.macroFamily;
         });
      }
   };


   highlightInActiveIfPresent = (sku) => {
      //if activeSku is empty --> do nothing
      if (!this.props.activeSku || this.props.activeSku.length < 1) {
         return;
      }

      //check if the corresponding active sku is present
      const isSkuPresentInActive =
         this.props.activeSku.findIndex((activeSku) => {
            if (isSameSku(activeSku, sku)) return true;
         }) >= 0;

      const macroList = _.cloneDeep(this.state.macroList);

      macroList && macroList.length && macroList.forEach((macro) => {
         macro && macro.moduleList && macro.moduleList.length && macro.moduleList.forEach((mod) => {
            mod && mod.products && mod.products.forEach((prod) => {
               // remove the fade from all skus
               if (prod.isFaded) {
                  prod.isFaded = false;
               }

               //here it is the selected sku to fade if the corresponding active sku is present
               if (isSkuPresentInActive && isSameSku(prod, sku)) {
                  prod.isFaded = true;
               }
            });
         });
      });

      this.setState({
         macroList: macroList,
      });

      this.props.highlightInActive(sku);
   };

   arr = [];

   onModuleChange = (module) => {
      const { flow, partialRight: pr, keyBy, values } = _;

      const lastUniqBy = (iteratee) => flow(pr(keyBy, iteratee), values);

      this.arr.push(module);

      const finalArr = lastUniqBy("moduleLabel")(this.arr);
      const finalArr2 =
         finalArr.length &&
         finalArr.flatMap((el) => {
            return el;
         });
      this.props.setSavablePublishableModules(finalArr2);
   };


   //CM added sort skus
   orderSKUs = (params) => {
      this.setState({
         skuOrder: ordersPossibilities[params] || ordersPossibilities.sellOut
      });

   }

   orderModules = (param) => {
      this.setState({
         moduleOrder: param || 'moduleName',
         dndSort: []
      });
      return param
   }



   filterSku = (value) => {
      console.log(value)
      this.setState({
         filterList: value
      })
   }
   highlightSku = (value) => {

      this.setState({
         highlightList: value
      })
   }

   discontinuationSku = (value) => {
      this.setState({
         discontinuation: value
      })
   }

   getModuleOrder = (modules) => {
      let direction = 'desc';
      if (this.state.moduleOrder === 'moduleName') {
         direction = 'asc';
      } else if (this.state.moduleOrder === 'length') {
         let mustHaveArray = modules.map(m => {
            m.countMustHave = m.productDtoList.filter(p => p.mustHave && p.moduleCompositionStatus.toUpperCase() !== "REMOVED").length;
            return m;
         });
         return _.orderBy(mustHaveArray, 'countMustHave', direction);
      }
      return _.orderBy(modules, this.state.moduleOrder, direction);
   }

   getSkusOrder = (modules) => {
      return modules.map(mod => {
         if (mod) {
            mod.productDtoList = _.orderBy(mod.productDtoList, this.state.skuOrder, ["desc", "desc", "desc"]);
         }
         if (this.state.skuOrder[0] === 'model') {
            mod.productDtoList = _.orderBy(mod.productDtoList, this.state.skuOrder, ["asc", "desc", "desc"]);
         }
         return mod;
      });
   }

   getDiscontinuedSkus = (modules) => {
      return modules.map(mod => {
         if(this.state.discontinuation){
            mod.productDtoList = mod.productDtoList.filter(sku => {
               return sku.flagDisco + '' === this.state.discontinuation
            })
         }
         return mod
      })
   }

   hideDetailsPhoto = (param) => {
      this.setState({
         hideDetailsPhoto: param
      })
   }
   hideDetailsGC = (param) => {
      this.setState({
         hideDetailsGC: param
      })
   }

   getHideDetails = (modules) => {
      return modules.map(mod => {
         if (mod) {
            mod.productDtoList = mod.productDtoList.map((sku) => {
               let customClass = null;
               if (this.state.hideDetailsPhoto === 'PHOTO') {
                  customClass = 'hidePhoto';
               }
               if (this.state.hideDetailsGC === 'GLOBAL CAT.') {
                  customClass = 'hideCatalogue';
               }
               if (this.state.hideDetailsGC === 'GLOBAL CAT.' && this.state.hideDetailsPhoto === 'PHOTO') {
                  customClass = 'hidePhoto hideCatalogue';
               }
               sku.customClass = customClass;
               return sku;
            });
         }
         return mod;
      });
   }

   getFilterList = (modules) => {
      return modules.map(mod => {
         mod.productDtoList = mod.productDtoList.filter(prod => {
            let ret = true;
            if (this.state.filterList) {
               ret = ret && (!this.state.filterList.release || !this.state.filterList.release.length || this.state.filterList.release.map(x => x.value).indexOf(prod.releaseDate) >= 0);
               ret = ret && (!this.state.filterList.modelName || !this.state.filterList.modelName.length || this.state.filterList.modelName.map(x => x.value).indexOf(prod.model) >= 0);

               const currentPrice = parseFloat(prod.srp.split(' ')[0]);
               if (this.state.filterList.minPrice && this.state.filterList.minPrice.length) {
                  const minPrice = parseFloat(this.state.filterList.minPrice);
                  ret = ret && currentPrice >= minPrice;
               }
               if (this.state.filterList.maxPrice && this.state.filterList.maxPrice.length) {
                  const maxPrice = parseFloat(this.state.filterList.maxPrice);
                  ret = ret && currentPrice <= maxPrice;
               }

            }
            return ret;
         });
         return mod;
      })
   }

   getHightLightList = (modules) => {
      return modules.map(mod => {
         mod.productDtoList = mod.productDtoList.map(prod => {

            let isToHighlight = true;
            if (this.state.highlightList) {

               if (this.state.highlightList.modelName && !this.state.highlightList.modelName.length &&
                  !this.state.highlightList.release.length &&
                  !this.state.highlightList.minPrice.length &&
                  !this.state.highlightList.maxPrice.length &&
                  !this.state.highlightList.isDuplicated) {//check has one filter with value
                  return prod;
               }

               isToHighlight = isToHighlight && (!this.state.highlightList.release || !this.state.highlightList.release.length || this.state.highlightList.release.map(x => x.value).indexOf(prod.releaseDate) >= 0);
               isToHighlight = isToHighlight && (!this.state.highlightList.modelName || !this.state.highlightList.modelName.length || this.state.highlightList.modelName.map(x => x.value).indexOf(prod.model) >= 0);

               const currentPrice = parseFloat(prod.srp.split(' ')[0]);
               if (this.state.highlightList.minPrice && this.state.highlightList.minPrice.length) {
                  const minPrice = parseFloat(this.state.highlightList.minPrice);
                  isToHighlight = isToHighlight && currentPrice >= minPrice;
               }
               if (this.state.highlightList.maxPrice && this.state.highlightList.maxPrice.length) {
                  const maxPrice = parseFloat(this.state.highlightList.maxPrice);
                  isToHighlight = isToHighlight && currentPrice <= maxPrice;
               }
               if (this.state.highlightList.isDuplicated) {

                  let findedDuplicated = false;
                  modules.forEach(checkModule => {
                     if (checkModule.moduleName !== mod.moduleName && !findedDuplicated) {
                        findedDuplicated = checkModule.productDtoList.some(cp => cp.upc === prod.upc && cp.moduleCompositionStatus !== 'REMOVED'&& prod.moduleCompositionStatus !== 'REMOVED');
                     }
                  });
                  isToHighlight = isToHighlight && findedDuplicated;
               }


               if (isToHighlight) {
                  prod.customClass = (prod.customClass || '') + ' greenHighlight';
               }
               else{
                  prod.customClass = (prod.customClass || '');
               }

            }
            return prod;
         });
         return mod;
      })
   }

   getDndSort = (modules) => {
      let source = _.cloneDeep(modules);


      if (this.state.dndSort.length) {

         this.state.dndSort.forEach(sort => {
            const element = source.splice(sort.from, 1)[0];
            source.splice(sort.to, 0, element);
         })
      }
      return source;
   }

   getRenderDataForFilter = (moduleList) => {
      let listMapped = _.cloneDeep(moduleList);


      //sort modules
      listMapped = this.getModuleOrder(listMapped);
      //sort skus
      listMapped = this.getSkusOrder(listMapped);
      //hide details
      listMapped = this.getHideDetails(listMapped);
      //highlight sku
      listMapped = this.getHightLightList(listMapped);
      //dndSort
      listMapped = this.getDndSort(listMapped)
      //discontinued
      listMapped = this.getDiscontinuedSkus(listMapped)

      return listMapped;
   }


   onNotifyModuleChanged = (moduleChanged, skuModifiedList) => {
      if (skuModifiedList && skuModifiedList.length) {
         const newMacroList = this.state.macroList.map(mc => {
            mc.moduleList = mc.moduleList?.map(module => {

               if (module?.moduleName === moduleChanged && module.productDtoList) {
                  let list = module.productDtoList;
                  skuModifiedList.forEach(skuModified => {
                     list = list.filter(sku => sku.upc !== skuModified.upc);
                     list.push(skuModified);
                  })
                  module.productDtoList = list;
                  module.isEdited = true;
               }
               return module;
            })
            return mc;
         });
         this.setState({
            macroList: newMacroList
         })
      }

   }

   setExportFinished = () => {
      this.setState({
         exportFinished: !this.state.exportFinished,
         // isAllSelected: false,
         // isAllDeselected: false,
         // selectedModules: [],
         isNoItemSelected: true,
      });
   };
  
   setComboboxEnabled = () => {
      this.setState({
         isComboboxEnabled: true,
      });
   };
  
   setPdfExportEnabled = () => {
      this.setState({
         isPdfExportSelected: true
      });
   };
  
   setExportFormat = (value) => {
      this.setState({
         exportFormat: value,
      });
   };
  
   handleSelectChange = (e) => {
      this.setState({
         isSelectedTemplate: true
      });
      this.setState({
         template: e.value
      });
   };
  
   setIsExportingAll = () => {
      this.setState({
         isExportingAll: true,
      });
   }
  

   exportAll = (exportFormat, template) => {
      let photo = false;
      if (exportFormat === "xls/photo" || exportFormat === "xls/photo_massive") {
         photo = true;
      }
      const {macroList,filtersModuleExport} =this.state;
      let {username } = this.props;
      const macroModuleList=macroList.map((e) => e.moduleList).flatMap(module => module);//da correg
      if (macroModuleList){  
         this.togglePopup("isExportModuleOpen");
         const selectedFilters = selectedAllFilters(macroModuleList,filtersModuleExport);
         this.props.exportModules(
            macroModuleList,
            selectedFilters,
            ["published","saved"],
            exportFormat,
            template,
            photo,
            username,
         );
      }
   };

   changeListFilters=(type, values)=>{
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.filtersModuleExport[type] = values;
   }

   //CM end

   render() {
      let { macroList, isAddModuleOpen, isImportModuleOpen, isExportModuleOpen } = this.state;
      let { isMacroListEmpty, loading } = this.props;
      let macroInPage = getMacroInPage(macroList);
      let isEditable = !this.props.isReleaseClosed;
      let moduleList = null;
      let templates = this.props.templates;
      let type = "modulo";
      if (macroList) {
         moduleList = [];
         macroList.forEach(macro => {
            if (macro.moduleList) {
               macro.moduleList.forEach(module => {
                  moduleList.push(module)
               })
            }
         })
      }
      const canCreateModule =
         this.props.auth.grants &&
         canCreate(this.props.auth.grants, this.props.filters.brand);


      const dragProps = {
         onDragEnd: (fromIndex, toIndex) => {
            if (fromIndex != null && toIndex != null && fromIndex >= 0 && toIndex >= 0) {
               this.setState({
                  dndSort: [...this.state.dndSort, { from: fromIndex, to: toIndex }]
               });
            }
         },
         nodeSelector: '.modulo-outline',
         handleSelector: '.title-modulo',
         lineClassName: 'modulo-droppable-order-line'
      };

      

      return (
         <div className={"working-area component leftbar-sibling " +
            (!this.state.openedModuleFilters ? 'paddingalign' : '')}>
            <header>
               <div className="col-4">
                  <h2> Working Area </h2>
               </div>

               {isEditable && (
                  <AddModule
                     addModuleHandler={() => {
                        this.togglePopup("isAddModuleOpen");
                     }}
                     importModuleHandler={() => {
                        this.togglePopup("isImportModuleOpen");
                     }}
                     exportModuleHandler={() => {
                        this.exportAll();
                     }}
                     isImportActive={!!this.props.moduleListResult}
                     isExportActive={!!this.props.moduleListResult}
                     disabled={!this.props.macroList || !canCreateModule}
                     exportClusterPopup={this.handlePopup}
                  />
               )}
            </header>
            <div className="macro-content">
               <PerformanceFilters
                  changeFilters={this.changeListFilters}
               />
               <ModuleActionsFilters
                  filterSku={this.filterSku}
                  highlightSku={this.highlightSku}
                  discontinuationSku={this.discontinuationSku}
                  orderSKUs={this.orderSKUs}
                  orderModules={this.orderModules}
                  hideDetailsPhoto={this.hideDetailsPhoto}
                  hideDetailsGC={this.hideDetailsGC}
                  moduleList={moduleList}
                  onToggle={(e) => {
                     this.setState({ openedModuleFilters: e })
                  }}
               />
               {!loading && !this.props.isLoading ? (
                  <>
                     <div
                        className={
                           "macro-list-container" +
                           (this.props.ibicFilters ? " with-filters" : "") +
                           (this.state.openedModuleFilters ? " with-filters_open" : "")
                        }
                     >

                        {moduleList && moduleList.length > 0
                           ? /* macroList.map((singleMacro) => */ (
                              <div
                                 className="macro-list-area"
                              //key={singleMacro.macroFamily}
                              >
                                 {/* <h3>{singleMacro.macroFamily}</h3> */}

                                 <ReactDragListView {...dragProps}>
                                    <div className="module-list-area">

                                       {this.getRenderDataForFilter(moduleList).map((singleItem) => (

                                          < Module
                                             onModuleChange={this.onModuleChange}
                                             moduleListLength={
                                                moduleList.length
                                             }
                                             key={singleItem.moduleName}
                                             moduleComponent={singleItem}
                                             singleMacro={singleItem.macroFamily}
                                             updateMacroList={this.updateMacroList}
                                             highlightInActiveIfPresent={
                                                this.highlightInActiveIfPresent
                                             }
                                             setDiffLength={this.setDiffLength}
                                             diffPrevMonthLength={this.state.diffPrevMonthLength}

                                             notifyChanges={this.onNotifyModuleChanged}
                                             addedFilters={this.state.filterList}
                                          //macroList={macroList}

                                          />


                                       ))}

                                       {moduleList.length === 0 && (
                                          <p className="no-modules">
                                             There are no modules {/* in this
                                          macrofamily */}. Create or import a module
                                             and save your changes.
                                             <Button
                                                btnClass="btn-medium"
                                                handleClick={() =>
                                                   this.togglePopup(
                                                      "isAddModuleOpen"
                                                   )
                                                }
                                             >
                                                Create Module
                                             </Button>
                                          </p>
                                       )}
                                    </div>
                                 </ReactDragListView>
                              </div >
                           )
                           : isMacroListEmpty && (
                              <p className="message-no-module">
                                 We couldn't find the module you are looking for.
                                 There may have been a technical problem.
                              </p>
                           )
                        }
                     </div>
                  </>
               ) : (
                  <Loader />
               )}
            </div >
            {isAddModuleOpen && (
               <AddModulePopup
                  togglePopup={() => this.togglePopup("isAddModuleOpen")}
                  setNewModule={(newMod) =>
                     this.props.addNewModule(newMod, this.props.macroList)
                  }
                  redirect={() => this.togglePopup("isAddModuleOpen")}
                  macroList={formatOptionsArray(macroInPage)}
                  getOptions={this.props.getOptions}
                  isNewMacro
                  newMacro={this.props.macroList[0].macroFamily}
                  {...this.props.filters}
               />
            )}
            {
               isImportModuleOpen && (
                  <ImportModulePopup
                     close={() => this.togglePopup("isImportModuleOpen")}
                     moduleListResult={this.props.moduleListResult}
                     importModules={this.props.importModules}
                     macroList={macroList}
                  />
               )
            }
            {
               isExportModuleOpen && this.props.AddModule
            }
            {this.state.exportClusterPopup ? (
                  <PopupExport
                     popupClass="popup-large"
                     title="Export"
                     subtitle="Which file do you want to export?"
                     close={this.handlePopup}
                     isDoubleButton
                     isTripleButton
                     isQuadrupleButton
                     isExportButton
                     isComboboxEnabled={this.state.isComboboxEnabled}
                     isPdfExportSelected={this.state.isPdfExportSelected}
                     isSelectedTemplate={this.state.isSelectedTemplate}
                     templates={templates}
                     //options={options}
                     setTemplate={this.setTemplate}
                     btnName="XLS"
                     btnName2="XLS PHOTO"
                     btnName3="PDF"
                     btnName4="EXPORT"
                     btnClass="btn-small"
                     exportClusterPopup
                     exportFormat={this.state.exportFormat}
                     maxHeightCustomSelectMenu="150px"
                     handleClick={() => {

                        this.setComboboxEnabled();
                        this.props.getTemplate(type);
                        if (moduleList.length > 1) {
                           this.setExportFormat("xls_massive");
                        } else {
                           this.setExportFormat("xls");
                        }
                     }}
                     handleClickSecondary={() => {
                        this.setComboboxEnabled();
                        this.props.getTemplate(type);
                        if (moduleList.length > 1) {
                           this.setExportFormat("xls/photo_massive");
                        } else {
                           this.setExportFormat("xls/photo");
                        }
                     }}
                     handleClickTertiary={() => {
                        this.setPdfExportEnabled();
                        if (moduleList.length > 1) {
                           this.setExportFormat("pdf_massive");
                        } else {
                           this.setExportFormat("pdf");
                        }
                     }}
                     handleClickQuartiary={() => {
                     switch (this.state.exportFormat) {
                        case "xls":
                           this.exportAll("xls", this.state.template);
                           break;
                        case "xls/photo":
                           this.exportAll("xls/photo", this.state.template);
                           break;
                        case "pdf":
                           this.exportAll("pdf");
                           break;
                        case "xls_massive":
                              this.exportAll("xls_massive", this.state.template);
                           break;
                        case "xls/photo_massive":
                              this.exportAll("xls/photo_massive", this.state.template);
                           break;
                        case "pdf_massive":
                              this.exportAll("pdf_massive");
                           break;
                        default:
                     }
                     this.handlePopup();
                     this.setExportFinished();
                     }}
                     handleClickTemplate={(e) => {
                            this.handleSelectChange(e);
                        }}
                  />
               ) : null}
         </div >
      )
   }
}


const mapStateToProps = (state) => {
   return {
      filters: state.filter.selectedFilters,
      macroList: state.module.macroList,
      isGetByMacro: state.module.isGetByMacro,
      isNewModule: state.module.isNewModule,
      isNewMacro: state.module.isNewMacro,
      isReleaseClosed: state.release.isReleaseClosed,
      lockModule: state.user.lockModule,
      lockMacro: state.user.lockMacro,
      loading: state.module.loading,
      isMacroListEmpty: state.module.isMacroListEmpty,
      ibicFilters: state.module.ibicFilters,
      activeSku: state.module.activeSku,
      auth: state.authentication,
      templates: state.cluster.templates,
      username: state.authentication.user.user_name,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      addNewModule: (newMod, macroList) =>
         dispatch(actions.addNewModule(newMod, macroList)),
      getOptions: () => dispatch(actions.getAllMacroFamily()),
      getWorkingModule: (params) => dispatch(actions.getWorkingModule(params)),
      // getWorkingModuleSuccess: (params) => dispatch(actions.getWorkingModuleSuccess(params)),
      getWorkingModuleMacro: (filters, macro) =>
         dispatch(actions.getWorkingModuleMacro(filters, macro)),
      importModules: (family, modulesToImport) =>
         dispatch(actions.importModules(family, modulesToImport)),
      exportModules: (modules, selectedFilters, status, type, template, photo,userName) =>
         dispatch(actions.exportModules(modules, selectedFilters, status, type, template, photo,userName)),
      updateMacroList: (newMacro, moduleI) =>
         dispatch(actions.updateMacroList(newMacro, moduleI)),
      clearModuleState: () => dispatch(actions.clearModuleState()),
      highlightInActive: (sku) => dispatch(actions.highlightInActive(sku)),
      setSavablePublishableModules: (savablePublishableModules) =>
         dispatch(
            actions.setSavablePublishableModules(savablePublishableModules)
         ),
      getTemplate: (type) =>
         dispatch(actions.getTemplate(type)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkingArea);
