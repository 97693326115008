import React, { Component } from "react";
import { connect } from "react-redux";
import { getCookie } from "../../services/cookie";
import * as actions from "../../store/actions";
import Button from "../UI-components/Button";
import Logo from "../UI-components/Logo";
import Loader from "../UI-components/Loader";

class LoginPage extends Component {
   state = {
      gotoPage: "",
      password: "",
      username: "",
   };

   componentDidMount = () => {
      if (this.props.user && this.props.isAuthenticated) {
         this.redirectToHome();
      } else {
         let token = getCookie("xAuthLuxotticaToken");
         if (token) this.props.getInfoUserFromToken();
         else this.props.logout();
      }
   };

   componentDidUpdate = (prevProps) => {
      if (prevProps.user !== this.props.user && !this.props.user) {
         let token = getCookie("xAuthLuxotticaToken");
         if (token) this.props.getInfoUserFromToken();
      }
      if (
         prevProps.user !== this.props.user &&
         this.props.isAuthenticated === true
      ) {
         this.redirectToHome();
      }
   };

   redirectToHome = () => {
      this.props.history.push("/");
   };

   handleUsernameChange = (e) => {
      let value = e.target.value;
      this.setState({
         username: value,
      });
   };

   handlePasswordChange = (e) => {
      let value = e.target.value;
      this.props.resetError();
      this.setState({
         password: value,
      });
   };

   handleSubmit = (e) => {
      e.preventDefault();
      this.props.resetError();
      if (this.state.username && this.state.password) {
         this.props.getAuthentication(this.state.username, this.state.password);
      }
   };

   render() {
      let loader = null;
      if (this.props.loading) {
         loader = <Loader />;
      }

      return (
         <div className="login-container">
            <div className="content-login row no-gutters">
               <div className="col-md-6 col-12-ipad col-12 half-page">
                  <div className="logo-container">
                     <Logo />
                     <h1>Stars Assortment</h1>
                     <h3>Welcome to your assortment personalization tool</h3>
                  </div>
               </div>
               <div className="col-md-6 col-12-ipad col-12 half-page">
                  <form className="form-container" onSubmit={this.handleSubmit}>
                     <h2>Login</h2>
                     <div className="input-container">
                        <label>Username</label>
                        <input
                           type="text"
                           className="input-style"
                           name="username"
                           value={this.state.username}
                           onChange={this.handleUsernameChange}
                           autoComplete="off"
                        />
                     </div>
                     <div className="input-container">
                        <label>Password</label>
                        <input
                           type="password"
                           className="input-style"
                           value={this.state.password}
                           onChange={this.handlePasswordChange}
                        />
                        <Button
                           btnClass="button btn-medium"
                           disabled={
                              this.state.password === "" ||
                              this.state.username === ""
                           }
                        >
                           Login
                        </Button>
                     </div>
                     {this.props.error ? (
                        <p className="msg-error">{this.props.error}</p>
                     ) : null}
                     {loader}
                  </form>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      loading: state.authentication.loading,
      user: state.authentication.user,
      error: state.authentication.error,
      isAuthenticated: state.authentication.isAuthenticated,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getAuthentication: (username, password) =>
         dispatch(actions.getAuthentication(username, password)),
      resetError: () => dispatch(actions.resetError()),
      getInfoUserFromToken: () => dispatch(actions.getInfoUserFromToken()),
      logout: () => dispatch(actions.logout()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
