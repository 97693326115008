import './DiscontinuationPanel.scss'

import React, { useEffect, useState } from "react"

import Button from '../../UI-components/Button'
import CustomSelect from '../../UI-components/CustomSelect'

const discontinuationOptions = [
    {
        label: 'YES',
        value: 'true'
    },
    {
        label: 'NO',
        value: 'false'
    }
]

const DiscontinuationPanel = ({
    filterSku,
    title
}) => {

    const [filters, setFilters] = useState()
    const [discontinuation, setDiscontinuation] = useState(null)

    useEffect(() => {
        setFilters(discontinuation?.value)
    }, [discontinuation])

    const handleChangeDiscontinuation = (e) => {
        setDiscontinuation(e)
    }

    const handleReset = () => {
        setDiscontinuation(null)
        const resetFilters = null
        filterSku(resetFilters)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        filterSku(filters)
    }

    return (
        <div className='filterPanel__section'>
            <form onSubmit={handleSubmit}>
                <div className='filterPanel__firstDiv'>
                    {title}
                </div>
                <CustomSelect
                    selectClass='zIndex402'
                    placeholder={!discontinuation ? "Discontinuation" : discontinuation.label}
                    options={discontinuationOptions}
                    handleChange={e => {
                        handleChangeDiscontinuation(e)
                    }}
                    value={!discontinuation ? "Discontinuation" : discontinuation.label}
                />
                <div className={'filterPanel__buttonSection '}>
                    <Button type='button' handleClick={handleReset} btnClass='filterPanel__reset'>RESET</Button>
                    <Button type="submit" handleClick={() => filterSku(filters)} btnClass='filterPanel__btn'>APPLY</Button>
                </div>
            </form>
        </div>
    )
}

export default DiscontinuationPanel