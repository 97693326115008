import React from "react";
import { Link } from "react-router-dom";

const linkedButton = (props) => {
   return (
      <Link
         className={props.btnContainerClass ? props.btnContainerClass : ""}
         to={props.link}
         onClick={props.onClick}
      >
         <button
            disabled={props.disabled}
            className={
               "button " + props.btnClass + (props.disabled ? " disabled" : "")
            }
         >
            {props.children}
         </button>
      </Link>
   );
};
export default linkedButton;
