import React from "react";
import Popup from "../../UI-components/Popup";
import CustomInput from "../../UI-components/CustomInput";

const addMacroPopup = (props) => {
	let checkDisabled = props.macroFamily !== "";

	return (
		<Popup
			popupClass="overflow-visible popup-small"
			close={() => props.togglePopup("isAddMacroOpen")}
			title="Create a new family"
			btnName="Create"
			disabled={!checkDisabled}
			handleClick={props.handleCreateMacro}
		>
			<div className="row no-gutters section">
				<div className="section-content">
					<div className="col-12">
						<CustomInput
							inputClass="input-big mt-3"
							placeholder="Macrofamily Name"
							handleChange={(e) => props.handleChangeFamily(e)}
							value={props.macroFamily}
							maxLength="100"
						/>
					</div>
				</div>
			</div>
		</Popup>
	);
};
export default addMacroPopup;
