import { combineReducers } from "redux";

import filterReducer from "./filterReducer";
import clusterReducer from "./clusterReducer";
import moduleReducer from "./moduleReducer";
import statisticsReducer from "./statisticsReducer";
import releaseReducer from "./releaseReducer";
import userReducer from "./userReducer";
import authenticationReducer from "./authenticationReducer";
import errorReducer from "./errorReducer";
import uploadReducer from "./uploadReducer";
import performancePopupReducer from "./PerformancePopupReducer";

const rootReducer = combineReducers({
   filter: filterReducer,
   cluster: clusterReducer,
   release: releaseReducer,
   module: moduleReducer,
   statistics: statisticsReducer,
   user: userReducer,
   authentication: authenticationReducer,
   error: errorReducer,
   upload: uploadReducer,
   performancePopup: performancePopupReducer,
});

export default rootReducer;
