import * as actions from "../../store/actions";

import React, { Component } from "react";
import {
   exportFilters,
   exportStatus,
   exportType,
   moduleType,
} from "../../services/constList";

import Button from "../UI-components/Button";
import Checkbox from "../UI-components/Checkbox";
import CustomSearch from "../UI-components/CustomSearch";
import ExportItem from "./ExportItem";
import LinkedButton from "../UI-components/LinkedButton";
import Loader from "../UI-components/Loader";
import Logo from "../UI-components/Logo";
import { PaginatedSelect } from "../UI-components/PaginatedSelect";
import PopupExport from "../UI-components/PopupExport";
import PopupOpenSelect from "../UI-components/PopupOpenSelect";
import RadioGrp from "../UI-components/RadioGroup";
import _ from "lodash";
import { connect } from "react-redux";
import { getValueCheckbox } from "../../services/Api";
import star from "../../img/Vector.png";

class ExportData extends Component {
   constructor(props) {
      super(props);

      this.state = {
         typeOptions: [exportType.MODULE, exportType.CLUSTER],
         statusOptions: [exportStatus.PUBLISHED, exportStatus.SAVED],
         moduleTypeOptions: [
            moduleType.SWITCH,
            moduleType.NOT_SWITCH,
            moduleType.ALTERNATIVE,
         ],
         selectedStatus: [exportStatus.PUBLISHED],
         selectedModuleType: [],
         selectValue: "",
         selectedModules: [],
         selectedClusters: [],
         selectedOption: [],
         template:[],
         typeIndex: 0,
         exportResults: [],
         isExportingAll: false,
         exportFormat: null,
         isExportingSelected: false,
         isNoItemSelected: true,
         isCustomSearch: false,
         isComboboxEnabled: false,
         isSelectedTemplate: false,
         isPdfExportSelected: false,
         exportFinished: false,
         exportClusterPopup: false,
         el: null,
         reloadingItem: true,
         openSelectedPopup:false,
      };
   }

   componentDidMount = () => {
      document.addEventListener("keyup", this.enterSearch);

      if (!this.props.isGAJustInizialized)
         this.props.ReactGA.pageview(
            window.location.pathname + window.location.search
         );
      // if (this.props.releaseList.length === 0) this.props.getReleaseList();

      this.getOptSelectOnStart();
      
      console.log(this.props.selectedFilters)
   };

   getOptSelectOnStart = () => {
      this.props.getFiltersExport();
   };

   componentDidUpdate = (prevProps) => {
      if (prevProps.exportResults !== this.props.exportResults) {
         this.setState({
            exportResults: JSON.parse(JSON.stringify(this.props.exportResults)),
         });
      }
   };

   componentWillUnmount = () => {
      this.props.cleanExportFilters();
      this.props.cleanSelectedExportFilters();
      this.props.resetExportSearch();
      document.removeEventListener("keyup", this.enterSearch);
   };

   enterSearch = (event) => {
      //Check if the button pressed is enter and if the button search is enabled
      // const { selectedStatus } = this.state;
      // const { selectedFilters } = this.props;

      // let isMonthEmpty =
      //    selectedFilters.monthId &&
      //    selectedFilters.monthId.constructor !== Object;

      const isMonthEmpty =
         this.props.selectedFilters.monthId &&
         this.props.selectedFilters.monthId.length === 0;

      // let isPLorCountryEmpty = !(
      //    !isMonthEmpty &&
      //    (selectedFilters.planningChannel.length !== 0 ||
      //       selectedFilters.country.length !== 0)
      // );

      if (event.keyCode === 13 && !this.props.isFiltersExportLoading) {
         // if (
         //    !(
         //       selectedStatus.length === 0 ||
         //       selectedFilters.month === "" ||
         //       isMonthEmpty ||
         //       isPLorCountryEmpty ||
         //       this.noOtherFilterSelected()
         //    )
         // ) {
         if (!isMonthEmpty) {
            event.preventDefault();
            this.handleSearchFilter();
         }

         // }
      }
   };

   search = (e) => {
      let { typeIndex, typeOptions } = this.state;
      let value = e.currentTarget.value;
      if (value.length > 0) {
         this.setState({isCustomSearch:true});
      } else {
         this.setState({isCustomSearch:false});
      }

      if (this.props.exportResults.length > 0) {
         if (typeOptions[typeIndex] === exportType.MODULE) {
            let exportResults = this.props.exportResults.filter((result) => {
               return result.moduleLabel
                  .toLowerCase()
                  .includes(value.toLowerCase());
            });

            this.setState({
               exportResults,
            });
         } else {
            let exportResults = this.props.exportResults.filter((result) => {
               return result.clusterLabel
                  .toLowerCase()
                  .includes(value.toLowerCase());
            });

            this.setState({
               exportResults,
            });
         }
      }
   };

   togglePopup = () => {
      this.setState({
         exportClusterPopup: !this.state.exportClusterPopup,
         isSelectedTemplate: false,
         isPdfExportSelected: false,
         exportFormat:null,
      });
   };

   setExportFinished = () => {
      this.setState({
         exportFinished: !this.state.exportFinished,
         el: null,
         selectedModules: [],
         selectedClusters: [],
         isNoItemSelected: true,
      });
   };
   setModuleListSelect = (list) => {
      this.setState({
         selectedModules: list,
      });
   };
   setClusterListSelect = (list) => {
      this.setState({
         selectedClusters: list,
      });
   };

   setComboboxEnabled = () => {
      this.setState({
         isComboboxEnabled: true,
      });
   };

   setSelectedOption = () => {
      this.setState({
         selectedOption: ["A","B"],
      });
   };

   setPdfExportEnabled = () => {
      this.setState({
         isPdfExportSelected: true
      });
   };

   setExportFormat = (value) => {
      this.setState({
         exportFormat: value,
      });
   };

   handleSelectChange = (e) => {
      this.setState({
         isSelectedTemplate: true
      });
      this.setState({
         template: e.value
      });
   };


   exportEl = (value) => {
      this.setState({
         el: value,
      });
   };

   setFilters = (type, opt) => {
      let { typeIndex, typeOptions } = this.state;
      this.props.setExportSelectedFilters(
         type,
         opt,
         typeOptions[typeIndex],
         false
      );
   };

   setType = (type, i) => {
      this.setState(
         {
            typeIndex: i,
         },
         () => {
            this.props.cleanSelectedExportFilters();
            this.props.resetExportSearch();
         }
      );
   };

   setStatus = (value, isChecked) => {
      let selectedStatus = getValueCheckbox(
         value,
         isChecked,
         this.state.selectedStatus
      );

      this.setState({
         selectedStatus,
      });
   };

   setModuleType = (value, isChecked) => {
      let selectedModuleType = getValueCheckbox(
         value,
         isChecked,
         this.state.selectedModuleType
      );

      this.setState({
         selectedModuleType,
      });
   };

   handleSearchFilter = () => {
      let { typeIndex, typeOptions, selectedStatus, selectedModuleType } =
         this.state;
      let { selectedFilters } = this.props;
      this.props.getExportSearchResults(
         typeOptions[typeIndex],
         selectedFilters,
         selectedStatus,
         selectedModuleType
      );
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.selectedModules = [];
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.selectedClusters = [];
      let type;
      if (typeOptions[typeIndex] === "module") {
         type = "modulo";
      } else {
         type = typeOptions[typeIndex];
      }
      this.props.getTemplate(type);
   };

   exportOne = (el, selectedFilters, exportFormat, template) => {
      let { typeIndex, typeOptions, selectedStatus } =
         this.state;
         let {username } = this.props;
      let photo = false;
      if (exportFormat === "xls/photo" || exportFormat === "xls/photo_massive") {
         photo = true;
      }

      this.setState({
         isExportingAll: false,
      });

      if (typeOptions[typeIndex] === exportType.MODULE) {
         this.props.exportModules(
            [el],
            selectedFilters,
            selectedStatus,
            //selectedModuleType,
            exportFormat,
            template,
            photo,
            username
         );
      } else {
         this.props.exportCluster([el], selectedFilters, selectedStatus, exportFormat, template, photo,username);
      }
   };

   exportAll = (exportFormat, template) => {
      let { typeIndex, typeOptions, selectedStatus } =
         this.state;
         let {username } = this.props;

      let photo = false;
      if (exportFormat === "xls/photo" || exportFormat === "xls/photo_massive") {
         photo = true;
      }

      if (typeOptions[typeIndex] === exportType.MODULE) {
         if (this.state.isExportingSelected) {
            this.props.exportModules(
               this.state.selectedModules,
               this.props.selectedFilters,
               selectedStatus,
               //selectedModuleType,
               exportFormat,
               template,
               photo,
               username
            );
         } else {
            this.props.exportModules(
               this.props.exportResults,
               this.props.selectedFilters,
               selectedStatus,
               //selectedModuleType,
               exportFormat,
               template,
               photo,
               username
            );
         }
         this.setState({
            isExportingSelected: false,
            isExportingAll: false,
         });
      } else {
         if (this.state.isExportingSelected) {
            this.props.exportCluster(
               this.state.selectedClusters,
               this.props.selectedFilters,
               selectedStatus,
               //selectedModuleType,
               exportFormat,
               template,
               photo,
               username
            );
         } else {
            this.props.exportCluster(
               this.props.exportResults,
               this.props.selectedFilters,
               selectedStatus,
               //selectedModuleType,
               exportFormat,
               template,
               photo,
               username
            );
         }
         this.setState({
            isExportingSelected: false,
            isExportingAll: false,
         });
      }
   };

   selectAll = () => {
      if(this.state.typeIndex === 0){
         let toDeselect = true;
         let selected = this.state.selectedModules;
         this.state.exportResults.forEach((expResult) => {
            if(!selected || selected.length === 0) {
               toDeselect = false;
               return;
            } else {
               let found = selected.find((singleSelected)=> singleSelected.moduleName === expResult.moduleName && singleSelected.monthId === expResult.monthId);
               if(!found){
                  toDeselect = false;
                  return;
               }
            }
         });
         if(toDeselect){
            this.state.exportResults.forEach((expResult) => {
               let found = this.state.selectedModules.findIndex((singleSelected)=> singleSelected.moduleName === expResult.moduleName && singleSelected.monthId === expResult.monthId);
               if(found!==-1) {
                  this.state.selectedModules.splice(found,1);
               }
            })
         } else {
            this.state.exportResults.forEach((exportSingleItem)=>{
               let foundItem = this.state.selectedModules.filter((item) => item.moduleName === exportSingleItem.moduleName && item.monthId === exportSingleItem.monthId);
               if(foundItem && foundItem.length === 0){
                  this.state.selectedModules.push(exportSingleItem);
               }
            })
         }
      } else {
         let toDeselect = true;
         let selected = this.state.selectedClusters;
         this.state.exportResults.forEach((expResult) => {
            if(!selected || selected.length === 0) {
               toDeselect = false;
               return;
            } else {
               let found = selected.find((singleSelected)=> singleSelected.clusterId === expResult.clusterId && singleSelected.monthId === expResult.monthId);
               if(!found){
                  toDeselect = false;
                  return;
               }
            }
         });
         if(toDeselect){
            this.state.exportResults.forEach((expResult) => {
               let found = this.state.selectedClusters.findIndex((singleSelected)=> singleSelected.clusterId === expResult.clusterId && singleSelected.monthId === expResult.monthId);
               if(found!==-1) {
                  this.state.selectedClusters.splice(found,1);
               }
            })
         } else {
            this.state.exportResults.forEach((exportSingleItem)=>{
               let foundItem = this.state.selectedClusters.filter((item) => item.clusterId === exportSingleItem.clusterId && item.monthId === exportSingleItem.monthId);
               if(foundItem.length === 0){
                  this.state.selectedClusters.push(exportSingleItem);
               }
            })
         }
      }
      this.forceUpdate();
   }

   selectItem = (singleItem, type) => {
      if(type === "module"){
         let foundItem = this.state.selectedModules.filter((item) => item.moduleName === singleItem.moduleName && item.monthId === singleItem.monthId);
         if(foundItem.length === 0){
            this.state.selectedModules.push(singleItem);
         } else {
            let index = this.state.selectedModules.findIndex((item) => item.moduleName === singleItem.moduleName && item.monthId === singleItem.monthId);
            this.state.selectedModules.splice(index,1);
         }
         this.setState({
            selectedModules:this.state.selectedModules
         });
      } else {
         let foundItem = this.state.selectedClusters.filter((item) => item.clusterId === singleItem.clusterId && item.monthId === singleItem.monthId);
         if(foundItem.length === 0){
            this.state.selectedClusters.push(singleItem);
         } else {
            let index = this.state.selectedClusters.findIndex((item) => item.clusterId === singleItem.clusterId && item.monthId === singleItem.monthId);
            this.state.selectedClusters.splice(index,1);
         }
         this.setState(this.state.selectedClusters);
      }

   };

   checkIfItemSelected = (singleItem, type) => {
      if(singleItem.moduleName === "AX_OPT_INSTRU_W_1"){
         console.log("test");
      }
      if(type === 0){
         if(this.state.selectedModules.length === 0){
            return false;
         }
         let foundItem = this.state.selectedModules.filter((moduleItem) => {
            return moduleItem.moduleName === singleItem.moduleName && moduleItem.monthId === singleItem.monthId;
         });
         return foundItem.length > 0;
      } else {
         if(this.state.selectedClusters.length === 0){
            return false;
         }
         let foundItem =  this.state.selectedClusters.filter((clusterItem) => {
            return clusterItem.clusterId === singleItem.clusterId && clusterItem.monthId === singleItem.monthId;
         });
         return foundItem.length > 0;
      }
   };

   setSelectedModules = (selectedItems) => {
      this.setState({
         selectedModules: selectedItems,
      });
   }

   setSelectedClusters = (selectedItems) => {
      this.setState({
         selectedClusters: selectedItems,
      });
   }

   setIsExportingAll = () => {
      this.setState({
         isExportingAll: true,
      });
   }
   openPopupSelected = () => {
      this.setState({
         openSelectedPopup: true,
      });
   }
   confermPopupSelected = (list) => {
      if(list !== null && list.length > 0){
         list = list.filter((item) => item.isChecked);
         if(this.state.typeIndex === 0){
            this.setModuleListSelect(list);
         }else{
            this.setSelectedClusters(list);
         }
      }
      this.setState({
         openSelectedPopup: false,
      });
   }
   closedPopupSelected = () => {
      this.setState({
         openSelectedPopup: false,
      });
   }

   setIsExportingSelected = () => {
      this.setState({
         isExportingSelected: true,
      });
   }

   noOtherFilterSelected = () => {
      let isFilterSelected = false;
      for (let filter in this.props.selectedFilters) {
         if (this.props.selectedFilters[filter] !== exportFilters.MONTH) {
            if (this.props.selectedFilters[filter].length > 0)
               isFilterSelected = true;
         }
      }

      return !isFilterSelected;
   };

   render() {
      let {
         typeOptions,
         typeIndex,
         statusOptions,
         selectedStatus,
         isExportingAll,
         isExportingSelected
         // moduleTypeOptions,
         // selectedModuleType,
      } = this.state;

      let {
         isExportSearchLoading,
         isExportSearchEmpty,
         selectedFilters,
         isLoadingExportCluster,
         isLoadingExportModule,
         exportingModules,
         exportingClusters,
         templates,
         isFiltersExportLoading,
         filters,
      } = this.props;

      const adaptedFilterOptions = (filter) => {
         let items = [];

         if (
            filter &&
            this.props.exportFilters &&
            this.props.exportFilters[filter]
         ) {
            const mapped = this.props.exportFilters[filter].map((element) => ({
               value: element,
               label: element,
            }));
            items = [...items, ...mapped];
         }
         //SL keep the months order of BE
         if (filter === 'months') {
            return items;
         } else {
            return _.sortBy(items, "value");
         }
      };

      let isLoadingExport = isLoadingExportModule || isLoadingExportCluster;

      const isMonthEmpty =
         this.props.selectedFilters.monthId &&
         this.props.selectedFilters.monthId.length === 0;

      const { commodities: collectionOptions } = filters;
      return (
         <div className="export-data-page">
            <header className="minimal-header">
               <Logo />
               <h2>Export Data</h2>
            </header>
            <div className="export-data-container">
               <div className="options-container">
                  <div className="option-type">Type of results: </div>
                  <div className="option-value">
                     <RadioGrp
                        options={typeOptions}
                        active={false}
                        selectedIndex={typeIndex}
                        handleClick={this.setType}
                     />
                  </div>
               </div>
               {/*typeIndex === 0 && (
						<div className="options-container">
							<div className="option-type">Type of module: </div>
							<div className="option-value checkbox-container">
								<Checkbox
									value={moduleTypeOptions[0]}
									checked={selectedModuleType.includes(moduleTypeOptions[0])}
									handleClick={this.setModuleType}
								>
									Switch
								</Checkbox>
								<Checkbox
									value={moduleTypeOptions[1]}
									checked={selectedModuleType.includes(moduleTypeOptions[1])}
									handleClick={this.setModuleType}
								>
									Not Switch
								</Checkbox>
								<Checkbox
									value={moduleTypeOptions[2]}
									checked={selectedModuleType.includes(moduleTypeOptions[2])}
									handleClick={this.setModuleType}
								>
									Alternative
								</Checkbox>
							</div>
						</div>
          )*/}
               <div className="options-container">
                  <div className="option-type">Status: </div>
                  <div className="option-value checkbox-container">
                     <Checkbox
                        value={statusOptions[0]}
                        checked={selectedStatus.includes(statusOptions[0])}
                        handleClick={this.setStatus}
                     >
                        Published
                     </Checkbox>
                     <Checkbox
                        value={statusOptions[1]}
                        checked={selectedStatus.includes(statusOptions[1])}
                        handleClick={this.setStatus}
                     >
                        Saved
                     </Checkbox>
                  </div>
               </div>
               <div className="pdf-button-container">
                  <div className="btn-pdf-area"> 
                     <LinkedButton
                        btnClass="btn-medium"
                        link="/pdf-area"
                        disabled={isLoadingExport && isExportingAll}
                     >
                        PDF FILES
                     </LinkedButton>
                  </div>
               </div>
               <div className="filters-container">
                  <span className="title">Filters:</span>
                  <div className="component filters">
                     <div className="row no-gutters">
                        <div className="col-2 single-filter">
                           <h3>MONTH</h3>
                           <PaginatedSelect
                              isLoading={isFiltersExportLoading}
                              disabled={isFiltersExportLoading}
                              options={adaptedFilterOptions("months")}
                              placeholder="MONTH"
                              handleChange={(e) =>
                                 this.setFilters("monthId", e)
                              }
                              isMulti
                              value={selectedFilters.monthId}
                           />
                        </div>
                        <div className="col-2 single-filter">
                           <h3>PLANNING CHANNEL</h3>
                           <PaginatedSelect
                              options={adaptedFilterOptions("planningChannels")}
                              isLoading={isFiltersExportLoading}
                              placeholder="Planning Channel"
                              disabled={isFiltersExportLoading}
                              handleChange={(e) =>
                                 this.setFilters("planningChannel", e)
                              }
                              isMulti
                              value={selectedFilters.planningChannel}
                           />
                        </div>
                        <div className="col-2 single-filter">
                           <h3>BRAND</h3>
                           <PaginatedSelect
                              options={adaptedFilterOptions("brand")}
                              isLoading={isFiltersExportLoading}
                              placeholder="Brand"
                              disabled={isFiltersExportLoading}
                              handleChange={(e) => {
                                 this.setFilters("brand", e);
                              }}
                              isMulti
                              value={selectedFilters.brand}
                           />
                        </div>
                        <div className="col-2 single-filter">
                           <h3>SUN/OPT</h3>
                           <PaginatedSelect
                              options={collectionOptions}
                              isLoading={isFiltersExportLoading}
                              placeholder="Collection"
                              disabled={isFiltersExportLoading}
                              handleChange={(e) =>
                                 this.setFilters("collection", e)
                              }
                              isMulti
                              value={selectedFilters.collection}
                           />
                        </div>
                        <div className="col-2 single-filter">
                           <h3>Stars Zone</h3>
                           <PaginatedSelect
                              options={adaptedFilterOptions("countries")}
                              isLoading={isFiltersExportLoading}
                              placeholder="Stars Zone"
                              disabled={isFiltersExportLoading}
                              handleChange={(e) =>
                                 this.setFilters("country", e)
                              }
                              isMulti
                              value={selectedFilters.country}
                           />
                        </div>
                        <div className="col-2 single-filter">
                           <h3>STARS CUSTOMER</h3>
                           <PaginatedSelect
                              options={adaptedFilterOptions("starsCustomer")}
                              isLoading={isFiltersExportLoading}
                              disabled={isFiltersExportLoading}
                              placeholder="Stars Customer"
                              isMulti
                              handleChange={(e) =>
                                 this.setFilters("starsCustomer", e)
                              }
                              value={selectedFilters.starsCustomer}
                           />
                        </div>
                     </div>
                     {typeIndex === 0 ? (
                        <div className="row no-gutters">
                           <div className="col-2 single-filter">
                              <h3>CLUSTER</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("clusterIds")}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="Cluster"
                                 disabled={isFiltersExportLoading}
                                 handleChange={(e) =>
                                    this.setFilters("clusterId", e)
                                 }
                                 isMulti
                                 value={selectedFilters.clusterId}
                              />
                           </div>
                           <div className="col-2 single-filter">
                              <h3>FAMILY</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("families")}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="Family"
                                 disabled={isFiltersExportLoading}
                                 handleChange={(e) =>
                                    this.setFilters("macroFamily", e)
                                 }
                                 isMulti
                                 value={selectedFilters.macroFamily}
                              />
                           </div>
                           <div className="col-2 single-filter">
                              <h3>TYPE</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("types")}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="Type"
                                 disabled={isFiltersExportLoading}
                                 handleChange={(e) =>
                                    this.setFilters("newModule", e)
                                 }
                                 isMulti
                                 value={selectedFilters.newModule}
                              />
                           </div>
                           {/* tB:03-2022 */}
                           <div className="col-2 single-filter">
                              <h3>MODULE LABEL</h3>
                              <input
                                 className="search inputStyle inputFilter"
                                 onChange={(e) =>
                                    this.setFilters(
                                       "moduleLabel",
                                       [e.target.value]
                                    )
                                 }
                                 type="text"
                                 value={selectedFilters.moduleLabel}
                              />
                           </div>
                        </div>
                     ) : (
                        <div className="row no-gutters">
                           <div className="col-2 single-filter">
                              <h3>Store id</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("storeIds")}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="Store id"
                                 disabled={isFiltersExportLoading}
                                 handleChange={(e) =>
                                    this.setFilters("storeJda", e)
                                 }
                                 isMulti
                                 value={selectedFilters.storeJda}
                              />
                           </div>
                           <div className="col-2 single-filter">
                              <h3>SAP Number</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("sapNumbers")}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="SAP Number"
                                 disabled={isFiltersExportLoading}
                                 handleChange={(e) =>
                                    this.setFilters("codiceStoreSap", e)
                                 }
                                 isMulti
                                 value={selectedFilters.codiceStoreSap}
                              />
                           </div>
                           <div className="col-2 single-filter">
                              <h3>Segmentation</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("segmentations")}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="Segmentation"
                                 disabled={isFiltersExportLoading}
                                 handleChange={(e) =>
                                    this.setFilters("customerSegmentation", e)
                                 }
                                 isMulti
                                 value={selectedFilters.customerSegmentation}
                              />
                           </div>
                           <div className="col-2 single-filter">
                              <h3>Range Facing</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("rangeFacings")}
                                 disabled={isFiltersExportLoading}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="Range Facing"
                                 handleChange={(e) =>
                                    this.setFilters("rangeFacing", e)
                                 }
                                 isMulti
                                 value={selectedFilters.rangeFacing}
                              />
                           </div>
                           {/* tB:03-2022 */}
                           <div className="col-2 single-filter">
                              <h3>CLUSTER LABEL</h3>
                              <input
                                 className="search inputStyle inputFilter"
                                 onChange={(e) =>
                                    this.setFilters(
                                       "clusterLabel",
                                       [e.target.value]
                                    )
                                 }
                                 type="text"
                                 value={selectedFilters.cluster}
                              />
                           </div>
                           <div className="col-2 single-filter">
                              <h3>CLIENT CLASS.</h3>
                              <PaginatedSelect
                                 options={adaptedFilterOptions("classifications")}
                                 disabled={isFiltersExportLoading}
                                 isLoading={isFiltersExportLoading}
                                 placeholder="Client class."
                                 handleChange={(e) =>
                                    this.setFilters("clientClassification", e)
                                 }
                                 isMulti
                                 value={selectedFilters.clientClassification}
                              />
                           </div>
                        </div>
                     )}
                  </div>
                  <div className="btn-container">
                     <Button
                        btnClass="btn-medium btn-search"
                        disabled={isFiltersExportLoading || isMonthEmpty}
                        icon="icon-arrow1"
                        iconAfter
                        handleClick={this.handleSearchFilter}
                     >
                        Search
                     </Button>
                  </div>
                  {this.props.exportResults &&
                     this.props.exportResults.status === 413 &&
                     !isExportSearchLoading && (
                        <div
                           className="results-container component"
                           style={{ textAlign: "center" }}
                        >
                           <img
                              src={star}
                              alt="MyStars Assortment"
                              className="logo-star"
                           />

                           <h1 style={{ marginTop: "20px" }}>
                              The search returned too many elements
                           </h1>
                           <h2>Change the filters and search again</h2>
                        </div>
                     )}
                  {isExportSearchLoading ? (
                     <div className="results-container component">
                        <Loader />
                     </div>
                  ) : this.props.exportResults.length === 0 &&
                     isExportSearchEmpty ? (
                     <div
                        className="results-container component"
                        style={{ textAlign: "center" }}
                     >
                        There aren't search results.
                     </div>
                  ) : (
                     this.props.exportResults.length > 0 && (
                        <div className="results-container component">
                           <div className="search-container" style={{display: 'flex'}}>
                              <span>Results:</span>
                              <span>
                                 <CustomSearch
                                    type="text"
                                    placeholder="Search items..."
                                    handleChange={this.search}
                                 />
                              </span>
                              <span>
                                 <Button
                                    btnClass="btn-medium"
                                    handleClick={this.selectAll}
                                    disabled={!this.state.isCustomSearch}
                                 >
                                    Select All
                                 </Button>
                              </span>
                              <span>
                                 <Button
                                    btnClass="btn-medium"
                                    handleClick={this.openPopupSelected}
                                    disabled={this.state.typeIndex === 0 ? this.state.selectedModules.length === 0 : this.state.selectedClusters.length === 0}
                                 >
                                    Open Selected
                                 </Button>
                              </span>
                           </div>
                           <div className="results-list">
                              {!this.state.exportResults || this.state.exportResults.length === 0  || !Array.isArray(this.state.exportResults)? (
                                 <div />
                              ) :
                                 (<div className="scroll-container">
                                 {this.state.exportResults.map((singleItem)=> (
                                 <ExportItem
                                       key={this.state.typeIndex === 0 ? singleItem.moduleName+singleItem.monthId : singleItem.clusterId+singleItem.monthId}
                                       type={typeOptions[typeIndex]}
                                       results={this.state.exportResults}
                                       exportOne={this.exportOne}
                                       isLoadingExport={isLoadingExport}
                                       exportingModules={exportingModules}
                                       exportingClusters={exportingClusters}
                                       exportClusterPopup={this.togglePopup}
                                       exportEl={this.exportEl}
                                       setSelectedModules={this.setSelectedModules}
                                       setSelectedClusters={this.setSelectedClusters}
                                       exportFinished={this.state.exportFinished}
                                       item={singleItem}
                                       selectItem={this.selectItem}
                                       selected={this.checkIfItemSelected(singleItem, this.state.typeIndex)}
                                    />)
                                 )}
                                 </div>
                              )
                              }
                           </div>
                           <div className="export-all-container">
                              <Button
                                 btnClass="btn-medium"
                                 handleClick={() => {
                                    this.setIsExportingSelected();
                                    this.togglePopup();

                                 }}
                                 disabled={(this.state.typeIndex === 0 && this.state.selectedModules.length === 0) || (this.state.typeIndex === 1 && this.state.selectedClusters.length === 0)}
                              >
                                 Export Selected
                              </Button>
                              <Button
                                 btnClass="btn-medium"
                                 handleClick={() => {
                                    this.setIsExportingAll();
                                    this.togglePopup();

                                 }}
                                 disabled={isLoadingExport && isExportingAll}
                              >
                                 Export All
                              </Button>
                           </div>
                        </div>
                     )
                  )}
               </div>
               { this.state.exportClusterPopup ? (
                  <PopupExport
                     popupClass="popup-large"
                     title="Export"
                     subtitle="Which file do you want to export?"
                     close={this.togglePopup}
                     isDoubleButton
                     isTripleButton
                     isQuadrupleButton
                     isExportButton
                     isComboboxEnabled={this.state.isComboboxEnabled}
                     isPdfExportSelected={this.state.isPdfExportSelected}
                     isSelectedTemplate={this.state.isSelectedTemplate}
                     templates={templates}
                     setTemplate={this.setTemplate}
                     btnName="XLS"
                     btnName2="XLS PHOTO"
                     btnName3="PDF"
                     btnName4="EXPORT"
                     btnClass="btn-small"
                     exportClusterPopup
                     exportFormat={this.state.exportFormat}
                     maxHeightCustomSelectMenu="500px"
                     handleClick={() => {

                        this.setComboboxEnabled();
                        let selected = this.state.typeIndex === 0 ? this.state.selectedModules : this.state.selectedClusters;
                        if (isExportingAll || (isExportingSelected && selected.length > 1)) {
                           this.setExportFormat("xls_massive");
                        } else {
                           this.setExportFormat("xls");
                        }
                     }}
                     handleClickSecondary={() => {
                        this.setComboboxEnabled();
                        let selected = this.state.typeIndex === 0 ? this.state.selectedModules : this.state.selectedClusters;
                        if (isExportingAll || (isExportingSelected && selected.length > 1)) {
                           this.setExportFormat("xls/photo_massive");
                        } else {
                           this.setExportFormat("xls/photo");
                        }
                     }}
                     handleClickTertiary={() => {
                        this.setPdfExportEnabled();
                        let selected = this.state.typeIndex === 0 ? this.state.selectedModules : this.state.selectedClusters;
                        if (isExportingAll || (isExportingSelected && selected.length > 1)) {
                           this.setExportFormat("pdf_massive");
                        } else {
                           this.setExportFormat("pdf");
                        }
                     }}
                     handleClickQuartiary={() => {
                     switch (this.state.exportFormat) {
                        case "xls":
                           this.exportOne(this.state.typeIndex === 0 ? (this.state.el ? this.state.el : this.state.selectedModules[0]) : (this.state.el ? this.state.el :this.state.selectedClusters[0]), this.props.selectedFilters, "xls", this.state.template);
                           console.log(this.state.template)
                           break;
                        case "xls/photo":
                           this.exportOne(this.state.typeIndex === 0 ? (this.state.el ? this.state.el : this.state.selectedModules[0]) : (this.state.el ? this.state.el :this.state.selectedClusters[0]), this.props.selectedFilters, "xls/photo", this.state.template);
                           break;
                        case "pdf":
                           this.exportOne(this.state.typeIndex === 0 ? (this.state.el ? this.state.el : this.state.selectedModules[0]) : (this.state.el ? this.state.el :this.state.selectedClusters[0]), this.props.selectedFilters, "pdf");
                           break;
                        case "xls_massive":
                           this.exportAll("xls_massive", this.state.template);
                           break;
                        case "xls/photo_massive":
                           this.exportAll("xls/photo_massive", this.state.template);
                           break;
                        case "pdf_massive":
                           this.exportAll("pdf_massive");
                           break;
                        default:
                     }
                     this.togglePopup();
                     this.setExportFinished();
                     }}
                     handleClickTemplate={(e) => {
                            this.handleSelectChange(e);
                        }}
                  />
               ) : null} 

               {this.state.openSelectedPopup ? (
               <PopupOpenSelect
                  close={this.closedPopupSelected}
                  handleClick={(e)=>this.confermPopupSelected(e)}
                  list={this.state.typeIndex === 0 ?this.state.selectedModules : this.state.selectedClusters}
                  btnName="OK"
                  title="Selected elements to download:"
                  popupClass="popup-small"
               >
               </PopupOpenSelect>
               ) : null}
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      filters: state.filter.filterHome,
      // releaseList: state.release.releaseList,
      exportFilters: state.filter.exportFilters,
      isExportSearchLoading: state.filter.isExportSearchLoading,
      exportResults: state.filter.exportResults,
      isExportSearchEmpty: state.filter.isExportSearchEmpty,
      isFiltersExportLoading: state.filter.isFiltersExportLoading,
      selectedFilters: state.filter.exportSelectedFilters,
      isLoadingExportModule: state.module.isLoadingExportModule,
      isLoadingExportCluster: state.cluster.isLoadingExportCluster,
      exportingModules: state.module.exportingModules,
      exportingClusters: state.cluster.exportingClusters,
      templates: state.cluster.templates,
      username: state.authentication.user.user_name,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      exportModules: (modules, selectedFilters, status, type, template, photo,username) =>
         dispatch(actions.exportModules(modules, selectedFilters, status, type, template, photo,username)),
      getTemplate: (type) =>
         dispatch(actions.getTemplate(type)),
      logout: () => dispatch(actions.logout()),
      exportCluster: (cluster, filter, status, type, template, photo,username) =>
         dispatch(actions.exportCluster(cluster, filter, status, type, template, photo,username)),
      getExportSearchResults: (type, filters, status, moduleType) =>
         dispatch(
            actions.getExportSearchResults(type, filters, status, moduleType)
         ),
      resetExportSearch: () => dispatch(actions.resetExportSearch()),
      // getReleaseList: () => dispatch(actions.getReleaseList()),
      cleanExportFilters: () => dispatch(actions.cleanExportFilters()),
      setExportSelectedFilters: (type, opt, selectedType, getData) =>
         dispatch(
            actions.setExportSelectedFilters(type, opt, selectedType, getData)
         ),
      getFiltersExport: () => dispatch(actions.getFiltersExport()),
      cleanSelectedExportFilters: () =>
         dispatch(actions.cleanSelectedExportFilters()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportData);

export const filters = [
   {
      title: "MONTH",
      options: "months",
      filter: "month",
      disabled: false,
   },
];
