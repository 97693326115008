import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { useFetch } from "use-http";

import Logo from "../../components/UI-components/Logo";
import Popup from "../../components/UI-components/Popup";
import Loader from "../../components/UI-components/Loader";
import { UploadsTable, UploadFile } from "../../components";
import { useInterval } from "../../hooks";
import { useGetUploadStatus } from "../../fetch";
import { getCookie } from "../../services/cookie";
import {CLUSTER_MASSIVE_CREATION, MODULE_MASSIVE_CREATION} from "../../config";

const SECONDS_FOR_POLLING = 5;

function canSubmitFile(uploads) {
  return !uploads?.find((item) => item.status === "WIP");
}

const Upload = () => {
  const [showError, setShowError] = useState("");
  const {
    get,
    data: uploads,
    response,
    loading,
  } = useGetUploadStatus(({ error }) => {
    setShowError(error.message || "Oops, Something Went Wrong");
  });
  const canBeSubmitted = useMemo(() => {
    return response.ok && Array.isArray(uploads) && canSubmitFile(uploads);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads]);
  const { post: uploadClusterPost, response: responseUploadCluster } = useFetch(
      CLUSTER_MASSIVE_CREATION,
    {
      headers: {
        xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
      },
      cacheLife: 1,
      cachePolicy: "no-cache",
      onError: ({ error }) => {
        setShowError(error.message || "Oops, Something Went Wrong");
      },
    }
  );
  const { post: uploadModulePost, response: responseUploadModule } = useFetch(
      MODULE_MASSIVE_CREATION,
    {
      headers: {
        xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
      },
      cacheLife: 1,
      cachePolicy: "no-cache",
      onError: ({ error }) => {
        setShowError(error.message || "Oops, Something Went Wrong");
      },
    }
  );
  const [showResponse, setShowResponse] = useState("");
  const polling = useRef(false);
  const inputCluster = useRef();
  const inputModule = useRef();

  const handleClusterUpload = useCallback(
    async (file) => {
      await get();
      if (
        response.ok &&
        Array.isArray(response.data) &&
        canSubmitFile(response.data)
      ) {
        const formData = new FormData();
        formData.append("excelFile", file, file.name);
        await uploadClusterPost(formData);
        responseUploadCluster.ok && inputCluster.current.clear();
        await get();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleModuleUpload = useCallback(
    async (file) => {
      await get();
      if (
        response.ok &&
        Array.isArray(response.data) &&
        canSubmitFile(response.data)
      ) {
        const formData = new FormData();
        formData.append("excelFile", file, file.name);
        await uploadModulePost(formData);
        responseUploadModule.ok && inputModule.current.clear();
        await get();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!loading) {
      polling.current = true;
    }
  }, [loading]);

  useInterval(get, SECONDS_FOR_POLLING * 1000);

  if (loading && !polling.current) {
    return (
      <div className="full-page">
        <Loader />
      </div>
    );
  }

  return (
    <div className="upload-page">
      {showResponse && (
        <Popup
          popupClass="upload-popup-response"
          title="Response"
          close={() => setShowResponse("")}
        >
          {showResponse.split(";").map((str) => (
            <>
              <span>{str}</span>
              <br />
            </>
          ))}
        </Popup>
      )}
      {showError && (
        <Popup
          popupClass="upload-popup-response"
          title="Error"
          close={() => setShowError("")}
        >
          {showError}
        </Popup>
      )}
      <header>
        <Logo />
        <h2>Import Data</h2>
      </header>
      <div className="content">
        <div style={{ width: "100%" }}>
          <UploadFile
            ref={inputModule}
            onSubmit={handleModuleUpload}
            label="Import Module"
            disabled={!canBeSubmitted}
          />
          <UploadFile
            ref={inputCluster}
            onSubmit={handleClusterUpload}
            label="Import Cluster"
            disabled={!canBeSubmitted}
          />
        </div>
        <div className="upload-table-container">
          {uploads && (
            <UploadsTable
              uploads={uploads}
              onShowResponseClick={setShowResponse}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Upload;
