import React from "react";
import Popup from "../UI-components/Popup";


const SkuZoom = (props) => {

   return props.calledFromTablePerfo ? (
      <Popup
         close={props.hideZoomParent}
         popupClass="popup-zoom"
         handleClick={props.hideZoomParent}
      >
         
         <p style={{ marginTop: "4vh" }}>
            {props.skuModel} {props.skuColor} {props.skuSize}
         </p>
      </Popup>
   ) : (
      <Popup
         close={props.hideZoomParent}
         popupClass="popup-zoom"
         handleClick={props.hideZoomParent}
      >
         <img
            className={props.classImg ? props.classImg : ""}
            src={props.sku.imageUrl.replace("222x111", "1780x890")}
            alt={props.sku.model}
         />
         <p style={{ marginTop: "4vh" }}>
            {props.sku.model} {props.sku.color} {props.sku.size} OTHER
         </p>
      </Popup>
   );
};

export default SkuZoom;
