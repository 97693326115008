import React, { Component } from "react";
import ModuleJar from "./ModuleJar/ModuleJar";
import CustomSelect from "../UI-components/CustomSelect";
import Button from "../UI-components/Button";
import Loader from "../UI-components/Loader";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import CustomSearch from "../UI-components/CustomSearch";
import { mapArrayToObj, formatOptions, getArrayFromObj } from "../../services/Api";
import { DragDropContainer } from "react-drag-drop-container";
class SearchModule extends Component {
   state = {
      countryValues: null,
      familyValue: [],
      searchValue: "",
      countryOptions: null,
      familyOptions: null,
      isCountryPreselected: false,
      listModule:[],
   };

   enterSearch = (event) => {
      //Check if the button pressed is enter and if the button search is enabled
      let checkSearch =
         (this.state.countryValues !== null &&
            this.state.countryValues.length > 0) ||
         this.state.familyValue.length > 0 ||
         Object.keys(this.state.familyValue).length > 0 ||
         this.state.searchValue.length > 4;
      if (event.keyCode === 13 && checkSearch) {
         event.preventDefault();
         this.searchModules();
      }
   };

   componentDidMount() {
      document.addEventListener("keyup", this.enterSearch);

      if (this.props.results) {
         this.checkFilters();
      }
   }

   componentWillUnmount = () => {
      document.removeEventListener("keyup", this.enterSearch);
   };

   componentDidUpdate(prevProps) {
      if (prevProps.filters !== this.props.filters) {
         this.checkFilters();
      }

      if (
         prevProps.results !== this.props.results &&
         this.props.results &&
         this.props.results.macroFamilyList
      ) {
         this.checkFilters();
      }
   }

   checkFilters = () => {
      if (this.props.filters && this.props.selectedFilters) {
         let countryValues = [];
         let isCountryPreselected = false;

         if (
            this.props.selectedFilters.countryList !== null &&
            this.props.selectedFilters.countryList.length > 0
         ) {
            countryValues = [...this.props.selectedFilters.countryList];
            isCountryPreselected = true;
         }

         this.setState({
            countryOptions:
               countryValues.length === 0
                  ? [...this.props.filters.countries]
                  : countryValues,
            countryValues,
            isCountryPreselected,
            familyOptions: formatOptions(
               [...this.props && this.props.results && this.props.results.macroFamilyList ? this.props.results.macroFamilyList : []],
               "macroFamily"
            ),
         });
      }
   };

   searchModules = () => {
      const objToSend = {
         brand: this.props.clusterList.map(cluster => cluster.brandCode), //[this.props.cluster.brandCode],
         collection: this.props.clusterList.map(cluster => cluster.collection), //[this.props.cluster.collection],
         moduleLabel:
            this.state.searchValue !== "" ? [this.state.searchValue] : [],
         monthId: this.props.clusterList.map(cluster => cluster.monthId), //[this.props.cluster.monthId],
         country: mapArrayToObj(this.state.countryValues),
         macroFamily: Object.keys(this.state.familyValue).length
            ? [this.state.familyValue.value]
            : [],
      };

      this.props.searchModules(objToSend);
   };

   setFilters = (type, opts) => this.setState({ [type]: opts });

   handleChangeSearch = (event) => {
      this.setState({
         searchValue: event.target.value,
      });
   };

   onDragStart(ev, moduleItem) {
      this.props.dragStart(moduleItem);
   }

   render() {
      //check min length search
      let checkSearch =
         (this.state.countryValues !== null &&
            this.state.countryValues.length > 0) ||
         this.state.familyValue.length > 0 ||
         Object.keys(this.state.familyValue).length > 0 ||
         this.state.searchValue.length > 4;

      let resultsText = "Filter to start searching";

      if (
         this.props.searchModulesResult &&
         this.props.searchModulesResult.length < 1
      ) {
         resultsText = "You search did't return any results";
      }

      let clusterComposition=this.props.listModule?.map(module => module.moduleLabel);
      let commonModulesNames = getArrayFromObj(this.props.commonModules)?.map(module => module.moduleName)

      return (
         <div className="component col-6 search-module">
            <header className="search-module-header">
               <CustomSearch
                  placeholder="Search Module Label"
                  value={this.state.searchValue}
                  handleChange={this.handleChangeSearch}
                  maxLength="50"
                  inputClass="input-big search-modules"
               />
               {this.state.isCountryPreselected ? (
                  <p>
                     {this.state.countryValues.map((c, i) => {
                        let print = "";
                        if (i > 0) print = ", ";
                        print += c.value;
                        return print;
                     })}
                  </p>
               ) : (
                  <CustomSelect
                     placeholder="Stars Zone"
                     isMulti={true}
                     value={this.state.countryValues}
                     options={
                        this.state.countryOptions
                           ? this.state.countryOptions
                           : []
                     }
                     handleChange={(e) => this.setFilters("countryValues", e)}
                  />
               )}
               <CustomSelect
                  placeholder="Family"
                  value={this.state.familyValue}
                  options={
                     this.state.familyOptions ? this.state.familyOptions : []
                  }
                  handleChange={(e) => this.setFilters("familyValue", e)}
               />
               <Button
                  disabled={!checkSearch}
                  btnClass="btn-medium"
                  handleClick={this.searchModules}
               >
                  Search
               </Button>
            </header>
            <div className="container-jar-search">
               {this.props.isSearchMLoading ? (
                  <Loader />
               ) : this.props.searchModulesResult &&
                 this.props.searchModulesResult.length > 0 ? (
                  this.props.searchModulesResult.map((moduleItem) => {
                     const isACommonModule = commonModulesNames.includes(moduleItem.moduleName)
                     return (
                        <DragDropContainer
                           noDragging={this.props.isDisabled}
                           key={moduleItem.moduleName}
                           targetKey="drag-drop"
                           dragData={moduleItem}
                           onDragStart={(e) => this.onDragStart(e, moduleItem)}
                        >
                           <ModuleJar
                              dataId={moduleItem.moduleName}
                              isACommonModule={isACommonModule}
                              moduleItem={moduleItem}
                              clusterComposition={clusterComposition}
                              hideIcons
                              draggable={!this.props.isDisabled}
                              onDragStart={(e) =>
                                 this.onDragStart(e, moduleItem)
                              }
                              dragging={this.state.dragging}
                              onDragEnd={this.props.dragEnd}
                              onDragExit={this.props.dragEnd}
                              goToSingleModule={(moduleItem) =>
                                 this.props.goToSingleModule(moduleItem)
                              }
                           />
                        </DragDropContainer>
                     );
                  })
               ) : (
                  <p>{resultsText}</p>
               )}
            </div>
            <footer className="search-module-footer">
               <p>You have {this.props.counterWip} unpublished module</p>
            </footer>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      cluster: state.cluster.cluster, // da rimuovere
      clusterList: state.cluster.clusterList,
      searchModulesResult: state.cluster.searchModulesResult,
      selectedFilters: state.filter.selectedFilters,
      filters: state.filter.filterHome,
      results: state.filter.results,
      isSearchMLoading: state.cluster.isSearchMLoading,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      searchModules: (objToSend) => dispatch(actions.searchModules(objToSend)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchModule);
