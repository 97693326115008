import { useFetch } from "use-http";
import { GET_PDFAREA_INFO } from "../config";
import { getCookie } from "../services/cookie";
import {UPLOAD_GET_STATUS} from "../config";

function mapType(type) {
  switch (type) {
    case "CLUSTER_MASSIVE_CREATION": {
      return "Cluster";
    }
    case "MODULE_MASSIVE_CREATION": {
      return "Module";
    }
    default: {
      return "-";
    }
  }
}

function mapUpload({
  id,
  insertTime,
  progress,
  response,
  status,
  type,
  userId,
}) {
  return {
    id,
    status,
    progress,
    response,
    username: userId,
    date: new Date(insertTime),
    importType: mapType(type),
  };
}

function mapPdfArea({
  id,
  date,
  progress,
  pdfUrl,
  status,
  exportType,
  user,
}) {
  return {
    id,
    status,
    progress,
    pdfUrl,
    username: user,
    date,
    exportType,
  };
}

export function useGetUploadStatus(onError) {
  return useFetch(
      UPLOAD_GET_STATUS,
    {
      method: "GET",
      headers: {
        xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
      },
      data: [],
      cacheLife: 1,
      interceptors: {
        response: async function ({ response }) {
          if (response.ok && Array.isArray(response.data)) {
            response.data = response.data.sort((a, b) => {
              return b.insertTime - a.insertTime
            }).map(mapUpload);
          }
          return response;
        },
      },
      cachePolicy: "no-cache",
      onError
    },
    []
  );
}

export function useGetPDFAreaStatus(onError) {
  return useFetch(
     GET_PDFAREA_INFO,
    {
      method: "GET",
      headers: {
        xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
      },
      data: [],
      cacheLife: 1,
      interceptors: {
        response: async function ({ response }) {
          if (response.ok && Array.isArray(response.data)) {
            response.data = response.data.sort((a, b) => {
              return b.insertTime - a.insertTime
            }).map(mapPdfArea);
          }
          return response;
        },
      },
      cachePolicy: "no-cache",
      onError
    },
    []
  );
}
