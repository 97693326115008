import React, { Component } from "react";

import ClusterAnalysisModuleList from "./ClusterAnalysisModuleList"

class ClusterAnalysis extends Component {

    checkRefresh=(cluster) => {
            return cluster>30 ? "refreshRed" : "refresh";  
    }
    checkFacing=(facing, checkFacing) => {
        return facing < checkFacing ? "Red" : "";  
    }

    render() {
        return (this.props.skus.map((cluster) => (
            <div className="cluster-analysis" key={"cluster-analysis"+cluster.clusterLabel}>
                <div className="cluster-analysis-container" key={"cluster-analysis-container"+cluster.clusterLabel}>
                    <div className={"clusterName"} key={cluster.clusterLabel} >
                        {cluster.clusterLabel} -
                    </div>
                    <div className={"clusterBody"}>
                        <div>
                        <span className={"doors"} key={cluster.clusterLabel + " doors " + cluster.doors}>
                            {cluster.doors} DOORS
                        </span> - <span className={"skus"} key={cluster.clusterLabel + " skus"}>
                            {cluster.mhandEL} SKUS ( {cluster.mh} MH + {cluster.el} EL)
                        </span>
                        </div>
                        <div className="facing" key={cluster.clusterLabel + " facing"}>
                            <span className={"minFacing "+this.checkFacing(cluster.minFacing,cluster.mh)} key={cluster.clusterLabel + " min " + cluster.minFacing}>
                            MIN FACING: {cluster.minFacing === 0 && cluster.doors === 0?"-":cluster.minFacing} 
                            </span> - <span className={"maxFacing "+this.checkFacing(cluster.mhandEL,cluster.maxFacing)} key={cluster.clusterLabel + " max " + cluster.maxFacing}>
                            MAX FACING: {cluster.maxFacing === 0 && cluster.doors === 0?"-":cluster.minFacing}
                            </span>
                        </div>
                        <div className={"skusRefresh"} key={cluster.clusterLabel + "skus refresh"}>
                            <span className={ this.checkRefresh(cluster.refreshMH)}>
                            REFRESH MH: {cluster.refreshMH}%
                            </span> - <span className={ this.checkRefresh(cluster.refreshEL)}>
                            REFRESH EL: {cluster.refreshEL}% 
                            </span> - <span className={ this.checkRefresh(cluster.refreshMHandEL)}>
                            REFRESH MH+EL: {cluster.refreshMHandEL}%
                            </span>
                        </div>
                        <div>
                            <span className={"avgPrice"} key={cluster.clusterLabel + " avg " + cluster.avgPrice}>
                                AVG WHS PRICE {cluster.avgPrice} €
                            </span> - <span className={"avgPrice"} key={cluster.clusterLabel + " srp " + cluster.avgSRPPrice}>
                                AVG SRP PRICE {cluster.avgSRPPrice} €
                            </span>
                        </div>
                    </div>
                </div>
                <ClusterAnalysisModuleList modules={cluster.modules} macroList={this.props.macroList} cluster={cluster.clusterLabel}/>
                <br />
                <br />
            </div>
        )))
    }
}

export default ClusterAnalysis;
