import React, { Component } from 'react';
import Logo from '../UI-components/Logo';
import TableComponent from './Table-Component/TableComponent';
 
class ApproveCluster extends Component {

    componentDidMount() {
        if(!this.props.isGAJustInizialized) this.props.ReactGA.pageview(window.location.pathname + window.location.search);  
    }
    
    render () {
        return(
            <div className="approve-container">
                <header className="minimal-header"> 
                    <Logo/>
                    <h2>Approve cluster</h2>
                </header>
                <TableComponent tableClass="table-bo"/>
            </div>
        )
    }
}
export default ApproveCluster;