/* eslint-disable array-callback-return */
import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";
import { formatOptionsArray, handleErrorsJson } from "../../services/Api";
import { getCookie } from "../../services/cookie";
import { showError } from "./errorActions";
import * as _ from "lodash";

/*** ACTIVE SKUS */
export const getActiveFilter = (selectedFilters) => {
   return (dispatch) => {
      let url = urlList.GET_ACTIVE_FILTERS_URL;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(selectedFilters),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            console.log(json)
            if (json) {
               if (json.anagraFilters) {
                  let anag = json.anagraFilters;

                  Object.keys(anag).map((k) => {
                     return (anag[k] = formatOptionsArray(anag[k]));
                  });
               }

               if (json.ibicFilters && json.ibicFilters !== null) {
                  let ibic = json.ibicFilters;

                  Object.keys(ibic).map((k) => {
                     ibic[k] = _.orderBy(
                        ibic[k].map((item) => {
                           if (
                              k.toUpperCase() === "MONTHNR" ||
                              k.toUpperCase() === "YEARNR" ||
                              k.toUpperCase() === "WEEKNR"
                           ) {
                              item = parseInt(item);
                           }
                           return {
                              label: item,
                              value: item,
                           };
                        }),
                        ["label"]
                     );
                  });
               } else {
                  json.ibicFilters = null;
               }
            }
            dispatch(getActiveFilterSuccess(json));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

const getActiveFilterSuccess = (activeFilters) => {
   console.log(activeFilters)
   return {
      type: actionTypes.GET_ACTIVE_SUCCESS,
      activeFilters,
   };
};

export const getActiveSku = (filters, filterHome) => {
   Object.keys(filters).map((key) => {
      if (key === "monthsRolling") {
         return (filters[key] = [filters[key].value]);
      }
      if (
         key !== "model" &&
         key !== "color" &&
         key !== "size" &&
         key !== "upc" &&
         key !== "discontinuation"
      ) {
         return (filters[key] = filters[key].map((opt) => {
            if (
               key.toUpperCase() === "MONTHNR" ||
               key.toUpperCase() === "YEARNR" ||
               key.toUpperCase() === "WEEKNR"
            ) {
               return opt.value.toString();
            }
            // if (key === "priceMax" || key === "priceMin") {
            //   return opt;
            // }
            if (key === 'moduleList') {
               return opt
            }
            else
               return opt.constructor === Object
                  ? opt.value.toString()
                  : opt.toString();
         }));
      } else {
         if (key === "discontinuation"){
            const opt = filters[key]
            const result = opt.constructor === Object
                  ? opt.value.toString()
                  : opt.toString();
            return (filters[key] = [result])
         }
         return (filters[key] = [filters[key]]);
      }
   });

   return (dispatch) => {
      dispatch(getActiveSkuStart());
      let url = urlList.GET_PRODUCT_FILTERED;

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },

         body: JSON.stringify(filters),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((productsList) => {
            dispatch(getActiveSkuSuccess(productsList));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(getActiveSkuFailure());
         });
   };
};

const getActiveSkuStart = () => {
   return {
      type: actionTypes.GET_ACTIVE_SKU_START,
   };
};

const getActiveSkuSuccess = (activeSku) => {
   //console.log("skuAction activeSku", activeSku);
   return {
      type: actionTypes.GET_ACTIVE_SKU_SUCCESS,
      activeSku,
   };
};

const getActiveSkuFailure = () => {
   return {
      type: actionTypes.GET_ACTIVE_SKU_FAILURE,
   };
};
/** DRAG DROP */
export const dragSku = (sku, moduleFrom) => {
   return {
      type: actionTypes.DRAG_SKU,
      sku: sku,
      moduleFrom: moduleFrom,
   };
};

// HIGHLIGHT SKU IN WORKING AREA
export const highlightInWA = (sku) => {
   return (dispatch) => {
      dispatch(highlightInActive());

      dispatch({
         type: actionTypes.HIGHLIGHT_SKU_WA,
         highlightWASku: sku,
      });
   };
};

export const cleanHighlightWA = () => {
   return {
      type: actionTypes.CLEAN_HIGHLIGHT_WA,
   };
};

// HIGHLIGHT SKU IN ACTIVE AREA
export const highlightInActive = (sku) => {
   return (dispatch) => {
      dispatch(cleanHighlightWA());
      dispatch({
         type: actionTypes.HIGHLIGHT_SKU_ACTIVE,
         highlightActiveSku: sku,
      });
   };
};

export const cleanHighlightActive = () => {
   return {
      type: actionTypes.CLEAN_HIGHLIGHT_ACTIVE,
   };
};

export const cleanScrollHighlightActive = () => {
   return {
      type: actionTypes.CLEAN_SCROLL_HIGHLIGHT_ACTIVE,
   };
};

export const cleanScrollHighlightWA = () => {
   return {
      type: actionTypes.CLEAN_SCROLL_HIGHLIGHT_WA,
   };
};

export const clearHighlighted = () => {
   return {
      type: actionTypes.CLEAR_HIGHLIGHTED,
   };
};
