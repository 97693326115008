import React from "react";
import UserComponent from "./UserComponent";
import Logo from "../UI-components/Logo";

class manageUser extends React.Component {
  componentDidMount() {
    if (!this.props.isGAJustInizialized)
      this.props.ReactGA.pageview(
        window.location.pathname + window.location.search
      );
  }

  render() {
    return (
      <div className="module-comp">
        <header className="minimal-header">
          <Logo />
          <h2>Manage Users</h2>
        </header>
        <div className="manage-page-container">
          <UserComponent />
        </div>
      </div>
    );
  }
}

export default manageUser;
