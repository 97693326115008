/* eslint-disable react-hooks/rules-of-hooks */

import { MODULE_MASSIVE_EDIT, UPLOAD_STATUS } from "../../config";
import React, { useEffect, useState } from "react";

import Button from "../../components/UI-components/Button";
import Logo from "../../components/UI-components/Logo";
import MassiveEditSelectedWITable from "../../components/MassiveEditSelectedWITable";
import Popup from "../../components/UI-components/Popup";
import PopupGenericError from "../../components/Error-Handling/WithErrorHandling";
import { getCookie } from "../../services/cookie";

const SECONDS_FOR_POLLING = 2;

// eslint-disable-next-line no-empty-pattern
const MassiveEditSelectedWI = ({}) => {
   let pollingStatusTimeOut = false;
   const [showSaveModal, setShowSaveModal] = useState(false);
   const [showPublishModal, setShowPublishModal] = useState(false);
   const [isEmptyTable, setIsEmptyTable] = useState(true);
   const [tableData, setTableData] = useState([]);
   const [resetTableData, setResetTableData] = useState(false);
   const [showError, setShowError] = useState("");
   const [idStatus, setIdStatus] = useState(null);
   const [refreshCount, setrefreshCount] = useState(0);
   const [showResultModdal, setShowResultModdal] = useState(false);
   const [progressStatus, setProgressStatus] = useState(0);
   const [uploadStatusData, setUploadStatusData] = useState({});
   const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
   const [forceCloseLoadingPopup, setForceCloseLoadingPopup] = useState(false);
   const [showGenericError, setShowGenericError] = useState(false);

   const onEditRowTable = (rows, isEmpty) => {
      setIsEmptyTable(isEmpty);
      const p = JSON.parse(JSON.stringify(rows)).map((row) => {
         if (row && row.module && row.module !== "") {
            row.module = row.module.map((ml) => ml.value);
         } else {
            row.module = [];
         }
         if (row && row.month && row.moth !== "") {
            row.month = row.month.map((ml) => ml.value);
         } else {
            row.module = [];
         }
         return row;
      });
      setTableData(p);
   };

   useEffect(() => {
      if (idStatus && !pollingStatusTimeOut) {
         // eslint-disable-next-line react-hooks/exhaustive-deps
         pollingStatusTimeOut = setTimeout(() => {
            pollingStatus();
            clearTimeout(pollingStatusTimeOut);
         }, SECONDS_FOR_POLLING * 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [idStatus, refreshCount]);

   useEffect(() => {
      if (
         !showResultModdal &&
         progressStatus === 100 &&
         uploadStatusData !== null
      ) {
         setTimeout(() => {
            setShowResultModdal(true);
            setResetTableData(false);
         }, 1000);
      }
   }, [
      forceCloseLoadingPopup,
      idStatus,
      progressStatus,
      showResultModdal,
      uploadStatusData,
   ]);

   const pollingStatus = () => {
      fetch(UPLOAD_STATUS + idStatus, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            if (response.status === 404) {
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 403) {
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 401) {
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 500) {
               setShowGenericError(true);
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               setUploadStatusData(data);
               setProgressStatus(data.progress);
               if (data.progress >= 100) {
                  setIdStatus(null);
               } else {
                  setTimeout(() => {
                     setrefreshCount(refreshCount + 1);
                  }, 500); //polling elapse
               }
            } else {
               setUploadStatusData(null);
               setShowResultModdal(false);
               setShowGenericError(true);
            }
         })
         .catch((err) => {
            setShowGenericError(true);
            console.error(err);
         });
   };

   const handleMassiveEditResponse = (data) => {
      const status = data?.status
      const notAuthorizedItems = data?.notAuthorizedItems

      if (status && !notAuthorizedItems) {
         setIdStatus(status);
      } else {
         setOpenLoadingPopup(false);
         setShowGenericError(true);
      }
   }

   const saveData = () => {
      const dataToSend = getDataToSend(false);
      fetch(MODULE_MASSIVE_EDIT, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(dataToSend),
         cacheLife: 1,
         cachePolicy: "no-cache",
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            if (response.status === 404) {
               setOpenLoadingPopup(false);
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 403) {
               setOpenLoadingPopup(false);
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 401) {
               setOpenLoadingPopup(false);
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 500) {
               setOpenLoadingPopup(false);
               setShowGenericError(true);
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            handleMassiveEditResponse(data)
         })
         .catch((err) => {
            setOpenLoadingPopup(false);
            setShowGenericError(true);
            console.error(err);
         });
   };

   const publishData = () => {
      const dataToSend = getDataToSend(true);
      fetch(MODULE_MASSIVE_EDIT, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(dataToSend),
         cacheLife: 1,
         cachePolicy: "no-cache",
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            if (response.status === 404) {
               setOpenLoadingPopup(false);
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 403) {
               setOpenLoadingPopup(false);
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 401) {
               setOpenLoadingPopup(false);
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 500) {
               setOpenLoadingPopup(false);
               setShowGenericError(true);
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            handleMassiveEditResponse(data)
         })
         .catch((err) => {
            setOpenLoadingPopup(false);
            setShowGenericError(true);
            console.error(err);
         });
   };

   const getDataToSend = (toPublish) => {
      let ret = null;
      ret = {
         massiveEditList: tableData.map((el) => {
            const dataToSend = {};
            if (el && el.add && el.add.length) {
               const filteredAdd = el.add.filter((a) => a !== "");
               if (filteredAdd.length) {
                  const result = filteredAdd[0].split("\n");

                  const finalResult = result.filter((el) => {
                     return el;
                  });

                  dataToSend.upcToAddList = finalResult;
               } else {
                  dataToSend.upcToAddList = [];
               }
            }

            if (el && el.remove && el.remove.length) {
               const filteredRemove = el.remove.filter((a) => a !== "");

               if (filteredRemove.length) {
                  const result = filteredRemove[0].split("\n");

                  const finalResult = result.filter((el) => {
                     return el;
                  });

                  dataToSend.upcToRemoveList = finalResult;
               } else {
                  dataToSend.upcToRemoveList = [];
               }
            }

            if (el && el.minValueSkuList && el.minValueSkuList.length) {
               const filteredMinValueSkuList = el.minValueSkuList.filter((a) => a !== "");

               if (filteredMinValueSkuList.length) {
                  const result = filteredMinValueSkuList[0].split("\n");

                  const finalResult = result.filter((el) => {
                     return el;
                  });

                  dataToSend.upcMinValueSkuList = finalResult;
               } else {
                  dataToSend.upcMinValueSkuList = [];
               }

               if(el.minValueSku) {
                  dataToSend.upcMinValueSkuValue = el.minValueSku;
               } else {
                  dataToSend.upcMinValueSkuValue = 1;
               }
            }

            if (
               el.module[0] &&
               el.module[0] !== null &&
               el.module[0] !== undefined &&
               el.module[0] !== ""
            ) {
               dataToSend.moduleLabelList = el.module;
            } else {
               dataToSend.moduleLabelList = [];
            }

            if (el.status) {
               dataToSend.upcStatus = el.status[0];
            }

            if (
               el.month[0] &&
               el.month[0] !== null &&
               el.month[0] !== undefined &&
               el.month[0] !== ""
            ) {
               dataToSend.monthIdList = el.month;
            } else {
               dataToSend.monthIdList = [];
            }

            return dataToSend;
         }),
         toPublish: toPublish,
      };

      return ret;
   };

   return (
      <div className="massive-edit-page">
         {showError && (
            <Popup
               popupClass="upload-popup-response"
               title="Error"
               close={() => setShowError("")}
            >
               {showError.split(";").map((str) => (
                  <>
                     <span>{str}</span>
                     <br />
                  </>
               ))}
            </Popup>
         )}
         {showGenericError && (
            <PopupGenericError
               showError={showGenericError}
               closeAlert={() => {
                  setShowGenericError(false);
               }}
               handleClick={() => {
                  setShowGenericError(false);
               }}
            />
         )}
         {showSaveModal && (
            <Popup
               contextTitle="Save Massive Edit"
               title="Are you sure you want to save the changes? If you confirm the operation you will be irreversible."
               close={() => {
                  setShowSaveModal(false);
               }}
            >
               <div className="massive-edit-page-popup__btn--container">
                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setShowSaveModal(false);
                     }}
                  >
                     CANCEL
                  </span>

                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setOpenLoadingPopup(true);
                        saveData();
                        setShowSaveModal(false);
                     }}
                  >
                     CONFIRM
                  </span>
               </div>
            </Popup>
         )}

         {openLoadingPopup ? (
            <Popup
               close={() => {
                  setOpenLoadingPopup(false);
               }}
               contextTitle="Massive edit process"
            >
               <div>
                  {uploadStatusData ? (
                     <div>
                        <div className="progress-bar-container">
                           <div
                              className="progress-bar-container--progress"
                              style={{ width: `${progressStatus}%` }}
                           />
                        </div>

                        <div>{progressStatus}%</div>
                     </div>
                  ) : null}
               </div>
            </Popup>
         ) : null}

         {showResultModdal ? (
            <Popup
               contextTitle="Result"
               close={() => {
                  setOpenLoadingPopup(false);
                  setShowResultModdal(false);
                  setForceCloseLoadingPopup(true);
                  setProgressStatus(0);
                  setResetTableData(true);
               }}
            >
               {uploadStatusData.response !== null &&
               uploadStatusData.response?.split(";") ? (
                  uploadStatusData.response.split(";").map((str) => (
                     <>
                        <span>{str}</span>
                        <br />
                     </>
                  ))
               ) : (
                  <span>{uploadStatusData.response}</span>
               )}
               {uploadStatusData.response === "" ||
               uploadStatusData.response === null ||
               uploadStatusData.response === undefined ? (
                  <span>{uploadStatusData.status}</span>
               ) : null}
            </Popup>
         ) : null}

         {showPublishModal && (
            <Popup
               title="Are you sure you want to publish the changes? If you confirm the operation you will be irreversible."
               contextTitle="Publish Massive Edit"
               close={() => {
                  setShowPublishModal(false);
               }}
            >
               <div className="massive-edit-page-popup__btn--container">
                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setShowPublishModal(false);
                     }}
                  >
                     CANCEL
                  </span>

                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setOpenLoadingPopup(true);
                        publishData();
                        setShowPublishModal(false);
                     }}
                  >
                     CONFIRM
                  </span>
               </div>
            </Popup>
         )}
         <header>
            <Logo />
            <h2>Massive Edit</h2>
         </header>

         <div className="content">
            <div className="massive-edit-table-container">
               <MassiveEditSelectedWITable
                  resetTableData={resetTableData}
                  onEditRow={onEditRowTable}
               />
            </div>
         </div>
         <div className="MassiveEditWi__cta--container">
            <Button
               disabled={isEmptyTable}
               handleClick={() => {
                  setShowSaveModal(true);
               }}
            >
               Save All
            </Button>
            <Button
               disabled={isEmptyTable}
               handleClick={() => {
                  setShowPublishModal(true);
               }}
            >
               Save & Publish All
            </Button>
         </div>
      </div>
   );
};

export default MassiveEditSelectedWI;
