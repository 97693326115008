import Button from "./Button";
import React, { Component } from "react";
import { connect } from "react-redux";


class PopupOpenSelect extends Component {
   constructor(props) {
      super(props);
      this.state = {
        list: [],
      }
   }

   checkList = () => {
      this.props.list.map((one) => {
        return this.handleChange(null, one, true);
      });
   };

   componentDidMount() {
      this.checkList();
   }

   handleClick = () => {
      this.props.handleClick(this.state.list);
   }

   handleChange = (e = null, one, isAllchecked = false) => {
      const itemName = e ? e.target.name : one.moduleName;
      const itemMonth = e ? e.target.value : one.monthId;
      const checked = e ? e.target.checked : true;
      const list = isAllchecked ? this.props.list : this.state.list;
      let newList;
      //console.log(itemName,itemMonth,checked);
      newList = list.map((item) => {
        if ( ((item.moduleName === itemName || item.clusterId === itemName) && item.monthId === itemMonth) || isAllchecked) {         
         return {
            ...item,
            isChecked: checked,
          };
        } else {
         return {...item};
        }
      });
      this.setState({list: newList});    
   };
   render() {
      
      let {props} = this;
      let {list} = this.state;

      return (
      <div>
         <div className="popup-background" onClick={this.handleClick} />
         <div
            className={
               "popup-container " + (props.popupClass ? props.popupClass : "")
            }
         >
            {props.contextTitle ? (
               <div
                  style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                  }}
               >
                  <span
                     style={{ position: "absolute", top: "30px", left: "35px" }}
                  >
                     {props.contextTitle}
                  </span>
                  <div className="close-popup" onClick={props.close}>
                     <span className="icon-plus" />
                  </div>
               </div>
            ) : (
               <div className="close-popup" onClick={props.close}>
                  <span className="icon-plus" />
               </div>
            )}

            <div
               className={
                  "popup-header " + (props.headerClass ? props.headerClass : "")
               }
            >
               {props.titleTop && <h3>{props.titleTop}</h3>}
               {props.title && <h3>{props.title}</h3>}
               {props.subtitle ? (
                  <span style={{ position: "relative", top: "40px" }}>
                     {props.subtitle}
                  </span>
               ) : null}
            </div>

            {list && list.length > 0 && (
                  <ul className="inner-list">
                     {list.map((single) =>  
                        (<div key={single.moduleName ? (single.moduleName + "_" + single.monthId + "1") : (single.clusterId + "_" + single.monthId + "1")}>
                             <div className="inner-box">
                               <label className="container">
                                 <input
                                   type="checkbox"
                                   name={single.moduleName ? single.moduleName : single.clusterId }                                   
                                   value={single.monthId}
                                   checked={single.isChecked}
                                   onChange={this.handleChange}
                                   className="checkboxOpenSelect"
                                 />
                                 <span className="checkmark"/>
                                 <p className="inner-text">{single.moduleLabel?single.moduleLabel:single.clusterLabel}</p>
                                 <p className="inner-text">({single.monthId})</p>
                               </label>
                             </div>
                        </div>)
                     )}
                  </ul>
               )}
            <br/>
            <br/>
            {props.btnName && (
               <div className="footer-popup" style={{display:'flex', flexDirection:'column'}}>
                  <div style={{display:'flex', justifyContent:'center'}}>
                   <Button
                     btnClass="btn-small"
                     handleClick={this.handleClick}
                     disabled={props.disabled}
                  >
                     {props.btnName}
                  </Button>
                  </div>
                  <br/>
               </div>
            )}
         </div>
      </div>
   )};
};
const mapStateToProps = (state) => {
   return {
   };
 };
export default connect(mapStateToProps)(PopupOpenSelect);
