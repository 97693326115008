import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import * as actions from "../../store/actions";

class PrivateRoute extends Component {
  resetErrStatus() {
    this.props.resetErrStatus();
  }

  componentDidMount() {
    if (!this.props.auth.grants) {
      this.props.getGrants();
    }
  }

  render() {
    const {
      component: Component,
      authorizedRoles,
      user,
      auth,
      isGAJustInizialized,
      ...rest
    } = this.props;
    if (user && user.roles && user.roles.length && auth.errStatus !== 401) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Component
              {...props}
              ReactGA={ReactGA}
              isGAJustInizialized={isGAJustInizialized}
            />
          )}
        />
      );
    } else {
      this.resetErrStatus();
      return <Redirect to={"/login"} />; //temporary
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGrants: () => dispatch(actions.getAuthorizationGrant()),
    resetErrStatus: () => dispatch(actions.resetErrStatus()),
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
