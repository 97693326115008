import * as _ from "lodash";
import * as actions from "../../store/actions";

import React, { Component } from "react";
import {
   addSelectAll,
   checkThereIsSelectAll,
   formatOptionsArray,
   getProductsList,
   isSameSku,
   selectedModuleBrandAndColl
} from "../../services/Api";

import Button from "../UI-components/Button";
import CustomInput from "../UI-components/CustomInput";
import CustomSearch from "../UI-components/CustomSearch";
import CustomSelect from "../UI-components/CustomSelect";
import { DragDropContainer } from "react-drag-drop-container";
import Loader from "../UI-components/Loader";
import RadioGrp from "../UI-components/RadioGroup";
import Sku from "./Sku";
import SkuInfoPopup from "./SkuInfoPopup";
import { connect } from "react-redux";

class ActiveSkus extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isPopupOpen: false,
         isAnagrFiltersOpen: false,
         isPerfFiltersOpen: false,
         selectedFiltersActive: {},
         activeFilters: null,
         ibicFilters: null,
         model: "",
         color: "",
         size: "",
         upc: "",
         countryOptions: null,
         sellOption: null,
         activeSku: null,
         optionsSearch: ["sku", "model", "model/color", "model/size"],
         skuClicked: null,
         searchType: "sku",
         isDraggable: true,
         selectedIndex: null,
         orderBy: null,
         optionsSell: ["sell in", "sell out"],
         productModuleList: null,
         filteredModuleList: null,
         valueSearchModule: "",
         orderSku: "sellIn",
         productsList: [],
         planningChannel: [],
         brand: [],
         collection: [],
         selectedModuleBrand: [],
         selectedModuleCollection: [],
         priceMin: [],
         priceMax: [],
         moduleList: [],
      };
   }

   componentDidMount() {
      this.updateState();
      if (this.props.macroList && this.props.macroList.length > 0) {
         let productsList = getProductsList(this.props.macroList);
         this.setState({
            productsList,
         });
      }

      if (this.props.ibicFilters) {
         let ibicFilters = addSelectAll(this.props.ibicFilters);
         this.setState({
            ibicFilters,
         });
      }

      const { brand, collection } = selectedModuleBrandAndColl(
         this.props.macroList
      );
      this.setState({
         selectedModuleBrand: brand,
         selectedModuleCollection: formatOptionsArray(collection),
      });

      if (brand.length === 1) {
         this.setState({ brand: formatOptionsArray(brand)[0] });
      }
      if (collection.length === 1) {
         this.setState({ collection: formatOptionsArray(collection)[0] });
      }

   }

   componentDidUpdate(prevProps) {
      if (prevProps.activeFilters !== this.props.activeFilters) {
         this.updateState();
      }

      if (
         prevProps.ibicFilters !== this.props.ibicFilters &&
         this.props.ibicFilters
      ) {
         let ibicFilters = addSelectAll(this.props.ibicFilters);
         this.setState({
            ibicFilters,
         });
      }
      if (prevProps.activeSku !== this.props.activeSku) {
         //console.log("ActiveSkus componentDidUpdate prevProps.activeSku", prevProps.activeSku);
         //console.log("ActiveSkus componentDidUpdate this.props.activeSku", this.props.activeSku);
         let activeSku = _.orderBy(
             this.props.activeSku,
             this.state.orderSku,
             "desc"
         );
         //console.log("ActiveSkus componentDidUpdate set state", activeSku);
         this.setState({
                activeSku,
             }, () => {
                //this.orderSkuHandler(this.state.orderSku);
                this.forceUpdate();
             }
         );
      }

      if (
         prevProps.macroList !== this.props.macroList &&
         this.props.macroList &&
         this.props.macroList.length
      ) {
         let productsList = getProductsList(this.props.macroList);

         const { brand, collection } = selectedModuleBrandAndColl(
            this.props.macroList
         );
         this.setState({
            selectedModuleBrand: brand,
            selectedModuleCollection: formatOptionsArray(collection),
            productsList,
         });
         if (brand.length === 1) {
            this.setState({ brand: formatOptionsArray(brand)[0] });
         }
         if (collection.length === 1) {
            this.setState({ collection: formatOptionsArray(collection)[0] });
         }
         if (
            this.props.selectedFilters.planningChannel &&
            this.props.selectedFilters.planningChannel.constructor === Object
         ) {
            this.setState({
               planningChannel: this.props.selectedFilters.planningChannel,
            });
         }
      }
   }

   togglePopup = (name) =>
      this.setState((prevState) => {
         return { [name]: !prevState[name] };
      });
   handleSubmit = (event) => {
      const isSearchEnabled =
         this.state.planningChannel.constructor === Object ||
         this.state.brand.constructor === Object ||
         this.state.collection.constructor === Object;
      if (isSearchEnabled) {
         event.preventDefault();
         this.handleSearch();
      }
   }

   updateState = () => {
      let selectedCountry = [];
      let countryList = this.props.selectedFilters.countryList;

      if (countryList !== null && countryList.length > 0) {
         selectedCountry = [...countryList];
      }

      let activeFilters = this.props.activeFilters
         ? addSelectAll(this.props.activeFilters)
         : null;

      this.setState({
         countryOptions: [...this.props.filters.countries],
         selectedFiltersActive:
            selectedCountry.length > 0 ? { country: selectedCountry } : {},
         activeFilters,
      });
   };

   getInfoSku = (skuClicked) => {
      this.setState({ skuClicked });
      this.props.getModulesByProducts(skuClicked, this.props.selectedFilters);
      this.togglePopup("isPopupOpen");
   };

   setSearchType = (selectedOption, i) => {
      this.setState({
         searchType: selectedOption,
         isDraggable: selectedOption === "sku" ? true : false,
         selectedIndex: i,
      });
   };

   onDragStart = (e, sku) => {
      sku.from = "added";
      this.props.dragSku(sku);
   };

   formatParameter = (p) => {
      if (p) {
         if (p.constructor === Object) {
            return [p];
         } else if (p.constructor === Array) {
            return p;
         }
      } else {
         return [];
      }
   };

   handleSearch = () => {
      let objToSend = {
         ...this.state.selectedFiltersActive,
      };
      if (this.state.model !== "") objToSend.model = this.state.model;
      if (this.state.color !== "") objToSend.color = this.state.color;
      if (this.state.size !== "") objToSend.size = this.state.size;
      if (this.state.upc !== "") objToSend.upc = this.state.upc;

      objToSend.collection = this.formatParameter(this.state.collection);
      objToSend.planningChannel = this.formatParameter(
         this.state.planningChannel
      );
      objToSend.brand = this.formatParameter(this.state.brand);
      objToSend.priceMin = this.formatParameter(this.state.priceMin);
      objToSend.priceMax = this.formatParameter(this.state.priceMax);
      objToSend.moduleList = this.props.moduleListInput
      this.props.getActiveSku(objToSend, this.props.selectedFilters);
   };

   setFilterActive = (type, opts) => {
      if (opts?.length === 0 || opts === null) {
         let previousState = { ...this.state.selectedFiltersActive };
         delete previousState[type];
         this.setState({ selectedFiltersActive: previousState });
      } else {
         for (var i = 0; i < opts.length; i++) {
            if (opts[i].value === "SELECT_ALL") {
               if (opts.length === 1) {
                  let newValues = this.state.activeFilters[type]
                     ? this.state.activeFilters[type]
                     : this.state.ibicFilters[type];
                  return this.setState((prevState) => {
                     return {
                        selectedFiltersActive: {
                           ...prevState.selectedFiltersActive,
                           [type]: newValues,
                        },
                     };
                  });
               }

               if (
                  checkThereIsSelectAll(this.state.selectedFiltersActive[type])
               ) {
                  let newValues = [...opts];
                  newValues.splice(i, 1);
                  return this.setState((prevState) => {
                     return {
                        selectedFiltersActive: {
                           ...prevState.selectedFiltersActive,
                           [type]: newValues,
                        },
                     };
                  });
               } else {
                  let newValues = this.state.activeFilters[type]
                     ? this.state.activeFilters[type]
                     : this.state.ibicFilters[type];
                  return this.setState((prevState) => {
                     return {
                        selectedFiltersActive: {
                           ...prevState.selectedFiltersActive,
                           [type]: newValues,
                        },
                     };
                  });
               }
            }
         }

         this.setState((prevState) => {
            return {
               selectedFiltersActive: {
                  ...prevState.selectedFiltersActive,
                  [type]: opts,
               },
            };
         });
      }
   };

   searchEventHandler = (e, type) => this.setState({ [type]: e.target.value });

   orderSkuHandler = (orderSku) => {
      //console.log("ActiveSkus orderSkuHandler", this.state.activeSku);
      let activeSku = _.orderBy(this.state.activeSku, orderSku, "desc");

      this.setState({
         orderSku,
         activeSku,
      });
   };

   printSku = () => {
      let listUnique = this.printUnique(
         this.state.activeSku,
         this.state.searchType
      );

      return listUnique.map((sku) => {
         return (
            <Sku
               key={sku.model + sku.color + sku.size}
               isActiveSku
               draggable={false}
               searchType={this.state.searchType}
               sku={sku}
            />
         );
      });
   };

   renderActiveSkus = (isEditable, productsList) => {
      let activeSku = _.orderBy([...this.props.activeSku], this.state.orderSku, "desc");
      //console.log("ActiveSkus renderActiveSkus", activeSku);

      return [...activeSku].map((sku) => {
         return (
             <DragDropContainer
                 targetKey="drag-drop-module"
                 onDragStart={(e) =>
                     this.onDragStart(e, sku)
                 }
                 key={
                     sku.model +
                     sku.color +
                     sku.size +
                     sku.releaseId
                 }
                 noDragging={
                    !(
                        this.state.isDraggable &&
                        isEditable
                    )
                 }
             >
                <Sku
                    isActiveSku
                    isHighlighted={
                       this.props.highlightActiveSku
                           ? isSameSku(
                               sku,
                               this.props
                                   .highlightActiveSku
                           )
                           : false
                    }
                    scrollHighlightActiveSku={
                       this.props
                           .scrollHighlightActiveSku
                    }
                    handleClickOnImg={() =>
                        this.highlightInWA(sku)
                    }
                    openPopup={() =>
                        this.getInfoSku(sku)
                    }
                  //   draggable={true}
                    changeDraging={this.changeDraging}
                    searchType={this.state.searchType}
                    orderSku={this.state.orderSku}
                    isFaded={productsList.includes(
                        sku.model + sku.color + sku.size
                    )}
                    sku={sku}
                />
             </DragDropContainer>
         );
      });
   }

   printUnique = (skuList, searchType) => {
      if (searchType.includes("/")) {
         let search = searchType.split("/");
         let skuObj = {};

         return skuList.filter((sku) => {
            let uniqueValue = sku[search[0]] + sku[search[1]];
            if (skuObj[uniqueValue] !== undefined) return false;
            else {
               skuObj[uniqueValue] = sku;
               return true;
            }
         });
      } else return _.uniqBy(skuList, searchType);
   };

   highlightInWA = (sku) => {
      if (this.state.productsList.includes(sku.model + sku.color + sku.size)) {
         this.props.highlightInWA(sku);
      }
   };

   handleChangePC = (option) => {
      this.setState({
         planningChannel: option,
      });
   };

   handleChangeBrand = (option) => {
      this.setState({
         brand: option,
      });
   };

   handleChangeCollection = (option) => {
      this.setState({
         collection: option,
      });
   };

   handlePriceMin = (value, event) => {
      const priceMin = event.target.value;
      this.setState({ priceMin: priceMin !== "" ? [priceMin] : [] });
   };

   handlePriceMax = (value, event) => {
      const priceMax = event.target.value;
      this.setState({ priceMax: priceMax !== "" ? [priceMax] : [] });
   };

   changeDraging=() => {
         this.setState({ isDraggable: !this.state.isDraggable, });
   }

   render() {
      let { activeFilters, ibicFilters } = this.state;
      let { activeSku, productsList, isPerfFiltersOpen, isAnagrFiltersOpen } =
         this.state;
      let isEditable = !this.props.isReleaseClosed;
      let resultsText = "Filter to start searching";

      if (activeSku && activeSku.length < 1) {
         resultsText = "You search didn't return any results";
      }

      const isSearchEnabled =
         this.state.planningChannel.constructor === Object ||
         (this.state.brand.constructor === Object &&
            this.state.collection.constructor === Object);

      const { brand, collection } = selectedModuleBrandAndColl(
         this.props.macroList
      );
      return (
         <div
            className={
               "component leftbar-sibling active-content " +
               (activeFilters && !this.props.isLoading ? "" : "loading")
            }
         >
            {activeFilters && !this.props.isLoading ? (
               <React.Fragment>
                  <form onSubmit={this.handleSubmit}>
                     <header>
                        <div className="active">
                           <RadioGrp
                              selectedIndex={
                                 this.state.selectedIndex !== null
                                    ? this.state.selectedIndex
                                    : 0
                              }
                              options={this.state.optionsSearch}
                              active
                              handleClick={(selectedOption, i) =>
                                 this.setSearchType(selectedOption, i)
                              }
                           />
                        </div>
                        <div className="search-bar">
                           <h2>Filter</h2>
                           <CustomSearch
                              placeholder="Model"
                              handleChange={(e) =>
                                 this.searchEventHandler(e, "model")
                              }
                              maxLength="10"
                              value={this.state.model}
                           />
                           <CustomSearch
                              placeholder="Color"
                              handleChange={(e) =>
                                 this.searchEventHandler(e, "color")
                              }
                              maxLength="10"
                              value={this.state.color}
                           />
                           <CustomSearch
                              placeholder="Size"
                              handleChange={(e) =>
                                 this.searchEventHandler(e, "size")
                              }
                              maxLength="10"
                              value={this.state.size}
                           />
                           <CustomSearch
                              placeholder="Upc code"
                              handleChange={(e) =>
                                 this.searchEventHandler(e, "upc")
                              }
                              maxLength="20"
                              value={this.state.upc}
                           />
                           <span>or select a dropdown:</span>
                        </div>
                        <span
                           className="open-filters"
                           onClick={() => this.togglePopup("isAnagrFiltersOpen")}
                        >
                           {isAnagrFiltersOpen
                              ? "- Close Filters"
                              : "+ Open Filters"}
                        </span>

                        <div
                           className={
                              "filter-sku" + (isAnagrFiltersOpen ? " show" : "")
                           }
                        >
                           {this.props.selectedFilters.planningChannel &&
                              this.props.selectedFilters.planningChannel.length >=
                              1 ? (
                              <CustomSelect
                                 placeholder="Planning Channel"
                                 value={
                                    this.props.selectedFilters.planningChannel
                                       .length === 1
                                       ? this.props.selectedFilters.planningChannel
                                       : this.state.planningChannel
                                 }
                                 options={
                                    this.props.selectedFilters.planningChannel
                                 }
                                 disabled={
                                    this.props.selectedFilters.planningChannel
                                       .length === 1
                                 }
                                 handleChange={this.handleChangePC}
                              />
                           ) : (
                              <CustomSelect
                                 placeholder="Planning Channel"
                                 options={this.props.filters.planningChannels}
                                 value={this.state.planningChannel}
                                 handleChange={this.handleChangePC}
                              />
                           )}
                           <CustomSelect
                              placeholder="Brand"
                              options={formatOptionsArray(brand)}
                              value={this.state.brand}
                              handleChange={this.handleChangeBrand}
                              disabled={brand.length === 1}
                           />
                           <CustomSelect
                              placeholder="Collection"
                              options={formatOptionsArray(collection)}
                              value={this.state.collection}
                              handleChange={this.handleChangeCollection}
                              disabled={collection.length === 1}
                           />

                           <div className="outer-select-box">
                              {Object.keys(activeFilters).map((singleFilter) => {
                                 return (
                                    <CustomSelect
                                       key={singleFilter}
                                       isMulti={singleFilter !== "discontinuation"}
                                       placeholder={singleFilter}
                                       options={singleFilter === "discontinuation" ? 
                                          activeFilters[singleFilter].filter(value => value.value !== "SELECT_ALL") :
                                          activeFilters[singleFilter]
                                       }
                                       value={
                                          this.state.selectedFiltersActive[
                                             singleFilter
                                          ]
                                             ? this.state.selectedFiltersActive[
                                             singleFilter
                                             ]
                                             : ""
                                       }
                                       handleChange={(e) =>
                                          this.setFilterActive(singleFilter, e)
                                       }
                                       isDiscontinuation
                                    />
                                 );
                              })}
                              <div className="inner-price">
                                 <CustomInput
                                    inputClass="input-price"
                                    placeholder={`PR.MIN`}
                                    min="0"
                                    type="number"
                                    handleChange={(e) =>
                                       this.handlePriceMin("priceMin", e)
                                    }
                                    value={this.state.priceMin}
                                 />
                                 <CustomInput
                                    inputClass="input-price"
                                    placeholder="PR.MAX"
                                    min="0"
                                    type="number"
                                    handleChange={(e) =>
                                       this.handlePriceMax("priceMax", e)
                                    }
                                    value={this.state.priceMax}
                                 />
                              </div>
                           </div>
                        </div>

                        {ibicFilters !== null && (
                           <React.Fragment>
                              <span
                                 className="open-filters"
                                 onClick={() =>
                                    this.togglePopup("isPerfFiltersOpen")
                                 }
                              >
                                 {isPerfFiltersOpen
                                    ? "- Close Filters"
                                    : "+ Open Filters"}
                              </span>
                              <div
                                 className={
                                    "filter-sku" +
                                    (isPerfFiltersOpen ? " show" : "")
                                 }
                              >
                                 {Object.keys(ibicFilters).map((singleFilter) => {
                                    if (singleFilter === "country")
                                       return (
                                          <CustomSelect
                                             key="Country"
                                             placeholder="Stars zone"
                                             value={
                                                this.state.selectedFiltersActive
                                                   .country
                                             }
                                             options={this.state.countryOptions}
                                             handleChange={(e) =>
                                                this.setFilterActive("country", e)
                                             }
                                          />
                                       );
                                    else if (singleFilter === "monthsRolling")
                                       return (
                                          <CustomSelect
                                             key={"monthsRolling"}
                                             placeholder={singleFilter}
                                             options={ibicFilters[singleFilter]}
                                             value={
                                                this.state.selectedFiltersActive[
                                                   singleFilter
                                                ]
                                                   ? this.state
                                                      .selectedFiltersActive[
                                                   singleFilter
                                                   ]
                                                   : ""
                                             }
                                             handleChange={(e) =>
                                                this.setFilterActive(
                                                   singleFilter,
                                                   e
                                                )
                                             }
                                          />
                                       );
                                    else {
                                       return (
                                          <CustomSelect
                                             key={singleFilter}
                                             isMulti
                                             placeholder={singleFilter}
                                             options={ibicFilters[singleFilter]}
                                             value={
                                                this.state.selectedFiltersActive[
                                                   singleFilter
                                                ]
                                                   ? this.state
                                                      .selectedFiltersActive[
                                                   singleFilter
                                                   ]
                                                   : ""
                                             }
                                             handleChange={(e) =>
                                                this.setFilterActive(
                                                   singleFilter,
                                                   e
                                                )
                                             }
                                          />
                                       );
                                    }
                                 })}
                              </div>
                           </React.Fragment>
                        )}
                        <Button
                           type='submit'
                           btnClass="btn-medium"
                           handleClick={() => this.handleSearch}
                           disabled={!isSearchEnabled}
                        >
                           Search
                        </Button>
                     </header>
                  </form>
                  <div
                     className={
                        "sku-list" +
                        (isAnagrFiltersOpen
                           ? " lower-anagr"
                           : "" || isPerfFiltersOpen
                              ? " lower-perf"
                              : "")
                     }
                  >
                     {this.props.isSearchLoading ? (
                        <Loader />
                     ) : activeSku &&
                        activeSku.length > 0 &&
                        !this.props.isSearchLoading ? (
                        <>
                           <div className="btn-container-sell">
                              <p>ORDER BY:</p>
                              <Button
                                 btnClass={
                                    "btn-medium " +
                                    (this.state.orderSku === "sellIn"
                                       ? "active"
                                       : "inactive")
                                 }
                                 handleClick={() =>
                                    this.orderSkuHandler("sellIn")
                                 }
                              >
                                 Sell In
                              </Button>
                              <Button
                                 btnClass={
                                    "btn-medium " +
                                    (this.state.orderSku === "sellOut"
                                       ? "active"
                                       : "inactive")
                                 }
                                 handleClick={() =>
                                    this.orderSkuHandler("sellOut")
                                 }
                              >
                                 Sell Out
                              </Button>
                              <Button
                                 btnClass={
                                    "btn-medium " +
                                    (this.state.orderSku === "outOfStars"
                                       ? "active"
                                       : "inactive")
                                 }
                                 handleClick={() =>
                                    this.orderSkuHandler("outOfStars")
                                 }
                              >
                                 Out of Stars
                              </Button>
                           </div>
                           <div className="sku-container">
                              {this.state.searchType === "sku"? this.renderActiveSkus(isEditable, productsList) : this.printSku(isEditable)}
                           </div>
                        </>
                     ) : (
                        <div className="search-active-results">
                           {resultsText}
                        </div>
                     )}
                  </div>
               </React.Fragment>
            ) : (
               <Loader />
            )
            }
            {
               this.state.isPopupOpen && (
                  <SkuInfoPopup
                     isActive
                     skuClicked={this.state.skuClicked}
                     productModuleList={this.props.productModuleList}
                     
                     close={() => {
                        this.togglePopup("isPopupOpen");
                        this.props.resetGetModulesByProduct();
                     }}
                     fetchExtraSellInData={false}
                  />
               )
            } 
         </div >
      );
   }
}


const mapStateToProps = (state) => {
   return {
      isReleaseClosed: state.release.isReleaseClosed,
      activeFilters: state.module.activeFilters,
      ibicFilters: state.module.ibicFilters,
      selectedFilters: state.filter.selectedFilters,
      activeSku: state.module.activeSku,
      macroList: state.module.macroList,
      filters: state.filter.filterHome,
      isSearchLoading: state.module.isSearchLoading,
      productModuleList: state.module.productModuleList,
      highlightActiveSku: state.module.highlightActiveSku,
      scrollHighlightActiveSku: state.module.scrollHighlightActiveSku,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getActiveFilter: (filters) => dispatch(actions.getActiveFilter(filters)),
      getActiveSku: (objToSend, filters) =>
         dispatch(actions.getActiveSku(objToSend, filters)),
      dragSku: (sku) => dispatch(actions.dragSku(sku)),
      getModulesByProducts: (sku, filters) =>
         dispatch(actions.getModulesByProducts(sku, filters)),
      resetGetModulesByProduct: () =>
         dispatch(actions.resetGetModulesByProduct()),
      highlightInWA: (sku) => dispatch(actions.highlightInWA(sku)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSkus);
