import React, { useState } from 'react';
import RadioGrp from "../../../components/UI-components/RadioGroup";
import './HideShowPanel.scss'


const HideShowPanel = ({ hideDetailsPhoto, hideDetailsGc }) => {

    const [selectedPhoto, setSelectedPhoto] = useState();
    const [selectedGC, setSelectedGC] = useState();
    const items = ["PHOTO", "GLOBAL CAT."];

    return (
        <div className='filterPanel__section'>
            <div className='filterPanel__firstDiv'>
                Hide / Show
            </div>
            <div className='orderPanel__buttonSection'>
                <RadioGrp
                    containerClass="filterPanel_radioGroup"
                    options={['PHOTO']}
                    active={false}
                    selectedIndex={selectedPhoto}
                    handleClick={(e) => {
                        const finded = items.indexOf(e);
                        let valueToSet = null;
                        let valueToPropagate = null;
                        if (finded >= 0) {
                            valueToSet = finded;
                            valueToPropagate = e;
                            if (selectedPhoto === finded) {
                                valueToSet = null;
                                valueToPropagate = null;
                            }
                        }
                        setSelectedPhoto(valueToSet);
                        hideDetailsPhoto(valueToPropagate);
                    }}
                />
                <RadioGrp
                    containerClass="filterPanel_radioGroup"
                    options={["GLOBAL CAT."]}
                    active={false}
                    selectedIndex={selectedGC}
                    handleClick={(e) => {
                        const finded = items.indexOf(e) - 1;
                        let valueToSet = null;
                        let valueToPropagate = null;
                        if (finded >= 0) {
                            valueToSet = finded;
                            valueToPropagate = e;
                            if (selectedGC === finded) {
                                valueToSet = null;
                                valueToPropagate = null;
                            }
                        }
                        setSelectedGC(valueToSet);
                        hideDetailsGc(valueToPropagate);
                    }}
                />

            </div>
        </div>
    )
}
export default HideShowPanel