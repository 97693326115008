/* eslint-disable array-callback-return */

import * as actionTypes from "./actionTypes";
import * as urlList from "../../config";
import { getCookie } from "../../services/cookie";
import { handleErrorsJson } from "../../services/Api";
import { showError } from "./errorActions";
import axios from "axios";

/* LOCK */
export const lockModule = (params, callback = null) => {
   return (dispatch) => {
      let url = urlList.LOCK_MODULE_URL;
      dispatch(lockModuleRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            dispatch(lockModuleSuccess(response));
            if (callback) callback();
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(lockModuleFailure());
         });
   };
};

const lockModuleRequest = () => {
   return {
      type: actionTypes.LOCK_MODULE_REQUEST,
   };
};

const lockModuleSuccess = (response) => {
   return {
      type: actionTypes.LOCK_MODULE_SUCCESS,
      response,
   };
};

const lockModuleFailure = () => {
   return {
      type: actionTypes.LOCK_MODULE_FAILURE,
   };
};

export const lockMacro = (params, callback = null) => {
   return (dispatch) => {
      let url = urlList.LOCK_MACRO_URL;
      dispatch(lockMacroRequest());
      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((response) => {
            dispatch(lockMacroSuccess(response));
            if (callback != null) callback();
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(lockMacroFailure());
         });
   };
};

const lockMacroRequest = () => {
   return {
      type: actionTypes.LOCK_MACRO_REQUEST,
   };
};

const lockMacroSuccess = (response) => {
   return {
      type: actionTypes.LOCK_MACRO_SUCCESS,
      response,
   };
};

const lockMacroFailure = () => {
   return {
      type: actionTypes.LOCK_MACRO_FAILURE,
   };
};

export const lockCluster = (params, callback = null) => {
   return (dispatch) => {
      let url = urlList.LOCK_CLUSTER_URL;
      dispatch(lockClusterRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            dispatch(lockClusterSuccess(json));
            if (callback) callback();
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(lockClusterFailure());
         });
   };
};

export const lockClusters = (clusterList, callback = null) => {

   const params = clusterList.map(cluster => {
      return({
         clusterId: cluster.clusterId,
         monthId: cluster.monthId
      })
   })

   return (dispatch) => {
      let url = urlList.LOCK_CLUSTER_URL;
      dispatch(lockClusterRequest());

      fetch(url, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            dispatch(lockClusterSuccess(json));
            if (callback) callback();
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(lockClusterFailure());
         });
   };
};

const lockClusterRequest = () => {
   return {
      type: actionTypes.LOCK_CLUSTER_REQUEST,
   };
};

const lockClusterSuccess = (response) => {
   return {
      type: actionTypes.LOCK_CLUSTER_SUCCESS,
      response,
   };
};

const lockClusterFailure = () => {
   return {
      type: actionTypes.LOCK_CLUSTER_FAILURE,
   };
};

/* UNLOCK ELEMENT */
export const unlockElement = (item, typeToUnlock) => {
   return (dispatch, getState) => {
      let filters = getState().filter.selectedFilters;

      switch (typeToUnlock) {
         case "module":
            dispatch(
               unlockModule(
                  [
                     {
                        moduleName: item.moduleName,
                        monthId: item.monthId,
                     },
                  ],
                  true
               )
            );
            break;
         case "macro-family":
            dispatch(unlockMacro(filters, [item], true));
            break;
         case "cluster":
            dispatch(unlockCluster([item], true));
            break;
         default:
      }
   };
};

/* UNLOCK */
export const unlockModule = (modules, isForced = false) => {
   return (dispatch) => {
      let url = urlList.UNLOCK_MODULE_URL;
      dispatch(unlockModuleoRequest());

      fetch(url + "?forced=" + isForced, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(modules),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            dispatch(unlockModuleSuccess(json));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(unlockModuleFailure());
         });
   };
};

const unlockModuleoRequest = () => {
   return {
      type: actionTypes.UNLOCK_MODULE_REQUEST,
   };
};

const unlockModuleSuccess = (response) => {
   return {
      type: actionTypes.UNLOCK_MODULE_SUCCESS,
      response,
   };
};

const unlockModuleFailure = () => {
   return {
      type: actionTypes.UNLOCK_MODULE_FAILURE,
   };
};

export const unlockMacro = (filters, macro, isForced = false) => {
   let params = [];
   macro.map((m) => {
      return params.push({
         monthId: filters.months ? [filters.months.value] : [],
         brand: m.brand,
         macroFamily: m.macroFamily,
         collection: m.collection,
      });
   });

   return (dispatch) => {
      let url = urlList.UNLOCK_MACRO_URL;
      dispatch(unlockMacroRequest());

      fetch(url + "?forced=" + isForced, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            dispatch(unlockMacroSuccess(json));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(unlockMacroFailure());
         });
   };
};
const unlockMacroRequest = () => {
   return {
      type: actionTypes.UNLOCK_MACRO_REQUEST,
   };
};

const unlockMacroSuccess = (releaseList) => {
   return {
      type: actionTypes.UNLOCK_MACRO_SUCCESS,
      releaseList,
   };
};

const unlockMacroFailure = () => {
   return {
      type: actionTypes.UNLOCK_MACRO_FAILURE,
   };
};

export const unlockCluster = (params, isForced = false) => {
   return (dispatch) => {
      let url = urlList.UNLOCK_CLUSTER_URL;
      dispatch(unlockClusterRequest());

      fetch(url + "?forced=" + isForced, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            dispatch(unlockClusterSuccess(json));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(unlockClusterFailure());
         });
   };
};

export const unlockClusters = (clusterList, isForced = false) => {
   
   const params = clusterList.map(cluster => {
      return({
         clusterId: cluster.clusterId,
         monthId: cluster.monthId
      })
   })

   return (dispatch) => {
      let url = urlList.UNLOCK_CLUSTER_URL;
      dispatch(unlockClusterRequest());

      fetch(url + "?forced=" + isForced, {
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         body: JSON.stringify(params),
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((json) => {
            dispatch(unlockClusterSuccess(json));
         })
         .catch((err) => {
            dispatch(showError(err));
            dispatch(unlockClusterFailure());
         });
   };
};

const unlockClusterRequest = () => {
   return {
      type: actionTypes.LOCK_CLUSTER_REQUEST,
   };
};

const unlockClusterSuccess = (response) => {
   return {
      type: actionTypes.UNLOCK_CLUSTER_SUCCESS,
      response,
   };
};

const unlockClusterFailure = () => {
   return {
      type: actionTypes.UNLOCK_CLUSTER_FAILURE,
   };
};

/*** GET USERS */
export const getUserList = () => {
   return (dispatch) => {
      let url = urlList.GET_USERS_LIST_URL;

      fetch(url, {
         method: "get",
         headers: { xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken") },
      })
         .then((res) => handleErrorsJson(res, dispatch))
         .then((userList) => {
            dispatch(getUserListSuccess(userList));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getUserListSuccess = (userList) => {
   return {
      type: actionTypes.GET_USERLIST,
      userList,
   };
};

export const createCreator = (params, callback) => {
   return (dispatch) => {
      let url = urlList.CREATE_USER_CREATOR_URL;
      dispatch(createCreatorRequest());

      axios({
         url,
         method: "post",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         data: JSON.stringify(params),
      })
         .then((json) => {
            dispatch(createCreatorSuccess(json.data, callback));
         })
         .catch((err) => {
            dispatch(createCreatorFailure(err.response.data, callback));
         });
   };
};

const createCreatorRequest = () => {
   return {
      type: actionTypes.CREATE_USER_CREATOR_REQUEST,
   };
};

const createCreatorSuccess = (response, callback) => {
   return {
      type: actionTypes.CREATE_USER_CREATOR_SUCCESS,
      response,
      callback,
   };
};

const createCreatorFailure = (response, callback) => {
   return {
      type: actionTypes.CREATE_USER_CREATOR_FAILURE,
      response,
      callback,
   };
};

export const saveUserGrants = (grants, isSavingDB) => {
   return {
      type: actionTypes.SAVE_USER_GRANT,
      grants,
      isSavingDB,
   };
};

export const getCurrentUser = (user) => {
   return {
      type: actionTypes.GET_CURRENT_USER,
      user,
   };
};

export const closeCurrentUser = (grants) => {
   return {
      type: actionTypes.CLOSE_CURRENT_USER,
      grants,
   };
};

export const setSaveLoading = (payload) => {
   return {
      type: actionTypes.SET_SAVE_LOADING,
      payload: payload,
   };
};

export const saveUser = (currentUser) => {
   return (dispatch) => {
      dispatch(setSaveLoading(true));

      let url = urlList.SAVE_USER;
      try {
         axios
            .post(url, currentUser, {
               headers: {
                  xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
               },
            })
            .then((res) => {
               dispatch(setSaveLoading(false));
               dispatch(saveUserGrants(res.data.grants, true));
               dispatch(getCurrentUser(currentUser));
            });
      } catch (err) {
         dispatch(saveUserFailure());
         dispatch(setSaveLoading(false));
         dispatch(showError(err));
      }
   };
};

const saveUserFailure = () => {
   return {
      type: actionTypes.SAVE_USER_FAILURE,
   };
};

export const getStatusUser = (userId, isEnabled) => {
   return (dispatch) => {
      let url = urlList.ENABLE_DISABLE_USER;
      let status = isEnabled ? "/disable" : "/enable";
      let completeUrl = url + userId + status;
      axios({
         url: completeUrl,
         method: "POST",
         headers: { xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken") },
      })
         .then((statusUser) => {
            dispatch(getStatusUserSuccess(statusUser.data.status));
            dispatch(setSaveLoading(false));
         })
         .catch((err) => {
            console.error(err);
            dispatch(setSaveLoading(false));
            dispatch(showError(err));
         });
   };
};

export const getStatusUserSuccess = (statusUser) => {
   return {
      type: actionTypes.GET_STATUS_USER,
      statusUser,
   };
};

/*** getManageBrandList */
export const getManageBrandList = () => {
   return (dispatch) => {
      let url = urlList.GET_MANAGE_BRANDLIST_URL;
      axios({
         url,
         method: "GET",
         headers: { xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken") },
      })
         .then((brandList) => {
            dispatch(getManageBrandListSuccess(brandList.data));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getManageBrandListSuccess = (brandList) => {
   return {
      type: actionTypes.GET_MANAGE_BRANDLIST,
      brandList,
   };
};

/*** getManageStarsZone */
export const getManageStarsZoneList = () => {
   return (dispatch) => {
      let url = urlList.GET_COUNTRYLIST_URL;
      axios({
         url,
         method: "GET",
         headers: { xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken") },
      })
         .then((starsZoneList) => {
            dispatch(getManageStarsZoneListSuccess(starsZoneList.data));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getManageStarsZoneListSuccess = (starsZoneList) => {
   return {
      type: actionTypes.GET_MANAGE_STARSZONELIST,
      starsZoneList,
   };
};

/*** getRuleList */
export const getRuleList = () => {
   return (dispatch) => {
      let url = urlList.GET_RULE_LIST_URL;
      axios({
         url,
         method: "GET",
         headers: { xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken") },
      })
         .then((res) => {
            dispatch(getRuleListSuccess(res.data));
         })
         .catch((err) => {
            dispatch(showError(err));
         });
   };
};

export const getRuleListSuccess = (ruleList) => {
   return {
      type: actionTypes.GET_RULE_LIST,
      ruleList,
   };
};
