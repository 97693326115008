import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import * as actions from "../../../store/actions";
import Sku from "../Sku";
import SkuZoom from "../../Module-Composition/SkuZoom";
import { Switch } from "../../UI-components/Switch";
import { isEmpty } from "lodash";

function PerformanceTable({
   setIsSegmentationView,
   isSegmentationView,
   showBoxShadowTableHead,
   showBoxShadowSkus,
   upcList,
   headerTable,
   tableContent,
   // elementScrollTop,
   percentual,
}) {
   const [openPopupZoom, setOpenPopupZoom] = useState({
      isOpen: false,
      imgPath: "",
      skuModel: "",
      skuColor: "",
      skuSize: "",
   });
   const [currentPage, setCurrentPage] = useState(1);
   const tableDataUpcList = upcList;
   const [data, setData] = useState(tableDataUpcList);
   const elementToShow = 10;

   useEffect(() => {
      setData(tableDataUpcList);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      setData(tableDataUpcList);
   }, [tableDataUpcList, isSegmentationView]);

   const paginateUpcList = useCallback(() => {
      const res =
         tableDataUpcList &&
         Array.isArray(tableDataUpcList) &&
         tableDataUpcList.slice(0, elementToShow * currentPage);

      return res ? res : [];

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentPage, data, isSegmentationView]);

   const paginateTableContent = useCallback(() => {
      const sliced =
         !isEmpty(tableContent) &&
         Object.keys(tableContent)
            .slice(0, elementToShow * currentPage)
            .reduce((result, key) => {
               result[key] = tableContent[key];

               return result;
            }, {});

      return sliced ? sliced : {};

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentPage, tableContent, data, isSegmentationView]);

   const [upcListPaginated, setUpcListPaginated] = useState([]);
   const [tableContentPaginated, setTableContentPaginated] = useState([]);

   useEffect(() => {
      setUpcListPaginated(paginateUpcList());
      setTableContentPaginated(paginateTableContent());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [currentPage, isSegmentationView, tableContent, data]);

   useEffect(() => {
      setCurrentPage(1);
   }, [isSegmentationView]);

   const incrementPagination = useCallback(() => {
      setCurrentPage(currentPage + 1);
   }, [currentPage]);

   // useEffect(() => {
   //    if (elementScrollTop >= 200 * currentPage) {
   //       incrementPagination();
   //    }
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [elementScrollTop]);

   useEffect(() => {
      if (percentual >= 85) {
         incrementPagination();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [percentual]);

   return (
      <table
         id="PerformanceTable__container"
         className="PerformanceTable__container"
      >
         <thead
            className={
               !showBoxShadowTableHead
                  ? "PerformanceTable__container__thead"
                  : "PerformanceTable__container__thead PerformanceTable__container__thead--box-shadow"
            }
         >
            <tr className="PerformanceTable__container__tr-switch--container">
               <th
                  className={
                     !showBoxShadowSkus
                        ? "PerformanceTable__container__cell-sku-container"
                        : "PerformanceTable__container__cell-sku-container--shadow"
                  }
                  style={{ zIndex: "200" }}
               >
                  <Switch
                     isChecked={isSegmentationView}
                     onChange={() => {
                        setIsSegmentationView(!isSegmentationView);
                     }}
                     label={["stars zone view", "buying behavior view"]}
                  />
               </th>
               {headerTable.map((starZone, i) => {
                  return (
                     <th key={"header - " + i}>
                        <div className="PerformanceTable__container__header-label--container">
                           {isSegmentationView ? (
                              <>
                                 <span
                                    data-tip={`${starZone.segmentationName} - ${starZone.starZoneName}`}
                                    className="PerformanceTable__container__header--label"
                                 >
                                    {starZone.segmentationName} <br />
                                    <span>{starZone.summedNumDoors} D</span>
                                 </span>
                                 <ReactTooltip
                                    className="disco-remove-table__tooltip--style"
                                    effect="solid"
                                 />
                              </>
                           ) : (
                              <span className="PerformanceTable__container__header--label">
                                 {starZone.name}
                              </span>
                           )}

                           {isSegmentationView ? null : (
                              <span>{starZone.summedNumDoors} D</span>
                           )}
                        </div>
                     </th>
                  );
               })}
            </tr>
         </thead>
         <tbody>
            {upcListPaginated &&
               upcListPaginated.map((sku, i) => {
                  const sku2 = {
                     ...sku,
                     // upc: sku.model,
                     upc: sku.skuId,
                  };

                  return (
                     //<tr style={{ height: "160px" }} key={`${sku.model} - tr`}>
                     <tr style={{ height: "160px" }} key={`${sku.skuId} - tr`}>
                        <td
                           className={
                              !showBoxShadowSkus
                                 ? "PerformanceTable__container__cell-sku-container"
                                 : "PerformanceTable__container__cell-sku-container--shadow"
                           }
                        >
                           <div className="PerformanceTable__sku--container">
                              <Sku
                                 //key={`${sku.model} - sku`}
                                 key={`${sku.skuId} - sku`}
                                 isWorking
                                 isHighlighted={false}
                                 scrollHighlightWASku={false}
                                 searchType="sku"
                                 // tB:03-2022
                                 showMHELasLabel
                                 isEditable={false}
                                 openPopup={() => () => { }}
                                 sku={sku2}
                                 handleClickOnImg={() => { }}
                                 handleClick={() => { }}
                                 isFaded={false}
                                 handleEligibleAndMH={() => { }}
                                 handleClickOnZoom={() => {
                                    setOpenPopupZoom({
                                       isOpen: true,
                                       imgPath: sku.imageUrl,
                                       skuModel: sku.model,
                                       skuColor: sku.color,
                                       skuSize: sku.size,
                                    });
                                 }}
                              />
                           </div>
                        </td>

                        {tableContentPaginated &&
                           //tableContentPaginated[sku.model] &&
                           //tableContentPaginated[sku.model].map(
                           tableContentPaginated[sku.skuId] &&
                           tableContentPaginated[sku.skuId].map(
                              (content, i) => {
                                 return (
                                    <td
                                       //key={`${sku.model} - ${content.summedSellOut} - ${i} - ${content.typeInterval}`}
                                       key={`${sku.skuId} - ${content.summedSellOut} - ${i} - ${content.typeInterval}`}
                                    >
                                       <span
                                          className={`PerformanceTable__sellout--label ${content.typeInterval}`}
                                       >
                                          {content.summedSellOut}
                                       </span>
                                    </td>
                                 );
                              }
                           )}
                        {openPopupZoom.isOpen && (
                           <SkuZoom
                              imgPath={openPopupZoom.imgPath}
                              skuModel={openPopupZoom.skuModel}
                              skuColor={openPopupZoom.skuColor}
                              skuSize={openPopupZoom.skuSize}
                              calledFromTablePerfo
                              classImg="PerformanceTable__sku-zoommed"
                              hideZoomParent={() => {
                                 setOpenPopupZoom({
                                    isOpen: false,
                                    imgPath: "",
                                    skuModel: "",
                                    skuColor: "",
                                    skuSize: "",
                                 });
                              }}
                           />
                        )}
                     </tr>
                  );
               })}
         </tbody>
      </table>
   );
}

const mapStateToProps = (state) => {
   return {
      isSegmentationView: state.performancePopup.isSegmentationView,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setIsSegmentationView: (isSegmentationView) =>
         dispatch(actions.setIsSegmentationView(isSegmentationView)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceTable);
