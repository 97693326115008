/* eslint-disable array-callback-return */
import React, { Component } from "react";
import Logo from "../UI-components/Logo";
import CustomIcon from "../UI-components/CustomIcon";
import Loader from "../UI-components/Loader";
import Button from "../UI-components/Button";
import ModuleJar from "../Cluster-Composition/ModuleJar/ModuleJar";
import Module from "../Module-Composition/Module";
import Tooltip from "../UI-components/Tooltip";
import {
   formatCamelCase,
   orderModulesByMacro,
   formattedDateGivenAnInput,
} from "../../services/Api";
import { connect } from "react-redux";
import * as actions from "../../store/actions";

let orderLine = [
   "country",
   "userSuggest",
   "dateSuggest",
   "originalAssortment",
   "assortmentId",
   "period",
   "starsCustomer",
   "customerSegmentation",
   "storeJda",
   "status",
];

class PreviewCluster extends Component {
   state = {
      cluster: null,
      modulePrincList: null,
      moduleSelected: null,
   };

   componentDidMount = () => {
      this.initCluster();
   };

   componentDidUpdate = (prevProps) => {
      //to change from originle to preview
      if (
         prevProps.selectedCluster.assortmentId !==
         this.props.selectedCluster.assortmentId
      ) {
         this.initCluster();
      }

      if (prevProps.cluster !== this.props.cluster) {
         let modulePrincList = [];
         this.props.cluster.modules.map((moduleItem) => {
            if (
               moduleItem.foType === "SWITCH" ||
               moduleItem.foType === "NOT SWITCH"
            ) {
               modulePrincList.push(moduleItem);
            }
         });

         modulePrincList = orderModulesByMacro(modulePrincList, true);
         this.setState({
            cluster: this.props.cluster,
            modulePrincList,
         });
      }
   };

   initCluster = () => {
      let selectedCluster = this.props.selectedCluster;
      this.props.getCluster(selectedCluster);
      this.setState({
         isSuggested: selectedCluster.isSuggested,
      });
   };

   handleClick = (moduleItem) => {
      this.setState({
         moduleSelected: moduleItem,
      });
   };

   render() {
      let clusterLine = this.props.clusterLine;

      return (
         <React.Fragment>
            {!this.props.isLoadingCluster && this.state.cluster ? (
               <div className="preview">
                  <header className="minimal-header">
                     <Logo />
                     <h2>Viewing cluster</h2>
                     <Button
                        btnClass="btn-small btn-outline"
                        handleClick={this.props.closePreview}
                     >
                        Back
                     </Button>
                  </header>
                  <div className="table">
                     <div className="table-header">
                        <ul>
                           {this.props.filters ? (
                              <React.Fragment>
                                 {this.props.filters.map((filter) => (
                                    <li key={filter.filterName}>
                                       <div className="title-container">
                                          {" "}
                                          {formatCamelCase(
                                             filter.filterName
                                          )}{" "}
                                       </div>
                                    </li>
                                 ))}
                              </React.Fragment>
                           ) : (
                              <Loader />
                           )}
                        </ul>
                     </div>
                     <div className="table-body">
                        <div className="table-body-container">
                           <ul className="table-item">
                              {orderLine.map((key) => {
                                 let item = clusterLine[key];
                                 if (item && item.constructor === Array) {
                                    item = item.join();
                                 }
                                 if (
                                    key === "status" &&
                                    item.toUpperCase() === "PENDING"
                                 ) {
                                    return (
                                       <li
                                          key={clusterLine.assortmentId + key}
                                          className="actions"
                                       >
                                          <CustomIcon
                                             iconClass="icon-check-mark-icon"
                                             handleClick={() =>
                                                this.props.handleClick(
                                                   "approve",
                                                   clusterLine
                                                )
                                             }
                                          />
                                          <CustomIcon
                                             iconClass="icon-close_icon"
                                             handleClick={() =>
                                                this.props.handleClick(
                                                   "reject",
                                                   clusterLine
                                                )
                                             }
                                          />
                                       </li>
                                    );
                                 }
                                 if (
                                    key === "status" &&
                                    item.toUpperCase() === "REJECTED"
                                 ) {
                                    return (
                                       <li
                                          key={clusterLine.assortmentId + key}
                                          className="rejected"
                                       >
                                          {clusterLine.message ? (
                                             <Tooltip
                                                text={clusterLine.message}
                                                tooltipClass="tooltip-table large normal left"
                                             >
                                                <p>{item}</p>
                                             </Tooltip>
                                          ) : (
                                             <p>{item}</p>
                                          )}
                                       </li>
                                    );
                                 }
                                 if (
                                    key === "originalAssortment" ||
                                    key === "assortmentId"
                                 )
                                    return (
                                       <li
                                          key={clusterLine.assortmentId + key}
                                          className="clickable"
                                          onClick={() =>
                                             this.props.seePreview(
                                                clusterLine,
                                                clusterLine[key],
                                                key
                                             )
                                          }
                                       >
                                          <Tooltip
                                             text={item}
                                             tooltipClass="tooltip-table large normal right"
                                          >
                                             <p>{clusterLine[key]}</p>
                                          </Tooltip>
                                       </li>
                                    );
                                 if (key === "dateSuggest") {
                                    return (
                                       <li key={clusterLine.assortmentId + key}>
                                          {" "}
                                          <p>
                                             {formattedDateGivenAnInput(item)}
                                          </p>{" "}
                                       </li>
                                    );
                                 }
                                 return (
                                    <li
                                       key={clusterLine.assortmentId + key}
                                       className={
                                          key === "status"
                                             ? "voted " + item.toLowerCase()
                                             : ""
                                       }
                                    >
                                       <p>{item}</p>
                                    </li>
                                 );
                              })}
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="content">
                     <div className="black-section module-list">
                        <header className="header-section">
                           <h2 className="title">
                              {this.state.cluster.assortmentId}
                           </h2>
                           {/* <Button btnClass="btn-medium">Export Excel</Button>                           */}
                        </header>
                        <div>
                           {Object.keys(this.state.modulePrincList).map(
                              (key) => (
                                 <div key={key} className="family-container">
                                    <h2>{key}</h2>
                                    {this.state.modulePrincList[key].map(
                                       (moduleI) => {
                                          return (
                                             <ModuleJar
                                                key={moduleI.moduleName}
                                                moduleItem={moduleI}
                                                draggable={false}
                                                isSwitched={
                                                   moduleI.moduleNameSwitch
                                                      ? true
                                                      : false
                                                }
                                                isAdded={moduleI.added}
                                                goToSingleModule={() =>
                                                   this.props.getModule([
                                                      moduleI,
                                                   ])
                                                }
                                             />
                                          );
                                       }
                                    )}
                                 </div>
                              )
                           )}
                        </div>
                     </div>
                     <div
                        className={
                           "white-section" +
                           (this.props.singleModule ? "" : " empty")
                        }
                     >
                        {this.props.singleModule ? (
                           <React.Fragment>
                              <header>
                                 <h2>Module Composition </h2>
                              </header>
                              <div className="module-list-area">
                                 <Module
                                    moduleComponent={this.props.singleModule}
                                    isDisabled
                                    hideRemoved
                                    singleMacro={
                                       this.props.singleModule.macroFamily
                                    }
                                 />
                              </div>
                           </React.Fragment>
                        ) : (
                           <p>Click on a module to see its composition</p>
                        )}
                     </div>
                  </div>
               </div>
            ) : (
               <div className="full-page">
                  <Loader />
               </div>
            )}
         </React.Fragment>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      cluster: state.cluster.cluster,
      singleModule: state.cluster.singleModule,
      isLoadingCluster: state.cluster.isLoadingCluster,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getCluster: (cluster) => dispatch(actions.getCluster(cluster)),
      getModule: (moduleItem) =>
         dispatch(actions.getWorkingModule(moduleItem, true)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCluster);
