import * as actions from "../../store/actions";

import { GET_PENDING_AREA_INITIAL_FILTERS, GET_PENDING_CLUSTER_INFO, GET_PENDING_MODULE_INFO } from "../../config";
import React, { useEffect, useLayoutEffect, useState } from "react";

import Button from "../../components/UI-components/Button";
import Loader from "../../components/UI-components/Loader";
import Logo from "../../components/UI-components/Logo";
import { PaginatedSelect } from "../../components/UI-components/PaginatedSelect";
import { PendingAreaTable } from "../../components/PendingAreaTable";
import { Switch } from "../../components/UI-components/Switch";
import _ from "lodash";
import { connect } from "react-redux";
import { getCookie } from "../../services/cookie";

const emptySelectedFilters = {
    status: [],
    brandCode: [],
    commodity: [],
    months: [],
 };

 const PendingArea = ({
    rowsToSendMassiveEdit,
    logout,
    setResetRowsToSendMassiveEdit,
    history,
 }) => {
    const [isClusterArea, setIsClusterArea] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [inputValue, setInputValue] = useState(1);
    const [tableData, setTableData] = useState();
    const [showLimitNumber, setShowLimitNumber] = useState(false);
    const [showBoxShadow, setShowBoxShadow] = useState(false);
    const [showAlreadyInChoosedPage, setShowAlreadyInChoosedPage] = useState(false);
    const [actualPage, setActualPage] = useState(1);
    const [reload, setReload] = useState(false);

 
    const [selectedFiltersPendingArea, setSelectedFiltersPendingArea] = useState(emptySelectedFilters);
    const [filterValues, setFilterValues] = useState(emptySelectedFilters);
    const [tooManyResults, setTooManyResults] = useState(false);
    const [isStart, setIsStart] = useState(true);
    const [isTypeViewChanged, setIsTypeViewChanged] = useState(false);
 
    useEffect(() => {
       setIsDataLoading(true);
       setResetRowsToSendMassiveEdit({});
       setTooManyResults(false);
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    useEffect(() => {
       setIsDataLoading(true);
       setResetRowsToSendMassiveEdit({});
       setTooManyResults(false);
       /* getFilteredDataAndTableData({
          brandCode: [],
          commodity: [],
          status: [],
          moduleLabel: [],
          months: [],
          pageIndex: 0,
       }); */
       getFiltersContent();
       setActualPage(1);
       setIsTypeViewChanged(true); 
       setTableData();
       setSelectedFiltersPendingArea(emptySelectedFilters);        
       setIsTypeViewChanged(true);
       setIsStart(true);
       setIsDataLoading(false);
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClusterArea]);
 
    useEffect(() => {
       // GETS DATA AT EVERY CHANGE OF PAGE, AT START AND AT CHANGE OF TYPE OF VIEW
       //setIsDataLoading(true);
       setTooManyResults(false);
       if (!isStart && !isTypeViewChanged) {
         getFilteredDataAndTableData(
         adaptedSelectedFiltersToSendAndSendActualPage()
        );
         setIsTypeViewChanged(false)       
      }
      setIsTypeViewChanged(false) 
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualPage]);
 
    useLayoutEffect(() => {
       const tableContainerElementDOM = document.getElementById(
          "table-pending-area-container"
       );
 
       const renderizedTableContainerElementDOM = tableContainerElementDOM
          ? tableContainerElementDOM
          : null;
 
       const detect = () => {
          const isTabledScrolledEnoughToShowShadow =
             renderizedTableContainerElementDOM.scrollTop >= 50 ? true : false;
 
          setShowBoxShadow(isTabledScrolledEnoughToShowShadow);
       };
 
       renderizedTableContainerElementDOM !== null &&
          renderizedTableContainerElementDOM.addEventListener("scroll", detect);
 
       return () => {
          renderizedTableContainerElementDOM !== null &&
             renderizedTableContainerElementDOM.removeEventListener(
                "scroll",
                detect
             );
       };
    });
 
    useLayoutEffect(() => {
       const tableContainerElementDOM = document.getElementById(
          "table-pending-area-container"
       );
 
       const renderizedTableContainerElementDOM = tableContainerElementDOM
          ? tableContainerElementDOM
          : null;
 
       renderizedTableContainerElementDOM !== null &&
          renderizedTableContainerElementDOM.scrollTo(0, 0);
       setShowBoxShadow(false);
    }, [actualPage]);

    useEffect(() => {
      if(reload){
         getFilteredDataAndTableData(
            adaptedSelectedFiltersToSendAndSendActualPage()
         );
         setReload(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reload]);
 
    const handleSearch = (e) => {
       setIsDataLoading(true);
 
       if (actualPage !== 1) {
          setActualPage(1);
          setResetRowsToSendMassiveEdit({});
       } else {
          getFilteredDataAndTableData(
             adaptedSelectedFiltersToSendAndSendActualPage()
          );
          setResetRowsToSendMassiveEdit({});
       }
       setInputValue(1);
       e.preventDefault();
    };
   /*  const handleReload = () => {
      setIsDataLoading(true);
      getFilteredDataAndTableData(
         adaptedSelectedFiltersToSendAndSendActualPage()
      );
      setResetRowsToSendMassiveEdit({});
      setInputValue(1); //da testare
   }; */
 
    const getFiltersContent = () => {
       const url = GET_PENDING_AREA_INITIAL_FILTERS; 
       fetch(url, { 
          method: "GET",
          headers: {
             "Content-Type": "application/json",
             xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
          },
          cacheLife: 1,
          cachePolicy: "no-cache",
       })
          .then((response) => {
             if (response.ok) {
                return response.json();
             }
             if (response.status === 201) {
                return response.json();
             }
             if (response.status === 401) {
                logout();
                return response.json();
             }
             if (response.status === 403) {
                return response.json();
             }
             if (response.status === 404) {
                return response.json();
             }
             return response.json();
          })
          .then((data) => {
             if (data && data !== null && data !== undefined) {
                return setFilterValues(data);
             }
          })
          .catch((err) => {
             console.error(err);
          });
    };
 
    const adaptedFilterOptions = (filter) => {
       let items = [];
       if (filterValues && filter) {
          const mapped = filterValues[filter] && filterValues[filter].map((element) => ({
             value: element,
             label: element,
          }));
          if (mapped) {
 
             items = [...items, ...mapped];
          }
       }
       return _.sortBy(items, "value");
    };
 
    const isDisabled = (filter) => (filterValues[filter]) ? !filterValues[filter].length : false;
 
    const getFilteredDataAndTableData = (selectedFilters) => {
       setTableData([]);
       setIsStart(false);
       const url = !isClusterArea
          ? GET_PENDING_MODULE_INFO
          : GET_PENDING_CLUSTER_INFO; 
       fetch(url, {
          method: "POST", 
          headers: {
             "Content-Type": "application/json",
             xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
          },
          cacheLife: 1,
          cachePolicy: "no-cache",
          body: JSON.stringify(selectedFilters),
       })
          .then((response) => {
             if (response.ok) {
                setIsDataLoading(false);
                setTooManyResults(false);
                return response.json();
             }
             if (response.status === 201) {
                setIsDataLoading(false);
                setTooManyResults(false);
                return response.json();
             }
             if (response.status === 401) {
                setIsDataLoading(false);
                setTooManyResults(false);
                logout();
                return response.json();
             }
             if (response.status === 403) {
                setTooManyResults(true);
                setIsDataLoading(false);
                return response.json();
             }
             if (response.status === 404) {
                setIsDataLoading(false);
                setTooManyResults(false);
                return response.json();
             }
             return response.json();
          })
          .then((data) => {
             if (data && data !== null && data !== undefined) {
                setTableData(data);
                setIsDataLoading(false);
             }
          })
          .catch((err) => {
             setIsDataLoading(false);
             console.error(err);
          });
    };
 
    const adaptedSelectedFiltersToSendAndSendActualPage = () => {
       let objToSend = {};
 
       const brandFilterToSend =
          selectedFiltersPendingArea.brandCode.length &&
          selectedFiltersPendingArea.brandCode.map((el) => {
             return el.value;
          });
 
       objToSend.brandCode = brandFilterToSend || [];
 
       const commodityFilterToSend =
          selectedFiltersPendingArea.commodity.length &&
          selectedFiltersPendingArea.commodity.map((el) => {
             return el.value;
          });
 
       objToSend.commodity = commodityFilterToSend || [];
 
       const monthsFilterToSend =
          selectedFiltersPendingArea.months.length &&
          selectedFiltersPendingArea.months.map((el) => {
             return el.value;
          });
 
       objToSend.months = monthsFilterToSend || [];

       const statusFilterToSend =
          selectedFiltersPendingArea.status.length &&
          selectedFiltersPendingArea.status.map((el) => {
             return el.value;
          });
 
       objToSend.status = statusFilterToSend || [];
       objToSend.pageIndex = actualPage - 1;
 
       return objToSend;
    };
 
    const setFilterValue = (type, opts) => {
       let selectedFilters = _.cloneDeep(selectedFiltersPendingArea);
       selectedFilters[type] = opts;
       return setSelectedFiltersPendingArea(selectedFilters);
    };
 
    const totalPages =
       tableData && Math.ceil(tableData.count / tableData.maxResultsForPage);
 
    return (
       <div className="PendingArea__page-container row no-gutters">
          <div className="col-3 sidebar PendingArea__sidebar">
             <div className="filters">
                <header className="PendingArea__sidebar-header sidebar-header col-12">
                   <div className="PendingArea__vector-container vector-container">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a href="/myStarsBo/" className="vector" />
                      <div className="PendingArea__vector--label">
                         <span>Stars</span>
                         <span>Assortment</span>
                      </div>
                   </div>
                   <div className="PendingArea__switch--container">
                      <Switch
                         isChecked={isClusterArea}
                         onChange={() => {
                            setIsClusterArea(!isClusterArea);
                            setFilterValues(emptySelectedFilters)
                            setSelectedFiltersPendingArea(emptySelectedFilters);
                            setIsTypeViewChanged(true);
                         }}
                         label={["Modules", "Clusters"]}
                      />
                   </div>
                </header>
 
                <div className="sidebar-content">
                   <span className="PendingArea__title">
                      Pending Area
                   </span>
                   <div className="top-filters">
                      <form
                         onSubmit={(event) => {
                            handleSearch(event);
                         }}
                      >
                        <PaginatedSelect
                            isLoading={!filterValues}
                            disabled={isDisabled('status')}
                            options={adaptedFilterOptions("status")}
                            placeholder="Status"
                            handleChange={(e) => {
                               setFilterValue("status", e);
                            }}
                            isMulti
                            value={selectedFiltersPendingArea.status}
                         />
                         <PaginatedSelect
                            isLoading={!filterValues}
                            disabled={isDisabled('brandCode')}
                            options={adaptedFilterOptions("brandCode")}
                            placeholder="Brand"
                            handleChange={(e) => {
                               setFilterValue("brandCode", e);
                            }}
                            value={selectedFiltersPendingArea.brandCode}
                            isMulti
                         />
                         <PaginatedSelect
                            isLoading={!filterValues}
                            disabled={isDisabled('commodity')}
                            options={adaptedFilterOptions("commodity")}
                            placeholder="Commodity"
                            handleChange={(e) => {
                               setFilterValue("commodity", e);
                            }}
                            value={selectedFiltersPendingArea.commodity}
                            isMulti
                         />
                         <PaginatedSelect
                            isLoading={!filterValues}
                            disabled={isDisabled('months')}
                            options={adaptedFilterOptions("months")}
                            placeholder="Months"
                            handleChange={(e) => {
                               setFilterValue("months", e);
                            }}
                            isMulti
                            value={selectedFiltersPendingArea.months}
                         />

                         < div className="PendingArea__secondary-cta-container">
                            <Button
                               btnClass="btn-medium center btn-search btn-white"
                               icon="icon-arrow1"
                               iconAfter
                               disabled={selectedFiltersPendingArea.brandCode.length <= 0 && 
                                 selectedFiltersPendingArea.commodity.length <= 0 && 
                                 selectedFiltersPendingArea.status.length <= 0 &&
                                 selectedFiltersPendingArea.months.length <= 0}
                               type="submit"
                            >
                               Search
                            </Button>
                         </div>
                      </form>
                   </div>
                   <span
                      className="PendingArea__separator"
                      style={
                         !isClusterArea
                            ? { marginTop: "2vh" }
                            : { marginTop: "5vh" }
                      }
                   />
                </div>
             </div>
          </div >
         
          {              
            selectedFiltersPendingArea.brandCode.length <= 0 && 
            selectedFiltersPendingArea.commodity.length <= 0 && 
            selectedFiltersPendingArea.status.length <= 0 &&
            selectedFiltersPendingArea.months.length <= 0 && isStart ? (
             <div className="PendingArea__no-res-message--container">
                 <span className="vector--image" />
                 <span className="PendingArea__no-res-message--container__label--message">
                    Please select at least one filter.
                 </span>
             </div>
            ) : null
          }
             
          {
             (isDataLoading) ? (
                <div className="PendingArea__loader--container">
                   <Loader />
                </div>
             ) : null
          }
          
          {tooManyResults ?
             (
               <div className="PendingArea__table--wrapper">
               <div 
                  id="table-pending-area-area-container"
                  className="PendingArea__table--container"
               >
                  <h1>
                     The search returned too many elements
                  </h1>
                  <h2>Change the filters and search again</h2>
                  <div className="PendingArea__table--container__logo">
                     <Logo />
                  </div>
               </div>
               </div>
            ) :
            (
             tableData &&
                tableData.modulePendingAreaDtoList &&
                tableData.modulePendingAreaDtoList.length > 0 ? (
                <div className="PendingArea__table--wrapper">
                   <div
                      id="table-pending-area-area-container"
                      className="PendingArea__table--container"
                   >
                      <PendingAreaTable
                         isChangedTypeView={!isClusterArea}
                         tableData={tableData.modulePendingAreaDtoList}
                         showBoxShadow={showBoxShadow}
                         actualPage={actualPage}
                         isModules={true}
                         history={history}
                        //  loadingListToModule={setIsDataLoading}
                        //  loadingFiltersToModule={setIsFiltersLoading}
                         reload={setReload}
                      />
                   </div>
                   <div className="PendingArea__pagination--container">
                      {/* <div className="PendingArea__pagination--container__label">
                         <span>
                            Total Modules: {tableData.count}, modules per page:{" "}
                            {tableData.modulePendingAreaDtoList.length}
                         </span>
                      </div> */}
                      <div className="PendingArea__pagination-buttons--container">
                         <form
                            disabled={
                               inputValue <= 0 ||
                               isNaN(inputValue) ||
                               typeof inputValue !== "number" ||
                               inputValue === null ||
                               inputValue === actualPage ||
                               inputValue > totalPages
                            }
                            onSubmit={(event) => {
                               setActualPage(inputValue);
                               event.preventDefault();
                            }}
                         >
                            <div className="PendingArea__input-page--container">
                               <span className="PendingArea__input-page--container--label">
                                  Go to page:
                               </span>
                               <input
                                  className="PendingArea__input--style"
                                  min={1}
                                  max={totalPages}
                                  value={inputValue}
                                  onChange={(e) => {
                                     if (e.target.valueAsNumber <= 0) {
                                        e.target.valueAsNumber = 1;
                                     }
 
                                     const limitExceded =
                                        e.target.valueAsNumber > totalPages;
 
                                     const alreadyInChoosedPage =
                                        e.target.valueAsNumber === actualPage;
 
                                     setShowLimitNumber(limitExceded);
                                     setShowAlreadyInChoosedPage(
                                        alreadyInChoosedPage
                                     );
                                     setInputValue(e.target.valueAsNumber);
                                  }}
                                  type="number"
                                  placeholder="Page"
                               />
                               <Button
                                  btnClass="btn-medium"
                                  type="submit"
                                  disabled={
                                     inputValue <= 0 ||
                                     isNaN(inputValue) ||
                                     typeof inputValue !== "number" ||
                                     inputValue === null ||
                                     inputValue === actualPage ||
                                     inputValue > totalPages
                                  }
                               >
                                  Apply
                               </Button>
                            </div>
                         </form>
 
                         <div className="PendingArea__pagination-buttons--container__btn--container">
                            <Button
                               btnClass="btn-medium center"
                               handleClick={() => {
                                  setActualPage(1);
                               }}
                               disabled={actualPage === 1}
                            >
                               1
                            </Button>
                         </div>
 
                         <Button
                            btnClass="btn-medium center"
                            handleClick={() => {
                               setActualPage(actualPage - 1);
                            }}
                            disabled={actualPage === 1}
                         >
                            Back
                         </Button>
 
                         <span className="PendingArea__actual-page--label">
                            Page: {actualPage} / {totalPages}
                         </span>
                         <div className="PendingArea__pagination-buttons--container__btn--container">
                            <Button
                               btnClass="btn-medium center"
                               handleClick={() => {
                                  setActualPage(actualPage + 1);
                               }}
                               disabled={actualPage === totalPages}
                            >
                               Next
                            </Button>
                         </div>
                         <Button
                            btnClass="btn-medium center"
                            handleClick={() => {
                               setActualPage(totalPages);
                            }}
                            disabled={actualPage === totalPages}
                         >
                            {totalPages}
                         </Button>
                      </div>
                   </div>
                   {showLimitNumber ? (
                      <span className="PendingArea__limit-number--container">
                         Value must be lower than {totalPages}
                      </span>
                   ) : null}
                   {showAlreadyInChoosedPage ? (
                      <span className="PendingArea__already-in-page-message--container">
                         You are already seeing page {actualPage}
                      </span>
                   ) : null}
                </div>
             ) : null
         )}
          {
             tableData &&
                tableData.modulePendingAreaDtoList &&
                tableData.modulePendingAreaDtoList.length === 0 ? (
                <div className="PendingArea__no-res-message--container">
                   <span className="vector--image" />
                   <span className="PendingArea__no-res-message--container__label--message">
                      No results found, please try another combination of filters.
                   </span>
                </div>
             ) : null
          }
          {
             tableData &&
                tableData.clusterPendingAreaDtoList &&
                tableData.clusterPendingAreaDtoList.length > 0 ? (
                <div className="PendingArea__table--wrapper">
                   <div
                      id="table-pending-area-area-container"
                      className="PendingArea__table--container"
                   >
                      <PendingAreaTable
                         isChangedTypeView={isClusterArea}
                         tableData={tableData.clusterPendingAreaDtoList}
                         showBoxShadow={showBoxShadow}
                         actualPage={actualPage}
                         isModules={false}
                         history={history}
                         //loadingListToModule={setIsDataLoading}
                         //loadingFiltersToModule={setIsFiltersLoading}
                         reload={setReload}
                      />
                   </div>
                   <div className="PendingArea__pagination--container">
                      {/* <div className="PendingArea__pagination--container__label">
                         <span>
                            Total Modules: {tableData.count}, modules per page:{" "}
                            {tableData.modulePendingAreaDtoList.length}
                         </span>
                      </div> */}
                      <div className="PendingArea__pagination-buttons--container">
                         <form
                            disabled={
                               inputValue <= 0 ||
                               isNaN(inputValue) ||
                               typeof inputValue !== "number" ||
                               inputValue === null ||
                               inputValue === actualPage ||
                               inputValue > totalPages
                            }
                            onSubmit={(event) => {
                               setActualPage(inputValue);
                               event.preventDefault();
                            }}
                         >
                            <div className="PendingArea__input-page--container">
                               <span className="PendingArea__input-page--container--label">
                                  Go to page:
                               </span>
                               <input
                                  className="PendingArea__input--style"
                                  min={1}
                                  max={totalPages}
                                  value={inputValue}
                                  onChange={(e) => {
                                     if (e.target.valueAsNumber <= 0) {
                                        e.target.valueAsNumber = 1;
                                     }
 
                                     const limitExceded =
                                        e.target.valueAsNumber > totalPages;
 
                                     const alreadyInChoosedPage =
                                        e.target.valueAsNumber === actualPage;
 
                                     setShowLimitNumber(limitExceded);
                                     setShowAlreadyInChoosedPage(
                                        alreadyInChoosedPage
                                     );
                                     setInputValue(e.target.valueAsNumber);
                                  }}
                                  type="number"
                                  placeholder="Page"
                               />
                               <Button
                                  btnClass="btn-medium"
                                  type="submit"
                                  disabled={
                                     inputValue <= 0 ||
                                     isNaN(inputValue) ||
                                     typeof inputValue !== "number" ||
                                     inputValue === null ||
                                     inputValue === actualPage ||
                                     inputValue > totalPages
                                  }
                               >
                                  Apply
                               </Button>
                            </div>
                         </form>
 
                         <div className="PendingArea__pagination-buttons--container__btn--container">
                            <Button
                               btnClass="btn-medium center"
                               handleClick={() => {
                                  setActualPage(1);
                               }}
                               disabled={actualPage === 1}
                            >
                               1
                            </Button>
                         </div>
 
                         <Button
                            btnClass="btn-medium center"
                            handleClick={() => {
                               setActualPage(actualPage - 1);
                            }}
                            disabled={actualPage === 1}
                         >
                            Back
                         </Button>
 
                         <span className="PendingArea__actual-page--label">
                            Page: {actualPage} / {totalPages}
                         </span>
                         <div className="PendingArea__pagination-buttons--container__btn--container">
                            <Button
                               btnClass="btn-medium center"
                               handleClick={() => {
                                  setActualPage(actualPage + 1);
                               }}
                               disabled={actualPage === totalPages}
                            >
                               Next
                            </Button>
                         </div>
                         <Button
                            btnClass="btn-medium center"
                            handleClick={() => {
                               setActualPage(totalPages);
                            }}
                            disabled={actualPage === totalPages}
                         >
                            {totalPages}
                         </Button>
                      </div>
                   </div>
                   {showLimitNumber ? (
                      <span className="PendingArea__limit-number--container">
                         Value must be lower than {totalPages}
                      </span>
                   ) : null}
                   {showAlreadyInChoosedPage ? (
                      <span className="PendingArea__already-in-page-message--container">
                         You are already seeing page {actualPage}
                      </span>
                   ) : null}
                </div>
             ) : null
          }
          {
             tableData &&
                tableData.clusterPendingAreaDtoList &&
                tableData.clusterPendingAreaDtoList.length === 0 ? (
                <div className="PendingArea__no-res-message--container">
                   <span className="vector--image" />
                   <span className="PendingArea__no-res-message--container__label--message">
                      No results found, please try another combination of filters.
                   </span>
                </div>
             ) : null
          }
       </div >
    );
 };
 
 const mapStateToProps = (state) => {
    return {
       selectedFiltersPendingArea: state.filter.selectedFiltersPendingArea,
       rowsToSendMassiveEdit: state.filter.rowsToSendMassiveEdit,
       results: state.filter.results,
    };
 };
 
 const mapDispatchToProps = (dispatch) => {
    return {
       logout: () => dispatch(actions.logout()),
       setResetRowsToSendMassiveEdit: (rowsToSendMassiveEdit) =>
          dispatch(actions.setResetRowsToSendMassiveEdit(rowsToSendMassiveEdit)),
    };
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(PendingArea);