import React from 'react';
 
const tooltip = props => (
    <div 
        className={"tooltip " + (props.tooltipClass ? props.tooltipClass : '')} 
        onClick={props.onClick}
        style={{zIndex: 100}}
    >
        {props.children}
        <span className={"tooltip-text " + (props.tooltipTextClass ? props.tooltipTextClass : '') }>
            {props.text}
        </span>
    </div>
)
export default tooltip;
