export const exportFilters = {
   MONTH: "monthId",
   PL_CHANNEL: "planningChannel",
   COUNTRY: "country",
   STARS_CUSTOMER: "starsCustomer",
   COLLECTION: "collection",
   BRAND: "brand",
   TYPE: "newModule",
   FAMILY: "macroFamily",
   CLUSTER_MODULE: "clusterId",
   CUST_SEGMENTATION: "customerSegmentation",
   RANGE_FACING: "rangeFacing",
   STORE_JDA: "storeJda",
   STORE_SAP: "codiceStoreSap",
   // tB:03-2022
   MODULE_LABEL: "moduleLabel",
   CLUSTER_LABEL: "clusterLabel",
   CLASSIFICATION: "clientClassification"
};

export const initialExportFilters = {
   [exportFilters.MONTH]: [],
   [exportFilters.PL_CHANNEL]: [],
   [exportFilters.COUNTRY]: [],
   [exportFilters.STARS_CUSTOMER]: [],
   [exportFilters.COLLECTION]: [],
   [exportFilters.BRAND]: [],
   [exportFilters.TYPE]: [],
   [exportFilters.FAMILY]: [],
   [exportFilters.CLUSTER_MODULE]: [],
   [exportFilters.CUST_SEGMENTATION]: [],
   [exportFilters.RANGE_FACING]: [],
   [exportFilters.STORE_JDA]: [],
   [exportFilters.STORE_SAP]: [],
   // tB:03-2022
   [exportFilters.MODULE_LABEL]: [],
   [exportFilters.CLUSTER_LABEL]: [],
   [exportFilters.CLASSIFICATION]: []
};

export const BUTTONS_TO_PRINT = [
   {
      type: "pdf",
      icon: "file",
      label: "PDF",
   },
   {
      type: "xlsx",
      icon: "xls",
      label: "XLS",
   },
   {
      type: "xlsx/photo",
      icon: "xls",
      label: "XLS PHOTO",
   },
];

export const exportStatus = {
   PUBLISHED: "published",
   SAVED: "saved",
};

export const exportType = {
   MODULE: "module",
   CLUSTER: "cluster",
};

export const moduleType = {
   SWITCH: "switch",
   NOT_SWITCH: "not switch",
   ALTERNATIVE: "alternative",
};

const {
   MONTH,
   PL_CHANNEL,
   BRAND,
   TYPE,
   COLLECTION,
   COUNTRY,
   FAMILY,
   CLUSTER_MODULE,
   STARS_CUSTOMER,
   STORE_JDA,
   STORE_SAP,
   CUST_SEGMENTATION,
   RANGE_FACING,
   // tB:03-2022
   MODULE_LABEL,
   CLUSTER_LABEL,
} = exportFilters;

export const exportFilterClusterRel = {
   [MONTH]: [
      PL_CHANNEL,
      BRAND,
      COLLECTION,
      COUNTRY,
      STARS_CUSTOMER,
      STORE_JDA,
      STORE_SAP,
      CUST_SEGMENTATION,
      RANGE_FACING,
   ],
   [PL_CHANNEL]: [
      BRAND,
      COLLECTION,
      COUNTRY,
      STARS_CUSTOMER,
      STORE_JDA,
      STORE_SAP,
      CUST_SEGMENTATION,
      RANGE_FACING,
   ],
   [BRAND]: [
      COLLECTION,
      COUNTRY,
      STARS_CUSTOMER,
      STORE_JDA,
      STORE_SAP,
      CUST_SEGMENTATION,
      RANGE_FACING,
   ],
   [COLLECTION]: [
      COUNTRY,
      STARS_CUSTOMER,
      STORE_JDA,
      STORE_SAP,
      CUST_SEGMENTATION,
      RANGE_FACING,
   ],
   [COUNTRY]: [
      STARS_CUSTOMER,
      STORE_JDA,
      STORE_SAP,
      CUST_SEGMENTATION,
      RANGE_FACING,
   ],
   [STARS_CUSTOMER]: [STORE_JDA, STORE_SAP, CUST_SEGMENTATION, RANGE_FACING],
   [STORE_JDA]: [STORE_SAP, CUST_SEGMENTATION, RANGE_FACING],
   [STORE_SAP]: [CUST_SEGMENTATION, RANGE_FACING],
   [CUST_SEGMENTATION]: [RANGE_FACING],
   [RANGE_FACING]: [],
   // tB:03-2022
   [MODULE_LABEL]: "",
   [CLUSTER_LABEL]: "",
};

export const exportFilterModuleRel = {
   [MONTH]: [
      PL_CHANNEL,
      BRAND,
      COLLECTION,
      COUNTRY,
      CLUSTER_MODULE,
      TYPE,
      FAMILY,
      STARS_CUSTOMER,
   ],
   [PL_CHANNEL]: [
      BRAND,
      COLLECTION,
      COUNTRY,
      CLUSTER_MODULE,
      TYPE,
      FAMILY,
      STARS_CUSTOMER,
   ],
   [BRAND]: [COLLECTION, COUNTRY, CLUSTER_MODULE, TYPE, FAMILY, STARS_CUSTOMER],
   [COLLECTION]: [COUNTRY, CLUSTER_MODULE, TYPE, FAMILY, STARS_CUSTOMER],
   [COUNTRY]: [CLUSTER_MODULE, TYPE, FAMILY, STARS_CUSTOMER],
   [STARS_CUSTOMER]: [CLUSTER_MODULE, TYPE, FAMILY],
   [CLUSTER_MODULE]: [TYPE, FAMILY],
   [FAMILY]: [TYPE],
   [TYPE]: [],
   // tB:03-2022
   [MODULE_LABEL]: "",
   [CLUSTER_LABEL]: "",
};
