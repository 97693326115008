import * as actions from "./store/actions";

import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import ApproveCluster from "./components/Approve-Cluster/ApproveCluster";
import ClusterComposition from "./components/Cluster-Composition/ClusterComposition";
import DiscoRemoveArea from "./pages/DiscoRemoveArea";
import ExportData from "./components/Export-Data/ExportData";
import Homepage from "./components/Homepage/Homepage";
import LoginPage from "./components/LoginPage/LoginPage";
import ManageBrand from "./components/ManageBrand-Page/ManageBrand";
import ManageRelease from "./components/Manage-Page/ManageRelease";
import ManageUser from "./components/Manage-Page/ManageUser";
import MassiveEditSelectedWI from "./pages/MassiveEditSelectedWI";
import MassiveEditWI from "./pages/MassiveEditWI";
import ModuleComposition from "./components/Module-Composition/ModuleComposition";
import PDFArea from "./pages/PDFArea";
import PendingArea from "./pages/PendingArea";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ReactGA from "react-ga";
import StatisticsPage from "./components/Statistics-Page/StatisticsPage";
import { Upload } from "./pages";
import UploadPageTwo from "./components/Upload-Component/UploadPage2";
import WithErrorHandling from "./components/Error-Handling/WithErrorHandling";
import { connect } from "react-redux";

class App extends Component {
  componentDidMount() {
    if (
      window.location.hostname &&
      window.location.hostname.includes("mystarsassortment") &&
      !window.location.hostname.includes("certi")
    ) {
      ReactGA.initialize("UA-179478530-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.props.setGACheck(true);

      this.props.history.listen((location) => {
        this.props.setGACheck(false);
      });
    }
  }

  render() {
    let {
      user,
      showMessage,
      error,
      showError,
      closeAlert,
      isGAJustInizialized,
    } = this.props;

    return (
      <WithErrorHandling
        showError={showError}
        error={showMessage ? error : null}
        closeAlert={closeAlert}
      >
        <Switch>
          <PrivateRoute
            exact
            path="/history"
            component={ApproveCluster}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/module"
            component={ModuleComposition}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/cluster"
            component={ClusterComposition}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/statistics"
            component={StatisticsPage}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/manageUser"
            component={ManageUser}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/manageBrand"
            component={ManageBrand}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/manageRelease"
            component={ManageRelease}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/uploadTwo"
            component={UploadPageTwo}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/upload"
            component={Upload}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/pdf-area"
            component={PDFArea}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/massive-edit-wi"
            component={MassiveEditWI}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
           <PrivateRoute
            exact
            path="/disco-remove-area"
            component={DiscoRemoveArea}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/pending-area"
            component={PendingArea}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
            <PrivateRoute
            exact
            path="/massive-edit-selected-wi"
            component={MassiveEditSelectedWI}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/export"
            component={ExportData}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <PrivateRoute
            exact
            path="/"
            component={Homepage}
            user={user}
            isGAJustInizialized={isGAJustInizialized}
          />
          <Route exact path="/login" component={LoginPage} />
          <Redirect to="/login" push />
        </Switch>
      </WithErrorHandling>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    user: state.authentication.user,
    showError: state.error.showError,
    isGAJustInizialized: state.authentication.isGAJustInizialized,
    error: state.error.error,
    showMessage: state.error.showMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAlert: () => dispatch(actions.closeAlert()),
    setGACheck: (val) => dispatch(actions.setGACheck(val)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
