import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/Api';

const initialState = {
    releaseList: null,    
    uploadStatus: null,
    isUploading: false,
    isCheckStatus : false,
    isRefresh: false
};


const setReleaseList = (state, action) => {     
    return updateObject( state, { releaseList: action.releaseList });
};

const setUploadStatusRequest = state => {     
    return updateObject( state, { isCheckStatus: true });
};

const setUploadStatus = (state, action) => {     
    return updateObject( state, { 
        uploadStatus: action.uploadStatus,
        isCheckStatus: false 
    });
};

const setUploadStatusFailure = state => {     
    return updateObject( state, { isCheckStatus: false });
};

const importFilesRequest = state => {     
    return updateObject( state, { 
        isUploading: true,
        isRefresh: false 
    });
};

const importFilesSuccess = (state, action) => {     
    return updateObject( state, { 
        isUploading: false,
        isRefresh: true
    });
};

const importFilesFailure = state => {     
    return updateObject( state, { 
        isUploading: false,
        isRefresh: false 
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_RELEASELIST_UPLOAD_SUCCESS: return setReleaseList(state, action);
        case actionTypes.GET_UPLOAD_STATUS_REQUEST: return setUploadStatusRequest(state);
        case actionTypes.GET_UPLOAD_STATUS_SUCCESS: return setUploadStatus(state, action);
        case actionTypes.GET_UPLOAD_STATUS_FAILURE: return setUploadStatusFailure(state);
        case actionTypes.IMPORT_FILES_REQUEST: return importFilesRequest(state);
        case actionTypes.IMPORT_FILES_SUCCESS: return importFilesSuccess(state, action);
        case actionTypes.IMPORT_FILES_FAILURE: return importFilesFailure(state);
        default:
            return state;
    }
};

export default reducer;