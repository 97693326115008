import React, { useState, useEffect } from 'react';
import CustomInput from '../../UI-components/CustomInput';
import CustomSelect from '../../UI-components/CustomSelect';
import Button from "../../../components/UI-components/Button";
import RadioGrp from "../../../components/UI-components/RadioGroup";
import * as _ from 'lodash'
import './FilterPanel.scss'


const FilterPanel = ({ isHighlight, title, filterSku, products }) => {
    const [isDuplicated, setIsDuplicated] = useState(false);
    const [modelName, setModelName] = useState([])
    const [minPrice, setMinPrice] = useState('')
    const [maxPrice, setMaxPrice] = useState('')
    const [release, setRelease] = useState([]);
    const [selectedDuplicated, setSelectedDuplicated] = useState();
    const [filters, setFilters] = useState()


    useEffect(() => {
        setFilters({ modelName, minPrice, maxPrice, isDuplicated, release, isHighlight })

    }, [modelName, minPrice, maxPrice, isDuplicated, release, isHighlight])





    const handleReset = (e) => {
        e.preventDefault()
        setRelease([]);
        setModelName([]);
        setMinPrice('');
        setMaxPrice('');
        setIsDuplicated(false);
        setSelectedDuplicated(false)
        const resetFilters = { modelName: [], minPrice: '', maxPrice: '', selectedDuplicated, release: [], isHighlight }
        filterSku(resetFilters);

    }

    const handleChange = () => {
        if (selectedDuplicated === 0) {
            setSelectedDuplicated(null);
            setIsDuplicated(false);
        } else {
            setSelectedDuplicated(0);
            setIsDuplicated(true);
        }
    }

    const handleChangeRelease = (e) => {
        setRelease(e)
        const selectAll = e.map(e => e.value).includes('SELECT_ALL');
        if (e.length && selectAll) {
            setRelease(releaseOption)
        }
    }
    const handleChangeModel = (e) => {
        setModelName(e)
        const selectAll = e.map(e => e.value).includes('SELECT_ALL');
        if (e.length && selectAll) {
            setModelName(modelNamesOption)
        }
    }


    // const excludeOtherField = (param) => {
    //     if (isHighlight) {
    //         const fields = {
    //             release: () => setRelease([]),
    //             modelName: () => setModelName([]),
    //             minPrice: () => setMinPrice(''),
    //             maxPrice: () => setMaxPrice(''),
    //             isDuplicated: () => {
    //                 setSelectedDuplicated(null);
    //                 setIsDuplicated(false);
    //             }
    //         }
    //         Object.keys(fields).forEach(k => {
    //             if (param != k) {
    //                 fields[k]();
    //             }
    //         })
    //     }
    // }

    const handleSubmit = (event) => {
        event.preventDefault()
        filterSku(filters)
    }

    const releaseDates = [];
    const modelNames = [];


    if (products) {
        products.forEach((p) => {
            if (modelNames.indexOf(p.model) < 0) {
                modelNames.push(p.model);
            }
            if (releaseDates.indexOf(p.releaseDate) < 0) {
                releaseDates.push(p.releaseDate);
            }
        })
    }

    const releaseOption = releaseDates && _.orderBy(releaseDates).map((element) => ({
        label: element,
        value: element,
    }))
    const releaseOptionSelectAll = [{ label: 'Select All', value: 'SELECT_ALL' }, ...releaseOption]

    const modelNamesOption = modelNames && _.orderBy(modelNames).map((element) => ({
        label: element,
        value: element,
    }))
    const modelNamesOptionSelectAll = [{ label: 'Select All', value: 'SELECT_ALL' }, ...modelNamesOption]

    return (
        <div className='filterPanel__section'>
            <form onSubmit={handleSubmit}>
                <div className='filterPanel__firstDiv'>
                    {title}
                </div>
                <CustomSelect
                    selectClass='zIndex402'
                    placeholder="Release Date"
                    options={releaseOptionSelectAll}
                    isMulti
                    handleChange={e => {
                        handleChangeRelease(e);
                    }}
                    value={release}

                />
                <CustomSelect
                    selectClass='zIndex401'
                    placeholder="Model"
                    options={modelNamesOptionSelectAll}
                    isMulti
                    handleChange={e => {
                        handleChangeModel(e);
                    }}
                    value={modelName}

                />
                <div className="filterPanel__priceSection">
                    <CustomInput
                        inputClass="filterPanel__priceInput"
                        placeholder={`Pr.Min`}
                        min="0"
                        type="number"
                        handleChange={(e) => {
                            setMinPrice(e.target.value);
                        }}
                        value={minPrice}
                    />
                    <CustomInput
                        inputClass="filterPanel__priceInput"
                        placeholder="Pr.Max"
                        min="0"
                        type="number"
                        handleChange={(e) => {
                            setMaxPrice(e.target.value);
                        }}
                        value={maxPrice}
                    />
                </div>
                <div className='duplicate-radioSection'>
                    {isHighlight &&
                        <RadioGrp
                            containerClass="filterPanel_radioGroup"
                            options={['Duplicate Sku']}
                            active={false}
                            selectedIndex={selectedDuplicated}
                            handleClick={handleChange}
                        />}
                </div>
                <div className={'filterPanel__buttonSection '}>
                    <Button type='button' handleClick={handleReset} btnClass='filterPanel__reset'>RESET</Button>
                    <Button type="submit" handleClick={() => filterSku(filters)} btnClass='filterPanel__btn'>APPLY</Button>
                </div>
            </form>
        </div >
    )
}
export default FilterPanel

