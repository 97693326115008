import { useEffect, useState } from "react";
import Button from "../UI-components/Button";
import CustomSelect from "../UI-components/CustomSelect";
import RadioGrp from "../UI-components/RadioGroup";
import { connect } from "react-redux";
import { isFunction } from "lodash";
import _ from "lodash";

const emptyRowModel = {
   module: [""],
   add: [""],
   remove: [""],
   status: ["MH"],
   month: [""],
   minValueSkuList: [""],
   minValueSku: 1,
};

const emptyRowModelStatus2 = {
   module: [""],
   add: [""],
   remove: [""],
   status: ["EL"],
   month: [""],
   minValueSkuList: [""],
   minValueSku: 1,
};

const emptyRowStatusModel3 = {
   module: [],
   add: [""],
   remove: [""],
   status: ["MH"],
   month: [],
   minValueSkuList: [""],
   minValueSku: 1,
};

const emptyRowStatusModel4 = {
   module: [],
   add: [""],
   remove: [""],
   status: ["EL"],
   month: [],
   minValueSkuList: [""],
   minValueSku: 1,
};

const initialState = { 
   module: [""],
   add: [""],
   remove: [""],
   status: ["MH"],
   month: [""],
   minValueSkuList: [""],
   minValueSku: 1,
};

const MassiveEditSelectedWITable = ({
   onEditRow,
   resetTableData,
   selectedModules,
}) => {
   const [inputRows, setInputRows] = useState([initialState]);

   useEffect(() => {
      setInputRows([
         {
            module: [],
            add: [""],
            remove: [""],
            status: ["MH"],
            month: [],
            minValueSkuList: [""],
            minValueSku: 1,
         },
      ]);
   }, []);

   useEffect(() => {
      if (resetTableData) {
         setInputRows([
            {
               module: [],
               add: [""],
               remove: [""],
               status: ["MH"],
               month: [],
               minValueSkuList: [""],
               minValueSku: 1,
            },
         ]);
      }
   }, [resetTableData]);

   const checkIsEmpty = () => {
      const isEmpty =
         (inputRows.length >= 1 &&
            JSON.stringify(inputRows[0]) === JSON.stringify(emptyRowModel)) ||
         JSON.stringify(inputRows[0]) ===
            JSON.stringify(emptyRowModelStatus2) ||
         JSON.stringify(inputRows[0]) ===
            JSON.stringify(emptyRowStatusModel3) ||
         JSON.stringify(inputRows[0]) === JSON.stringify(emptyRowStatusModel4);
      return isEmpty;
   };

   const handleSelectChange = (selectType, optionsSelected, index) => {
      const mapped = inputRows.map((row, rowIndex) => {
         if (rowIndex === index) {
            row[selectType] = optionsSelected;
         }
         return row;
      });

      setInputRows(mapped);
   };

   const handleChange = (selectType, optionsSelected, index) => {
      const mapped = inputRows.map((row, rowIndex) => {
         if(selectType === "minValueSku") {
            if(rowIndex === index) {
               let value = +optionsSelected;
               if(value < 1) {
                  value = 1;
               }
               row[selectType] = value;
            }
            return row;
         }

         const inputLine = optionsSelected.split(/[\n\r]/g).length;
         
         if (rowIndex === index && inputLine === 1) {
            row[selectType] = [optionsSelected];
         }

         if (inputLine > 1) {
            if (rowIndex === index) {
               row[selectType] = [optionsSelected];
            }
         }

         return row;
      });

      setInputRows(mapped);
   };

   const addRow = () => {
      setInputRows((prevState) => [
         {
            module: [""],
            add: [""],
            remove: [""],
            status: ["MH"],
            month: [""],
            minValueSkuList: [""],
            minValueSku: 1,
         },
         ...prevState,
      ]);
   };

   const removeRow = (index) => {
      setInputRows((prevState) => {
         const x = prevState.filter(
            (p, i) => prevState.length === 1 || i !== index
         );

         if (x.length === 1 && prevState.length === 1) {
            return [
               {
                  module: [""],
                  add: [""],
                  remove: [""],
                  status: ["MH"],
                  month: [""],
                  minValueSkuList: [""],
                  minValueSku: 1,
               },
            ];
         }
         return x;
      });
   };

   useEffect(() => {
      if (isFunction(onEditRow)) onEditRow(inputRows, checkIsEmpty());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputRows]);

   const getSelected = (status) => {
      return !status || status === "" ? null : status === "MH" ? 0 : 1;
   };

   const MassiveEditWITableGeneratedRows = () => {
      const radioButtonOptions = ["MH", "EL"];

      const listNameOfSelectedModule =
         selectedModules &&
         selectedModules.map((el, i) => {
            return {
               value: el.moduleName,
               label: el.moduleName,
            };
         });

      const sortedMonths = _.orderBy(
         [...new Set(selectedModules.map(module => module.monthId))],
         ["asc"]
      ).map(month => {
         return({
            label: month,
            value: month
         })
      })

      return inputRows.map((row, i) => {
         return (
            <tr key={i}>
               <td>
                  <div className="margin-select-massive-edit">
                     <CustomSelect
                        noInput={false}
                        containerSelectClass="selectContainerTable"
                        options={listNameOfSelectedModule || []}
                        placeholder="Select module..."
                        handleChange={(event) => {
                           handleSelectChange("module", event, i, event);
                        }}
                        isMulti
                        showMultiValue
                        value={row.module}
                     />
                  </div>
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     placeholder="Write your sku..."
                     onChange={(event) =>
                        handleChange("add", event.target.value, i, event)
                     }
                     value={row.add}
                  />
               </td>
               <td>
                  <textarea
                     style={{ resize: "vertical" }}
                     className="search inputStyle "
                     name="remove"
                     placeholder="Write your sku..."
                     onChange={(event) =>
                        handleChange("remove", event.target.value, i, event)
                     }
                     value={row.remove}
                  />
               </td>
               <td>
                  <RadioGrp
                     selectedIndex={getSelected(row.status[0])}
                     options={radioButtonOptions}
                     active={false}
                     handleClick={(radioButtonOptions) => {
                        handleChange("status", radioButtonOptions, i);
                     }}
                     selectedValue={row.status[0]}
                  />
               </td>

               <td>
                  <div className="margin-select-massive-edit">
                     <CustomSelect
                        noInput={false}
                        containerSelectClass="selectContainerTable"
                        options={sortedMonths}
                        placeholder="Select your month..."
                        handleChange={(event) => {
                           handleSelectChange("month", event, i, event);
                        }}
                        isMulti
                        showMultiValue
                        value={row.month}
                     />
                  </div>
               </td>
               <td style={{verticalAlign: "top"}}>
                  <div className="minValueSkuRow">
                     <textarea
                        style={{ resize: "vertical" }}
                        className="search inputStyle "
                        placeholder="Write your sku..."
                        onChange={(event) =>
                           handleChange("minValueSkuList", event.target.value, i, event)
                        }
                        value={row.minValueSkuList}
                     />

                     <div style={{ display: "flex" }}>
                        <Button btnClass="minValueSkuButton" 
                           disabled={!row.minValueSkuList?.[0] || row.minValueSku <= 1} 
                           handleClick={(event) => handleChange("minValueSku", row.minValueSku - 1, i, event)}
                        >
                           -
                        </Button>
                        <input 
                           className={"minValueSkuInput"} 
                           onChange={(event) => handleChange("minValueSku", event.target.value?.replace(/[^0-9]+/g, ""), i, event)} 
                           value={row.minValueSku} 
                           maxLength={9}
                           type="number"
                           disabled={!row.minValueSkuList?.[0]}
                        />
                        <Button 
                           btnClass="minValueSkuButton"
                           disabled={!row.minValueSkuList?.[0]}
                           handleClick={(event) => handleChange("minValueSku", row.minValueSku + 1, i, event)}
                        >
                           +
                        </Button>
                     </div>
                  </div>
               </td>
               <td>
                  {i === 0 ? (
                     <div style={{ display: "flex" }}>
                        <Button
                           handleClick={() => {
                              addRow();
                           }}
                        >
                           +
                        </Button>
                        <Button handleClick={() => removeRow(i)}>-</Button>
                     </div>
                  ) : (
                     <div style={{ display: "flex" }}>
                        <Button btnClass="hideBtn" handleClick={() => {}}>
                           +
                        </Button>
                        <Button handleClick={() => removeRow(i)}>-</Button>
                     </div>
                  )}
               </td>
            </tr>
         );
      });
   };

   return (
      <table className="massive-edit-module-table">
         <thead>
            <tr>
               <th>MODULE</th>
               <th>ADD</th>
               <th>REMOVE</th>
               <th>STATUS</th>
               <th>MONTH</th>
               <th>MIN VALUE</th>
            </tr>
         </thead>
         <tbody>{MassiveEditWITableGeneratedRows()}</tbody>
      </table>
   );
};

const mapStateToProps = (state) => {
   return {
      selectedModules: state.filter.selectedModules,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {};
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(MassiveEditSelectedWITable);
