import Button from "./Button";
import ButtonExport from "./ButtonExport";
import CustomSelect from "./CustomSelect";
import React from "react";

const formatListXls = ["xls", "xls_massive", "xls/photo", "xls/photo_massive"];

const PopupExport = (props) => {
    
   return (
      <div>
         <div className="popup-background" onClick={props.close} />
         <div
            className={
               "popup-container " + (props.popupClass ? props.popupClass : "")
            }
         >
            {props.contextTitle ? (
               <div
                  style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                  }}
               >
                  <span
                     style={{ position: "absolute", top: "30px", left: "35px" }}
                  >
                     {props.contextTitle}
                  </span>
                  <div className="close-popup" onClick={props.close}>
                     <span className="icon-plus" />
                  </div>
               </div>
            ) : (
               <div className="close-popup" onClick={props.close}>
                  <span className="icon-plus" />
               </div>
            )}

            <div
               className={
                  "popup-header " + (props.headerClass ? props.headerClass : "")
               }
            >
               {props.titleTop && <h3>{props.titleTop}</h3>}
               {props.title && <h3>{props.title}</h3>}
               {props.subtitle ? (
                  <span style={{ position: "relative", top: "40px" }}>
                     {props.subtitle}
                  </span>
               ) : null}
            </div>
            <div
               className={
                  "popup-content " +
                  (props.isFlex ? " flex" : "") +
                  (props.children ? "" : "no-content")
               }
            >
               {props.children}
               {props.moduleList && props.moduleList.length > 0 && (
                  <ul>
                     {props.moduleList.map((singleModule) => {
                        return <li>{singleModule}</li>;
                     })}
                  </ul>
               )}
            </div>
            <br/>
            <br/>
            {props.btnName && (
               <div className="footer-popup" style={{display:'flex', flexDirection:'column'}}>
                  <div style={{display:'flex', justifyContent:'center'}}>
                  {props.isDoubleButton && (
                     <ButtonExport
                     btnClass="btn-small"
                     iconClass="icon-file-excel"
                     style={props.exportFormat === formatListXls[0] || props.exportFormat === formatListXls[1] ? {borderColor:'#FFD700'} : {}}
                     handleClick={props.handleClick}
                     disabled={props.disabled}
                  >
                     {props.btnName}
                  </ButtonExport>
                     
                  )}
                  {props.isTripleButton && (
                     <ButtonExport
                        style={props.exportFormat === formatListXls[2] || props.exportFormat === formatListXls[3] ? {borderColor:'#FFD700'} : {}}
                        btnClass="btn-small"
                        iconClass="icon-file-picture"
                        handleClick={
                           !!props.handleClickSecondary
                              ? props.handleClickSecondary
                              : props.close
                        }
                     >
                        {props.btnName2}
                     </ButtonExport>
                  )}
                     <ButtonExport
                        btnClass="btn-small"
                        iconClass="icon-file-pdf"
                        style={props.exportFormat === "pdf_massive" || props.exportFormat === "pdf" ? {borderColor:'#FFD700'} : {}}
                        handleClick={
                           !!props.handleClickTertiary
                              ? props.handleClickTertiary
                              : props.close
                        }
                     >
                        {props.btnName3}
                     </ButtonExport>
                  </div>
                  <br/>
                  
                  <div style={{display:'flex', flexDirection: 'column'}}>
                   {props.isExportButton && formatListXls.includes(props.exportFormat) ? (
                   
                   <div style={{display:'flex', flexDirection: 'column'}}><label htmlFor="templateList">Select the template:  </label>
                     <br/>
                     <CustomSelect
                              //options={adaptedFilterOptions("planningChannels")}
                              options={props.templates}
                              //isLoading={isFiltersExportLoading}
                              placeholder="Template"
                              disabled={!props.isComboboxEnabled}
                              handleChange={props.handleClickTemplate}
                              //value={props.templates.value}
                              maxHeightCustomSelectMenu={props.maxHeightCustomSelectMenu}
                              //value={props.options.value}
                              isMulti={false}
                     /> 
                  
                   </div>
                  ) : 
                  <div> 
                   <br/>
                   <br/>
                   <br/>
                   <br/>
                   <br/>
                  </div>
                  } </div>
                  
                  <br/>
                  <div style={{display:'flex', flexDirection: 'column'}}>
                   {props.isQuadrupleButton && (
                     <div style={{display:'flex', flexDirection: 'column'}}>
                     <Button
                        btnClass="btn-small"
                        handleClick={
                           !!props.handleClickQuartiary
                              ? props.handleClickQuartiary
                              : props.close
                        }
                        disabled={!props.isSelectedTemplate && !props.isPdfExportSelected}
                        
                     >
                         
                        {props.btnName4}
                     </Button>
                     </div>
                  )}
                  </div>
                  
               </div>
            )}
         </div>
      </div>
   );
};
export default PopupExport;
