/* eslint-disable array-callback-return */
import React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";

import * as actions from "../../../store/actions";
import CheckboxManageUser from "./CheckboxManageUser";
import Chip from "../../UI-components/Chip";

class ManageUserPopupContent extends React.Component {
   handleSetGrant = (grantType, grantCode, value) => {
      let newGrants = _.cloneDeep(this.props.grants);

      if (grantCode === "ALL") {
         let newArray = [];

         if (value) {
            if (grantType === "brand") {
               let newBrandList = this.props.brandList.map((brand) => {
                  return {
                     grantCode: brand.brandCode,
                     grantType,
                     permissionType: this.props.tabType,
                     userId: this.props.currentUser.userId,
                  };
               });

               if (this.props.tabType !== "view") {
                  for (let i = 0; i < this.props.brandList.length; i++) {
                     let brand = this.props.brandList[i];
                     let index = null;
                     for (let y = 0; y < this.props.grants.length; y++) {
                        if (
                           this.props.grants[y].grantCode === brand.brandCode &&
                           this.props.grants[y].grantType === grantType &&
                           this.props.grants[y].permissionType === "view"
                        ) {
                           index = y;
                        }
                     }
                     if (index === null) {
                        newBrandList.push({
                           grantCode: brand.brandCode,
                           grantType,
                           permissionType: "view",
                           userId: this.props.currentUser.userId,
                        });
                     }
                  }
               }

               newArray = [...newGrants, ...newBrandList];
            } else if (grantType === "starszone") {
               let newStarsZoneList = this.props.starsZoneList.map(
                  (country) => {
                     return {
                        grantCode: country,
                        grantType,
                        permissionType: this.props.tabType,
                        userId: this.props.currentUser.userId,
                     };
                  }
               );

               if (this.props.tabType !== "view") {
                  for (let i = 0; i < this.props.starsZoneList.length; i++) {
                     let plChannel = this.props.starsZoneList[i];
                     let index = null;
                     for (let y = 0; y < this.props.grants.length; y++) {
                        if (
                           this.props.grants[y].grantCode ===
                              plChannel.planningChannelId &&
                           this.props.grants[y].grantType === grantType &&
                           this.props.grants[y].permissionType === "view"
                        ) {
                           index = y;
                        }
                     }
                     if (index === null) {
                        newStarsZoneList.push({
                           grantCode: plChannel,
                           grantType,
                           permissionType: "view",
                           userId: this.props.currentUser.userId,
                        });
                     }
                  }
               }

               newArray = [...newGrants, ...newStarsZoneList];
            }
         } else {
            newArray = newGrants.filter((grant) => {
               if (
                  grant.grantType === grantType &&
                  grant.permissionType === this.props.tabType
               ) {
                  if (this.props.tabType === "view") {
                     let index = null;
                     for (let y = 0; y < this.props.grants.length; y++) {
                        if (
                           this.props.grants[y].grantCode === grant.grantCode &&
                           this.props.grants[y].grantType === grantType &&
                           this.props.grants[y].permissionType !== "view"
                        ) {
                           index = y;
                        }
                     }
                     if (index !== null) {
                        return true;
                     }
                  }
                  return false;
               }
               return true;
            });
         }

         return this.props.saveUserGrants(
            _.uniqWith(newArray, (a, b) => {
               return (
                  a.grantCode === b.grantCode &&
                  a.permissionType === b.permissionType &&
                  a.grantType === b.grantType
               );
            })
         );
      }

      let singleGrant = {
         grantCode,
         grantType,
         permissionType: this.props.tabType,
         userId: this.props.currentUser.userId,
      };

      if (value) {
         if (this.props.tabType !== "view" && grantType !== "rule") {
            //if set edit or submit, set also view privilege
            let isViewPresent = false;
            this.props.grants.map((g) => {
               if (
                  g.grantCode === grantCode &&
                  g.permissionType === "view" &&
                  g.grantType === grantType
               ) {
                  isViewPresent = true;
               }
            });
            if (!isViewPresent) {
               newGrants.push({
                  grantCode,
                  grantType,
                  permissionType: "view",
                  userId: this.props.currentUser.userId,
               });
            }
         }

         newGrants.push(singleGrant);
      } else {
         if (this.props.tabType === "view" && grantType !== "rule") {
            //if same edit or submit privilege is present, don't do anything
            let isEditOrSubmitPresent = false;
            this.props.grants.map((g) => {
               if (
                  g.grantCode === grantCode &&
                  g.permissionType !== "view" &&
                  g.grantType === grantType
               ) {
                  isEditOrSubmitPresent = true;
               }
            });

            if (isEditOrSubmitPresent) return;
         }

         let index = null;

         for (let i = 0; i < newGrants.length; i++) {
            if (
               newGrants[i].grantCode === grantCode &&
               newGrants[i].permissionType === this.props.tabType &&
               newGrants[i].grantType === grantType
            ) {
               index = i;
            }
         }

         if (index !== null) {
            newGrants.splice(index, 1);
         }
      }
      this.props.saveUserGrants(newGrants);
   };

   render() {
      return (
         <div className="tab-content">
            <div className="single-section">
               <div className="small-column">
                  <p>Brand</p>
               </div>
               <div className="large-column">
                  {this.props.brandList &&
                     this.props.brandList.map((brand) => {
                        return (
                           <div
                              className="chip-container"
                              key={brand.brandCode}
                           >
                              <Chip
                                 label={brand.brandCode}
                                 code={brand.brandCode}
                                 handleClick={this.handleSetGrant}
                                 type="brand"
                                 icon="icon-plus"
                                 active={
                                    this.props.grants.filter(
                                       (g) =>
                                          g.grantCode === brand.brandCode &&
                                          g.permissionType ===
                                             this.props.tabType &&
                                          g.grantType === "brand"
                                    ).length > 0
                                 }
                              />
                           </div>
                        );
                     })}
               </div>
               <div className="chip-all">
                  <Chip
                     classChip="chip "
                     label="ALL"
                     handleClick={this.handleSetGrant}
                     type="brand"
                     code="ALL"
                     icon="icon-plus"
                     active={
                        this.props.brandList &&
                        this.props.grants.filter(
                           (g) =>
                              g.permissionType === this.props.tabType &&
                              g.grantType === "brand"
                        ).length === this.props.brandList.length
                     }
                  />
               </div>
            </div>
            <div className="single-section">
               <div className="small-column">
                  <p>Stars zone</p>
               </div>
               <div className="large-column">
                  {this.props.starsZoneList &&
                     this.props.starsZoneList.map((star, i) => {
                        return (
                           <div className="chip-container" key={i}>
                              <Chip
                                 classChip="chip "
                                 label={star}
                                 type="starszone"
                                 icon="icon-plus"
                                 handleClick={this.handleSetGrant}
                                 code={star}
                                 active={
                                    this.props.grants.filter(
                                       (g) =>
                                          g.grantCode === star &&
                                          g.permissionType ===
                                             this.props.tabType &&
                                          g.grantType === "starszone"
                                    ).length > 0
                                 }
                              />
                           </div>
                        );
                     })}
               </div>
               <div className="chip-all">
                  <Chip
                     classChip="chip "
                     label="ALL"
                     handleClick={this.handleSetGrant}
                     type="starszone"
                     code="ALL"
                     icon="icon-plus"
                     active={
                        this.props.starsZoneList &&
                        this.props.grants.filter(
                           (g) =>
                              g.permissionType === this.props.tabType &&
                              g.grantType === "starszone"
                        ).length === this.props.starsZoneList.length
                     }
                  />
               </div>
            </div>

            <div className="footer-section">
               {this.props.ruleList &&
                  this.props.ruleList
                     .filter(
                        (rule) => rule.permissionType === this.props.tabType
                     )
                     .map((rule) => (
                        <CheckboxManageUser
                           key={rule.ruleCode}
                           setChecked={(e) =>
                              this.handleSetGrant("rule", rule.ruleCode, e)
                           }
                           label={rule.description}
                           checked={
                              this.props.grants.filter(
                                 (g) =>
                                    g.grantCode === rule.ruleCode &&
                                    g.grantType === "rule"
                              ).length > 0
                           }
                        />
                     ))}
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      ruleList: state.user.ruleList,
      grants: state.user.currentUser.grants,
      currentUser: state.user.currentUser,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      saveUserGrants: (grants) => dispatch(actions.saveUserGrants(grants)),
   };
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ManageUserPopupContent);
