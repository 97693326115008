import React from "react";
import star from "../../img/Vector.png";
import { Link } from "react-router-dom";

const logo = (props) => (
   <div className="icon-home">
      <Link to="/">
         <img src={star} alt="MyStars Assortment" className="logo-star" />
      </Link>
   </div>
);
export default logo;
