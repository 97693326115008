/* eslint-disable array-callback-return */

import * as _ from "lodash";

import { exportType } from "../services/constList";
import { logout } from "../store/actions";

export const collectionFormatArray = (collection) => {
   return collection.map((item) => {
      let label = "OPT";

      if (item === "20") {
         label = "SUN";
      }

      return {
         label,
         value: item,
      };
   });
};
// helper func
// intersection of n arrays
export const intersectArray = (first = [], ...rest) => {
   rest = rest.map((array) => new Set(array));
   return first.filter((e) => rest.every((set) => set.has(e)));
};

// grant brands functions
export const isAdmin = (grants) => {
   return grants && grants.length && grants[0].role.toLowerCase() === "admin";
};

export const isSubmitUser = (grants) => {
   return (
      grants && grants.some((grantType) => grantType.types.includes("submit"))
   );
};

export const isEditorUser = (grants) => {
   return (
      grants && grants.some((grantType) => grantType.types.includes("edit"))
   );
};

export const grantEditableBrands = (grants) => {
   return grants
      .map((grant) => {
         if (grant.types.includes("edit")) {
            return formatOptionsArray(grant.brands);
         }
      })
      .filter((brandList) => brandList)[0];
};

export const hasBrandEditGrants = (selFiltersbrand, grants) => {
   return selFiltersbrand && selFiltersbrand.length
      ? grantEditableBrands(grants).some((brand) => {
         return selFiltersbrand.some((selBrand) => {
            return selBrand.value === brand.value;
         });
      })
      : false;
};

// grants checkboxes permissions
const checkBoxPermission = (
   grants,
   type,
   rule,
   brand = false,
   starZones = false
) => {
   return grants.some((grant) => {
      if (grant.role.toLowerCase() === "admin") {
         return true;
      }
      if (!grant.types.includes(type)) {
         return false;
      }
      const hasRule = grant.rules.includes(rule);
      // if brand is false it means that is not selected in homepage
      let hasBrand;
      if (brand && brand.length) {
         hasBrand =
            brand.constructor === String
               ? grant.brands.includes(brand)
               : brand.some((selBrand) =>
                  grant.brands.includes(selBrand.value)
               );
      } else {
         hasBrand = true;
      }
      let hasStarsZones =
         starZones &&
         grant.starsZones.some((grantStarZone) =>
            starZones.includes(grantStarZone)
         );
      if (!starZones) {
         hasStarsZones = true;
      }

      return hasRule && hasBrand && hasStarsZones;
   });
};

export const canReopen = (grants, brand, starZones) => {
   return checkBoxPermission(grants, "edit", "reopen", brand, starZones);
};

export const canCreate = (grants, brand, starZones) => {
   return checkBoxPermission(grants, "edit", "create", brand, starZones);
};

export const canDelete = (grants, brand, starZones) => {
   return checkBoxPermission(grants, "submit", "delete", brand, starZones);
};

// grant permissions integration
const isVisibleForType = (type, grants, brand, starsZone) => {
   return grants.some((grant) => {
      if (!grant.types.includes(type)) {
         return false;
      }
      const userHasBrand = grant.brands.includes(brand);

      const userHasStarszone = grant.starsZones.some((grantZone) =>
         starsZone.includes(grantZone)
      );
      if (userHasBrand && userHasStarszone) {
         return true;
      }
      return false;
   });
};

export const isVisibleForUser = (grants, isSubmit, brand, starsZone) => {
   if (grants.some((grantType) => grantType.role.toLowerCase() === "admin")) {
      return true;
   }

   if (
      isSubmit &&
      grants.some((grantType) => grantType.types.includes("submit"))
   ) {
      if (isVisibleForType("submit", grants, brand, starsZone)) {
         return true;
      }
   }

   if (
      !isSubmit &&
      grants.some((grantType) => grantType.types.includes("edit"))
   ) {
      if (isVisibleForType("edit", grants, brand, starsZone)) {
         return true;
      }
   }

   return false;
};

export const selectedModuleBrandAndColl = (macroList) => {
   const selectedFilters = {
      brand: [],
      collection: [],
   };
   macroList &&
      macroList.map((macro) => {
         macro.moduleList.map((module) => {
            if (!selectedFilters.brand.includes(module.brand)) {
               selectedFilters.brand.push(module.brand);
            }
            if (!selectedFilters.collection.includes(module.collection)) {
               selectedFilters.collection.push(module.collection);
            }
         });
      });
   return selectedFilters;
};

export const selectedAllFilters = (macroList,filtersModuleExport) => {
   const selectedFiltersAll = {
      brand: [],
      clusterId: [],
      collection: [],
      clusterLabel: [],
      codiceStoreSap: [],
      country: filtersModuleExport!==undefined && filtersModuleExport.zonaPerMysa !== undefined? filtersModuleExport.zonaPerMysa:[], //star zone
      customerSegmentation: [],
      macroFamily: [],
      moduleLabel: [],
      monthId: [],
      newModule: [],
      planningChannel: [],
      rangeFacing: [],
      starsCustomer: filtersModuleExport!==undefined && filtersModuleExport.starsCustomer !== undefined? filtersModuleExport.starsCustomer:[],//star customer
      starZones: [],
      storeJda: []
   };
   
   macroList.map((module) => {

            if (!selectedFiltersAll.brand.includes(module.brand)) {
               selectedFiltersAll.brand.push(module.brand);
            }
            if (!selectedFiltersAll.monthId.includes(module.monthId)) {
               selectedFiltersAll.monthId.push(module.monthId);
            }
            if (!selectedFiltersAll.macroFamily.includes(module.macroFamily)) {
               selectedFiltersAll.macroFamily.push(module.macroFamily);
            }
            if (!selectedFiltersAll.collection.includes(module.collection)) {
               selectedFiltersAll.collection.push(module.collection);
            }
            if (!selectedFiltersAll.moduleLabel.includes(module.moduleLabel)) {
               selectedFiltersAll.moduleLabel.push(module.moduleLabel);
            }
            if (!selectedFiltersAll.starZones.includes(module.starZones)) {
               selectedFiltersAll.starZones = [
                  ...selectedFiltersAll.starZones,
                  ...module.starZones,
               ];
            }
         });
   return selectedFiltersAll;
};

export const selectedModuleBrandAndStarszone = (macroList) => {
   const selectedFilters = {
      brand: [],
      starZones: [],
   };
   macroList &&
      macroList.map((macro) => {
         macro.moduleList.map((module) => {
            if (!selectedFilters.brand.includes(module.brand)) {
               selectedFilters.brand.push(module.brand);
            }
            if (!selectedFilters.starZones.includes(module.starZones)) {
               selectedFilters.starZones = [
                  ...selectedFilters.starZones,
                  ...module.starZones,
               ];
            }
         });
      });
   return selectedFilters;
};

export const isBestModule = function (macro) {
   return safeCompare(macro, "bestsellers") || safeCompare(macro, "bestseller");
};

export const printArray = (arrayToPrint) => {
   let stringToPrint = mapArrayToObj(arrayToPrint).join(", ");
   return stringToPrint;
};

export const formattedDate = () => {
   return new Date().toLocaleDateString("it-IT", {
      // weekday: 'long',
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
   });
};

export const formattedDateGivenAnInput = (date) => {
   let dateObj = new Date(date);
   return dateObj.toLocaleDateString("it-IT", {
      // weekday: 'long',
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "numeric",
      hour12: false,
   });
};

export const formatNumber = (num) => {
   let stringNumber = num.toString().replace(".", ",");
   return stringNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const isSameDay = (d1, d2) => {
   return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
   );
};

export const isWithinRange = (dateStart, dateEnd, dateToCheck) => {
   if (dateToCheck > dateStart && dateToCheck < dateEnd) {
      return true;
   }

   return false;
};

export const printPty = (pty) => {
   let ptyN = parseFloat(pty);
   return ptyN.toFixed(1);
};

export const escapeRegexCharacters = (str) => {
   return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const toSafeString = (str) => {
   if (str === undefined) return "";
   return str.trim().replace(" ", "").toLowerCase();
};

export const safeCompare = (str1, str2) => {
   return toSafeString(str1) === toSafeString(str2);
};

export const getSku = (product) => {
   return product["model"] + product["color"] + product["size"];
};

export const orderRangeFacing = (optionsList) => {
   return optionsList.sort(function (a, b) {
      let arrayValuesA = a.split("-");
      let arrayValuesB = b.split("-");

      if (parseInt(arrayValuesA[0]) > parseInt(arrayValuesB[0])) {
         return 1;
      } else {
         return -1;
      }
   });
};

export const updateObject = (oldObject, updatedProperties) => {
   return {
      ...oldObject,
      ...updatedProperties,
   };
};

export const updateObject2 = (oldObject, updatedProperties) => {
   if (
      !Object.keys(oldObject.rowsToSendMassiveEdit).includes(
         Object.keys(updatedProperties.rowsToSendMassiveEdit)[0]
      )
   ) {
      return {
         ...oldObject,
         rowsToSendMassiveEdit: {
            ...oldObject.rowsToSendMassiveEdit,
            ...updatedProperties.rowsToSendMassiveEdit,
         },
      };
   } else {
      let keyToMerge = Object.keys(updatedProperties.rowsToSendMassiveEdit)[0];

      return {
         ...oldObject,
         rowsToSendMassiveEdit: {
            ...oldObject.rowsToSendMassiveEdit,
            [keyToMerge]: updatedProperties.rowsToSendMassiveEdit[keyToMerge],
         },
      };
   }
};

export const toggleState = (stateName) =>
   this.setState((prevState) => {
      return { [stateName]: !prevState[stateName] };
   });

export const mapArrayToObj = (filter) => {
   if (filter.length > 0) {
      return filter.map((singleFilter) => singleFilter.value);
   } else {
      return [];
   }
};
export const formatCamelCase = (title) => {
   return title.replace(/([a-z])([A-Z])/g, "$1 $2");
};

/*** FORMAT OPTIONS WHEN ARRAY OF OBJ ***/
export const formatOptions = (optionList, labelProp) => {
   return labelProp && optionList && optionList.map((item) => {
      return {
         ...item,
         label: item[labelProp],
         value: item[labelProp],
      };
   });
};

export const formatOptionsOrdered = (optionList, labelProp) => {
   return _.orderBy(
      labelProp && optionList && optionList.map((item) => {
         return {
            ...item,
            label: item[labelProp],
            value: item[labelProp],
         };
      }),
      ["label"]
   );
};

/*** FORMAT OPTIONS WHEN ARRAY OF STRING ***/
export const formatOptionsArray = (optionList) => {
   return _.orderBy(
      optionList && optionList.map((item) => {
         return {
            label: item,
            value: item,
         };
      }),
      ["label"]
   );
};

/*** FORMAT OPTIONS WHEN ARRAY OF STRING UNORDERED ***/
export const formatOptionsArrayUnordered = (optionList) => {
   return optionList && optionList.map((item) => {
      return {
         label: item,
         value: item,
      };
   });
};

/*** FORMAT OPTIONS WHEN ARRAY OF STRING WITH FILTERNAME***/
export const formatFiltersOptionsArray = (optionList, filterName) => {
   return _.orderBy(
      optionList && optionList.map((item) => {
         let label = item;
         if (filterName.includes("date")) {
            label = formattedDateGivenAnInput(item);
         }
         return {
            label,
            value: item,
            filterName,
         };
      }),
      ["label"]
   );
};

/*** FORMAT OPTIONS WHEN ARRAY OF OBJECT WITH FILTERNAME***/
export const formatFiltersOptionsObjArray = (optionList, filterName) => {
   return _.orderBy(
      filterName && optionList && optionList.map((item) => {
         let label = item[filterName];

         return {
            label,
            value: item[filterName],
            filterName,
            ...item,
         };
      }),
      ["label"]
   );
};

export const getValue = (selectedOpts) => {
   return selectedOpts.map((opt) => opt.value);
};

export const orderModulesByMacro = (listModule, isPreview = false) => {
   let objMacro = {};

   //get macrofamily
   listModule && listModule.map((moduleItem) => {
      let family = moduleItem.macroFamily;
      if (objMacro[family] !== undefined) {
         objMacro[family].push(moduleItem);
      } else {
         objMacro[family] = [moduleItem];
      }
   });

   //order modules
   for (let key in objMacro) {
      objMacro[key] = orderModules(objMacro[key], isPreview);
   }

   return sortMacro(objMacro);
};

/* ORDER MODULES - ORDER: NOT SWITCH (A-Z), SWITCH (A-Z) */
export const orderModules = (listModules, isPreview = false) => {
   let items = [];
   // notSwitchItems = [];

   // if (isPreview) {
   // 	listModules.map((moduleI) => {
   // 		if (moduleI.foType === "SWITCH") switchItems.push(moduleI);
   // 		if (moduleI.foType === "NOT SWITCH") notSwitchItems.push(moduleI);
   // 	});
   // } else {
   listModules && listModules.map((moduleI) => {
      if (moduleI.clusterCompositionStatus) items.push(moduleI);
      // if (moduleI.boType === "NOT SWITCH") notSwitchItems.push(moduleI);
   });
   // }

   let orderedItems = _.orderBy(items, ["moduleName"]);
   // let orderedNotSwitch = _.orderBy(notSwitchItems, ["moduleName"]);
   return orderedItems;
};

/* SORT MACRO - ORDER: BESTSELLER, NEWNESS, A-Z */
export const sortMacro = (objMacro) => {
   let definiteObj = {},
      bestseller,
      newness,
      others = {};

   for (let key in objMacro) {
      if (
         key.toLowerCase() === "best sellers" ||
         key.toLowerCase() === "best seller"
      )
         bestseller = objMacro[key];
      else if (key.toLowerCase() === "newness") newness = objMacro[key];
      else others[key] = objMacro[key];
   }
   if (bestseller) definiteObj["BEST SELLERS"] = [...bestseller];
   if (newness) definiteObj["NEWNESS"] = [...newness];
   let sorted = sortObjKeysAlphabetically(others);
   return { ...definiteObj, ...sorted };
};

export const sortObjKeysAlphabetically = (obj) => {
   var ordered = {};
   if (obj) {
      Object.keys(obj)
         .sort()
         .forEach(function (key) {
            ordered[key] = obj[key];
         });
   }

   return ordered;
};

/** FILTER SEARCH RESULTS BY SEARCH VALUE */
export const handleSearchResults = (e, listItemProps, type) => {
   let valueSearch = e && e.target ? e.target.value : e,
      listItem,
      regex = "";

   if (type !== "date" && type !== "dateRange") {
      const escapedValue = escapeRegexCharacters(valueSearch.trim());
      if (escapedValue === "") listItem = { ...listItemProps };

      regex = new RegExp("\\b" + escapedValue, "i");
   }

   switch (type) {
      case "module":
         listItem = listItemProps.filter((moduleItem) => {
            return regex.test(moduleItem.moduleLabel);
         });
         break;
      case "macro":
         listItem = listItemProps.filter((family) => {
            return regex.test(family.macroFamily);
         });
         break;
      case "cluster":
         listItem = listItemProps.filter((cluster) => {
            return regex.test(cluster.clusterLabel);
         });
         break;
      case "user":
         listItem = listItemProps.filter((item) => {
            return regex.test(item.email);
         });
         break;
      case "dateRange":
         if (e === null) {
            listItem = listItemProps;
         } else {
            listItem = listItemProps.filter((item) => {
               return isWithinRange(e[0], e[1], new Date(item.publicationDate));
            });
         }
         break;
      case "date":
         if (e === null) {
            listItem = listItemProps;
         } else {
            listItem = listItemProps.filter((item) => {
               return isSameDay(new Date(item.publicationDate), e);
            });
         }
         break;
      default:
         return;
   }

   return {
      listItem,
      valueSearch,
   };
};

const checkFilters = (filters) => {
   let isNull = false;
   if (filters) {
      Object.keys(filters).forEach((key) => {
         if (filters[key] && key !== "publishDate") return (isNull = true);
      });
   }
   return isNull;
};

/*** FILTER RESULTS WITH FILTERS MULTIPLE */
export const filterResults = (
   allResults,
   selectedFilters, // tutti i filtri
   filterArray, // quello correntemente selezionato
   filterName, // il nome e.g classification
   isAssortment = false,
   isModuleHome = false
) => {
   let filteredResults = [];

   if (filterArray.length === 0 && selectedFilters[filterName] !== undefined)
      delete selectedFilters[filterName];
   else selectedFilters[filterName] = filterArray;

   if (
      selectedFilters &&
      Object.keys(selectedFilters).length &&
      checkFilters(selectedFilters)
   ) {
      //filter results in AND
      Object.keys(selectedFilters).forEach((key) => {
         
         let newArray;
         if (key !== "publishDate") {
            if (
               isAssortment &&
               filterName !== "status" &&
               key !== "rangeFacing" &&
               filterName !== "classification"
            ) {
               newArray = intersectionArray(
                  allResults,
                  selectedFilters[key],
                  true
               );
            } else if (
               isModuleHome &&
               key !== "macroFamily" &&
               key !== "newModule"
            ) {
               newArray = intersectionArrayModule(
                  allResults,
                  selectedFilters[key]
               );
            } else {
               newArray = intersectionArray(allResults, selectedFilters[key]);
            }

            filteredResults.length > 0
               ? (filteredResults = _.intersection(filteredResults, newArray))
               : (filteredResults = newArray);
         }
      });
   } else filteredResults = allResults;

   return {
      selectedFilters,
      filteredResults,
   };
};

//filter results in OR
const intersectionArray = (completeList, filterArray, isStores = false) => {
   let filteredList = [];

   if (filterArray) {
      filterArray.map((filter) => {
         let filterName = filter.filterName === "starsCustomer" ? "storeCustomer" : filter.filterName;
         filterName = filter.filterName === "classification" ? "classifications" : filterName;
         let newArray;

         // filterName = classification
         if (isStores) {
            newArray = completeList.filter((item) => {
               // item -> il dto del cluster. Ha un attibuto classifications che è un array
               let isPresent = false;
               item.stores.map((store) => {
                  console.log("filter store[filterName]", filterName);
                  if (
                     store[filterName] &&
                     store[filterName].constructor === Array
                  ) {
                     let checkValue = store[filterName].filter((value) => {
                        if (value.toLowerCase() === filter.value.toLowerCase())
                           isPresent = true;
                     });
                     if (checkValue.length > 0) isPresent = true;
                  } else {
                     if (
                        store[filterName] &&
                        store[filterName].toLowerCase() ===
                        filter.value.toLowerCase()
                     ){
                        isPresent = true
                     }
                  }
               });
               return isPresent;
            });
         } else {
            newArray = completeList.filter((item) => {
               // item -> il dto del cluster. Ha un attibuto classifications che è un array
               // problema: filterName = classification ma l'attributo è classifications
               if (item[filterName].constructor === Array) {
                  let checkValue = item[filterName].filter((value) => {
                     if(filterName === "classifications"){
                        if (value.classificationLabel.toLowerCase() === filter.value.toLowerCase())
                           return true;   
                     }
                     else{
                        if (value.toLowerCase() === filter.value.toLowerCase())
                           return true;
                     }
                  });
                  return checkValue.length > 0 ? true : false;
               } else {
                  if (filterName.includes("date")) {
                     let itemDate = new Date(item[filterName]).toDateString();
                     if (itemDate === filter.value) return true;
                  } else {
                     if (
                        item[filterName].toString().toLowerCase() ===
                        filter.value.toLowerCase()
                     )
                        return true;
                  }
               }
            });
         }
         filteredList = _.union(filteredList, newArray);
      });
      return filteredList;
   }
   return completeList;
};

//filter results in OR
const intersectionArrayModule = (completeList, filterArray) => {
   let filteredList = [];

   if (filterArray) {
      filterArray.map((filter) => {
         let filterName = filter.filterName,
            newArray;

         newArray = completeList.filter((item) => {
            let isPresent = false;

            if (filterName === "status") {
               isPresent =
                  item[filterName].toLowerCase() === filter.value.toLowerCase();
            } else if (item.clusters) {
               if (filterName === "starsCustomer") {
                  item.clusters.forEach((cluster) => {
                     if (cluster[filterName].includes(filter.value)) {
                        isPresent = true;
                     }
                  });
               } else {
                  item.clusters.forEach((cluster) => {
                     isPresent = cluster[filterName] === filter.value;
                  });
               }
            }

            return isPresent;
         });

         filteredList = _.union(filteredList, newArray);
      });
      return filteredList;
   }
   return completeList;
};

/** ORDER BY DATE ***/
export const orderByDate = (currentOrder, releaseList, propertyName) => {
   let newOrder, sortedList;

   if (currentOrder === "asc") {
      sortedList = releaseList.sort((a, b) => {
         return new Date(b[propertyName]) - new Date(a[propertyName]);
      });
      newOrder = "desc";
   } else {
      sortedList = releaseList.sort((a, b) => {
         return new Date(a[propertyName]) - new Date(b[propertyName]);
      });
      newOrder = "asc";
   }

   return {
      sortedList,
      newOrder,
   };
};

/* CHECK IF OBJ IS NULL */
export const checkNullObj = (obj) => {
   for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "" && obj[key] !== []) return false;
   }
   return true;
};

/** GET ALL PRODUCTS IN PAGE */
export const getProductsList = (macroList) => {
   let productsList = [];

   // get array of all products
   macroList.map((macro) => {
      macro.moduleList.map((m) => {
         productsList.push(...m.productDtoList);
      });
   });

   //check unique values
   productsList = productsList
      .map(function (e) {
         return e.model + e.color + e.size;
      })
      .filter((p, i, l) => l.indexOf(p) === i);

   return productsList;
};

export const checkIfSkuIsPresentInPage = (productsList, sku) => {
   let skuCode = getSku(sku),
      counterSku = 0;

   productsList.map((p) => {
      if (p === skuCode) counterSku++;
   });

   return counterSku;
};

/* GET PRODUCT LENGTH */
export const getProductsLength = (
   moduleI,
   isBestseller = false,
   isEl = false
) => {
   let type = "default";
   if (isBestseller) {
      isEl ? (type = "el") : (type = "mh");
   }

   return moduleI.productDtoList.filter((p) => {
      switch (type) {
         case "mh":
            if (p.moduleCompositionStatus !== "REMOVED" && p.mustHave)
               return true;
            break;
         case "el":
            if (p.moduleCompositionStatus !== "REMOVED" && !p.mustHave)
               return true;
            break;
         default:
            if (p.moduleCompositionStatus !== "REMOVED") return true;
      }
   }).length;
};

// FORMAT STATUS LABEL
export const formatStatusLabel = (status) => {
   return status.replace(/_/g, " ");
};

/* CALCULATE SKU IN CLUSTER */
export const calculateTotSku = (modules) => {
   let totSku = 0;

   if (modules) {
      Object.keys(modules).forEach((macro) => {
         modules[macro].forEach((moduleI) => {
            totSku += moduleI.products.length;
         });
      });
   }

   return totSku;
};

export const calcTotBestsellerSku = (products) => {
   return products.filter((p) => {
      return p.mustHave;
   }).length;
};

/* PERC OF REFRESH CLUSTER */
export const calculatePercOfRefresh = (modules) => {
   let totNew = 0,
      totSku = 0;

   if (modules) {
      Object.keys(modules).forEach((macro) => {
         modules[macro].forEach((moduleI) => {
            if (isBestModule(moduleI.macroFamily)) {
               totSku += calcTotBestsellerSku(moduleI.products);
               if (
                  moduleI.boStatus.toUpperCase() === "NEW" ||
                  (moduleI.foType.toUpperCase() === "ALTERNATIVE" &&
                     moduleI.foStatus.toUpperCase() === "ADDED")
               )
                  totNew += calcTotBestsellerSku(moduleI.products);
            } else {
               totSku += moduleI.products.length;
               if (
                  moduleI.boStatus.toUpperCase() === "NEW" ||
                  (moduleI.foType.toUpperCase() === "ALTERNATIVE" &&
                     moduleI.foStatus.toUpperCase() === "ADDED")
               )
                  totNew += moduleI.products.length;
            }
         });
      });
   }

   let perc = (totNew / totSku) * 100;
   let percOfRefresh = perc.toFixed(0);

   return {
      totSku,
      totNew,
      percOfRefresh,
   };
};

/* TRANSFORM OBJ IN ARRAY */
export const getArrayFromObj = (princ) => {
   let arrayOutput = [];

   if (princ) {
      Object.keys(princ).forEach((key) => {
         arrayOutput.push(...princ[key]);
      });
   }

   return arrayOutput;
};

/** FORMAT OBJ TO SEND */
export const formatFilters = (selectedFilters) => {
   return {
      monthId: selectedFilters.months ? [selectedFilters.months.value] : [],
      planningChannel: selectedFilters.planningChannel.map((pc) => pc.value),
      brand: selectedFilters.brand.map((brand) => brand.value),
      collection: selectedFilters.commodities.map((col) => col.value),
      country: getValue(selectedFilters.countryList),
   };
};

export const formatExportFilters = (
   selectedFilters,
   typeSelected,
   status = [],
   moduleType = []
) => {
   const { MODULE } = exportType;

   return typeSelected === MODULE
      ? {
         // monthId: [selectedFilters.monthId.value],
         monthId: getValue(selectedFilters.monthId),
         planningChannel: getValue(selectedFilters.planningChannel),
         brand: getValue(selectedFilters.brand),
         collection: getValue(selectedFilters.collection),
         country: getValue(selectedFilters.country),
         starsCustomer: getValue(selectedFilters.starsCustomer),
         macroFamily: getValue(selectedFilters.macroFamily),
         clusterId: getValue(selectedFilters.clusterId),
         newModule: getValue(selectedFilters.newModule),
         status: status,
         types: moduleType,
         // tB:03-2022
         moduleLabel: selectedFilters.moduleLabel,
      }
      : {
         // monthId: [selectedFilters.monthId.value],
         monthId: getValue(selectedFilters.monthId),
         planningChannel: getValue(selectedFilters.planningChannel),
         brand: getValue(selectedFilters.brand),
         collection: getValue(selectedFilters.collection),
         country: getValue(selectedFilters.country),
         starsCustomer: getValue(selectedFilters.starsCustomer),
         storeJda: getValue(selectedFilters.storeJda),
         codiceStoreSap: getValue(selectedFilters.codiceStoreSap),
         rangeFacing: getValue(selectedFilters.rangeFacing),
         customerSegmentation: getValue(selectedFilters.customerSegmentation),
         classification: getValue(selectedFilters.clientClassification),
         status: status,
         // tB:03-2022
         clusterLabel: selectedFilters.clusterLabel,
      };
};

/* TAKE OFF MODULES IN PAGE */
export const excludeModulesInPage = (moduleList, macroList) => {
   let moduleOptions;
   if (macroList !== null && macroList !== undefined) {
      let options = [];
      macroList.map((macro) => {
         let temp = _.differenceBy(moduleList, macro.moduleList, "moduleName");
         if (options.length > 0) {
            let inter = _.intersection(options, temp);
            options = inter;
         } else options.push(...temp);
      });
      moduleOptions = _.flatten(options);
   }
   return moduleOptions;
};

/* HANDLE ERROR */
export const handleErrors = (response) => {
   if (!response.ok) {
      response.json().then((res) => {
         throw Error(
            "Request rejected with status " +
            res.status +
            " Msg: " +
            res.message
         );
      });
   }
   return response;
};

/* HANDLE ERROR LOGIN */
export const handleErrorsLogin = (response) => {
   if (!response.ok) {
      return Promise.reject(response);
   }
   return response;
};

/* HANDLE ERROR */
export const handleErrorsJson = (response, dispatch) => {
   if (response.ok) {
      return response.json();
   } else {
      if (response.status === 401) {
         return dispatch(logout(dispatch));
      }
      // if(response.json()){
      response.json().then((err) => {
         throw err;
      });
      // }
      // if(response.text()){
      //     response.text().then(err => {
      //         throw err;
      //     })
      // }
   }
};

export const handleErrorsText = (response, dispatch) => {
   if (response.ok) {
      return response;
   } else {
      if (response.status === 401) {
         return dispatch(logout(dispatch));
      }

      return response;
   }
};

export const onUnload = (e, areThereChanges) => {
   if (areThereChanges) {
      var confirmationMessage =
         "Are you sure you want to leave this page? All changes will be lost";

      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
   }
};

export const addSelectAll = (filtersToChange) => {
   let filters = _.cloneDeep(filtersToChange);
   if (filtersToChange) {
      Object.keys(filters).forEach((key) => {
         if (key !== "monthsRolling") {
            filters[key].unshift({
               label: "Select All",
               value: "SELECT_ALL",
            });
         }
      });
   }

   return filters;
};

export const checkThereIsSelectAll = (filterList) => {
   let isThere = false;
   filterList.map((filter) => {
      if (filter.value === "SELECT_ALL") {
         isThere = true;
      }
   });

   return isThere;
};

export const isSameSku = (sku, skuToCompare) => {
   return (
      sku.model === skuToCompare.model &&
      sku.color === skuToCompare.color &&
      sku.size === skuToCompare.size
   );
};

//Print label on sku in Active Area
export const printLabelSku = (searchType, sku) => {
   let label = "";
   switch (searchType) {
      case "sku":
         label = sku.model + " " + sku.color + " " + sku.size;
         break;
      case "model":
         label = sku.model;
         break;
      case "model/color":
         label = sku.model + " " + sku.color;
         break;
      case "model/size":
         label = sku.model + " " + sku.size;
         break;
      default:
   }
   return label;
};

//order sku in a module by pty
export const orderByPty = (moduleComponent) => {
   let products = [...moduleComponent.productDtoList],
      total = 0;

   for (let i = 0; i < products.length; i++) {
      if (
         products[i].pty &&
         products[i].moduleCompositionStatus !== "REMOVED"
      ) {
         products[i].pty = parseFloat(products[i].pty);
         total += products[i].pty;
      }
   }

   moduleComponent.products = _.orderBy(products, "pty", "desc");
   moduleComponent.pty = total;

   return moduleComponent;
};

//get macro name list
export const getMacroInPage = (macroList) => {
   if (macroList && macroList.length > 0) {
      return macroList.map((m) => {
         return m.macroFamily;
      });
   }
};

export const getValueCheckbox = (value, isChecked, selectedOld) => {
   let selected = [...selectedOld];

   if (!isChecked) {
      if (!selected.includes(value)) {
         selected.push(value);
      }
   } else {
      if (selected.includes(value)) {
         let index = selected.indexOf(value);
         selected.splice(index, 1);
      }
   }

   return selected;
};
