import "./PDFAreaTable.scss";

import { PdfAreaTableRow } from "../PdfAreaTableRow";
import { useMemo } from "react";

const PDFAreaTable = ({ uploads, onShowResponseClick,user }) => {
  const PdfAreaTableRows = useMemo(() => {
    if (!Array.isArray(uploads) || !uploads.length) {
      return (
        <tr>
          <td colSpan="100%">No Data Available</td>
        </tr>
      );
    }

    return uploads.map((upload) => (
      <PdfAreaTableRow
        key={upload.id.toString()}
        {...upload}
        onShowResponseClick={onShowResponseClick}
        user={user}
      />
    ));
  }, [uploads, onShowResponseClick, user]);

  return (
    <table className="upload-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>User name</th>
          <th>Export</th>
          <th>Status</th>
          <th>Progress</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>{PdfAreaTableRows}</tbody>
    </table>
  );
};

export default PDFAreaTable;
