import React, { Component } from "react";

import ClusterAnalysis from "./ClusterAnalysis";
import CustomIcon from "../UI-components/CustomIcon";
import Loader from "../UI-components/Loader";
import { handleSearchResults } from "../../services/Api";

class PercentageRange extends Component {
  state = {
    isPercPopupOpen: false,
    clusterList: [],
    valueSearch: "",
    isNotChangedYet: false,
  };

  componentDidMount() {
    if (
      this.props.macroList !== null &&
      //this.props.macroList.length > 0 &&
      this.props.isPercOpen
    ) {
      this.props.getPercData();
      /*if(this.props.data[0].length > 0){
        this.setState({clusterList: this.props.data,});
      }*/
    }
    if (!this.props.isListChanged)
      this.setState({
        isNotChangedYet: true,
      });
  }

  // shouldComponentUpdate = nextProps => {
  //     return (nextProps.isPercOpen) ? true : false;
  // }

  componentDidUpdate(prevProps) {
    
    if (prevProps.isPercOpen !== this.props.isPercOpen) {
      if (this.props.isPercOpen) this.props.getPercData()
    }
    if (
      prevProps.isListChanged !== this.props.isListChanged &&
      this.props.isListChanged
    )
      this.setState({
        isNotChangedYet: false,
      });
  }

  openPopup = (datum, index) => {
    this.setState({
      clusterList: datum.assortments,
      clusterListSearch: datum.assortments,
      isPercPopupOpen: true,
    });
  };

  togglePopup = (stateName) =>
    this.setState((prevState) => {
      return { [stateName]: !prevState[stateName] };
    });

  handleSearch = (e) => {
    let clusterList = [...this.state.clusterList],
      searchObj = handleSearchResults(e, clusterList, "cluster");

    this.setState({
      clusterListSearch: searchObj.listItem,
      valueSearch: searchObj.valueSearch,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={
            "orizontal-bar component " + (this.props.isPercOpen ? "hide" : "")
          }
          onClick={this.props.togglePerc}
        >
          <span>Open Cluster Analysis</span>
          <CustomIcon iconClass="icon-arrow1" />
        </div>
        <div
          className={
            "component perc-range " + (this.props.isPercOpen ? "open" : "")
          }
        >
          <header onClick={this.props.togglePerc}>
            <h2>Close Cluster Analysis</h2>
            <CustomIcon iconClass="icon-arrow1" />
          </header>
          <div className="chart-container">
            {!this.props.isGetPercDataLoading ? (
              <React.Fragment>
                <br/>
                <p className="legend">
                  List of clusters containing the open modules: {this.props.totAssortmentData}
                </p>
                {this.props.data && this.props.macroList? (
                  <ClusterAnalysis skus={this.props.data} macroList={this.props.macroList.flatMap(module=>module.moduleList).map(module=>module.moduleLabel)}/>
                ): null}
              </React.Fragment>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PercentageRange;
