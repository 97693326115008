import Button from "../UI-components/Button";

const UploadsTableRow = ({
  date,
  username,
  importType,
  status,
  progress,
  response,
  onShowResponseClick,
}) => {
  return (
    <tr>
      <td>
        {Intl.DateTimeFormat("it", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(date)}
      </td>
      <td>{username}</td>
      <td>{importType}</td>
      <td>{status}</td>
      <td>{progress ? `${progress}%` : "-"}</td>
      <td>
        {response ? (
          <Button handleClick={() => onShowResponseClick(response)}>
            Show
          </Button>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
};

export default UploadsTableRow;
