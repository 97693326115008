import React from 'react';
import CustomIcon from '../UI-components/CustomIcon';

const leftbar = (props) =>(
    <div className={"left-bar " + props.leftbarClass} onClick={props.handleClick}>    
        <div className="label-container">        
                <span>{props.label}</span>
            <CustomIcon iconClass='icon-arrow1' />        
        </div>                          
    </div>
)
export default leftbar;