/* eslint-disable array-callback-return */
import React, { Component } from "react";
import ListItemHome from "./ListItemHome";
import ListItem from "./ListItem";
import CustomSearch from "../../UI-components/CustomSearch";
import AddMacroPopup from "../Popup/AddMacroPopup";
import Button from "../../UI-components/Button";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import { handleSearchResults, canCreate } from "../../../services/Api";

class ListMacro extends Component {
   constructor(props) {
      super(props);

      this.state = {
         isAddMacroOpen: false,
         selectedFamilies: [],
         listItem: [],
         valueSearch: "",
         values: {
            macroFamily: "",
            modulesList: [],
         },
         isMacroClicked: false,
         collection: null,
      };

      this.handleSearch = this.handleSearch.bind(this);
   }

   componentDidMount = () => {
      if (this.props.listItem)
         this.setState({ listItem: [...this.props.listItem] });
   };

   componentDidUpdate = (prevProps) => {
      if (this.props.listItem && prevProps.listItem !== this.props.listItem)
         this.setState({ listItem: [...this.props.listItem] });

      if (
         prevProps.isLockMacroLoading !== this.props.isLockMacroLoading &&
         !this.props.isLockMacroLoading &&
         this.state.isMacroClicked
      ) {
         this.props.history.push("/module");
         this.setState({
            isMacroClicked: false,
         });
      }

      if (
         prevProps.isNewMacro !== this.props.isNewMacro &&
         this.props.isNewMacro
      ) {
         this.props.history.push("/module");
      }
   };

   selectFamily = (macrofamily) => {
      let selectedFamilies = [...this.state.selectedFamilies],
         counter = 0;

      if (selectedFamilies.length > 0) {
         //togli elemento se c'è già
         selectedFamilies = selectedFamilies.filter((family) => {
            if (family.macroFamily === macrofamily.macroFamily) {
               counter++;
               return false;
            } else return true;
         });

         if (counter === 0) {
            selectedFamilies.push(macrofamily);
         }
      } else {
         selectedFamilies.push(macrofamily);
      }
      this.setState({ selectedFamilies });
   };

   deselectAll = () => this.setState({ selectedFamilies: [] });

   checkIfSelected = (singleItem) => {
      return this.state.selectedFamilies.filter((family) => {
         if (family.macroFamily === singleItem.macroFamily) {
            return true;
         }
      });
   };

   goToMacro = () => {
      this.props.getWorkingModuleMacroWithLock(
         this.props.selectedFilters,
         this.state.selectedFamilies
      );
      this.setState({
         isMacroClicked: true,
      });
   };

   handleSearch = (e) => {
      let valueObj = handleSearchResults(e, this.props.listItem, "macro");

      this.setState({
         listItem: valueObj.listItem,
         valueSearch: valueObj.valueSearch,
      });
   };

   printListItem = () => {
      return this.state.listItem.map((singleItem) => {
         let checkArray = this.checkIfSelected(singleItem);
         return (
            <ListItemHome
               key={singleItem.macroFamily}
               item={singleItem}
               type="macro-family"
               listItemClass={checkArray.length > 0 ? "selected" : ""}
               handleClick={() => this.selectFamily(singleItem)}
               iconClass="icon-arrow2"
            >
               {singleItem.macroFamily}
            </ListItemHome>
         );
      });
   };

   //ADD MACRO POPUP METHODS
   togglePopup = () =>
      this.setState((prevState) => {
         return { isAddMacroOpen: !prevState.isAddMacroOpen };
      });

   handleChange = (type, opts) => {
      if (type === "macroFamily") {
         opts = opts.target.value;
      }

      this.setState((prevState) => {
         return {
            values: { ...prevState.values, [type]: opts },
         };
      });
   };

   handleCreateMacro = () => {
      this.props.createMacroFamily(this.state.values.macroFamily);
      // this.props.history.push('/module');
   };

   handleChangePC = (option) => {
      this.setState({
         planningChannel: option,
      });
   };

   handleChangeBrand = (option) => {
      this.setState({
         brand: option,
      });
   };

   handleChangeCollection = (option) => {
      this.setState({
         collection: option,
      });
   };

   render() {
      let listItem = this.state.listItem !== null ? this.printListItem() : [];

      return (
         <React.Fragment>
            <div className="list-component col-4 macro">
               <div className="list-content component">
                  <header>
                     <h3>Macrofamily Selection</h3>
                     <CustomSearch
                        placeholder="Macrofamily Name"
                        handleChange={this.handleSearch}
                        value={this.state.valueSearch}
                     />
                  </header>
                  <div className="main-content">
                     <ul className="list">
                        <ListItem
                           handleClick={() => {
                              this.togglePopup("isAddMacroOpen");
                           }}
                           listItemClass={
                              "list-item-black " +
                              (this.props.isEditable ? "" : "close")
                           }
                           isDisabled={
                              this.props.auth &&
                              this.props.auth.grants &&
                              !canCreate(
                                 this.props.auth.grants,
                                 this.props.selectedFilters.brand
                              )
                           }
                           iconClass="icon-plus"
                        >
                           Create New Family
                        </ListItem>
                        {listItem}
                     </ul>
                  </div>
                  <footer>
                     <Button
                        btnClass="btn-medium"
                        disabled={this.state.selectedFamilies.length === 0}
                        handleClick={this.goToMacro}
                     >
                        Open Families
                     </Button>
                     <Button
                        btnClass="btn-medium"
                        disabled={this.state.selectedFamilies.length === 0}
                        handleClick={this.deselectAll}
                     >
                        Deselect All
                     </Button>
                  </footer>
               </div>
            </div>
            {this.state.isAddMacroOpen && (
               <AddMacroPopup
                  togglePopup={() => this.togglePopup("isAddMacroOpen")}
                  handleChangeFamily={(o) => {
                     this.handleChange("macroFamily", o);
                  }}
                  handleChangeModules={(o) => {
                     this.handleChange("modulesList", o);
                  }}
                  handleCreateMacro={this.handleCreateMacro}
                  moduleOptions={this.props.moduleOptions}
                  filterHome={this.props.filterHome}
                  collectionState={this.state.collection}
                  handleChangeCollection={this.handleChangeCollection}
                  {...this.props.selectedFilters}
                  {...this.state.values}
               />
            )}
         </React.Fragment>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      moduleOptions: state.filter.results.moduleList,
      selectedFilters: state.filter.selectedFilters,
      lockMacro: state.user.lockMacro,
      isLockMacroLoading: state.user.isLockMacroLoading,
      filterHome: state.filter.filterHome,
      isNewMacro: state.module.isNewMacro,
      auth: state.authentication,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getWorkingModuleMacroWithLock: (filters, macro) =>
         dispatch(actions.getWorkingModuleMacroWithLock(filters, macro)),
      createMacroFamily: (family) =>
         dispatch(actions.createMacroFamily(family)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListMacro);
