import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import Loader from "../../components/UI-components/Loader";
import Logo from "../../components/UI-components/Logo";
import { PDFAreaTable } from "../../components";
import Popup from "../../components/UI-components/Popup";
import { connect } from "react-redux";
import { useGetPDFAreaStatus } from "../../fetch";
import { useInterval } from "../../hooks";

const SECONDS_FOR_POLLING = 5;

const PDFArea = (props) => {
  const [showError, setShowError] = useState("");
  const {
    get,
    data: uploads,
    loading,
  } = useGetPDFAreaStatus(({ error }) => {
    setShowError(error.message || "Oops, Something Went Wrong");
  });

  const [showResponse, setShowResponse] = useState();
  const polling = useRef(false);

  useEffect(() => {
    if(showResponse){
      window.open(showResponse)
      setShowResponse();
    }
  },[showResponse]);

  useEffect(() => {
    if (!loading) {
      polling.current = true;
    }
  }, [loading]);

  useInterval(get, SECONDS_FOR_POLLING * 1000);

  if (loading && !polling.current) {
    return (
      <div className="full-page">
        <Loader />
      </div>
    );
  }
  return (
    <div className="upload-page">
      {showError && (
        <Popup
          popupClass="upload-popup-response"
          title="Error"
          close={() => setShowError("")}
        >
          {showError}
        </Popup>
      )}
      <header>
        <Logo />
        <h2>PDF FILES AREA</h2>
      </header>
      <div className="content">
        <div className="upload-table-pdf-area">
          {uploads && (
            <PDFAreaTable
              uploads={uploads}
              onShowResponseClick={setShowResponse}
              user={props.user}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
     selectedFiltersDiscontinued: state.filter.selectedFiltersDiscontinued,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFArea);
