import React from 'react';
import Logo from '../UI-components/Logo';
import ReleaseComponent from './ReleaseComponent'; 
 
class manageRelease extends React.Component {
    
    componentDidMount()  {
        if(!this.props.isGAJustInizialized) this.props.ReactGA.pageview(window.location.pathname + window.location.search);  
    }
    
    render() {
        return (
            <div className="module-comp">
                <header className="minimal-header"> 
                    <Logo/>
                    <h2>Manage Releases</h2>                
                </header>
                <div className="manage-page-container">
                    <ReleaseComponent />
                </div>
            </div>
        )
    }
}

export default manageRelease;