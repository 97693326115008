import React from "react";
import Filters from "./Filters";
import SearchResults from "./SearchResults";

class homepage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
       clientClassificationPopupVisible: false
    };
  }

  openClientClassificationPopup = () => {
    return this.setState(() => {
       return {
          clientClassificationPopupVisible: true,
       };
    });
  }

  closeClientClassificationPopup = () => {
    return this.setState(() => {
       return {
          clientClassificationPopupVisible: false,
       };
    });
  }

  componentDidMount() {
    if (!this.props.isGAJustInizialized)
      this.props.ReactGA.pageview(
        window.location.pathname + window.location.search
      );
  }

  render() {
    return (
      <div className="row no-gutters">
        <Filters 
          openClientClassificationPopup={this.openClientClassificationPopup}
          closeClientClassificationPopup={this.closeClientClassificationPopup}
          clientClassificationPopupVisible={this.state.clientClassificationPopupVisible}
        />
        <SearchResults
          history={this.props.history}
          location={this.props.location}
          clientClassificationPopupVisible={this.state.clientClassificationPopupVisible}
        />
      </div>
    );
  }
}

export default homepage;
