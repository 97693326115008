import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../services/Api";

const initialState = {
  activeFilters: null,
  ibicFilters: null,
  activeSku: null,
  draggedSku: null,
  isNewModule: null,
  isNewMacro: null,
  isGetByMacro: null,
  isListChanged: null,
  loading: null,
  macroList: null,
  moduleList: null,
  moduleFrom: null,
  moduleToAdd: null,
  newnessLegoList: [],
  newnessNotLegoList: [],
  isLoadingNewness: false,
  percData: null,
  totAssortmentData: 0,
  productsList: null,
  isSearchLoading: false,
  productModuleList: null,
  productModuleNewnessList: null,
  moduleSavedOrPublished: null,
  isSavingModule: false,
  isPublishingModule: false,
  errorSave: null,
  errorPublish: null,
  isModuleSaved: false,
  isModulePublished: false,
  modulesChangedList: [],
  isGetPercDataLoading: false,
  homeFilters: null,
  perfoSelectedFilters: {
    countryName: "",
    monthsRolling: "",
    starsCustomer: "",
    customerCode: "",
    distrChannelActual: "",
    legoClassDes: "",
  },
  currentlyVisibleNewnessTab: 0, //0:prevPrevLego, 1:prevPrevNotLego, 2:prevLego, 3:prevNotLego,
  isMacroListEmpty: false,

  //highlight in module comp z
  highlightWASku: null,
  highlightActiveSku: null,
  scrollHighlightActiveSku: false,
  scrollHighlightWASku: false,

  //unlock popup info
  isPopupUnlockOpen: false,
  itemToUnlock: null,
  typeToUnlock: null,
  isLoadingExportModule: false,
  exportingModules: [],
};

/** WORKING AREA */
const getWorkingModuleRequest = (state) => {
  return updateObject(state, {
    loading: true,
    isMacroListEmpty: false,
  });
};

const getWorkingModuleSuccess = (state, action) => {
  return updateObject(state, {
    macroList: action.macroList,
    loading: false,
    isNewMacro: false,
    isNewModule: false,
    isMacroListEmpty: action.macroList.length === 0,
  });
};

const getWorkingModuleFailure = (state) => {
  return updateObject(state, {
    loading: false,
    isMacroListEmpty: false,
  });
};

const dragSku = (state, action) => {
  return updateObject(state, {
    draggedSku: action.sku,
    moduleFrom: action.moduleFrom,
  });
};

const saveModuleRequest = (state) => {
  return updateObject(state, {
    isSavingModule: true,
    isModuleSaved: false,
    errorSave: null,
    moduleSavedOrPublished: null,
  });
};

const saveModuleSuccess = (state, action) => {
  const updatetModules = {
    loading: false,
    isModuleSaved: true,
    moduleSavedOrPublished: action.moduleNameSaved,
    modulesChangedList: action.modulesChangedList,
    isSavingModule: false,
  };

  if (action.macroList) {
    updatetModules.macroList = action.macroList;
  }

  return updateObject(state, updatetModules);
};

const deleteModuleRequest = (state) => {
  return state;
};

const deleteModuleSuccess = (state, action) => {
  return state;
};

const deleteSelectedModulesRequest = (state) => {
  return state;
};

const deleteSelectedModulesSuccess = (state, action) => {
  return state;
};

const saveModuleFailure = (state, action) => {
  return updateObject(state, {
    isSavingModule: false,
    errorSave: action.error,
    moduleSavedOrPublished: action.moduleNameSaved,
  });
};

const publishModuleRequest = (state) => {
  return updateObject(state, {
    isPublishingModule: true,
    isModulePublished: false,
    errorPublish: null,
    moduleSavedOrPublished: null,
  });
};

const publishModuleSuccess = (state, action) => {
  return updateObject(state, {
    macroList: action.macroList,
    loading: false,
    isModulePublished: true,
    isPublishingModule: false,
    moduleSavedOrPublished: action.moduleNameSaved,
    modulesChangedList: action.modulesChangedList,
  });
};

const publishModuleFailure = (state, action) => {
  return updateObject(state, {
    isPublishingModule: false,
    errorPublish: action.error,
    moduleSavedOrPublished: action.moduleNameSaved,
  });
};

const resetSaveAndPublish = (state) => {
  return updateObject(state, {
    isModuleSaved: false,
    moduleSavedOrPublished: null,
    isModulePublished: false,
  });
};

/*** NEWNESS ***/
const getNewnessLegoRequest = (state) => {
  return updateObject(state, {
    isLoadingNewness: true,
  });
};

const getNewnessLegoSuccess = (state, action) => {
  return updateObject(state, {
    newnessLegoList: action.newnessLegoList,
    loading: false,
    isLoadingNewness: false,
  });
};

const getNewnessLegoFailure = (state) => {
  return updateObject(state, {
    isLoadingNewness: false,
  });
};

const getNewnessNotLegoRequest = (state) => {
  return updateObject(state, {
    isLoadingNewness: true,
  });
};

const getNewnessNotLegoSuccess = (state, action) => {
  return updateObject(state, {
    newnessNotLegoList: action.newnessNotLegoList,
    loading: false,
    isLoadingNewness: false,
  });
};

const getNewnessNotLegoFailure = (state) => {
  return updateObject(state, {
    isLoadingNewness: false,
  });
};

/* PERC OF REFRESH */
const getPercDataRequest = (state) => {
  return updateObject(state, {
    isGetPercDataLoading: true,
  });
};

const setPercData = (state, action) => {
  return updateObject(state, {
    percData: action.percData,
    totAssortmentData: action.totAssortmentData,
    isListChanged: false,
    isGetPercDataLoading: false,
  });
};

const getPercDataFailure = (state) => {
  return updateObject(state, {
    isGetPercDataLoading: false,
  });
};
/* ACTIVE */
const getActiveFiltersSuccess = (state, action) => {
  return updateObject(state, {
    activeFilters: action.activeFilters.anagraFilters,
    ibicFilters: action.activeFilters.ibicFilters,
  });
};

const getActiveSkuStart = (state) => {
  return updateObject(state, { isSearchLoading: true });
};

const getActiveSkuSuccess = (state, action) => {
  //console.log("moduleReducer getActiveSkuSuccess", state, action);
  return updateObject(state, {
    activeSku: action.activeSku,
    isSearchLoading: false,
  });
};

const getActiveSkuFailure = (state) => {
  return updateObject(state, { isSearchLoading: false });
};

const getModulesByProductsRequest = (state) => {
  return updateObject(state, { productModuleList: null });
};

const setProductModuleList = (state, action) => {
  return updateObject(state, { productModuleList: action.productModuleList });
};

const setProductModuleNewnessList = (state, action) => {
  return updateObject(state, {
    productModuleNewnessList: action.productModuleList,
  });
};

const resetProductModuleList = (state) => {
  return updateObject(state, {
    productModuleList: null,
    productModuleNewnessList: null,
  });
};

const getModuleFiltersSuccess = (state, action) => {
  return updateObject(state, {
    homeFilters: action.homeFilters,
  });
};

const cleanPerfoFilters = (state) => {
  return updateObject(state, {
    perfoSelectedFilters: {
      countryName: "",
      monthsRolling: "",
      starsCustomer: "",
      customerCode: "",
      distrChannelActual: "",
    },
    ibicFilters: null,
  });
};

const setPerfoFilters = (state, action) => {
  return updateObject(state, {
    perfoSelectedFilters: {
      ...state.perfoSelectedFilters,
      [action.typeFilter]: action.value,
    },
  });
};

const setCurrentlyVisibleNewnessTab = (state, action) => {
  return updateObject(state, {
    currentlyVisibleNewnessTab: action.value,
  });
};

const setDefaultCurrentTab = (state) => {
  return updateObject(state, {
    currentlyVisibleNewnessTab: 0,
  });
};

/** NEW MODULE */
const setNewModule = (state, action) => {
  return updateObject(state, {
    macroList: action.macroList,
    isNewModule: true,
    isGetByMacro: false,
  });
};

const updateMacroList = (state, action) => {
  console.log(state,action);
  return updateObject(state, {
    macroList: action.macroList,
    isListChanged: true,
    modulesChangedList: action.modulesChangedList,
  });
};

const createMacroFamily = (state, action) => {
  return updateObject(state, { macroList: action.macroList, isNewMacro: true });
};

const clearModuleState = (state) => {
  return updateObject(state, initialState);
};

const setHighlightWA = (state, action) => {
  return updateObject(state, {
    highlightWASku: action.highlightWASku,
    scrollHighlightWASku: true,
  });
};

const setHighlightActive = (state, action) => {
  return updateObject(state, {
    highlightActiveSku: action.highlightActiveSku,
    scrollHighlightActiveSku: true,
  });
};

const cleanHighlightWA = (state) => {
  return updateObject(state, {
    highlightWASku: null,
    scrollHighlightWASku: false,
  });
};

const cleanHighlightActive = (state) => {
  return updateObject(state, {
    highlightActiveSku: null,
    scrollHighlightActiveSku: false,
  });
};

const cleanScrollHighlightActive = (state) => {
  return updateObject(state, { scrollHighlightActiveSku: false });
};

const cleanScrollHighlightWA = (state) => {
  return updateObject(state, { scrollHighlightWASku: false });
};

const setIsGetByMacro = (state) => {
  return updateObject(state, { isGetByMacro: true, isNewModule: false });
};

const setIsGetByModule = (state) => {
  return updateObject(state, { isGetByMacro: false, isNewMacro: false });
};

const toggleUnlockModulePopup = (state, action) => {
  return updateObject(state, {
    isPopupUnlockOpen: action.value,
    itemToUnlock: action.item,
    typeToUnlock: action.typeItem,
  });
};

export const clearHighlighted = (state) => {
  return updateObject(state, {
    highlightWASku: null,
    highlightActiveSku: null,
  });
};

export const exportModuleRequest = (state, action) => {
  return updateObject(state, {
    isLoadingExportModule: true,
    exportingModules: action.modules,
  });
};

export const exportModuleSuccess = (state) => {
  return updateObject(state, {
    isLoadingExportModule: false,
    exportingModules: [],
  });
};

export const exportModuleFailure = (state) => {
  return updateObject(state, {
    isLoadingExportModule: false,
    exportingModules: [],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WORKING_MODULE_REQUEST:
      return getWorkingModuleRequest(state);
    case actionTypes.GET_WORKING_MODULE_SUCCESS:
      return getWorkingModuleSuccess(state, action);
    case actionTypes.GET_WORKING_MODULE_FAILURE:
      return getWorkingModuleFailure(state);
    case actionTypes.GET_NEWNESS_LEGO_REQUEST:
      return getNewnessLegoRequest(state);
    case actionTypes.GET_NEWNESS_LEGO_SUCCESS:
      return getNewnessLegoSuccess(state, action);
    case actionTypes.GET_NEWNESS_LEGO_FAILULE:
      return getNewnessLegoFailure(state);
    case actionTypes.GET_NEWNESS_NOT_LEGO_REQUEST:
      return getNewnessNotLegoRequest(state);
    case actionTypes.GET_NEWNESS_NOT_LEGO_SUCCESS:
      return getNewnessNotLegoSuccess(state, action);
    case actionTypes.GET_NEWNESS_NOT_LEGO_FAILULE:
      return getNewnessNotLegoFailure(state);
    case actionTypes.GET_ACTIVE_SUCCESS:
      return getActiveFiltersSuccess(state, action);
    case actionTypes.GET_ACTIVE_SKU_START:
      return getActiveSkuStart(state);
    case actionTypes.GET_ACTIVE_SKU_SUCCESS:
      return getActiveSkuSuccess(state, action);
    case actionTypes.GET_ACTIVE_SKU_FAILURE:
      return getActiveSkuFailure(state);
    case actionTypes.DRAG_SKU:
      return dragSku(state, action);
    case actionTypes.CREATE_NEW_MODULE:
      return setNewModule(state, action);
    case actionTypes.GET_PERC_DATA_REQUEST:
      return getPercDataRequest(state);
    case actionTypes.GET_PERC_DATA_SUCCESS:
      return setPercData(state, action);
    case actionTypes.GET_PERC_DATA_FAILURE:
      return getPercDataFailure(state);
    case actionTypes.UPDATE_MACROLIST:
      return updateMacroList(state, action);
    case actionTypes.GET_MODULES_BY_PRODUCTS_REQUEST:
      return getModulesByProductsRequest(state);
    case actionTypes.GET_MODULES_BY_PRODUCTS_SUCCESS:
      return setProductModuleList(state, action);
    case actionTypes.GET_MODULES_BY_PRODUCTS_NEWNESS_SUCCESS:
      return setProductModuleNewnessList(state, action);
    case actionTypes.SAVE_MODULE_REQUEST:
      return saveModuleRequest(state);
    case actionTypes.SAVE_MODULE_SUCCESS:
      return saveModuleSuccess(state, action);
    case actionTypes.SAVE_MODULE_FAILURE:
      return saveModuleFailure(state, action);

    case actionTypes.DELETE_MODULE_REQUEST:
      return deleteModuleRequest(state, action);
    case actionTypes.DELETE_MODULE_SUCCESS:
      return deleteModuleSuccess(state, action);
    // case actionTypes.DLETE_MODULE_FAILURE:
    // 	return deleteModuleFailure(state, action);

    case actionTypes.DELETE_SELECTED_MODULES_REQUEST:
      return deleteSelectedModulesRequest(state, action);
    case actionTypes.DELETE_SELECTED_MODULES_SUCCESS:
      return deleteSelectedModulesSuccess(state, action);

    case actionTypes.PUBLISH_MODULE_REQUEST:
      return publishModuleRequest(state);
    case actionTypes.PUBLISH_MODULE_SUCCESS:
      return publishModuleSuccess(state, action);
    case actionTypes.PUBLISH_MODULE_FAILURE:
      return publishModuleFailure(state, action);
    case actionTypes.RESET_SAVE_AND_PUBLISH:
      return resetSaveAndPublish(state);
    case actionTypes.RESET_GET_MODULES_BY_PRODUCT:
      return resetProductModuleList(state);
    case actionTypes.CREATE_MACROFAMILY:
      return createMacroFamily(state, action);
    case actionTypes.CLEAR_MODULE_STATE:
      return clearModuleState(state);
    case actionTypes.GET_MODULE_FILTER_SUCCESS:
      return getModuleFiltersSuccess(state, action);
    case actionTypes.CLEAR_PERFO_FILTERS:
      return cleanPerfoFilters(state);
    case actionTypes.SET_PERFO_FILTERS:
      return setPerfoFilters(state, action);
    case actionTypes.SET_CURRENTLY_VISIBLE_NEWNESS_TAB:
      return setCurrentlyVisibleNewnessTab(state, action);
    case actionTypes.SET_DEFAULT_CURRENT_NEWNESS_TAB:
      return setDefaultCurrentTab(state);
    case actionTypes.HIGHLIGHT_SKU_WA:
      return setHighlightWA(state, action);
    case actionTypes.HIGHLIGHT_SKU_ACTIVE:
      return setHighlightActive(state, action);
    case actionTypes.CLEAN_HIGHLIGHT_WA:
      return cleanHighlightWA(state);
    case actionTypes.CLEAN_HIGHLIGHT_ACTIVE:
      return cleanHighlightActive(state);
    case actionTypes.CLEAN_SCROLL_HIGHLIGHT_ACTIVE:
      return cleanScrollHighlightActive(state);
    case actionTypes.CLEAN_SCROLL_HIGHLIGHT_WA:
      return cleanScrollHighlightWA(state);
    case actionTypes.CLEAR_HIGHLIGHTED:
      return clearHighlighted(state);
    case actionTypes.IS_GET_BY_MACRO:
      return setIsGetByMacro(state);
    case actionTypes.IS_GET_BY_MODULE:
      return setIsGetByModule(state);
    case actionTypes.TOGGLE_POPUP_UNLOCK_MODULE:
      return toggleUnlockModulePopup(state, action);
    case actionTypes.IS_LOADING_EXPORT_MODULE_REQUEST:
      return exportModuleRequest(state, action);
    case actionTypes.IS_LOADING_EXPORT_MODULE_SUCCESS:
      return exportModuleSuccess(state);
    case actionTypes.IS_LOADING_EXPORT_MODULE_FAILURE:
      return exportModuleFailure(state);
    default:
      return state;
  }
};

export default reducer;
