import {
  useState,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import filesize from "filesize";
import clsx from "clsx";

import Button from "../UI-components/Button";
import "./UploadFile.scss";

const UploadFile = ({ onSubmit, label, disabled }, ref) => {
  const [file, setFile] = useState();
  const randomId = useMemo(() => {
    return Math.trunc(Math.random() * 1000);
  }, []);
  const fileInputRef = useRef();

  useImperativeHandle(ref, () => ({
    clear: () => {
      fileInputRef.current.value = "";
      setFile();
    },
  }));

  return (
    <div className="upload-file">
      <div className="upload-file-input">
        <label htmlFor={`input-file-${randomId}`}>
          <input
            id={`input-file-${randomId}`}
            ref={fileInputRef}
            type="file"
            onChange={(e) => {
              e.target.files && setFile(e.target.files[0]);
            }}
            style={{ display: "none" }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            disabled={disabled}
          />
          <span
            className={clsx("button upload-btn", { disabled: disabled })}
            disabled={disabled}
          >
            {label}
          </span>
        </label>
      </div>
      {file && (
        <div className="upload-file-input">
          <div className="upload-file-info">
            <Button
              btnClass="upload-clear-btn"
              handleClick={() => {
                fileInputRef.current.value = "";
                setFile();
              }}
            >
              X
            </Button>
            <div>
              <span>{file.name}</span>
              <br />
              <span>{filesize(file.size)}</span>
            </div>
          </div>
          <Button
            btnClass="upload-submit-btn"
            disabled={disabled}
            handleClick={() => onSubmit(file)}
          >
            Upload
          </Button>
        </div>
      )}
    </div>
  );
};

export default forwardRef(UploadFile);
