import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import ModuleJar from "./ModuleJar/ModuleJar";
import { useState } from "react";
import Tooltip from "../UI-components/Tooltip";
import CustomIcon from "../UI-components/CustomIcon";

const ModuleAll = ({
    dropModule,
    dragStop,
    dragStart,
    modules,
    removedModules,
    removeFromAll,
    insertInAllFromRemoved,
    isDisabled
}) => {
    
    const [isBinOpen, setIsBinOpen] = useState(false)

    const toggleBin = () => {
        setIsBinOpen(open => !open)
    }

    const onDragOver = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
    };

    const onDrop = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        let areaDrop = document.querySelector(`.modulePrinc`);
        areaDrop.classList.remove("dragStart", "dragOver");
        dropModule();
        dragStop();
    };

    const onDragStart = (moduleI) => {
        dragStart(moduleI);
    };

    return (
        <div style={{
            height: "30%",
            display: "flex",
            flexDirection: "column"
        }}>
            <p style={{
                margin: "24px 0"
            }}>Add to all clusters</p>

            {
                isBinOpen ? (

                    <div
                        id="removedMod"
                        className="component half-area module-list"
                    >
                        <div className="header-half-area">
                            <h2 className="title">Removed Modules</h2>
                            <Tooltip
                                onClick={toggleBin}
                                tooltipTextClass="white"
                                tooltipClass="tooltip-cluster-comp left"
                                text="View Cluster Composition"
                            >
                                <CustomIcon iconClass="icon-cestino" />
                            </Tooltip>
                        </div>
                        <div className="container-module-jar">
                            {removedModules.length > 0 ? (
                                removedModules.map((moduleI) => (
                                    <DragDropContainer
                                        noDragging={isDisabled || isBinOpen}
                                        key={moduleI.moduleName}
                                        targetKey="drag-drop"
                                        dragData={moduleI}
                                        onDragStart={() => {}}
                                    >
                                        <ModuleJar
                                            moduleItem={moduleI}
                                            draggable={!isDisabled}
                                            goToSingleModule={() => {}}
                                            isRemoved
                                            addToClusterComp={() => insertInAllFromRemoved(moduleI)}
                                        />
                                    </DragDropContainer>
                                ))
                            ) : (
                                <p className="msg">There aren't removed modules</p>
                            )}
                        </div>
                    </div>

                ) : (
                    <DropTarget
                        targetKey="drag-drop"
                        onDragEnter={() => { }}
                        onDragOver={onDragOver}
                        onDragLeave={() => { }}
                        onHit={onDrop}
                    >
                        <div style={{
                            height: "100%"
                        }}>
                            <div
                                style={{
                                    height: "100%"
                                }}
                                className="component half-area module-list open"
                            >
                                <div className="header-half-area" style={{
                                    justifyContent: "space-between"
                                }}>
                                    <h2 className="title">Module List</h2>
                                    <Tooltip
                                        onClick={toggleBin}
                                        tooltipTextClass="white"
                                        tooltipClass="tooltip-cluster-comp left"
                                        text="View Removed Modules"
                                    >
                                        <CustomIcon iconClass="icon-cestino" />
                                    </Tooltip>
                                </div>
                                <div className="container-module-jar">
                                    {Object.keys(modules).length > 0 ? (
                                        Object.keys(modules).map((key) => (
                                            <div key={key} className="family-container">
                                                <h2>{key}</h2>
                                                {modules[key].map((moduleI) => {
                                                    return (
                                                        <DragDropContainer
                                                            noDragging={isDisabled}
                                                            key={moduleI.moduleName}
                                                            targetKey="drag-drop"
                                                            dragData={moduleI}
                                                            onDragStart={() => onDragStart(moduleI)}
                                                        >
                                                            <ModuleJar
                                                                moduleItem={moduleI}
                                                                draggable={!isDisabled}
                                                                goToSingleModule={() => { }}
                                                                // toggleType={() => this.props.toggleType(moduleI)}
                                                                remove={() => removeFromAll(moduleI)}
                                                            />
                                                        </DragDropContainer>
                                                    );
                                                })}
                                            </div>
                                        ))
                                    ) : (
                                        <p className="empty-cluster">
                                            Drop modules to add them to all opened clusters
                                        </p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </DropTarget>
                )
            }
        </div>
    )
}

export default ModuleAll