import './OrderPanel.scss'

import React, {useState} from 'react';

import RadioGrp from "../../../components/UI-components/RadioGroup";

const OrderPanel = ({ orderSKUs}) => {
    const [selected,setSelected] = useState(0);

    const orderList=[
        {
            data:'sellOut',
            text:'S.O.'
        },
        {
            data:'pty',
            text:'PTY'
        },
        {
            data:'skuId',
            text:'Model'
        }
    ];
    return (
        <div className='filterPanel__section'>
            <div className='filterPanel__firstDiv'>
                Order by
            </div>
            <div className='orderPanel__buttonSection'>
            <RadioGrp
                    containerClass="filterPanel_radioGroup"
                    options={orderList.map(p=>p.text)}
                    active={false}
                    selectedIndex={selected}
                    handleClick={(e)=>{
                        const finded=orderList.findIndex(x=>x.text === e);
                        let valueToSet=null;
                        let valueToPropagate=null;
                        if(finded>=0){
                            valueToSet=finded;
                            valueToPropagate=orderList.find(x=>x.text === e).data;
                        }
                        setSelected(valueToSet);
                        orderSKUs(valueToPropagate);
                    }}
                />
            {/*orderList.map((oi) => <Button 
                                    key={'sku_filter_sort_' +oi.data } 
                                    handleClick={()=>handleSelectedOrder(oi.data)} 
                                    btnClass={'orderPanel__btn' + (selected===oi.data?' active':'') }>
                                        {oi.text} 
                </Button>)*/}

            </div>
        </div>
    )
}
export default OrderPanel