import React, { Component } from "react";

import Select from "react-select";

class CustomSelect extends Component {
   constructor(props) {
      super(props);

      this.state = {
         menuIsOpen: false,
      };

      this.select = React.createRef();
   }

   componentDidMount = () => {
      window.addEventListener("click", this.closeMenu.bind(this));
   };

   componentWillUnmount = () => {
      window.removeEventListener("click", this.closeMenu.bind(this));
   };
   onFocus = () => {
      if (this.props.isMulti) {
         this.setState({
            menuIsOpen: true,
         });
      }
   };

   handleFocus = () => {
      //OnFocusevent
   };
   handleBlur = () => {
      //OnBlurEvent
   }

   closeMenu = (event) => {
      if (this.props.isMulti) {
         if (this.select.current && !this.select.current.contains(event.target))
            this.setState({
               menuIsOpen: false,
            });
      }
   };


   unFocusIfEmpty = (e) => {
      if (!e.length && this.props.value === 1) {
      }
   };

   handleOnClick = (e) => {
      if (
         e.target.className.constructor === String
            ? e.target.className.includes("react-select__placeholder") ||
            e.target.className.includes(" react-select__value-container")
            : e.target.className.animVal === "css-19bqh2r" ||
            e.target.className.animVal === ""
      ) {
         this.setState({ menuIsOpen: !this.state.menuIsOpen });
      }
   };

   render() {
      const checkMulti =
         this.props.isMulti && this.props.value && this.props.value.length > 1;
      return (
         <div className="react-select-cont-cont" ref={this.select}>
            {this.props.isMulti ? (
               <div
                  className={
                     this.props.containerSelectClass
                        ? this.props.containerSelectClass
                        : ""
                  }
                  onClick={this.handleOnClick}
               >
                  <Select
                     onMenuScrollToBottom={this.props.onScrollBottom}
                     options={this.props.options}
                     isMulti={this.props.isMulti}
                     classNamePrefix="react-select"
                     className={
                        "react-select " +
                        (this.props.selectClass ? this.props.selectClass : "") +
                        (checkMulti && !this.props.showMultiValue
                           ? " multiLabel"
                           : "")
                     }
                     //maxMenuHeight={this.props.maxHeightCustomSelectMenu ? this.props.maxHeightCustomSelectMenu : ""}
                     placeholder={this.props.placeholder}
                     isDisabled={this.props.disabled}
                     hideSelectedOptions={false}
                     isLoading={this.props.isLoading}
                     menuIsOpen={this.state.menuIsOpen}
                     // autoFocus
                     isSearchable={
                        this.props.noInput &&
                           typeof this.props.noInput === "boolean"
                           ? this.props.noInput
                           : true
                     }
                     value={this.props.value}
                     //onFocus={this.handleFocus}
                     //onBlur={this.handleBlur}
                     onChange={(e) => {
                        this.unFocusIfEmpty(e);
                        this.props.handleChange(e);
                     }}
                     //custom search logic
                     onInputChange={this.props.onSearch}
                     clearValue={() => {console.log("boh")}}
                  />
               </div>
            ) : (
               <Select
                  options={this.props.options}
                  onMenuScrollToBottom={this.props.onScrollBottom}
                  isMulti={this.props.isMulti}
                  classNamePrefix="react-select"
                  isSearchable={
                     this.props.noInput &&
                        typeof this.props.noInput === "boolean"
                        ? this.props.noInput
                        : true
                  }
                  className={
                     "react-select " +
                     (this.props.selectClass ? this.props.selectClass : "") +
                     (checkMulti && !this.props.showMultiValue
                        ? " multiLabel"
                        : "") +
                     (this.props.className ? this.props.className : "")
                  }
                  //maxMenuHeight={this.props.maxHeightCustomSelectMenu ? this.props.maxHeightCustomSelectMenu : ""}
                  placeholder={this.props.placeholder}
                  isDisabled={this.props.disabled}
                  hideSelectedOptions={false}
                  isLoading={this.props.isLoading}
                  // autoFocus
                  value={this.props.value}
                  onChange={this.props.handleChange}
                  //custom search logic
                  onInputChange={this.props.onSearch}
                  isClearable={this.props.isDiscontinuation}
               />
            )}
         </div>
      );
   }
}
export default CustomSelect;
