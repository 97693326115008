import React, { Component } from "react";
import Button from "../UI-components/Button";
import LeftBar from "../UI-components/LeftBar";
import Logo from "../UI-components/Logo";
import Loader from "../UI-components/Loader";
import ListMacro from "./Lists/ListMacro";
import ListModule from "./Lists/ListModule";
import ListCluster from "./Lists/ListCluster";
import Popup from "../UI-components/Popup";
import { printArray } from "../../services/Api";
import * as actions from "../../store/actions";
import { connect } from "react-redux";

class SearchResults extends Component {
   state = {
      isLoading: false,
   };

   componentDidMount() {
      this.props.clearSearch();

      if (this.isFiltersSelected()) {
         if (
            !this.props.isLockClusterLoading &&
            !this.props.isLockMacroLoading &&
            !this.props.isLockModuleLoading
         ) {
            this.props.sendHomeFilters(this.props.selectedFilters);
            // this.props.setReleaseStatus(this.props.selectedFilters.release.status);
         }
      }
      if (this.isFiltersSelected()) {
         this.setState({
            isLoading: true,
         });
      }
   }

   componentDidUpdate(prevProps) {
      if (prevProps.results !== this.props.results && this.props.results) {
         this.setState({
            isLoading: false,
         });
      }

      if (this.isFiltersSelected()) {
         if (
            (prevProps.isLockModuleLoading !== this.props.isLockModuleLoading &&
               !this.props.isLockModuleLoading) ||
            (prevProps.isLockClusterLoading !==
               this.props.isLockClusterLoading &&
               !this.props.isLockClusterLoading) ||
            (prevProps.isLockMacroLoading !== this.props.isLockMacroLoading &&
               !this.props.isLockMacroLoading)
         ) {
            this.props.sendHomeFilters(this.props.selectedFilters);
            // this.props.setReleaseStatus(this.props.selectedFilters.release.status);
         }
      }

      let areCallsEnded =
         this.props.isModComplete &&
         this.props.isMacroComplete &&
         this.props.isClusterComplete;

      if (
         prevProps.results !== this.props.results &&
         areCallsEnded &&
         this.props.results !== null &&
         !this.props.results.errorStatus
      ) {
         if (
            this.props.results.clusterList.length ||
            this.props.results.macroFamilyList.length ||
            this.props.results.moduleList.length
         )
            this.props.toggleHomeFilters(false);
      }
   }

   isFiltersSelected = () => {
      let { countryList, brand, planningChannel, commodities, months } =
         this.props.selectedFilters;


      return (
         months !== null && (
            brand.length > 0 ||
            planningChannel.length > 0 ||
            commodities.length > 0 ||
            countryList.length > 0
         )
      )


   };

   unlockElement = () => {
      this.props.unlockElement(
         this.props.itemToUnlock,
         this.props.typeToUnlock
      );
      this.closePopup();
   };

   closePopup = () => {
      this.props.togglePopupUnlock(false);
   };

   render() {
      let { selectedFilters, isPopupUnlockOpen } = this.props;
      let isEditable = !this.props.isReleaseClosed;

      let countryPrint = "";
      if (selectedFilters.countryList.length > 0) {
         countryPrint = " | " + printArray(selectedFilters.countryList);
      }

      let checkEndCalls =
         this.props.isModComplete &&
         this.props.isMacroComplete &&
         this.props.isClusterComplete;
      let checkResults = checkEndCalls
         ? this.props.results &&
         this.props.results.clusterList &&
         this.props.results.clusterList.length < 1 &&
         this.props.results.macroFamilyList &&
         this.props.results.macroFamilyList.length < 1 &&
         this.props.results.moduleList &&
         this.props.results.moduleList.length < 1
         : false;
      return (
         <React.Fragment>
            {!this.state.isLoading ? (
               <React.Fragment>
                  {(this.props.results && checkEndCalls && !checkResults) ||
                     (this.props.results &&
                        this.props.results.errorStatus === 413) ? (
                     <div
                        className={
                           " search-results" +
                           (this.props.isFiltersOpen
                              ? " offset-3 col-9"
                              : " col-12") +
                           (this.props.isSearchChanged ? " opacitize" : "")
                        }
                     >
                        <header className="filter-info">
                           <p>
                              You searched:
                              <span
                                 className="bold"
                                 style={{ textTransform: "uppercase" }}
                              >
                                 {` ${selectedFilters.months && selectedFilters.months.label}`}
                                 {!!selectedFilters.planningChannel.length &&
                                    " | " +
                                    selectedFilters.planningChannel
                                       .map((col) => col.label)
                                       .join(" ")}
                                 {!!selectedFilters.brand.length &&
                                    " | " +
                                    selectedFilters.brand
                                       .map((col) => col.label)
                                       .join(" ")}
                                 {!!selectedFilters.commodities.length &&
                                    " | " +
                                    selectedFilters.commodities
                                       .map((col) => col.label)
                                       .join(" ")}
                                 {countryPrint + " "}
                              </span>
                           </p>
                           <Button
                              btnClass="btn-outline btn-small"
                              handleClick={() =>
                                 this.props.toggleHomeFilters(true)
                              }
                           >
                              Edit
                           </Button>
                        </header>
                        <div
                           className={
                              "no-gutters" +
                              (this.props.isFiltersOpen
                                 ? " "
                                 : " leftbar-container")
                           }
                        >
                           {this.props.isFiltersOpen ? null : (
                              <LeftBar
                                 label="Open Filter"
                                 handleClick={() =>
                                    this.props.toggleHomeFilters(true)
                                 }
                                 leftbarClass="close leftbar-box component home"
                              />
                           )}
                           <div
                              className={
                                 "row no-gutters" +
                                 (this.props.isFiltersOpen
                                    ? ""
                                    : " leftbar-sibling")
                              }
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 height: "96vh",
                              }}
                           >
                              {this.props.results.errorStatus === 413 ? (
                                 <div className="welcome-page flex flex-column text-center">
                                    <h1 className="mb-3">
                                       The search returned too many elements
                                    </h1>
                                    <h2>Change the filters and search again</h2>
                                    <div>
                                       <Logo />
                                    </div>
                                 </div>
                              ) : (
                                 <>
                                    <ListMacro
                                       listItem={
                                          this.props.results.macroFamilyList
                                             ? this.props.results
                                                .macroFamilyList
                                             : []
                                       }
                                       isEditable={isEditable}
                                       history={this.props.history}
                                    />
                                    <ListModule
                                       listItem={this.props.results.moduleList}
                                       isEditable={isEditable}
                                       history={this.props.history} 
                                    />
                                    <ListCluster
                                       listItem={this.props.results.clusterList}
                                       isEditable={isEditable}
                                       history={this.props.history}
                                       clientClassificationPopupVisible={this.props.clientClassificationPopupVisible} // in order to trigger the update of classifications label in filters dropdown
                                    />
                                 </>
                              )}
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div
                        className={
                           "home-start-container" +
                           (this.props.isFiltersOpen ||
                              (this.props.isFiltersOpen && checkResults)
                              ? " offset-3 col-9"
                              : " col-12")
                        }
                     >
                        <div
                           className={
                              "no-gutters" +
                              (this.props.isFiltersOpen
                                 ? " "
                                 : " leftbar-container")
                           }
                        >
                           {this.props.isFiltersOpen ? null : (
                              <LeftBar
                                 label="Open Filter"
                                 handleClick={() =>
                                    this.props.toggleHomeFilters(true)
                                 }
                                 leftbarClass="close leftbar-box component home"
                              />
                           )}
                           <div
                              className={
                                 "welcome-page" +
                                 (this.props.isFiltersOpen
                                    ? ""
                                    : " leftbar-sibling")
                              }
                           >
                              {this.props.results ? null : (
                                 <h1>
                                    WELCOME TO YOUR ASSORTMENT PERSONALIZATION
                                    TOOL BACKOFFICE
                                 </h1>
                              )}
                              {this.props.results !== null && checkResults ? (
                                 <h1>No search results</h1>
                              ) : null}
                              <div>
                                 {!this.props.results || checkResults ? (
                                    <Logo />
                                 ) : (
                                    <Loader />
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
               </React.Fragment>
            ) : (
               <div className="full-page">
                  <Loader />
               </div>
            )}
            {isPopupUnlockOpen && (
               <Popup
                  close={this.closePopup}
                  popupClass="unlock-popup"
                  title=""
                  btnName="Yes, Unlock"
                  btnName2="No, Go Back"
                  handleClick={this.unlockElement}
                  isDoubleButton
               >
                  <p>
                     Are you sure you want to unlock this{" "}
                     {this.props.typeToUnlock}?
                  </p>
                  <p>The user will lose all unsaved work.</p>
               </Popup>
            )}
         </React.Fragment>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      results: state.filter.results,
      selectedFilters: state.filter.selectedFilters,
      isFiltersOpen: state.filter.isFiltersOpen,
      isModComplete: state.filter.isModComplete,
      isMacroComplete: state.filter.isMacroComplete,
      isClusterComplete: state.filter.isClusterComplete,
      isReleaseClosed: state.release.isReleaseClosed,
      isSearchChanged: state.filter.isSearchChanged,
      isLockModuleLoading: state.user.isLockModuleLoading,
      isLockMacroLoading: state.user.isLockMacroLoading,
      isLockClusterLoading: state.user.isLockClusterLoading,
      isPopupUnlockOpen: state.module.isPopupUnlockOpen,
      typeToUnlock: state.module.typeToUnlock,
      itemToUnlock: state.module.itemToUnlock,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      toggleHomeFilters: (value) => dispatch(actions.toggleHomeFilters(value)),
      clearSearch: () => dispatch(actions.clearSearch()),
      sendHomeFilters: (filters) => dispatch(actions.sendHomeFilters(filters)),
      setReleaseStatus: (status) => dispatch(actions.setReleaseStatus(status)),
      togglePopupUnlock: (value) => dispatch(actions.togglePopupUnlock(value)),
      unlockElement: (item, type) =>
         dispatch(actions.unlockElement(item, type)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
