import React from "react";

const button = (props) => {
   return (
      <button
         className={
            "button " + props.btnClass + (props.disabled ? " disabled" : "")
         }
         onKeyDown={props.onKeyDown}
         onKeyPress={props.onKeyPress}
         onClick={props.handleClick}
         disabled={props.disabled}
         style={props.style}
         type={props.type}
      >
         {props.iconBefore ? <span className={"icon " + props.icon} /> : null}
         {props.children}
         {props.iconAfter ? <span className={"icon " + props.icon} /> : null}
      </button>
   );
};
export default button;
