import React, { Component } from "react";
import Popup from "../../UI-components/Popup";
import Button from "../../UI-components/Button";
import CheckBoxLia from "./CheckboxLia";

class ConfermationLiaPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
    };
  }

  togglePopupLia = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  render() {
    return (
      <div className="wrapper-confirm-popup">
        <Button
          btnClass="btn-lia btn-medium"
          icon="icon-arrow2"
          iconAfter
          handleClick={this.togglePopupLia.bind(this)}
        >
          Publish
        </Button>
        {this.state.showPopup ? (
          <Popup
            title=""
            popupClass="popup-medium"
            close={this.togglePopupLia.bind(this)}
            isDoubleButton
            btnName="Confirm"
            btnName2="cancel"
            handleClick={this.handlePublishClick}
          >
            <div className="popup-lia-title bold">
              Are you sure you want to publish this form on the following Lia ?
            </div>
            <CheckBoxLia readOnly />
          </Popup>
        ) : null}
      </div>
    );
  }
}

export default ConfermationLiaPopup;
