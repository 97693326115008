/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import StatisticsList from "./StatisticsList";
import RangeContainer from "./RangeContainer";
import Logo from "../UI-components/Logo";
import Button from "../UI-components/Button";
import Loader from "../UI-components/Loader";

class StatisticsPage extends Component {
   state = {
      defaultValue: [0, 100],
      filtersValues: {
         marketingTheme: null,
         polar: null,
         gender: null,
         material: null,
         prizm: null,
      },
   };

   componentDidMount = () => {
      if (!this.props.isGAJustInizialized)
         this.props.ReactGA.pageview(
            window.location.pathname + window.location.search
         );
      //get filters
      this.props.getStatFilters(this.props.selectedFilters);
   };

   componentDidUpdate = (prevProps) => {
      if (prevProps.filters !== this.props.filters) {
         this.setState({
            filters: this.props.filters,
         });
      }

      if (
         prevProps.lockCluster !== this.props.lockCluster &&
         this.props.lockCluster
      ) {
         this.props.history.push("/cluster");
      }
   };

   componentWillUnmount = () => {
      this.props.resetSearchResults();
   };

   handleChange = (value, filter, category) => {
      this.setState((prevState) => {
         return {
            filtersValues: {
               ...prevState.filtersValues,
               [category]: {
                  ...prevState.filtersValues[category],
                  [filter]: value,
               },
            },
         };
      });
   };

   handleReset = () =>
      this.setState({
         filtersValues: {
            marketingTheme: null,
            polar: null,
            gender: null,
            material: null,
            prizm: null,
         },
      });

   handleSearch = () =>
      this.props.getStats(this.props.selectedFilters, this.state.filtersValues);

   goToClusterComp = (item) => {
      this.props.getClusterWithLock(item);
   };

   isFilterSelected = (selectedFilters) => {
      let counter = 0;
      Object.keys(selectedFilters).map((filterKey) => {
         if (selectedFilters[filterKey]) counter++;
      });

      return counter > 0 ? true : false;
   };

   render() {
      let filters = this.state.filters;
      let checkDisabled = !this.isFilterSelected(this.state.filtersValues);
      let selectedFilters = this.props.selectedFilters;

      return (
         <React.Fragment>
            {filters ? (
               <div className="statistic-page">
                  <header className="minimal-header filter-info">
                     <Logo />
                     <h2>Statistic Page</h2>
                     <p className="searched-filters">
                        Search filtered by:
                        <span className="bold">
                           {selectedFilters.months &&
                              selectedFilters.months.constructor === Object &&
                              " " + selectedFilters.months.label + " "}

                           {/* {(Object.keys(selectedFilters.planningChannel).length > 0 &&
                    selectedFilters.planningChannel.constructor === Object) ||
                  selectedFilters.planningChannel.length > 0
                    ? " | " + selectedFilters.planningChannel.label + " "
                    : null} */}

                           {selectedFilters.brand &&
                              selectedFilters.brand.constructor === Object &&
                              " | " + selectedFilters.brand.label + " "}

                           {/* {selectedFilters.collection.length > 0
                    ? " | " + selectedFilters.collection.join(" ")
                    : null}
                  {countryPrint + " "} */}
                        </span>
                     </p>
                  </header>
                  <div className="statistic-container">
                     {Object.keys(filters).map((category) => (
                        <RangeContainer
                           key={category}
                           category={category}
                           filterList={filters[category]}
                           defaultValue={this.state.defaultValue}
                           filtersValues={this.state.filtersValues}
                           handleChange={(v, filter, cat) =>
                              this.handleChange(v, filter, cat)
                           }
                        />
                     ))}
                     <div className="btn-container-statistic">
                        <Button
                           btnClass="button btn-medium btn-outline btn-reset"
                           disabled={checkDisabled}
                           handleClick={this.handleReset}
                        >
                           Reset all
                        </Button>
                        <Button
                           btnClass="button btn-medium"
                           disabled={checkDisabled}
                           handleClick={this.handleSearch}
                        >
                           Search
                        </Button>
                     </div>
                  </div>
                  {this.props.isLoaderShown ? (
                     <div className="statistic-list component">
                        <Loader />
                     </div>
                  ) : this.props.results ? (
                     this.props.results.length > 0 ? (
                        <StatisticsList
                           results={this.props.results}
                           currentUser={this.props.user.user_name}
                           handleClick={(listItem) => {
                              this.goToClusterComp(listItem);
                           }}
                        />
                     ) : (
                        <div className="component statistic-list no-results">
                           The aren't results for the selected filters.
                        </div>
                     )
                  ) : null}
               </div>
            ) : (
               <div className="full-page">
                  <Loader />
               </div>
            )}
         </React.Fragment>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      user: state.authentication.user,
      filters: state.statistics.filters,
      results: state.statistics.results,
      selectedFilters: state.filter.selectedFilters,
      isLoaderShown: state.statistics.isLoaderShown,
      lockCluster: state.user.lockCluster,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getStatFilters: (param) => dispatch(actions.getStatFilters(param)),
      getStats: (selectedFilters, statsFilter) =>
         dispatch(actions.getStats(selectedFilters, statsFilter)),
      getClusterWithLock: (cluster) =>
         dispatch(actions.getClusterWithLock(cluster)),
      resetSearchResults: () => dispatch(actions.resetSearchResults()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsPage);
