import React from "react";
import ListItem from "./ListItem";

const statisticsList = (props) => (
  <div className="component statistic-list">
    <ul className="list">
      {props.results.map((item) => {
        const isEnabled =
          item.locked === false || item.userLock === props.currentUser;
        return (
          <ListItem
            key={item.clusterId + item.monthId + item.classId}
            brand={item.brand}
            iconClass="icon-arrow2 "
            listItemClass={isEnabled ? "" : "disabled"}
            handleClick={() => props.handleClick(item)}
            locked={!isEnabled}
          >
            {item.clusterLabel}
          </ListItem>
        );
      })}
    </ul>
  </div>
);

export default statisticsList;
