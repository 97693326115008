/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { PaginatedSelect } from "../../components/UI-components/PaginatedSelect";
import Button from "../../components/UI-components/Button";
import Loader from "../../components/UI-components/Loader";
import LinkedButton from "../../components/UI-components/LinkedButton";
import PopupGenericError from "../../components/Error-Handling/WithErrorHandling";
import { Switch } from "../../components/UI-components/Switch";
import { DiscoRemoveTable } from "../../components/DiscoRemoveTable";
import * as actions from "../../store/actions";
import { getCookie } from "../../services/cookie";
import { SKU_DELETED_INFO_FILTERS, SKU_DELETED_INFO, SKU_DISCO_INFO, SKU_DISCO_INFO_FILTERS } from "../../config";


const emptySelectedFilters = {
   brandCode: [],
   commodity: [],
   skuCode: [],
   materialCode: [],
   moduleLabel: [],
   upc: [],
   planningChannel: [],
   starsZones: [],
   type: [],
   months: [],
   storeCount: [],
};

const DiscoRemoveArea = ({
   rowsToSendMassiveEdit,
   logout,
   setResetRowsToSendMassiveEdit,
}) => {
   const [isRemovedArea, setIsRemovedArea] = useState(false);
   const [isDataLoading, setIsDataLoading] = useState(false);
   const [isFiltersLoading, setIsFiltersLoading] = useState(false);
   const [inputValue, setInputValue] = useState(1);
   const [tableData, setTableData] = useState();
   const [showError, setShowError] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [showLimitNumber, setShowLimitNumber] = useState(false);
   const [showBoxShadow, setShowBoxShadow] = useState(false);
   const [showAlreadyInChoosedPage, setShowAlreadyInChoosedPage] = useState(false);
   const [actualPage, setActualPage] = useState(1);

   const [selectedFiltersDiscontinued, setSelectedFiltersDiscontinued] = useState(emptySelectedFilters);
   const [filterValues, setFilterValues] = useState(emptySelectedFilters);
   const [reload, setReload] = useState(false);


   useEffect(() => {
      setIsDataLoading(true);
      setResetRowsToSendMassiveEdit({});
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      setIsDataLoading(true);
      setIsFiltersLoading(true);
      setResetRowsToSendMassiveEdit({});
      getFilteredDataAndTableData({
         brandCode: [],
         commodity: [],
         skuCode: [],
         materialCode: [],
         moduleLabel: [],
         upc: [],
         pageIndex: 0,
      });
      getFiltersContent();
      setActualPage(1);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isRemovedArea]);

   useEffect(() => {
      if(reload){
         getFilteredDataAndTableData(
            adaptedSelectedFiltersToSendAndSendActualPage()
         );
         setReload(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reload]);

   useEffect(() => {
      // GETS DATA AT EVERY CHANGE OF PAGE, AT START AND AT CHANGE OF TYPE OF VIEW
      setIsDataLoading(true);
      getFilteredDataAndTableData(
         adaptedSelectedFiltersToSendAndSendActualPage()
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [actualPage]);

   useLayoutEffect(() => {
      const tableContainerElementDOM = document.getElementById(
         "table-disco-remove-area-container"
      );

      const renderizedTableContainerElementDOM = tableContainerElementDOM
         ? tableContainerElementDOM
         : null;

      const detect = () => {
         const isTabledScrolledEnoughToShowShadow =
            renderizedTableContainerElementDOM.scrollTop >= 50 ? true : false;

         setShowBoxShadow(isTabledScrolledEnoughToShowShadow);
      };

      renderizedTableContainerElementDOM !== null &&
         renderizedTableContainerElementDOM.addEventListener("scroll", detect);

      return () => {
         renderizedTableContainerElementDOM !== null &&
            renderizedTableContainerElementDOM.removeEventListener(
               "scroll",
               detect
            );
      };
   });

   useLayoutEffect(() => {
      const tableContainerElementDOM = document.getElementById(
         "table-disco-remove-area-container"
      );

      const renderizedTableContainerElementDOM = tableContainerElementDOM
         ? tableContainerElementDOM
         : null;

      renderizedTableContainerElementDOM !== null &&
         renderizedTableContainerElementDOM.scrollTo(0, 0);
      setShowBoxShadow(false);
   }, [actualPage]);

   const handleSearch = (e) => {
      setIsDataLoading(true);

      if (actualPage !== 1) {
         setActualPage(1);
         setResetRowsToSendMassiveEdit({});
      } else {
         getFilteredDataAndTableData(
            adaptedSelectedFiltersToSendAndSendActualPage()
         );
         setResetRowsToSendMassiveEdit({});
      }
      setInputValue(1);
      e.preventDefault();
   };

   const getFiltersContent = () => {
      const url = !isRemovedArea
         ? SKU_DISCO_INFO_FILTERS
         : SKU_DELETED_INFO_FILTERS;
      fetch(url, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            if (response.status === 201) {
               setIsFiltersLoading(false);
               setShowError(true);
               setErrorMessage(response);
               return response.json();
            }
            if (response.status === 401) {
               setIsFiltersLoading(false);
               logout();
               return response.json();
            }
            if (response.status === 403) {
               setIsFiltersLoading(false);
               setShowError(true);
               setErrorMessage(response);
               return response.json();
            }
            if (response.status === 404) {
               setIsFiltersLoading(false);
               setShowError(true);
               setErrorMessage(response);
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               setIsFiltersLoading(false);
               return setFilterValues(data);
            }
         })
         .catch((err) => {
            setIsFiltersLoading(false);
            setShowError(true);
            console.error(err);
         });
   };

   const adaptedFilterOptions = (filter) => {
      let items = [];
      if (filterValues && filter) {
         const mapped = filterValues[filter] && filterValues[filter].map((element) => ({
            value: element,
            label: element,
         }));
         if (mapped) {

            items = [...items, ...mapped];
         }
      }
      return _.sortBy(items, "value");
   };

   const isDisabled = (filter) => (filterValues[filter]) ? !filterValues[filter].length : false;

   const getFilteredDataAndTableData = (selectedFilters) => {
      setTableData([]);
      const url = !isRemovedArea
         ? SKU_DISCO_INFO
         : SKU_DELETED_INFO;
      fetch(url, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
         body: JSON.stringify(selectedFilters),
      })
         .then((response) => {
            if (response.ok) {
               setIsDataLoading(false);
               return response.json();
            }
            if (response.status === 201) {
               setIsDataLoading(false);
               setShowError(true);
               setErrorMessage(response);
               return response.json();
            }
            if (response.status === 401) {
               setIsDataLoading(false);
               logout();
               return response.json();
            }
            if (response.status === 403) {
               setIsDataLoading(false);
               setShowError(true);
               setErrorMessage(response);
               return response.json();
            }
            if (response.status === 404) {
               setIsDataLoading(false);
               setShowError(true);
               setErrorMessage(response);
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               setTableData(data);
               setIsDataLoading(false);
            }
         })
         .catch((err) => {
            setIsDataLoading(false);
            setShowError(true);
            console.error(err);
         });
   };

   const adaptedSelectedFiltersToSendAndSendActualPage = () => {
      let objToSend = {};

      const brandFilterToSend =
         selectedFiltersDiscontinued.brandCode.length &&
         selectedFiltersDiscontinued.brandCode.map((el) => {
            return el.value;
         });

      objToSend.brandCode = brandFilterToSend || [];

      const commodityFilterToSend =
         selectedFiltersDiscontinued.commodity.length &&
         selectedFiltersDiscontinued.commodity.map((el) => {
            return el.value;
         });

      objToSend.commodity = commodityFilterToSend || [];

      const gridFilterToSend =
         selectedFiltersDiscontinued.skuCode.length &&
         selectedFiltersDiscontinued.skuCode.map((el) => {
            return el.value;
         });

      objToSend.skuCode = gridFilterToSend || [];

      const materialCodeFilterToSend =
         selectedFiltersDiscontinued.materialCode.length &&
         selectedFiltersDiscontinued.materialCode.map((el) => {
            return el.value;
         });

      objToSend.materialCode = materialCodeFilterToSend || [];

      const moduleFilterToSend =
         selectedFiltersDiscontinued.moduleLabel.length &&
         selectedFiltersDiscontinued.moduleLabel.map((el) => {
            return el.value;
         });

      objToSend.moduleLabel = moduleFilterToSend || [];

      const upcFilterToSend =
         selectedFiltersDiscontinued.upc.length &&
         selectedFiltersDiscontinued.upc.map((el) => {
            return el.value;
         });

      objToSend.upc = upcFilterToSend || [];

      // NEW FILTERS tB:03-2022
      const planningChannelFilterToSend =
         selectedFiltersDiscontinued.planningChannel.length &&
         selectedFiltersDiscontinued.planningChannel.map((el) => {
            return el.value;
         });

      objToSend.planningChannel = planningChannelFilterToSend || [];

      const starsZonesFilterToSend =
         selectedFiltersDiscontinued.starsZones.length &&
         selectedFiltersDiscontinued.starsZones.map((el) => {
            return el.value;
         });

      objToSend.starsZones = starsZonesFilterToSend || [];

      const superDiscoFilterToSend =
         selectedFiltersDiscontinued.type.length &&
         selectedFiltersDiscontinued.type.map((el) => {
            return el.value;
         });

      objToSend.type = superDiscoFilterToSend || [];

      const monthsFilterToSend =
         selectedFiltersDiscontinued.months.length &&
         selectedFiltersDiscontinued.months.map((el) => {
            return el.value;
         });

      objToSend.months = monthsFilterToSend || [];

      const distributionFilterToSend =
         selectedFiltersDiscontinued.storeCount.length &&
         selectedFiltersDiscontinued.storeCount.map((el) => {
            return el.value;
         });

      objToSend.storeCount = distributionFilterToSend || [];

      objToSend.pageIndex = actualPage - 1;

      return objToSend;
   };

   const setFilterValue = (type, opts) => {
      let selectedFilters = _.cloneDeep(selectedFiltersDiscontinued);
      selectedFilters[type] = opts;
      return setSelectedFiltersDiscontinued(selectedFilters);
   };

   const totalPages =
      tableData && Math.ceil(tableData.count / tableData.maxResultsForPage);

   const adaptedArr =
      rowsToSendMassiveEdit &&
      Object.keys(rowsToSendMassiveEdit).flatMap((el) => {
         return rowsToSendMassiveEdit[el];
      });

   const enableButtonGoToMassiveEdit = adaptedArr.length > 0;

   return (
      <div className="DiscoRemoveArea__page-container row no-gutters">
         <div className="col-3 sidebar DiscoRemoveArea__sidebar">
            <div className="filters">
               <header className="DiscoRemoveArea__sidebar-header sidebar-header col-12">
                  <div className="DiscoRemoveArea__vector-container vector-container">
                     <a href="/myStarsBo/" className="vector" />
                     <div className="DiscoRemoveArea__vector--label">
                        <span>Stars</span>
                        <span>Assortment</span>
                     </div>
                  </div>
                  <div className="DiscoRemoveArea__switch--container">
                     <Switch
                        isChecked={isRemovedArea}
                        onChange={() => {
                           setIsRemovedArea(!isRemovedArea);
                           setFilterValues(emptySelectedFilters)
                           setSelectedFiltersDiscontinued(emptySelectedFilters);
                        }}
                        label={["Disco Area", "Removed Area"]}
                     />
                  </div>
               </header>

               <div className="sidebar-content">
                  <span className="DiscoRemoveArea__title">
                     {!isRemovedArea ? "Discontinuation Area" : "Removed Area"}
                  </span>
                  <div className="top-filters">
                     <form
                        onSubmit={(event) => {
                           handleSearch(event);
                        }}
                     >
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('brandCode')}
                           options={adaptedFilterOptions("brandCode")}
                           placeholder="Brand"
                           handleChange={(e) => {
                              setFilterValue("brandCode", e);
                           }}
                           value={selectedFiltersDiscontinued.brandCode}
                           isMulti
                        />
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('commodity')}
                           options={adaptedFilterOptions("commodity")}
                           placeholder="Commodity"
                           handleChange={(e) => {
                              setFilterValue("commodity", e);
                           }}
                           value={selectedFiltersDiscontinued.commodity}
                           isMulti
                        />
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('moduleLabel')}
                           placeholder="Module"
                           options={adaptedFilterOptions("moduleLabel")}
                           handleChange={(e) => {
                              setFilterValue("moduleLabel", e);
                           }}
                           value={selectedFiltersDiscontinued.moduleLabel}
                           isMulti
                        />
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('materialCode')}
                           options={adaptedFilterOptions("materialCode")}
                           placeholder="Model Code"
                           handleChange={(e) => {
                              setFilterValue("materialCode", e);
                           }}
                           isMulti
                           value={selectedFiltersDiscontinued.materialCode}
                        />
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('skuCode')}
                           options={adaptedFilterOptions("skuCode")}
                           placeholder="Grid"
                           handleChange={(e) => {
                              setFilterValue("skuCode", e);
                           }}
                           isMulti
                           value={selectedFiltersDiscontinued.skuCode}
                        />
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('upc')}
                           options={adaptedFilterOptions("upc")}
                           placeholder="UPC"
                           handleChange={(e) => {
                              setFilterValue("upc", e);
                           }}
                           isMulti
                           value={selectedFiltersDiscontinued.upc}
                        />

                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('planningChannel')}
                           options={adaptedFilterOptions("planningChannel")}
                           placeholder="Planning Channel"
                           handleChange={(e) => {
                              setFilterValue("planningChannel", e);
                           }}
                           isMulti
                           value={selectedFiltersDiscontinued.planningChannel}
                        />
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('starsZones')}
                           options={adaptedFilterOptions("starsZones")}
                           placeholder="Stars Zones"
                           handleChange={(e) => {
                              setFilterValue("starsZones", e);
                           }}
                           isMulti
                           value={selectedFiltersDiscontinued.starsZones}
                        />
                        {!isRemovedArea && (
                           <PaginatedSelect
                              isLoading={!filterValues}
                              disabled={isDisabled('type')}
                              options={adaptedFilterOptions("type")}
                              placeholder="Super Disco"
                              handleChange={(e) => {
                                 setFilterValue("type", e);
                              }}
                              isMulti
                              value={selectedFiltersDiscontinued.type}
                           />
                        )}
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('months')}
                           options={adaptedFilterOptions("months")}
                           placeholder="Months"
                           handleChange={(e) => {
                              setFilterValue("months", e);
                           }}
                           isMulti
                           value={selectedFiltersDiscontinued.months}
                        />
                        <PaginatedSelect
                           isLoading={!filterValues}
                           disabled={isDisabled('storeCount')}
                           options={adaptedFilterOptions("storeCount")}
                           placeholder="Distribution"
                           handleChange={(e) => {
                              setFilterValue("storeCount", e);
                           }}
                           isMulti
                           value={selectedFiltersDiscontinued.storeCount}
                        />

                        < div className="DiscoRemoveArea__secondary-cta-container">
                           <Button
                              btnClass="btn-medium center btn-search btn-white"
                              icon="icon-arrow1"
                              iconAfter
                              disabled={isDisabled('brandCode')}
                              type="submit"
                           >
                              Search
                           </Button>
                        </div>
                     </form>
                  </div>
                  <span
                     className="DiscoRemoveArea__separator"
                     style={
                        !isRemovedArea
                           ? { marginTop: "2vh" }
                           : { marginTop: "5vh" }
                     }
                  />
                  <div className="DiscoRemoveArea__main-cta-container">
                     <LinkedButton
                        link="/massive-edit-wi"
                        btnClass="btn-medium btn-white w100"
                        btnContainerClass="w100"
                        disabled={!enableButtonGoToMassiveEdit}
                     >
                        GO TO MASSIVE EDIT
                     </LinkedButton>
                  </div>
               </div>
            </div>
         </div >
         <PopupGenericError
            showError={showError}
            closeAlert={() => {
               setShowError(false);
            }}
            handleClick={() => {
               setShowError(false);
            }}
            error={typeof errorMessage === "string" ? errorMessage : null}
         />
         {
            (isDataLoading || isFiltersLoading) ? (
               <div className="DiscoRemoveArea__loader--container">
                  <Loader />
               </div>
            ) : null
         }
         {
            tableData &&
               tableData.skuDiscoTypeDtoList &&
               tableData.skuDiscoTypeDtoList.length > 0 ? (
               <div className="DiscoRemoveArea__table--wrapper">
                  <div
                     id="table-disco-remove-area-container"
                     className="DiscoRemoveArea__table--container"
                  >
                     <DiscoRemoveTable
                        isChangedTypeView={!isRemovedArea}
                        tableData={tableData.skuDiscoTypeDtoList}
                        showBoxShadow={showBoxShadow}
                        actualPage={actualPage}
                        loadingListToModule={setIsDataLoading}
                        loadingFiltersToModule={setIsFiltersLoading}
                        reload={setReload}
                     />
                  </div>
                  <div className="DiscoRemoveArea__pagination--container">
                     <div className="DiscoRemoveArea__pagination--container__label">
                        <span>
                           Total Skus: {tableData.count}, skus per page:{" "}
                           {tableData.skuDiscoTypeDtoList.length}
                        </span>
                     </div>
                     <div className="DiscoRemoveArea__pagination-buttons--container">
                        <form
                           disabled={
                              inputValue <= 0 ||
                              isNaN(inputValue) ||
                              typeof inputValue !== "number" ||
                              inputValue === null ||
                              inputValue === actualPage ||
                              inputValue > totalPages
                           }
                           onSubmit={(event) => {
                              setActualPage(inputValue);
                              event.preventDefault();
                           }}
                        >
                           <div className="DiscoRemoveArea__input-page--container">
                              <span className="DiscoRemoveArea__input-page--container--label">
                                 Go to page:
                              </span>
                              <input
                                 className="DiscoRemoveArea__input--style"
                                 min={1}
                                 max={totalPages}
                                 value={inputValue}
                                 onChange={(e) => {
                                    if (e.target.valueAsNumber <= 0) {
                                       e.target.valueAsNumber = 1;
                                    }

                                    const limitExceded =
                                       e.target.valueAsNumber > totalPages;

                                    const alreadyInChoosedPage =
                                       e.target.valueAsNumber === actualPage;

                                    setShowLimitNumber(limitExceded);
                                    setShowAlreadyInChoosedPage(
                                       alreadyInChoosedPage
                                    );
                                    setInputValue(e.target.valueAsNumber);
                                 }}
                                 type="number"
                                 placeholder="Page"
                              />
                              <Button
                                 btnClass="btn-medium"
                                 type="submit"
                                 disabled={
                                    inputValue <= 0 ||
                                    isNaN(inputValue) ||
                                    typeof inputValue !== "number" ||
                                    inputValue === null ||
                                    inputValue === actualPage ||
                                    inputValue > totalPages
                                 }
                              >
                                 Apply
                              </Button>
                           </div>
                        </form>

                        <div className="DiscoRemoveArea__pagination-buttons--container__btn--container">
                           <Button
                              btnClass="btn-medium center"
                              handleClick={() => {
                                 setActualPage(1);
                              }}
                              disabled={actualPage === 1}
                           >
                              1
                           </Button>
                        </div>

                        <Button
                           btnClass="btn-medium center"
                           handleClick={() => {
                              setActualPage(actualPage - 1);
                           }}
                           disabled={actualPage === 1}
                        >
                           Back
                        </Button>

                        <span className="DiscoRemoveArea__actual-page--label">
                           Page: {actualPage} / {totalPages}
                        </span>
                        <div className="DiscoRemoveArea__pagination-buttons--container__btn--container">
                           <Button
                              btnClass="btn-medium center"
                              handleClick={() => {
                                 setActualPage(actualPage + 1);
                              }}
                              disabled={actualPage === totalPages}
                           >
                              Next
                           </Button>
                        </div>
                        <Button
                           btnClass="btn-medium center"
                           handleClick={() => {
                              setActualPage(totalPages);
                           }}
                           disabled={actualPage === totalPages}
                        >
                           {totalPages}
                        </Button>
                     </div>
                  </div>
                  {showLimitNumber ? (
                     <span className="DiscoRemoveArea__limit-number--container">
                        Value must be lower than {totalPages}
                     </span>
                  ) : null}
                  {showAlreadyInChoosedPage ? (
                     <span className="DiscoRemoveArea__already-in-page-message--container">
                        You are already seeing page {actualPage}
                     </span>
                  ) : null}
               </div>
            ) : null
         }
         {
            tableData &&
               tableData.skuDiscoTypeDtoList &&
               tableData.skuDiscoTypeDtoList.length === 0 ? (
               <div className="DiscoRemoveArea__no-res-message--container">
                  <span className="vector--image" />
                  <span className="DiscoRemoveArea__no-res-message--container__label--message">
                     No results found, please try another combination of filters.
                  </span>
               </div>
            ) : null
         }
      </div >
   );
};

const mapStateToProps = (state) => {
   return {
      selectedFiltersDiscontinued: state.filter.selectedFiltersDiscontinued,
      rowsToSendMassiveEdit: state.filter.rowsToSendMassiveEdit,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      logout: () => dispatch(actions.logout()),
      setResetRowsToSendMassiveEdit: (rowsToSendMassiveEdit) =>
         dispatch(actions.setResetRowsToSendMassiveEdit(rowsToSendMassiveEdit)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscoRemoveArea);
