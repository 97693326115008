/* eslint-disable array-callback-return */
import React, { Component } from "react";
import PercentageRange from "./PercentageRange";
import Header from "../UI-components/Header";
import LeftBar from "../UI-components/LeftBar";
import WorkingArea from "./WorkingArea";
import ActiveSkus from "./ActiveSkus";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import {
   formatOptions,
   excludeModulesInPage,
   onUnload,
   selectedModuleBrandAndColl
} from "../../services/Api";
import { Redirect } from "react-router-dom";
import * as urlList from "../../config";
import { getCookie } from "../../services/cookie";
import { cloneDeep } from "lodash"

class ModuleComposition extends Component {
   state = {
      isPercOpen: true,
      isActiveOpen: true,
      isRedirect: false,
      filtersInputonFocus: false,
      isLoading: true,
      newTabHeader:null,
   };

   componentDidMount = () => {
      const query = new URLSearchParams(window.location.search);
      const moduleNameQuery = query.get("moduleName");
      const monthQuery = query.get("monthId");
      
      if (!this.props.isGAJustInizialized)
         this.props.ReactGA.pageview(
            window.location.pathname + window.location.search
         );
      if (!this.props.results && (!moduleNameQuery || !monthQuery)) {
         return this.props.history.push("/");
      }

      let unblock = this.props.history.block((location, action) => {
         if (
            this.props.moduleChangedList &&
            this.props.moduleChangedList.length
         )
            return "You are leaving the page without saving. Are you sure you want to continue?";
      });


      if(moduleNameQuery && monthQuery){
         let params={};
         params.moduleDto = [{
                  moduleName: moduleNameQuery,
                  monthId: monthQuery,
               }];
         
         if(!this.props.filters.months?.value){
            const newFilters = cloneDeep(this.props.filters)
            newFilters.months = {
               label: monthQuery,
               value: monthQuery
            }
            this.props.setSelectedFilters(newFilters)
         }
         // se apro il module dalla pagine dei cluster, nessun filtro viene settato in automatico.
         // L'unico che il BE vuole è monthsRolling. Se monthsRolling non è settato, vuole come valore [6]
         // guardare addPerfoFilters in moduleActions.js
         params.ibicFilters = {
            monthsRolling: [6]
         };
         let url = urlList.GET_WORKING_MODULE;
         fetch(url, {
               method: "post",
               headers: {
                  "Content-Type": "application/json",
                  xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
               },
               body: JSON.stringify(params),
            })
            .then((response) => {
               return response.json();
            })
            .then((data) => {
               if(!data){
                  return this.props.history.push("/");
                }
                const selectedFilters = {
                  monthId: [data[0].monthId],
                  planningChannel: [],
                  brand: [data[0].brand],
                  collection: [],
                  country: [],
               };
                this.props.getFilteredList("macroFamily", selectedFilters);
                this.props.getFilteredList("modules", selectedFilters);
                this.setState({newTabHeader:{
                  brand:data[0].brand,
                  monthId:data[0].monthId,
               },});
               const callId = Math.random();
               this.props.getModuleListSuccess(data,callId);
               let viewer = data && data.length && data[0].userLock && data[0].locked && this.props.username.toLowerCase() !== data[0].userLock.toLowerCase();
               this.props.getWorkingModuleWithLock(data,()=> { this.setState({isLoading:false}); }, viewer);

            });         
         //return this.props.history.push("/");
        } else if (
         (!this.props.isGetByMacro &&
            !this.props.lockModule &&
            !this.props.isNewModule &&
            !this.props.isNewMacro) ||
         (this.props.isGetByMacro && !this.props.lockMacro)
      ) {
         return this.props.history.push("/");
      }
      else{
         this.setState({isLoading:false});
      }
      this.setState({
         unblock,
      });

      if (process.env.NODE_ENV !== "development") {
         window.addEventListener("beforeunload", (e) =>
            onUnload(e, this.props.modulesChangedList.length > 0)
         );
      }

      window.addEventListener("click", this.clearHighlighted);
   };

   componentWillUnmount() {
      if (this.state.unblock && typeof this.state.unblock === "function")
         this.state.unblock();

      // IF MODULE SELECTED
      if (!this.props.isGetByMacro && this.props.macroList !== null) {
         let modules = [];
         this.props.macroList.map((macro) => {
            macro.moduleList.map((moduleItem) => {
               modules.push({
                  moduleName: moduleItem.moduleName,
                  monthId: moduleItem.monthId,
               });
            });
         });
         this.props.unlockModule(modules);
      }

      //IF MACRO SELECTED
      const selectedFilters = selectedModuleBrandAndColl(this.props.macroList);
      if (!selectedFilters.macroFamily) {
         selectedFilters.macroFamily =
            this.props.macroList && this.props.macroList[0].macroFamily;
      }
      // if (this.props.isGetByMacro && this.props.macroList !== null)
      this.props.unlockMacro(this.props.filters, [selectedFilters]);

      this.props.clearModuleState();

      if (process.env.NODE_ENV !== "development") {
         window.removeEventListener("beforeunload", onUnload);
      }

      window.removeEventListener("click", this.clearHighlighted);
   }

   clearHighlighted = (e) => {
      if (!e.target.closest(".glass-box")) {
         if (
            this.props.highlightWASku !== null ||
            this.props.highlightActiveSku !== null
         ) {
            this.props.clearHighlighted();
         }
      }
   };
   /*
      togglePerc = () => {
         this.setState((prevState) => {
            return {
               isPercOpen: !prevState.isPercOpen,
            };
         });
      };
   */

   togglePerc = () =>
      this.setState((prevState) => {
         return { isPercOpen: !prevState.isPercOpen };
      });

   toggleActive = () => {
      return this.setState((prevState) => {
         return { isActiveOpen: !prevState.isActiveOpen };
      });
   }


   //SEARCH BAR IN HEADER
   handleSearch = (value, type) => {
      if (type === "module") {
         this.props.addModule([value], this.props.macroList);
      }
      if (type === "macro") {
         this.props.addMacro(this.props.filters, value, this.props.macroList);
      }
   };

   goToCluster = (cluster) => {
      this.props.getCluster(cluster);
      this.setState({
         isRedirect: true,
      });
   };


   setFiltersInputOnFocus = (value) => {
      this.setState({ filtersInputonFocus: value })
   }

   render() {
      let redirect = null;
      if (this.state.isRedirect) {
         redirect = <Redirect to="/cluster" />;
      }

      //check Module Options
      let moduleList =
         this.props.results && this.props.results.moduleList
            ? formatOptions(this.props.results.moduleList, "moduleName")
            : [];
      let moduleOptions = excludeModulesInPage(
         moduleList,
         this.props.macroList
      );
      //check macrofamily options
      let familyOptions = this.props.results
         ? formatOptions(this.props.results.macroFamilyList, "macroFamily")
         : [];
      let moduleListInput = [];
      const macroList = this.props.macroList;
      macroList &&
         macroList.forEach(macro => {
            macro.moduleList &&
               macro.moduleList.forEach(module => {
                  moduleListInput.push({ moduleName: module.moduleName, monthId: module.monthId })
               })
         })
      return (
         <div className="module-comp">
            {redirect}
            {this.props.macroList ? (
               <Header
                  isNewModule={this.props.isNewModule}
                  isModuleComp
                  type="module"
                  optionsFirst={moduleOptions ? moduleOptions : []}
                  optionsSecond={familyOptions ? familyOptions : []}
                  handleSearchFirst={(e) => this.handleSearch(e, "module")}
                  handleSearchSecond={(e) => this.handleSearch(e, "macro")}
                  newTabHeader={this.state.newTabHeader}
                  selectedFilters={this.props.filters}
               />
            ) : (
               <Header isModuleComp />
            )}
            <div className="row no-gutters main-content">
               <div className={"leftbar-container center-area"}>
                  {this.state.isActiveOpen ? (
                     <LeftBar
                        label="Open Cluster Analysis"
                        leftbarClass={"leftbar-box component close"}
                        handleClick={this.toggleActive}
                     />
                  ) :
                     (<PercentageRange
                        isPercOpen={this.state.isPercOpen}
                        togglePerc={this.togglePerc}
                        getPercData={() => this.props.getPercData(
                           this.props.macroList,
                           this.props.filters
                        )
                        }
                        data={this.props.percData}
                        totAssortmentData={this.props.totAssortmentData}
                        isListChanged={this.props.isListChanged}
                        macroList={this.props.macroList}
                        goToCluster={this.goToCluster}
                        isGetPercDataLoading={this.props.isGetPercDataLoading}
                     />)
                  }
                  <WorkingArea
                     isActiveOpen={this.state.isActiveOpen}
                     dropSku={this.dropSku}
                     history={this.props.history}
                     macroList={this.props.macroList}
                     moduleListResult={moduleOptions}
                     isLoading ={this.state.isLoading}
                  />
                  {this.state.isActiveOpen ? (<div
                     className={
                        "active-skus leftbar-container " +
                        (this.state.isActiveOpen ? "open col-5" : "")
                     }
                  >
                     <ActiveSkus
                        moduleListInput={ moduleListInput}
                        isLoading ={this.state.isLoading}
                     />
                  </div>)
                     : (
                        <LeftBar
                           label="Open Active"
                           leftbarClass={"leftbar-box component"}
                           handleClick={this.toggleActive}
                        />
                     )}
               </div>

            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      filters: state.filter.selectedFilters,
      macroList: state.module.macroList,
      results: state.filter.results,
      percData: state.module.percData,
      totAssortmentData: state.module.totAssortmentData,
      isListChanged: state.module.isListChanged,
      modulesChangedList: state.module.modulesChangedList,
      isGetByMacro: state.module.isGetByMacro,
      isNewModule: state.module.isNewModule,
      isNewMacro: state.module.isNewMacro,
      lockModule: state.user.lockModule,
      lockMacro: state.user.lockMacro,
      isGetPercDataLoading: state.module.isGetPercDataLoading,
      highlightWASku: state.module.highlightWASku,
      highlightActiveSku: state.module.highlightActiveSku,
      loading: state.module.loading,
      username: state.authentication.user.user_name,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      getFilteredList: (type, params) =>
         dispatch(actions.getFilteredList(type, params)),
      addMacro: (filters, macro, currentMacrolist) =>
         dispatch(actions.addMacro(filters, macro, currentMacrolist)),
      addModule: (obj, currentMacrolist) =>
         dispatch(actions.addModule(obj, currentMacrolist)),
      getPercData: (macroList, filters) =>
         dispatch(actions.getPercData(macroList, filters)),
      getCluster: (cluster) => dispatch(actions.getCluster(cluster)),
      unlockModule: (moduleList) => dispatch(actions.unlockModule(moduleList)),
      unlockMacro: (filters, macroList) =>
         dispatch(actions.unlockMacro(filters, macroList)),
      clearModuleState: () => dispatch(actions.clearModuleState()),
      clearHighlighted: () => dispatch(actions.clearHighlighted()),
      getWorkingModuleWithLock: (selectedModules,callbackSuccess, modeViewer) =>
         dispatch(actions.getWorkingModuleWithLock(selectedModules,callbackSuccess, modeViewer)),
      getModuleListSuccess: (modules,callId) =>
         dispatch(actions.getModuleListSuccess(modules, callId)),
      getPercDataRequest:()=>
         dispatch(actions.getPercDataRequest()),
      setSelectedFilters: (selectedFilters) => dispatch(actions.setSelectedFilters(selectedFilters))
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleComposition);
