import * as _ from "lodash";
import * as actionTypes from "../actions/actionTypes";
import { updateObject, updateObject2 } from "../../services/Api";
import { initialExportFilters } from "../../services/constList";

const initialState = {
   allMacroList: [],
   error: null,
   filterHome: {
      months: [],
      planningChannel: [],
      brandList: [],
      countryList: [],
      commodities: [],
      collection: [],
      countries: [],
   },
   selectedFilters: {
      release: null,
      countryList: [],
      brand: [],
      commodities: [],
      planningChannel: [],
      moduleList: [],
      monthId: []
   },
   selectedFiltersDiscontinued: {
      brand: [],
      commodity: [],
      module: [],
      materialCode: [],
      grid: [],
      upc: [],
   },
   savablePublishableModules: [],
   countryAllList: null,
   results: null,
   isModComplete: false,
   isMacroComplete: false,
   isClusterComplete: false,
   isFiltersOpen: true,
   isSearchChanged: false,
   filtersOptionsCluster: {
      customerSegmentation: [],
      starsCustomer: [],
      rangeFacing: [],
      storeId: [],
      codiceStoreSap: [],
   },
   rowsToSendMassiveEdit: {},
   optionsByStars: null,
   isGetCodiceStoreSapEnded: false,
   exportFilters: initialExportFilters,
   exportSelectedFilters: initialExportFilters,
   isFiltersExportLoading: false,
   exportResults: [],
   isExportSearchEmpty: false,
   selectedModules: [],
};

const showLoader = (state) => {
   return updateObject(state, {
      isLoaderShown: true,
      isModComplete: false,
      isMacroComplete: false,
      isClusterComplete: false,
   });
};
const hideLoader = (state) => {
   return updateObject(state, { isLoaderShown: false });
};

/** FILTRI **/
const setSelectedFilters = (state, action) => {
   return updateObject(state, {
      selectedFilters: action.selectedFilters,
   });
};

//MIRKOO
const setSelectedFiltersDiscontinued = (state, action) => {
   return updateObject(state, {
      selectedFiltersDiscontinued: action.selectedFiltersDiscontinued,
   });
};

const setSavablePublishableModules = (state, action) => {
   return updateObject(state, {
      savablePublishableModules: action.savablePublishableModules,
   });
};

const setRowsToSendMassiveEdit = (state, action) => {
   return updateObject2(state, {
      rowsToSendMassiveEdit: action.rowsToSendMassiveEdit,
   });
};

const setResetRowsToSendMassiveEdit = (state, action) => {
   return updateObject(state, {
      rowsToSendMassiveEdit: action.rowsToSendMassiveEdit,
   });
};

const setInitialFilters = (state, action) => {
   _.cloneDeep(state.filterHome);
   let updatedFilterHome = {
      ...action.initialFiltersList,
   };

   return updateObject(state, {
      filterHome: updatedFilterHome,
   });
};

const setBrandList = (state, action) => {
   let filterHome = _.cloneDeep(state.filterHome);
   filterHome.brandList = action.brandList;
   return updateObject(state, { filterHome });
};

const setPlanningList = (state, action) => {
   let filterHome = _.cloneDeep(state.filterHome);
   filterHome.planningChannel = action.planningChannel;
   return updateObject(state, { filterHome });
};

const setCountryList = (state, action) => {
   let filterHome = _.cloneDeep(state.filterHome);
   filterHome.countryList = action.countryList;
   return updateObject(state, { filterHome });
};

const setCountryAllList = (state, action) => {
   return updateObject(state, { countryAllList: action.countryList });
};

const toggleFilters = (state, action) => {
   return updateObject(state, { isFiltersOpen: action.value });
};

/*** RESULTS MODULE / MACRO / CLUSTER */
const setModulesList = (state, action) => {
   let results = state.results !== null ? _.cloneDeep(state.results) : {};

   if (results.callId !== action.callId) {
      delete results.errorStatus;
   }

   if (!results.errorStatus) {
      results.callId = action.callId;
      results.moduleList = action.moduleList.length ? action.moduleList : [];
   }

   return updateObject(state, {
      results,
      isModComplete: true,
      isSearchChanged: false,
   });
};

const setMacroFamilyList = (state, action) => {
   let results = state.results !== null ? _.cloneDeep(state.results) : {};

   if (results.callId !== action.callId) {
      delete results.errorStatus;
   }

   if (!results.errorStatus) {
      results.callId = action.callId;
      results.macroFamilyList = action.macroFamilyList.length
         ? action.macroFamilyList
         : [];
      results.callIdMacro = action.callId;
   }

   return updateObject(state, {
      results,
      isMacroComplete: true,
      isSearchChanged: false,
   });
};

const setClusterList = (state, action) => {
   let results = state.results !== null ? _.cloneDeep(state.results) : {};

   if (results.callId !== action.callId) {
      delete results.errorStatus;
   }

   if (!results.errorStatus) {
      results.callId = action.callId;
      results.clusterList = action.clusterList.length ? action.clusterList : [];
   }

   return updateObject(state, {
      results,
      isClusterComplete: true,
      isSearchChanged: false,
   });
};

const showErrTooManyEl = (state, action) => {
   return updateObject(state, {
      results: { errorStatus: action.err.status, callId: action.callId },
   });
};

const darkenSearchResults = (state) => {
   return updateObject(state, { isSearchChanged: true });
};

/** CREATE MODULE / MACRO / CLUSTER */
const setFiltersOptionsCluster = (state, action) => {
   return updateObject(state, {
      filtersOptionsCluster: action.filtersOptionsCluster,
   });
};

const setStoreIdOptions = (state, action) => {
   return updateObject(state, {
      filtersOptionsCluster: {
         ...state.filtersOptionsCluster,
         storeId: action.storeId,
      },
   });
};

/* get codice store sap **/
const getCodiceStoreSapRequest = (state) => {
   return updateObject(state, {
      isGetCodiceStoreSapEnded: false,
   });
};

const setCodiceStoreSap = (state, action) => {
   return updateObject(state, {
      filtersOptionsCluster: {
         ...state.filtersOptionsCluster,
         codiceStoreSap: action.codiceStoreSap,
      },
      isGetCodiceStoreSapEnded: true,
   });
};

const setAllMacroList = (state, action) => {
   return updateObject(state, { allMacroList: action.allMacroList });
};

const getFilterExportSuccess = (state, action) => {
   return updateObject(state, {
      exportFilters: action.exportFilters,
   });
};

const cleanExportFilters = (state) => {
   return updateObject(state, {
      exportFilters: initialExportFilters,
   });
};

const cleanSelectedExportFilters = (state) => {
   return updateObject(state, {
      exportSelectedFilters: initialExportFilters,
   });
};

/* CLEAR SEARCH */
const clearSearch = (state) => {
   return updateObject(state, {
      results: null,
      isClusterComplete: false,
      isModComplete: false,
      isMacroComplete: false,
   });
};

const changeSelectedFilters = (state, action) => {
   let selectedFilters = state.selectedFilters;
   let release = { ...selectedFilters.release };
   release.status = action.status;
   return updateObject(state, {
      selectedFilters: {
         ...selectedFilters,
         release: {
            ...release,
         },
      },
   });
};

/** EXPORT RESULTS */
const getExportSearchResultsRequest = (state) => {
   return updateObject(state, {
      isExportSearchLoading: true,
      isExportSearchEmpty: false,
   });
};

const getExportSearchResultsSuccess = (state, action) => {
   return updateObject(state, {
      exportResults: action.results,
      isExportSearchLoading: false,
      isExportSearchEmpty: !!action.results ? action.results.length === 0 : true,
   });
};

const getExportSearchResultsFailure = (state) => {
   return updateObject(state, {
      isExportSearchLoading: false,
      isExportSearchEmpty: false,
   });
};

const resetExportSearch = (state) => {
   return updateObject(state, {
      isExportSearchLoading: false,
      isExportSearchEmpty: false,
      exportResults: [],
   });
};

const setExportSelectedFilters = (state, action) => {
   return updateObject(state, {
      exportSelectedFilters: action.exportSelectedFilters,
   });
};

const setFilterExportIsLoading = (state, action) => {
   return updateObject(state, {
      isFiltersExportLoading: action.isLoading,
   });
};

const cleanSelectedFilters = (state) => {
   const selectedFilters = {
      release: null,
      countryList: [],
      brand: [],
      commodities: [],
      planningChannel: [],
      months: null,
   };
   return updateObject(state, {
      results: null,
      selectedFilters,
   });
};

//MF added
/* MASSIVE EDIT PARAM */
const massiveEditParam = (state, action) => {
   return updateObject(state, {
      selectedModules: action.data,
   });
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case actionTypes.SHOW_LOADER:
         return showLoader(state);
      case actionTypes.HIDE_LOADER:
         return hideLoader(state);
      case actionTypes.GET_ALL_COUNTRIES:
         return setCountryAllList(state, action);
      case actionTypes.GET_INITIALFILTERS_SUCCESS:
         return setInitialFilters(state, action);
      case actionTypes.GET_BRAND_SUCCESS:
         return setBrandList(state, action);
      case actionTypes.GET_COUNTRY_SUCCESS:
         return setCountryList(state, action);
      case actionTypes.GET_PLANNING_SUCCESS:
         return setPlanningList(state, action);

      case actionTypes.GET_MODULES_SUCCESS:
         return setModulesList(state, action);
      case actionTypes.GET_MACRO_SUCCESS:
         return setMacroFamilyList(state, action);
      case actionTypes.GET_CLUSTERS_SUCCESS:
         return setClusterList(state, action);
      case actionTypes.GET_HOME_FILTER_FAILURE_TO_MANY_EL:
         return showErrTooManyEl(state, action);

      case actionTypes.GET_FILTERS_OPTIONS_SUCCESS:
         return setFiltersOptionsCluster(state, action);
      case actionTypes.GET_STORE_ID_SUCCESS:
         return setStoreIdOptions(state, action);
      case actionTypes.GET_CODICE_STORE_SAP_REQUEST:
         return getCodiceStoreSapRequest(state);
      case actionTypes.GET_CODICE_STORE_SAP_SUCCESS:
         return setCodiceStoreSap(state, action);
      case actionTypes.SET_SELECTED_FILTERS:
         return setSelectedFilters(state, action);
      case actionTypes.SET_SELECTED_FILTERS_DISCONTINUED:
         return setSelectedFiltersDiscontinued(state, action);

      case actionTypes.SET_SAVABLE_PUBLISHABLE_MODULES:
         return setSavablePublishableModules(state, action);

      case actionTypes.SET_ROWS_TO_SEND_MASSIVE_EDIT:
         return setRowsToSendMassiveEdit(state, action);
      case actionTypes.SET_RESET_ROWS_TO_SEND_MASSIVE_EDIT:
         return setResetRowsToSendMassiveEdit(state, action);
      case actionTypes.TOGGLE_FILTER_HOME:
         return toggleFilters(state, action);
      case actionTypes.DARKEN_SEARCH_RESULTS:
         return darkenSearchResults(state);
      case actionTypes.GET_ALL_MACRO_SUCCESS:
         return setAllMacroList(state, action);
      case actionTypes.CLEAR_SEARCH:
         return clearSearch(state);
      case actionTypes.CHANGE_SELECTED_FILTERS:
         return changeSelectedFilters(state, action);
      case actionTypes.GET_EXPORT_SEARCH_RESULTS_REQUEST:
         return getExportSearchResultsRequest(state);
      case actionTypes.GET_EXPORT_SEARCH_RESULTS_SUCCESS:
         return getExportSearchResultsSuccess(state, action);
      case actionTypes.GET_EXPORT_SEARCH_RESULTS_FAILURE:
         return getExportSearchResultsFailure(state);
      case actionTypes.RESET_EXPORT_SEARCH:
         return resetExportSearch(state);
      case actionTypes.CLEAN_EXPORT_FILTERS:
         return cleanExportFilters(state);
      case actionTypes.GET_EXPORT_FILTER_SUCCESS:
         return getFilterExportSuccess(state, action);
      case actionTypes.SET_EXPORT_FILTER_ISLOADING:
         return setFilterExportIsLoading(state, action);
      case actionTypes.SET_EXPORT_SELECTED_FILTERS:
         return setExportSelectedFilters(state, action);
      case actionTypes.CLEAN_SELECTED_EXPORT_FILTERS:
         return cleanSelectedExportFilters(state);
      case actionTypes.MASSIVE_EDIT_MODULES:
         return massiveEditParam(state, action);
      case actionTypes.LOGOUT:
         return cleanSelectedFilters(state);

      default:
         return state;
   }
};

export default reducer;
