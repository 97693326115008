import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/Api';

const initialState = {
    releaseList: [],
    isReleaseClosed: false, 
    newRelease: null,
    isCreatingRelease: false
};

const changeStatusRelease = (state, action) => {    
    let isReleaseClosed = action.status === 'CLOSED' ? true : false;
    return updateObject( state, { isReleaseClosed });
};

const setReleaseList = (state, action) => {     
    return updateObject( state, { 
        releaseList: action.releaseList 
    });
};

const createReleaseRequest = (state) => {     
    return updateObject( state, { 
        isCreatingRelease: true 
    });
};

const createReleaseSuccess = (state, action) => {     
    return updateObject( state, { 
        releaseList: action.releaseList, 
        isCreatingRelease: false
    });
};

const createReleaseFailure = (state) => {     
    return updateObject( state, { 
        isCreatingRelease: false 
    });
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {          
        case actionTypes.SET_STATUS_RELEASE: return changeStatusRelease(state, action);            
        case actionTypes.SET_RELEASELIST_SUCCESS: return setReleaseList(state, action);
        case actionTypes.CREATE_RELEASE_REQUEST: return createReleaseRequest(state);            
        case actionTypes.CREATE_RELEASE_SUCCESS: return createReleaseSuccess(state, action);
        case actionTypes.CREATE_RELEASE_FAILURE: return createReleaseFailure(state);    

        default:
            return state;
    }
};

export default reducer;