import {
   CLASSIFICATION_MASSIVE_EDIT,
   CLUSTER_MASSIVE_EDIT,
   MODULE_MASSIVE_EDIT,
   UPLOAD_STATUS
} from "../../config";
import React, { useEffect, useState } from "react";

import Button from "../../components/UI-components/Button";
import Logo from "../../components/UI-components/Logo";
import MassiveEditClassificationWITable from "../../components/MassiveEditClassificationWITable";
import MassiveEditClusterWITable from "../../components/MassiveEditClusterWITable";
import MassiveEditModuleWITable from "../../components/MassiveEditModuleWITable";
import Popup from "../../components/UI-components/Popup";
import PopupGenericError from "../../components/Error-Handling/WithErrorHandling";
import RadioGrp from "../../components/UI-components/RadioGroup";
import { getCookie } from "../../services/cookie";
import { connect } from "react-redux";

const SECONDS_FOR_POLLING = 2;

// eslint-disable-next-line no-empty-pattern
const MassiveEditWI = (props) => {
   let pollingStatusTimeOut = false;
   const [showError, setShowError] = useState("");
   const radioButtonOptions = ["Module", "Cluster", "Classification"];
   //const [isModuleAssortmentType, setIsModuleAssortmentType] = useState(true);
   const [selectedTab, setSelectedTab] = useState(0)
   const [
      showMessageToChangeAssortmentType,
      setShowMessageToChangeAssortmentType,
   ] = useState(false);
   
   const [assortmentType, setAssortmentType] = useState("Module");
   const [showSaveModal, setShowSaveModal] = useState(false);
   const [showPublishModal, setShowPublishModal] = useState(false);
   const [isEmptyTable, setIsEmptyTable] = useState(true);
   const [tableData, setTableData] = useState([]);
   const [resetTableData, setResetTableData] = useState(false);
   const [isModuleTable, setIsModuleTable] = useState(true);
   const [showResultModdal, setShowResultModdal] = useState(false);
   const [uploadStatusData, setUploadStatusData] = useState({});
   const [idStatus, setIdStatus] = useState(null);
   const [refreshCount, setrefreshCount] = useState(0);
   const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
   const [forceCloseLoadingPopup, setForceCloseLoadingPopup] = useState(false);
   const [showGenericError, setShowGenericError] = useState(false);
   const [disableCtas, setDisableCtas] = useState(true);
   const [progressStatus, setProgressStatus] = useState(0);
   const preventReset = isModuleTable && assortmentType !== "Module";
   const preventReset2 = !isModuleTable && assortmentType !== "Cluster";

   useEffect(() => {
      // Used to reset the table when you make more than one edit and saved/Publish
      if (progressStatus === 0) {
         setResetTableData(false);
      }
   }, [progressStatus]);

   useEffect(() => {
      if (isModuleTable) {
         if (isModuleTable && assortmentType === "Module" && !preventReset) {
            setResetTableData(false);
         } else {
            setResetTableData(true);
         }
      }

      return () => {
         setResetTableData(true);
         setProgressStatus(0);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isModuleTable]);

   useEffect(() => {
      if (!isModuleTable) {
         if (!isModuleTable && assortmentType === "Cluster" && !preventReset2) {
            setResetTableData(false);
         } else {
            setResetTableData(true);
         }
      }

      return () => {
         setResetTableData(true);
         setProgressStatus(0);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isModuleTable]);

   const onEditRowTable = (rows, isEmpty, isModuleTable, disableCtas) => {
      setIsEmptyTable(isEmpty);
      setTableData(rows);
      setIsModuleTable(isModuleTable);
      setDisableCtas(disableCtas);
   };

   const isEditor = () => {
      const grants = props.grants[0]
      return grants.types.length === 2 && (grants.types.includes("view") && grants.types.includes("edit"))
   }

   useEffect(() => {
      if (idStatus && !pollingStatusTimeOut) {
         // eslint-disable-next-line react-hooks/exhaustive-deps
         pollingStatusTimeOut = setTimeout(() => {
            pollingStatus();
            clearTimeout(pollingStatusTimeOut);
         }, SECONDS_FOR_POLLING * 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [idStatus, refreshCount]);

   useEffect(() => {
      if (
         !showResultModdal &&
         progressStatus === 100 &&
         uploadStatusData !== null
      ) {
         setTimeout(() => {
            setShowResultModdal(true);
         }, 1000);
      }
   }, [
      forceCloseLoadingPopup,
      idStatus,
      progressStatus,
      showResultModdal,
      uploadStatusData,
   ]);

   const pollingStatus = () => {
      fetch(UPLOAD_STATUS + idStatus, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
         },
         cacheLife: 1,
         cachePolicy: "no-cache",
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            if (response.status === 404) {
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 403) {
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 401) {
               setShowError(response.statusText);
               return response.json();
            }
            if (response.status === 500) {
               setShowGenericError(true);
               return response.json();
            }
            return response.json();
         })
         .then((data) => {
            if (data && data !== null && data !== undefined) {
               setUploadStatusData(data);
               setProgressStatus(data.progress);
               if (data.progress >= 100) {
                  setIdStatus(null);
               } else {
                  setTimeout(() => {
                     setrefreshCount(refreshCount + 1);
                  }, 500); //polling elapse
               }
            } else {
               setUploadStatusData(null);
               setShowResultModdal(false);
               setShowGenericError(true);
            }
         })
         .catch((err) => {
            setShowGenericError(true);
            console.error(err);
         });
   };

   const handleMassiveEditResponse = (data) => {
      const status = data?.status
      const notAuthorizedItems = data?.notAuthorizedItems
      let errors = false;
      let errorLabel = ""

      // check edit errors
      const editErrors = notAuthorizedItems?.edit

      // check submit errors
      const submitErrors = notAuthorizedItems?.submit

      // check if submitErrors is not undefined (defined only if clicked on submit and publish)
      if (submitErrors?.length){
         errors = submitErrors.length > 0
         const moduleList = submitErrors.join(", ")
         errorLabel = `Submit error: cannot submit module${moduleList.length > 1 ? "s" : ""} ${moduleList}`
      } 
      if (editErrors?.length) {
         errors = editErrors.length > 0
         const moduleList = editErrors.join(", ")
         errorLabel = `Edit error: cannot edit module${moduleList.length > 1 ? "s" : ""} ${moduleList}`
      }

      if (status && !errors) {
         setIdStatus(status);
      } else {
         // build error
         setOpenLoadingPopup(false);
         setShowError(errorLabel)
      }
   }

   const saveData = () => {
      const dataToSend = getDataToSend(false);
      if (assortmentType === "Module") {
         fetch(MODULE_MASSIVE_EDIT, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            body: JSON.stringify(dataToSend),
            cacheLife: 1,
            cachePolicy: "no-cache",
         })
            .then((response) => {
               if (response.ok) {
                  return response.json();
               }
               if (response.status === 404) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 403) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 401) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 500) {
                  setOpenLoadingPopup(false);
                  setShowGenericError(true);
                  return response.json();
               }
               return response.json();
            })
            .then((data) => {
               handleMassiveEditResponse(data)
            })
            .catch((err) => {
               setOpenLoadingPopup(false);
               setShowGenericError(true);
               console.error(err);
            });
      }
      
      if (assortmentType === "Cluster") {
         fetch(CLUSTER_MASSIVE_EDIT, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            body: JSON.stringify(dataToSend),
            cacheLife: 1,
            cachePolicy: "no-cache",
         })
            .then((response) => {
               if (response.ok) {
                  return response.json();
               }
               if (response.status === 404) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 403) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 401) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 500) {
                  setOpenLoadingPopup(false);
                  setShowGenericError(true);
                  return response.json();
               }
               return response.json();
            })
            .then((data) => {
               handleMassiveEditResponse(data)
            })
            .catch((err) => {
               setOpenLoadingPopup(false);
               setShowGenericError(true);
               console.error(err);
            });
      }

      if (assortmentType === "Classification") {
         fetch(CLASSIFICATION_MASSIVE_EDIT, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            body: JSON.stringify(dataToSend),
            cacheLife: 1,
            cachePolicy: "no-cache",
         })
            .then((response) => {
               if (response.ok) {
                  return response.json();
               }
               if (response.status === 404) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 403) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 401) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 500) {
                  setOpenLoadingPopup(false);
                  setShowGenericError(true);
                  return response.json();
               }
               return response.json();
            })
            .then((data) => {
               handleMassiveEditResponse(data)
            })
            .catch((err) => {
               setOpenLoadingPopup(false);
               setShowGenericError(true);
               console.error(err);
            });
      }
   };

   const publishData = () => {
      const dataToSend = getDataToSend(true);
      if (assortmentType === "Module") {
         fetch(MODULE_MASSIVE_EDIT, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            body: JSON.stringify(dataToSend),
            cacheLife: 1,
            cachePolicy: "no-cache",
         })
            .then((response) => {
               if (response.ok) {
                  return response.json();
               }
               if (response.status === 404) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 403) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 401) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 500) {
                  setOpenLoadingPopup(false);
                  setShowGenericError(true);
                  return response.json();
               }
               return response.json();
            })
            .then((data) => {
               handleMassiveEditResponse(data)
            })
            .catch((err) => {
               setOpenLoadingPopup(false);
               setShowGenericError(true);
               console.error(err);
            });
      }

      if (assortmentType === "Cluster") {
         fetch(CLUSTER_MASSIVE_EDIT, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken"),
            },
            body: JSON.stringify(dataToSend),
            cacheLife: 1,
            cachePolicy: "no-cache",
         })
            .then((response) => {
               if (response.ok) {
                  return response.json();
               }
               if (response.status === 404) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 403) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 401) {
                  setOpenLoadingPopup(false);
                  setShowError(response.statusText);
                  return response.json();
               }
               if (response.status === 500) {
                  setOpenLoadingPopup(false);
                  setShowGenericError(true);
                  return response.json();
               }
               return response.json();
            })
            .then((data) => {
               handleMassiveEditResponse(data)
            })
            .catch((err) => {
               setOpenLoadingPopup(false);
               setShowGenericError(true);
               console.error(err);
            });
      }
   };

   const getDataToSend = (toPublish) => {
      let ret = null;
      if (selectedTab === 0) {
         ret = {
            massiveEditList: tableData.map((el) => {
               const dataToSend = {};

               if (el && el.module && el.module.length) {
                  const filteredModule = el.module.filter((a) => a !== "");

                  if (filteredModule.length) {
                     const result = filteredModule[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.moduleLabelList = finalResult;
                  } else {
                     dataToSend.moduleLabelList = [];
                  }
               }

               if (el.status) {
                  dataToSend.upcStatus = el.status[0];
               }

               if (el && el.month && el.month.length) {
                  const filteredMonth = el.month.filter((a) => a !== "");
                  if (filteredMonth.length) {
                     const result = filteredMonth[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.monthIdList = finalResult;
                  } else {
                     dataToSend.monthIdList = [];
                  }
               }

               if (el && el.add && el.add.length) {
                  const filteredAdd = el.add.filter((a) => a !== "");
                  if (filteredAdd.length) {
                     const skus = filteredAdd[0].split("\n");
               
                     const finalSkus = skus.filter((el) => {
                        return el;
                     });

                     dataToSend.upcToAddList = finalSkus;
                  } else {
                     dataToSend.upcToAddList = [];
                  }
               }

               if (el && el.remove && el.remove.length) {
                  const filteredRemove = el.remove.filter((a) => a !== "");

                  if (filteredRemove.length) {
                     const result = filteredRemove[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.upcToRemoveList = finalResult;
                  } else {
                     dataToSend.upcToRemoveList = [];
                  }
               }

               if (el && el.minValueSkuList && el.minValueSkuList.length) {
                  const filteredMinValueSkuList = el.minValueSkuList.filter((a) => a !== "");

                  if (filteredMinValueSkuList.length) {
                     const result = filteredMinValueSkuList[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.upcMinValueSkuList = finalResult;
                  } else {
                     dataToSend.upcMinValueSkuList = [];
                  }

                  if(el.minValueSku) {
                     dataToSend.upcMinValueSkuValue = el.minValueSku;
                  } else {
                     dataToSend.upcMinValueSkuValue = 1;
                  }
               }
               return dataToSend;
            }),
            toPublish: toPublish,
         };
      } else if(selectedTab === 1){
         ret = {
            rowsClusterMassiveEdit: tableData.map((el) => {
               const dataToSend = {};

               if (el && el.cluster && el.cluster.length) {
                  const filteredCluster = el.cluster.filter((a) => a !== "");

                  if (filteredCluster.length) {
                     const result = filteredCluster[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.clusterLabels = finalResult;
                  } else {
                     dataToSend.clusterLabels = [];
                  }
               }

               if (el && el.add && el.add.length) {
                  const filteredAdd = el.add.filter((a) => a !== "");

                  if (filteredAdd.length) {
                     const result = filteredAdd[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.moduleLabelsToAdd = finalResult;
                  } else {
                     dataToSend.moduleLabelsToAdd = [];
                  }
               }

               if (el && el.remove && el.remove.length) {
                  const filteredRemove = el.remove.filter((a) => a !== "");

                  if (filteredRemove.length) {
                     const result = filteredRemove[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.moduleLabelsToRemove = finalResult;
                  } else {
                     dataToSend.moduleLabelsToRemove = [];
                  }
               }

               if (el && el.addLia && el.addLia.length) {
                  const filteredAddLia = el.addLia.filter((a) => a !== "");

                  if (filteredAddLia.length) {
                     const result = filteredAddLia[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.liasToAdd = finalResult;
                  } else {
                     dataToSend.liasToAdd = [];
                  }
               }

               if (el && el.removeLia && el.removeLia.length) {
                  const filteredRemoveLia = el.removeLia.filter(
                     (a) => a !== ""
                  );

                  if (filteredRemoveLia.length) {
                     const result = filteredRemoveLia[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.liasToRemove = finalResult;
                  } else {
                     dataToSend.liasToRemove = [];
                  }
               }

               if (el && el.month && el.month.length) {
                  const filteredMonth = el.month.filter((a) => a !== "");

                  if (filteredMonth.length) {
                     const result = filteredMonth[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.months = finalResult;
                  } else {
                     dataToSend.months = [];
                  }
               }

               return dataToSend;
            }),
            toPublish: toPublish,
         };
      } else if(selectedTab === 2){
         ret={
            rowsClusterClassificationMassiveEdit: tableData.map((el) => {
               const dataToSend = {}

               if (el && el.cluster && el.cluster.length) {
                  const filteredCluster = el.cluster.filter((a) => a !== "");

                  if (filteredCluster.length) {
                     const result = filteredCluster[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.clusterLabels = finalResult;
                  } else {
                     dataToSend.clusterLabels = [];
                  }
               }

               if (el && el.addClassification && el.addClassification.length) {
                  const filteredAddClassifiction = el.addClassification.filter(
                     (a) => a !== ""
                  );

                  if (filteredAddClassifiction.length) {
                     const result = filteredAddClassifiction[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.classificationsToAdd = finalResult;
                  } else {
                     dataToSend.classificationsToAdd = [];
                  }
               }

               if (el && el.removeClassification && el.removeClassification.length) {
                  const filteredRemoveClassification = el.removeClassification.filter(
                     (a) => a !== ""
                  );

                  if (filteredRemoveClassification.length) {
                     const result = filteredRemoveClassification[0].split("\n");

                     const finalResult = result.filter((el) => {
                        return el;
                     });

                     dataToSend.classificationsToRemove = finalResult;
                  } else {
                     dataToSend.classificationsToRemove = [];
                  }
               }

               return dataToSend
            })
         };
      }
      return ret;
   };

   /*
   const selectedTab = () => {
      isModuleAssortmentType ? 0 : 1
   }
   */

   const renderTab = () => {
      if(selectedTab === 0){
         return(
            <MassiveEditModuleWITable
               resetTableData={resetTableData}
               onEditRow={onEditRowTable}
            />
         )
      }
      else if(selectedTab === 1){
         return(
            <MassiveEditClusterWITable
               resetTableData={resetTableData}
               onEditRow={onEditRowTable}
            />
         )
      }
      else if(selectedTab === 2){
         return(
            <MassiveEditClassificationWITable
               resetTableData={resetTableData}
               onEditRow={onEditRowTable}
            />
         )
      }
   }

   const resetStateAfterPopup = () => {
      if(assortmentType === "Module"){
         return [
            setSelectedTab(0),
            setShowMessageToChangeAssortmentType(false),
         ]
      }
      else if(assortmentType === "Cluster"){
         return [
            setSelectedTab(1),
            setShowMessageToChangeAssortmentType(false),
         ]
      }
      else if(assortmentType === "Classification"){
         return [
            setSelectedTab(2),
            setShowMessageToChangeAssortmentType(false),
         ]
      }
   }

   return (
      <div className="massive-edit-page">
         {showError && (
            <Popup
               popupClass="upload-popup-response"
               title="Error"
               close={() => setShowError("")}
            >
               {showError.split(";").map((str) => (
                  <>
                     <span>{str}</span>
                     <br />
                  </>
               ))}
            </Popup>
         )}
         {showGenericError && (
            <PopupGenericError
               showError={showGenericError}
               closeAlert={() => {
                  setShowGenericError(false);
               }}
               handleClick={() => {
                  setShowGenericError(false);
               }}
            />
         )}
         {showMessageToChangeAssortmentType && (
            <Popup
               contextTitle="Attention!"
               title="Warning: if you have not saved and switch between modes, you will lose any changes you have made."
               subtitle=" Do you want to confirm the switch?"
               close={() => {
                  // eslint-disable-next-line no-unused-expressions
                  const res = resetStateAfterPopup()
                  return [...res, setResetTableData(true)]
               }}
            >
               <div className="massive-edit-page-popup__btn--container">
                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        resetStateAfterPopup()
                     }}
                  >
                     CANCEL
                  </span>

                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        resetStateAfterPopup()
                     }}
                  >
                     CONFIRM
                  </span>
               </div>
            </Popup>
         )}
         {showSaveModal && (
            <Popup
               contextTitle="Save Massive Edit"
               title="Are you sure you want to save the changes? If you confirm the operation you will be irreversible."
               close={() => {
                  setShowSaveModal(false);
               }}
            >
               <div className="massive-edit-page-popup__btn--container">
                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setShowSaveModal(false);
                     }}
                  >
                     CANCEL
                  </span>

                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setOpenLoadingPopup(true);
                        saveData();
                        setShowSaveModal(false);
                     }}
                  >
                     CONFIRM
                  </span>
               </div>
            </Popup>
         )}
         {openLoadingPopup ? (
            <Popup
               close={() => {
                  setOpenLoadingPopup(false);
               }}
               contextTitle="Massive edit process"
            >
               <div>
                  {uploadStatusData ? (
                     <div>
                        <div className="progress-bar-container">
                           <div
                              className="progress-bar-container--progress"
                              style={{ width: `${progressStatus}%` }}
                           />
                        </div>

                        <div>{progressStatus}%</div>
                     </div>
                  ) : null}
               </div>
            </Popup>
         ) : null}

         {showResultModdal ? (
            <Popup
               contextTitle="Result"
               close={() => {
                  setOpenLoadingPopup(false);
                  setShowResultModdal(false);
                  setForceCloseLoadingPopup(true);
                  setProgressStatus(0);
                  setResetTableData(true);
               }}
            >
               {uploadStatusData.response !== null &&
               uploadStatusData.response?.split(";") ? (
                  uploadStatusData.response.split(";").map((str) => (
                     <>
                        <span>{str}</span>
                        <br />
                     </>
                  ))
               ) : (
                  <span>{uploadStatusData.response}</span>
               )}
               {uploadStatusData.response === "" ||
               uploadStatusData.response === null ||
               uploadStatusData.response === undefined ? (
                  <span>{uploadStatusData.status}</span>
               ) : null}
            </Popup>
         ) : null}

         {showPublishModal && (
            <Popup
               title="Are you sure you want to publish the changes? If you confirm the operation you will be irreversible."
               contextTitle="Save & Publish Massive Edit"
               close={() => {
                  setShowPublishModal(false);
               }}
            >
               <div className="massive-edit-page-popup__btn--container">
                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setShowPublishModal(false);
                     }}
                  >
                     CANCEL
                  </span>

                  <span
                     className="massive-edit-page-popup__btn--label"
                     onClick={() => {
                        setOpenLoadingPopup(true);
                        publishData();
                        setShowPublishModal(false);
                     }}
                  >
                     CONFIRM
                  </span>
               </div>
            </Popup>
         )}
         <header>
            <Logo />
            <h2>Massive Edit</h2>
         </header>

         <div className="content">
            <div className="MassiveEditWi__selection--type__container">
               <span className="MassiveEditWi__selection--type--label">
                  Choose assorment type
               </span>
               <div className="massive-edit-page__assortment-switch-container-page">
                  <RadioGrp
                     className="MassiveEditWi__selection--type__radio"
                     selectedIndex={selectedTab}
                     options={radioButtonOptions}
                     active={true}
                     handleClick={(selectedOption) => {
                        if (
                           (assortmentType !== selectedOption &&
                              !isEmptyTable) ||
                           (tableData.length > 1 && isEmptyTable)
                        ) {
                           setShowMessageToChangeAssortmentType(true);
                           setAssortmentType(selectedOption);
                        }
                        if (
                           isEmptyTable &&
                           !(tableData.length > 1 && isEmptyTable)
                        ) {
                           if(selectedOption === "Module"){
                              //setAssortmentType(selectedOption);
                              setSelectedTab(0)
                              setAssortmentType("Module")
                           }
                           else if(selectedOption === "Cluster"){
                              setSelectedTab(1)
                              setAssortmentType("Cluster")
                           }
                           else if(selectedOption === "Classification"){
                              setSelectedTab(2)
                              setAssortmentType("Classification")
                           }
                           setAssortmentType(selectedOption);
                           setShowMessageToChangeAssortmentType(false);
                           setResetTableData(true);
                        }
                     }}
                  />
               </div>
            </div>
            <div className="massive-edit-table-container">
               {
                  renderTab()
               }
            </div>
         </div>
         <div className="MassiveEditWi__cta--container">
            <Button
               disabled={disableCtas}
               handleClick={() => {
                  setShowSaveModal(true);
               }}
            >
               Save All
            </Button>
            {
               selectedTab < 2 &&
                  <Button
                     disabled={disableCtas || isEditor()}
                     handleClick={() => {
                        setShowPublishModal(true);
                     }}
                  >
                     Save & Publish All
                  </Button>
            }
         </div>
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      grants: state.authentication.grants
   }
}

export default connect(mapStateToProps, () => {})(MassiveEditWI);
