import React, { useEffect } from "react";

import CustomIcon from "../UI-components/CustomIcon";

const Placeholder = (props) => {

  let className = () => {
    if (props.isBestSeller) {
      return props.isEl ? "eligible" : "must-have";
    } else return "";
  };
  const id = props.idModule + `placeholder ${props.isEl ? 'el' : 'mh'}` + props.idDragg

  return (
    <>
      <div
        id={props.idDragg}
        className={"placeholder " + className()}
        onDragOver={props.onDragOver}
        onDrop={props.onDrop}
      >
        <CustomIcon iconClass="icon-plus" />
        <p id={id}>Add Frame</p>
      </div>
      {props.placeholderScroll && <ScrollTo id={id} />}
    </>
  );
};
export default Placeholder;

export const ScrollTo = ({ id }) => {

  useEffect(() => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [id])

  return null
}
