import {
    GET_ALL_USERS,
    GET_BRANDS_WITH_USERS
} from "../../config.js"
import React, { useEffect, useState, useCallback } from "react"

import CustomIcon from "../UI-components/CustomIcon"
import CustomInput from "../UI-components/CustomInput.js"
import Loader from "../UI-components/Loader.js"
import Logo from "../UI-components/Logo"
import ManageBrandPopup from "./ManageBrandPopup/ManageBrandPopup.js"
import axios from "axios"
import { getCookie } from "../../services/cookie"
import { cloneDeep, sortBy } from "lodash"

const BrandRow = ({
    brand,
    toggleShowBrandPopup
}) => {
    return (
        <ul
            className="table-item user-item"
        >
            <li className="email">
                <div className="inner-icon-black">
                    <CustomIcon iconClass="icon-star" />
                </div>
                <div className="inner-email">{brand.brand}</div>
            </li>
            <li className="add">
                <div className="inner-add-bottom">
                    <div
                        className="icon-container-bottom"
                        onClick={() => {
                            toggleShowBrandPopup(brand);
                        }}
                    >
                        <CustomIcon iconClass="icon-arrow2 user-table-icon" />
                    </div>
                </div>
            </li>
        </ul>
    )
}

const ManageBrand = () => {

    const [brandList, setBrandList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentBrand, setCurrentBrand] = useState(undefined);
    const [queryBrand, setQueryBrand] = useState("");
    const [queryUser, setQueryUser] = useState("");

    const orderBrandList = (brandList) => {
        return sortBy(brandList, 'brand');
    }

    const searchUserResults = () => {
        return userList.filter(user => {
            return (user.firstName.toLowerCase().includes(queryUser.toLowerCase()) ||
                    user.lastName.toLowerCase().includes(queryUser.toLowerCase())
            )
        })
    }

    useEffect(() => {

        const brandPromise = axios.get(GET_BRANDS_WITH_USERS, {
            headers: {
                xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken")
            },
        })

        const userPromise = axios.get(GET_ALL_USERS, {
            headers: {
                xAuthLuxotticaToken: getCookie("xAuthLuxotticaToken")
            },
        })

        Promise.all([brandPromise, userPromise])
        .then(responses => {
            const brands = responses[0].data;
            const users = responses[1].data;
            setBrandList(orderBrandList(brands));
            setUserList(users);
        })
        .catch(err => console.error(err))
        .finally(() => {
            setIsLoading(false);
        })
    }, [])

    const toggleShowBrandPopup = (brand) => {
        setCurrentBrand(currentBrand => {
            if (currentBrand) {
                setQueryUser("")
                return undefined
            }
            return brand
        })
    };

    const handleQueryBrand = (e) => {
        const queryBrand = e.target.value
        setQueryBrand(queryBrand)
    }

    const handleQueryUser = (e) => {
        const queryUser = e.target.value
        setQueryUser(queryUser)
    }

    const handleSearch = () => {
        if(queryBrand){
            return brandList.filter(elem => {
                return elem.brand.toLowerCase().includes(queryBrand.toLowerCase())
            })
        }
        return brandList
    }

    const updateBrandList = useCallback((newBrand) => {
        let newBrandList = cloneDeep(brandList)
        newBrandList = newBrandList.map(brand => {
            if(brand.brand === newBrand.brand){
                return newBrand
            }
            return brand
        })
        setBrandList(orderBrandList(newBrandList))
    }, [brandList])
    
    const displayContent = (resultList) => {
        if (isLoading) {
            return (
                <div className="full-page">
                    <Loader />
                </div>
            )
        }
        else {
            if (resultList.length === 0) {
                return <p>No brands found.</p>
            }
            else {
                return (
                    resultList.map(elem => {
                        return (
                            <BrandRow
                                brand={elem}
                                key={elem.brand}
                                toggleShowBrandPopup={toggleShowBrandPopup}
                            />
                        )
                    })
                )
            }
        }
    }

    return (
        <div className="module-comp">
            <header className="minimal-header">
                <Logo />
                <h2>Manage Brands</h2>
            </header>
            <div className="manage-page-container">
                <div className="col-12 user-manage-container">
                    <div className="table-container">
                        <div className="table-header">
                            <div className="search-container-table">
                                <div className="input-container inner-box-email">
                                    <div className="inner-input">
                                        <p className="user-p">Brand name</p>
                                        <CustomInput
                                            inputClass="input-big"
                                            placeholder="Search brand"
                                            handleChange={handleQueryBrand}
                                            value={queryBrand}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-body">
                            <div className="table-body-container">
                                {
                                    displayContent(handleSearch())
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    currentBrand &&
                    <ManageBrandPopup
                        close={toggleShowBrandPopup}
                        currentBrand={currentBrand}
                        users={searchUserResults()}
                        updateBrandList={updateBrandList}
                        handleQueryUser={handleQueryUser}
                        queryUser={queryUser}
                    />
                }
            </div>
        </div>
    )
}

export default ManageBrand